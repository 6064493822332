import React from 'react';
import { getImageUrlWithTemplate } from '../../helpers/image';
import avatarImagePlaceholder from './../../../static/images/default-avatar.svg';
import styles from './Avatar.scss';

type Props = {
  src?: string;
  alt?: string;
  template?: string;
  size?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'totaliser'
    | 'share-prompt-profile'
    | 'share-prompt-profile-mobile';
  className?: string;
};

export function OwnerAvatar(props: Props) {
  return <Avatar template="CrowdfundingOwnerAvatar" {...props} />;
}

export function SupporterAvatar(props: Props) {
  return <Avatar template="CrowdfundingSupporterAvatar" {...props} />;
}

export function Avatar({
  src,
  alt,
  size = 'medium',
  template,
  className = '',
}: Props) {
  const imagePath =
    getImageUrlWithTemplate(src, template) || avatarImagePlaceholder;

  return (
    <img
      className={`${styles.avatar} ${styles[size]} ${className}`}
      src={imagePath}
      alt={alt}
    />
  );
}
