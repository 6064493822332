import { InitOptions } from 'i18next';

const i18nConfig: InitOptions = {
  whitelist: ['en', 'en-GB', 'en-US'],
  fallbackLng: 'en',
  ns: ['common'],
  defaultNS: 'common',
  keySeparator: false,
  debug: false,
};

export default i18nConfig;
