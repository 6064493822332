import * as React from 'react';
import { Formik, Form, FormikBag } from 'formik';
import {
  AnalyticsLoadingButton as LoadingButton,
  AnalyticsPageView,
} from 'src/components/Analytics';
import { FormattedMessage } from 'react-intl';
import Alert from 'src/components/Alert';
import StripeVerificationDocumentUpload from './StripeVerificationDocumentUpload';
import { processImage } from 'src/helpers/image';
import ImageEditor, {
  CropData,
} from 'src/components/CoverPhotoEditor/ImageEditor';
import { submitStripeVerificationDocument } from 'src/redux/modules/stripeVirtualAccount/actions';
import config from 'config';

export interface Props {
  submitStripeVerificationDocument: typeof submitStripeVerificationDocument;
  onSubmit: () => void;
  pageSection?: string;
  onCropChange?: (data: CropData) => void;
  onRemoveImage?: () => void;
  onRotateImage?: (data: CropData) => void;
  cropData?: CropData;
  updateCrop: (cropData: CropData) => void;
  rotateImage: (cropData: CropData) => void;
  removeImage: () => void;
}

interface State {
  areTipsShowing: boolean;
  image: string;
  errorType: string;
}

class StripeVerificationDocumentForm extends React.Component<Props, State> {
  fileInput: HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      areTipsShowing: false,
      image: '',
      errorType: '',
    };
  }

  handleSubmit = async (_: any, formikBag: FormikBag<Props, {}>) => {
    try {
      await this.props.submitStripeVerificationDocument(
        this.state.image as string,
        this.props.cropData as CropData
      );
      this.props.onSubmit();
    } catch (error) {
      formikBag.setSubmitting(false);
      formikBag.setStatus(error);
      this.setState({ errorType: 'submitFailureAlert' });
    }
  };

  toggleTips = () => {
    this.setState({ areTipsShowing: !this.state.areTipsShowing });
  };

  handleImage = async (pic: File) => {
    const result = await processImage(pic, { maxSize: config.MAX_IMAGE_SIZE });

    if (result.success) {
      this.setState({ image: result.image });
    } else {
      this.setState({
        errorType: 'imageTooLarge',
      });
    }
  };

  removeImage = () => {
    this.props.removeImage();
    this.setState({ image: '' });
  };

  rotateImage = (cropData: CropData) => {
    this.props.rotateImage(cropData);
  };

  render() {
    const hasUserSelectedImage = this.state.image.length > 1 ? true : false;

    return (
      <AnalyticsPageView
        eventValue="verify identity - government-issued id"
        pageSection="admin > verify identity"
        subtype="admin"
      >
        <div className="container jg-space-phsm jg-space-ptsm jg-space-phxxl@md">
          <Formik
            initialValues={{
              stripeVerificationDocument: null,
            }}
            onSubmit={this.handleSubmit}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <div className="jg-text--center jg-space-mbmd">
                    <p className="jg-text--large">
                      <b>
                        <FormattedMessage
                          id="StripeVerificationDocumentForm.pleaseUploadImage"
                          defaultMessage={`Please upload a scan or photograph of a government-issued photo ID`}
                        />
                      </b>
                    </p>
                    {!hasUserSelectedImage ? (
                      <StripeVerificationDocumentUpload
                        areTipsShowing={this.state.areTipsShowing}
                        toggleTips={this.toggleTips}
                        error={this.state.errorType}
                        addPhoto={this.handleImage}
                        removeImage={this.removeImage}
                      />
                    ) : (
                      <AnalyticsPageView
                        eventValue="verify identity - government-issued id > upload"
                        pageSection="admin > verify identity > government-issue id"
                        subtype="admin"
                      >
                        <div className="jg-space-mtmd">
                          <ImageEditor
                            image={this.state.image as string}
                            position={this.props.cropData!.position}
                            scale={this.props.cropData!.scale}
                            rotate={this.props.cropData!.rotate}
                            pageSection="verify your identity > verification document"
                            onCropChange={this.props.updateCrop}
                            onRemoveImage={this.removeImage}
                            onRotateImage={this.rotateImage}
                          />
                        </div>
                      </AnalyticsPageView>
                    )}
                  </div>
                  <div className="jg-space-phlg@md">
                    <div>
                      {this.state.errorType === 'submitFailureAlert' && (
                        <div className="jg-space-mtsm jg-space-mvmd">
                          <Alert
                            id="stripe-verification-document-alert"
                            type="red"
                            displayType="inline"
                            persistent
                          >
                            <FormattedMessage
                              id="StripeVerificationDocumentForm.submitFailureAlert"
                              defaultMessage="An error has occurred. Please try again."
                            />
                          </Alert>
                        </div>
                      )}
                      {hasUserSelectedImage && (
                        <LoadingButton
                          loading={isSubmitting}
                          className="jg-btn jg-btn--brand jg-space-mbms"
                          value={
                            <FormattedMessage
                              id="StripeVerificationDocumentForm.saveAndContinue"
                              defaultMessage={`Save and continue`}
                            />
                          }
                          analyticsOptions={{
                            event: 'link click',
                            eventValue: 'save and continue',
                            pageSection:
                              'admin > verify identity > government-issue id',
                            subtype: 'button',
                          }}
                        />
                      )}

                      <p className="jg-text--xsmall jg-color--nobel jg-text--center">
                        <FormattedMessage
                          id="StripeVerificationDocumentForm.disclaimerText"
                          defaultMessage={`JustGiving and the third party that helps us with identity verification will keep your information secure and will only use it for the purposes of verifying your identity`}
                        />
                      </p>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </AnalyticsPageView>
    );
  }
}

export default StripeVerificationDocumentForm;
