import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import CharacterCount from '@justgiving/character-count';
import { ValidationResult } from 'src/helpers/validation';
import { Field } from 'formik';
import styles from './TextareaWithCharacterCount.scss';

interface Props {
  characterLimit: number;
  error?: string;
  id: string;
  labelClassName?: string;
  labelDefaultMessage?: string;
  labelId?: string;
  onChange: (e: ChangeEvent<any>) => void;
  textareaClassName?: string;
  touched?: boolean;
  value: any;
  showLabel?: boolean;
  rows?: number;
  hideTextAreaBorder?: boolean;
  placeholder?: string;
}

const TextareaWithCharacterCount: React.StatelessComponent<Props> = ({
  characterLimit,
  error,
  id,
  labelClassName,
  labelDefaultMessage,
  labelId,
  textareaClassName = '',
  touched,
  value = '',
  showLabel = true,
  rows = 10,
  hideTextAreaBorder = false,
  placeholder = '',
}) => {
  return (
    <div className="jg-form-group">
      {showLabel &&
        labelId && (
          <label className={`jg-form-label ${labelClassName}`} htmlFor={id}>
            <FormattedMessage
              id={labelId}
              defaultMessage={labelDefaultMessage}
            />
          </label>
        )}

      <CharacterCount
        maxLength={characterLimit}
        value={value}
        children={({ charactersRemaining, isValid }) => {
          return (
            <div className="jg-relative">
              <Field
                id={id}
                name={id}
                component="textarea"
                className={`jg-form-control ${textareaClassName} ${
                  touched && error ? 'jg-form-control--error' : ''
                }
                ${hideTextAreaBorder ? 'jg-bdn' : ''}`}
                rows={rows}
                placeholder={placeholder}
              />

              <div
                className={`
                jg-text--small-light jg-text--pull-right
                 ${styles['character-count']}
                 ${!isValid ? 'jg-color--safety-orange' : ''}
                `}
              >
                {charactersRemaining}
              </div>
            </div>
          );
        }}
      />
      <ValidationResult touched={touched} error={error ? error : undefined} />
    </div>
  );
};

export default TextareaWithCharacterCount;
