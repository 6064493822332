import React from 'react';

interface Props {
  addCensure: () => void;
  className: string;
}

class ReportPage extends React.Component<Props> {
  handleCensure = () => {
    const { addCensure } = this.props;
    addCensure();
  };

  render() {
    const { className } = this.props;

    return (
      <a
        target="_blank"
        rel="noreferrer noopener"
        onClick={this.handleCensure}
        href="https://help.justgiving.com/hc/en-us/articles/202895531-JustGiving-Crowdfunding-Page-Guidelines"
        className={className}
      >
        Report this Page
      </a>
    );
  }
}

export default ReportPage;
