import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './RichUpdatesNavIcon.scss';
import Svg from '@justgiving/svg';
import update from '@justgiving/icons/lib/update.svg';

interface Props {
  isActive: boolean;
}

const RichUpdatesNavIcon: React.StatelessComponent<Props> = ({ isActive }) => (
  <div
    className={`jg-space-mrms ${styles['update-link']} ${
      isActive ? styles.active : styles.inactive
    }`}
  >
    <Svg className={`jg-fill--white ${styles.icon}`} markup={update} />
  </div>
);

export default CSSModules(RichUpdatesNavIcon, styles);
