import 'spin.js';

import React from 'react';
import classNames from 'classnames';

interface Props {
  loading?: boolean;
  value: string | JSX.Element;
  className?: string | string[];
  id?: string;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  color?: 'purple' | 'white' | 'grey' | 'blue';
}

const LoadingButton: React.StatelessComponent<Props> = ({
  id,
  value,
  onClick,
  disabled = false,
  type,
  className,
  loading = false,
  color = 'white',
}) => (
  <button
    id={id}
    className={classNames('jg-btn', className)}
    onClick={onClick}
    disabled={disabled || loading}
    type={type}
  >
    {loading ? (
      <div className={`jg-loading--small jg-loading--${color}`}>
        <svg
          className="jg-loading__container"
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle className="jg-loading__circle" cx="24" cy="24" r="20" />
        </svg>
      </div>
    ) : (
      value
    )}
  </button>
);

export default LoadingButton;
