import React from 'react';
import styles from './WhatsAppShareButton.scss';
import CSSModules from 'react-css-modules';
import { ShareActionOptions } from 'src/redux/modules/page';
import WhatsAppShare from '../SocialShare/WhatsAppShare';

export interface Props {
  shareByWhatsApp: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  isActive: boolean;
  isOwner: boolean;
  targetCurrency: Currency;
  pageSection: string;
  ownerName: string;
}

const WhatsAppShareButton: React.StatelessComponent<Props> = ({
  pageSection,
  shareByWhatsApp,
  socialShareUrl,
  targetAmount,
  pitchFor,
  isActive,
  targetCurrency,
  ownerName,
  isOwner,
}) => {
  return (
    <div styleName="share-button-container">
      <WhatsAppShare
        ownerName={ownerName}
        shareByWhatsApp={shareByWhatsApp}
        socialShareUrl={socialShareUrl}
        targetAmount={targetAmount}
        pitchFor={pitchFor}
        isActive={isActive}
        targetCurrency={targetCurrency}
        location={pageSection}
        className={styles.whatsapp}
        buttonText="WhatsApp"
        messageType={isOwner ? 'ownerShare' : 'supporterShare'}
      />
    </div>
  );
};

export default CSSModules(WhatsAppShareButton, styles);
