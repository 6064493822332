import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'src/components/Analytics';
import SuspensionCountdown from '../SuspensionCountdown';

interface Props {
  pageName: string;
  suspensionDate: string | Date;
}

const AddYourBankDetailsContent: React.StatelessComponent<Props> = ({
  pageName,
  suspensionDate,
}) => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-6 offset-md-3">
        <SuspensionCountdown suspensionDate={suspensionDate} />
        <h2>
          <FormattedMessage
            id="AddYourBankDetailsContent.addBankDetails"
            defaultMessage="You need to add your bank details"
          />
        </h2>
        <p className="jg-space-mbml">
          <FormattedMessage
            id="AddYourBankDetailsContent.addYourBankAccountPlease"
            defaultMessage="Now your Page is up and running, it’s time to let us know where you want the money you've raised to be sent. The timer above shows how long you've got left. To avoid your donations being refunded, add your bank details now."
          />
        </p>
        <div className="row">
          <Link
            to={`/${pageName}/funds/account`}
            className={`jg-btn jg-btn--brand col-xs-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3`}
            analyticsOptions={{
              event: 'link click',
              eventValue: 'add my bank details now',
              pageSection: 'admin > page view > prompt > add bank details',
              subtype: 'button',
            }}
          >
            <FormattedMessage
              id="AddYourBankDetailsContent.addBankDetailsButton"
              defaultMessage="Add my bank details now"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AddYourBankDetailsContent;
