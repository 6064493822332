export const currencyMap: Record<Currency, CurrencyPrefix> = {
  AUD: '$',
  GBP: '£',
  USD: '$',
};

type CurrencyPenny = '¢' | 'p';

export const currencyMapToPenny: Record<Currency, CurrencyPenny> = {
  AUD: '¢',
  GBP: 'p',
  USD: '¢',
};

export const currencyToCountryCodeMap: Record<Currency, Country> = {
  AUD: 'AU',
  GBP: 'GB',
  USD: 'US',
};

interface CurrencyName {
  code: Currency;
  name: string;
}

export const countryToCurrencyMap: Record<Country, CurrencyName> = {
  AU: { code: 'AUD', name: '$ - Australian Dollar' },
  GB: { code: 'GBP', name: '£ - Great British Pound' },
  US: { code: 'USD', name: '$ - United States Dollar' },
};

export interface SupportedCurrency {
  country: Country;
  currency: CurrencyName;
}

export const isValidCurrency = (currency: Currency | '') => {
  return currency && currencyToCountryCodeMap[currency];
};

export default currencyMap;
