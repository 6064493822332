import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from 'src/redux/modules';
import {
  shareByEmail,
  shareByWhatsApp,
  shareOnMessenger,
  shareOnTwitter,
  sharePageLink,
  ShareActionOptions,
} from 'src/redux/modules/page';
import { closeShareWithFriendsPrompts } from 'src/redux/modules/shareWithFriendsPrompts';
import ShareWithFriendsPrompts from 'src/components/ShareWithFriendsPrompts/ShareWithFriendsPrompts';
import currencyMap from 'src/helpers/currencyMap';
import { isActive } from 'src/helpers/page';
import { push } from 'react-router-redux';

interface StateProps {
  isActive: boolean;
  raised: number;
  targetAmount: number;
  pitchFor: string;
  ownerName: string;
  isOwner: boolean;
  isShareWithFriendsPromptsOpen: boolean;
  socialShareUrl: string;
  targetCurrency: Currency;
  pageSection: string;
  avatarImage?: string;
  pageName: string;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    isActive: isActive(state.page),
    raised: state.page.amountRaised,
    targetAmount: state.page.targetAmount,
    pitchFor: state.page.pitchFor,
    ownerName: state.page.ownerName,
    isOwner: state.page.isOwnerRequested,
    isShareWithFriendsPromptsOpen:
      state.shareWithFriendsPrompts.isShareWithFriendsPromptsOpen,
    socialShareUrl: state.page.socialShareUrl,
    targetCurrency: state.page.targetCurrency,
    pageSection: state.shareWithFriendsPrompts.pageSection,
    avatarImage: state.page.ownerProfileImageUrl,
    pageName: state.page.name,
  };
}

interface DispatchProps {
  shareByEmail: (options: ShareActionOptions) => void;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareOnTwitter: (options: ShareActionOptions) => void;
  shareOnMessenger: (options: ShareActionOptions) => void;
  sharePageLink: (options: ShareActionOptions) => void;
  closeShareWithFriendsPrompts: () => void;
  push: (location: any) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      shareByEmail,
      shareByWhatsApp,
      shareOnMessenger,
      shareOnTwitter,
      sharePageLink,
      closeShareWithFriendsPrompts,
      push,
    },
    dispatch
  );
}

type Props = StateProps & DispatchProps;

export class ShareWithFriendsPromptsContainer extends React.Component<Props> {
  render() {
    if (!this.props.isShareWithFriendsPromptsOpen) {
      return null;
    }

    return (
      <ShareWithFriendsPrompts
        isActive={this.props.isActive}
        raised={this.props.raised}
        shareByEmail={this.props.shareByEmail}
        socialShareUrl={this.props.socialShareUrl}
        targetAmount={this.props.targetAmount}
        targetCurrency={this.props.targetCurrency}
        pitchFor={this.props.pitchFor}
        ownerName={this.props.ownerName}
        isOwner={this.props.isOwner}
        shareByWhatsApp={this.props.shareByWhatsApp}
        currencySymbol={
          currencyMap[this.props.targetCurrency] || currencyMap.GBP
        }
        sharePageLink={this.props.sharePageLink}
        closeSharePrompts={this.props.closeShareWithFriendsPrompts}
        pageSection={this.props.pageSection}
        push={this.props.push}
        pageName={this.props.pageName}
        shareOnMessenger={this.props.shareOnMessenger}
        shareOnTwitter={this.props.shareOnTwitter}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareWithFriendsPromptsContainer);
