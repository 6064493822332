import fromPairs from 'lodash/fromPairs';
import { getOrThrow } from './common';

interface UserResource {
  id: string;
  profileImage: string;
}

export interface AvatarsResponse {
  [id: string]: string;
}

export async function getAvatars(userIds: string[]): Promise<AvatarsResponse> {
  // TODO: expose an endpoint in the API that will allow to do this in one call
  const promises = userIds.map(userId =>
    getOrThrow<UserResource>(`users/${userId}`)
  );
  const users = await Promise.all(promises);

  return fromPairs(users.map(u => [u.id, u.profileImage]));
}
