import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import ShareWithFriendsButton from './ShareWithFriendsButton';
import { openShareWithFriendsPrompts } from 'src/redux/modules/shareWithFriendsPrompts';
import {
  isLocalStorageAvailable,
  isPropertyInLocalStorage,
} from 'src/helpers/browser';
import styles from 'src/components/Prompt/ShareWithFriends/ShareWithFriends.scss';
import { AnalyticsPageView, Button } from 'src/components/Analytics';
import { Trans } from 'react-i18next';
import moment from 'moment';
import ClickableElement from 'src/components/ClickableElement';

const ShareWithFriends: Rule<JSX.Element, PromptRulesEngineOptions> = (
  state,
  options
) => {
  const { id, status } = state.page;

  const pageSection = 'admin > page creation > share your page with friends';
  const localStorageKey = `prompt:${id}_shareWithFriends:close`;
  const dateFormat = 'YYYY-MM-DD HH:mm';
  const isInLocalStorage = () =>
    isLocalStorageAvailable() && isPropertyInLocalStorage(localStorageKey);
  const setInLocalStorage = (value: string) =>
    isLocalStorageAvailable() && localStorage.setItem(localStorageKey, value);
  const getFromLocalStorage = () =>
    String(localStorage.getItem(localStorageKey));

  const isPromptDismissed = () => {
    if (isInLocalStorage()) {
      const value = getFromLocalStorage();
      const dateValue = moment.utc(value, dateFormat);
      if (dateValue.isValid()) {
        return moment.utc().isBefore(dateValue);
      }
      return true;
    }
    return false;
  };

  const close = () => {
    setInLocalStorage('true');
    options!.closePrompt();
  };

  const delay = () => {
    setInLocalStorage(
      moment
        .utc()
        .add(24, 'hours')
        .format(dateFormat)
        .toString()
    );
    options!.closePrompt();
  };

  const openPrompt = () => {
    options!.dispatch(openShareWithFriendsPrompts(pageSection));
  };

  if (status === 'Active' && !isPromptDismissed()) {
    return {
      matched: true,

      result: (
        <AnalyticsPageView
          eventValue="prompt displayed"
          pageSection={pageSection}
          subtype="admin"
        >
          <section
            className={`${
              styles.prompt
            } jg-bdb jg-bd--solid jg-bd--very-light-grey jg-text--center`}
          >
            <div className="container">
              <div className={`${styles.people} row`}>
                <div className="col-md-1 col-lg-3 col-xl-3" />
                <div
                  className={`col-md-10 col-lg-7 col-xl-6 ${
                    styles['col-info']
                  }`}
                >
                  <div
                    className="jg-background--white
                                 jg-space-ptmd jg-space-ptml@md
                                 jg-space-pbmd jg-space-pbml@md
                                 jg-space-plml jg-space-prml
                                 jg-bd jg-bd--solid jg-bd--very-light-grey jg-bd--radius-3px"
                  >
                    <Button
                      className={`${styles['grey-cross']} jg-space-mrsm`}
                      onClick={close}
                      analyticsOptions={{
                        event: 'click',
                        eventValue: 'x',
                        pageSection,
                        subtype: 'button',
                      }}
                    />
                    <div
                      className={`jg-display-flex jg-flex-column jg-flex-ais ${
                        styles.messages
                      }`}
                    >
                      <p className="jg-h5">
                        <Trans i18nKey="ShareWithFriends.title" />
                      </p>
                      <p className={`jg-text--label ${styles['prompt-text']}`}>
                        <Trans i18nKey="ShareWithFriends.text" />
                      </p>
                    </div>
                    <div className="jg-display-flex jg-flex-row row">
                      <div className={`col-md-5 ${styles['col-btn-share']}`}>
                        <ShareWithFriendsButton
                          pageSection={pageSection}
                          openShareWithFriendsPrompt={openPrompt}
                        />
                      </div>
                      <div className={`col-md-3 ${styles['col-share']}`}>
                        <Button
                          className={`jg-btn jg-btn--ghost jg-text--large-regular ${
                            styles['btn-share']
                          }`}
                          onClick={delay}
                          analyticsOptions={{
                            event: 'click',
                            eventValue: 'later',
                            pageSection,
                            subtype: 'button',
                          }}
                        >
                          <Trans i18nKey="ShareWithFriends.later" />
                        </Button>
                        <ClickableElement
                          className={`jg-text--link ${styles['link-share']}`}
                          onClick={delay}
                          analyticsOptions={{
                            event: 'click',
                            eventValue: 'later',
                            pageSection,
                            subtype: 'button',
                          }}
                        >
                          <Trans i18nKey="ShareWithFriends.laterLink" />
                        </ClickableElement>
                      </div>
                      <div className="col-md-4" />
                    </div>
                  </div>
                </div>
                <div className="col-md-1 col-lg-2 col-xl-3" />
              </div>
            </div>
          </section>
        </AnalyticsPageView>
      ),
    };
  }

  return { matched: false };
};

export default ShareWithFriends;
