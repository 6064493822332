import React from 'react';
import format from 'date-fns/format';
import { Link as ScrollLink } from 'react-scroll';
import styles from './PageClosedFullyFundedSupporter.scss';
import Svg from '@justgiving/svg';
import timer from '@justgiving/icons/lib/timer.svg';
import FormattedCurrency from 'src/components/FormattedCurrency';
import { FormattedMessage } from 'react-intl';

interface Props {
  amountRaised: number;
  targetCurrency: Currency;
  finishedAt?: string;
  supporterName: string;
  totalCountSupporters: number;
}

const SpecificSupporter: React.StatelessComponent<Props> = ({
  supporterName,
  amountRaised,
  targetCurrency,
  finishedAt,
  totalCountSupporters,
}) => {
  const otherSupportersCount = totalCountSupporters - 1;

  return (
    <div
      id="specific-supporter"
      className={`${
        styles['container-funded']
      } jg-space-pmd jg-bdb jg-bd--solid jg-bd--very-light-grey row jg-text--center`}
    >
      <div className="col-xs-12 col-md-6 offset-md-3">
        <h1 className="jg-h1">
          <FormattedMessage
            id="SpecificSupporter.thankYou"
            defaultMessage="Thank you, {supporterName}"
            values={{ supporterName }}
          />
        </h1>
        <p className="jg-space-mbmd">
          <FormattedMessage
            id="SpecificSupporter.youHelpedRaise"
            defaultMessage="Your donation helped raise {amount}{supporters}."
            values={{
              amount: (
                <b>
                  <FormattedCurrency
                    value={amountRaised}
                    currency={targetCurrency || 'GBP'}
                    minimumFractionDigits={0}
                  />
                </b>
              ),
              supporters:
                otherSupportersCount > 0 ? (
                  <FormattedMessage
                    id="SpecificSupporter.youHelpedRaise.alongWith"
                    defaultMessage=" along with {supporters}"
                    values={{
                      supporters: (
                        <FormattedMessage
                          id="SpecificSupporter.youHelpedRaise.alongWith.others"
                          defaultMessage="{count} other {count, plural, one {supporter} other {supporters}}"
                          values={{
                            count: otherSupportersCount,
                          }}
                          tagName="b"
                        />
                      ),
                    }}
                  />
                ) : null,
            }}
          />
        </p>
        <div className="row jg-space-mbmd">
          <div className="col-xs-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3">
            <ScrollLink to="updates">
              <button
                id="button-read"
                className="jg-btn jg-btn--brand"
                styleName="action-link"
              >
                <FormattedMessage
                  id="SpecificSupporter.seeMessage"
                  defaultMessage="See their message"
                />
              </button>
            </ScrollLink>
          </div>
        </div>
        <div className="jg-display-flex jg-flex-aic jg-flex-jcc">
          <Svg
            className={`jg-fill--nobel jg-icon--medium-small jg-space-mbsm jg-space-mrxs ${
              styles.timer
            }`}
            markup={timer}
          />
          <p className="jg-space-mbn jg-text--small">
            <FormattedMessage
              id="SpecificSupporter.closedAt"
              defaultMessage="Closed {date}"
              values={{ date: format(finishedAt!, 'DD/MM/YYYY') }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default SpecificSupporter;
