import React from 'react';
import FacebookShare from 'src/components/FacebookShare/FacebookShare';
import ClickableElement from 'src/components/ClickableElement';

import { FormattedMessage } from 'react-intl';
import DesktopPrompt from 'src/components/SharePopup/DesktopSharePopup';

interface Props {
  closeModal: () => void;
  ownerName: string;
  ownerProfileImage?: string;
}

const DesktopEncourageShare: React.StatelessComponent<Props> = ({
  ownerName,
  closeModal,
  ownerProfileImage,
}) => (
  <DesktopPrompt ownerProfileImage={ownerProfileImage} ownerName={ownerName}>
    <>
      <h1>
        <FormattedMessage
          id="DesktopEncourageShare.title"
          defaultMessage="Sharing with friends is powerful"
        />
      </h1>

      <p className="jg-space-mbmd">
        <FormattedMessage
          id="DesktopEncourageShare.didYouKnow"
          defaultMessage="Did you know? Sharing this page with your friends could help {ownerName} get over £100* in donations."
          values={{
            ownerName,
          }}
        />
      </p>

      <FacebookShare
        buttonText="Share to Facebook"
        location="page > share prompt"
        className="jg-space-mbmd"
      />

      <ClickableElement
        id="skip-share-prompt"
        onClick={closeModal}
        className="jg-text--link jg-text--center jg-space-mbmd"
      >
        <FormattedMessage
          id="DesktopEncourageShare.maybeLater"
          defaultMessage="Not now, maybe later."
        />
      </ClickableElement>

      <p className="jg-text--xsmall-light jg-text--center">
        <FormattedMessage
          id="DesktopEncourageShare.basedOn"
          defaultMessage="*Based on JustGiving data from 01/18 to 06/18."
        />
      </p>
    </>
  </DesktopPrompt>
);

export default DesktopEncourageShare;
