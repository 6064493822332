import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import loudspeaker from './svg/loudspeaker.svg';

const PageStrandedPageOwnerContent: FunctionComponent = () => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-8 offset-md-2">
        <img src={loudspeaker} className="jg-svg-sm" alt="loudspeaker icon" />
        <h2>
          <FormattedMessage
            id="PageStrandedPageOwnerContent.pageStranded"
            defaultMessage="Your funds are no longer available"
          />
        </h2>
        <p className="jg-space-mbml">
          <FormattedMessage
            id="PageStrandedPageOwnerContent.details"
            defaultMessage="We've tried to contact you several times about your page. Since we didn't hear from you, we're now closing this page and are unable to pay out your funds.

            We'll be refunding the donations made to your page back to your supporters, or where that isn't possible, sending the donations to a registered UK charity which best aligns with the goals set out on your page."
          />
        </p>
      </div>
    </div>
  );
};

export default PageStrandedPageOwnerContent;
