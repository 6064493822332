import {
  getPaymentDetails,
  PaymentDetailsResource,
} from 'src/helpers/api/paymentDetails';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';

interface LoadPaymentDetailsRequestAction {
  type: 'LOAD_PAYMENT_DETAILS_REQUEST';
}

interface LoadPaymentDetailsSuccessAction {
  type: 'LOAD_PAYMENT_DETAILS_SUCCESS';
  payload: PaymentDetailsResource;
}

interface LoadPaymentDetailsFailureAction {
  type: 'LOAD_PAYMENT_DETAILS_FAILURE';
  error: true;
  payload: Error;
}

export function loadPaymentDetails() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<LoadPaymentDetailsRequestAction>({
      type: 'LOAD_PAYMENT_DETAILS_REQUEST',
    });

    try {
      const { id } = getState().page;
      const paymentDetails = await getPaymentDetails({ pageId: id });

      dispatch<LoadPaymentDetailsSuccessAction>({
        type: 'LOAD_PAYMENT_DETAILS_SUCCESS',
        payload: paymentDetails,
      });
    } catch (error) {
      dispatch<LoadPaymentDetailsFailureAction>({
        type: 'LOAD_PAYMENT_DETAILS_FAILURE',
        error: true,
        payload: error,
      });
    }
  };
}

export type PaymentDetailsState = PaymentDetailsResource & {
  isLoading: boolean;
};

const initialState: PaymentDetailsState = {
  isLoading: false,
  accountEmailAddress: '',
  accountHolder: '',
  accountNumber: '',
  bankName: '',
  isPayPal: false,
  sortCode: '',
};

export default function reducer(
  state: PaymentDetailsState = initialState,
  action:
    | LoadPaymentDetailsRequestAction
    | LoadPaymentDetailsSuccessAction
    | LoadPaymentDetailsFailureAction
) {
  switch (action.type) {
    case 'LOAD_PAYMENT_DETAILS_REQUEST':
      return { ...state, isLoading: true };
    case 'LOAD_PAYMENT_DETAILS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case 'LOAD_PAYMENT_DETAILS_FAILURE':
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
