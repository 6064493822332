import React from 'react';
import Error from 'src/components/Error/Error';
import Raven from 'raven-js';

interface State {
  hasError: boolean;
  error: Error | null;
}

class AppErrorBoundary extends React.Component<void, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ hasError: true, error });
    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      if (__DEVELOPMENT__) {
        if (this.state.error) {
          const RedBox = require('redbox-react').default;

          return <RedBox error={this.state.error} />;
        }
      }

      return <Error error="500" />;
    }

    return <div>{this.props.children}</div>;
  }
}

export default AppErrorBoundary;
