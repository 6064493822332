import { AnalyticsMeta } from 'src/redux/modules/analytics';

interface OpenSharePromptsAction {
  type: 'OPEN_SHARE_PROMPTS';
  payload: string;
}

export function openSharePrompts(pageSection: string): OpenSharePromptsAction {
  return {
    type: 'OPEN_SHARE_PROMPTS',
    payload: pageSection,
  };
}

interface CloseSharePromptsAction {
  type: 'CLOSE_SHARE_PROMPTS';
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function closeSharePrompts(): CloseSharePromptsAction {
  return {
    type: 'CLOSE_SHARE_PROMPTS',
    meta: {
      analytics: {
        event: 'click',
        subtype: 'dismiss',
        event_value: 'Go to my page',
        page_section: 'page > page creation > share prompt > All done',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export interface SharePromptsState {
  isSharePromptsOpen: boolean;
  hasShared: boolean;
  pageSection: string;
}

export default function reducer(
  state: SharePromptsState = {
    isSharePromptsOpen: false,
    hasShared: false,
    pageSection: '',
  },
  action: OpenSharePromptsAction | CloseSharePromptsAction
): SharePromptsState {
  switch (action.type) {
    case 'OPEN_SHARE_PROMPTS':
      return {
        ...state,
        isSharePromptsOpen: true,
        hasShared: false,
        pageSection: action.payload,
      };
    case 'CLOSE_SHARE_PROMPTS':
      return {
        ...state,
        isSharePromptsOpen: false,
        hasShared: true,
        pageSection: '',
      };
    default:
      return state;
  }
}
