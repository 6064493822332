import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'src/components/Analytics';
import snowflake from '../svg/snowflake.svg';
import Svg from '@justgiving/svg';
import Countdown, {
  CountdownReturnProps,
} from 'src/components/Countdown/Countdown';
interface Props {
  pageName: string;
  refundDate: string | Date;
}

const PageFrozenContent: React.StatelessComponent<Props> = ({
  pageName,
  refundDate,
}) => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-8 offset-md-2">
        <Svg markup={snowflake} className="jg-svg-sm" />
        <h2>
          <FormattedMessage
            id="PageFrozenContent.pageFrozen"
            defaultMessage="Your Page has been frozen"
          />
        </h2>
        <p>
          <FormattedMessage
            id="PageFrozenContent.noMoreDonations"
            defaultMessage="Your supporters can no longer donate to your Page. Add your bank details now to re-open your Page and continue to receive&nbsp;donations."
          />
        </p>
        <Countdown endDate={refundDate} tick={60000}>
          {({
            daysRemaining,
            hoursRemaining,
            minutesRemaining,
          }: CountdownReturnProps) => (
            <div className="jg-space-mbml">
              <h3 className={`jg-color--sunset-red jg-space-mbn`}>
                {`${daysRemaining}d ${hoursRemaining}h ${minutesRemaining}m`}
              </h3>
              <p className="jg-color--sunset-red">
                <b>
                  <FormattedMessage
                    id="PageFrozen.untilRefundingDonations"
                    defaultMessage="left before we refund your donations"
                  />
                </b>
              </p>
            </div>
          )}
        </Countdown>
        <div className="row">
          <Link
            to={`/${pageName}/funds/account`}
            className={`jg-btn jg-btn--brand col-xs-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3`}
            analyticsOptions={{
              event: 'link click',
              eventValue: 'add my bank details now',
              pageSection: 'admin > page view > prompt > page frozen',
              subtype: 'button',
            }}
          >
            <FormattedMessage
              id="PageFrozenContent.addBankDetailsButton"
              defaultMessage="Add my bank details now"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageFrozenContent;
