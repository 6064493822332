import { post, del, getOrThrow } from 'src/helpers/api/common';

interface PushNotificationSubscriptionData {
  endpoint?: string;
  pushKey?: string;
  authKey?: string;
  userGuid: string;
}

interface NotificationsSubscriptionResponse {
  id: number;
}

interface Topic {
  name: string;
}

interface TopicResponse {
  topics: Topic[];
}

export async function createNotificationsSubscription(
  subscriptionData: PushNotificationSubscriptionData
) {
  const result = await post('subscriptions', subscriptionData);
  return result.body as NotificationsSubscriptionResponse;
}

interface SubscriptionTopicData {
  subscriptionId: number;
  topicName: string;
}

export async function addSubscriptionTopic({
  topicName,
  subscriptionId,
}: SubscriptionTopicData) {
  await post(`subscriptions/${subscriptionId}/topics`, {
    subscriptionId,
    topicName,
  });
}

export async function deleteSubscriptionTopic({
  topicName,
  subscriptionId,
}: SubscriptionTopicData) {
  await del(`subscriptions/${subscriptionId}/topics/${topicName}`);
}

export async function getSubscriptionTopics(subscriptionId: number) {
  const response = await getOrThrow<TopicResponse>(
    `subscriptions/${subscriptionId}/topics`
  );
  return response.topics;
}
