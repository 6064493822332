import {
  ActivateYourPage,
  BankDetailsVerified,
  CountdownToRefunding,
  DocumentsManualVerification,
  DonationsRefunded,
  DonationsRefundedSupporter,
  GiveFeedback,
  PageClosedFullyFundedOwner,
  PageClosedFullyFundedSupporter,
  PageClosedTargetNotReachedOwner,
  PageClosedTargetNotReachedSupporter,
  PageFrozenSupporter,
  PageLive,
  RecurringDonationsPrompt,
  SayThanks,
  ShareWithFriends,
  PageStranded,
  PageStrandedPageOwner,
} from 'src/components/Prompt';
import { Rule, rulesEngine } from 'src/helpers/rulesEngine';

import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { MeasureSize } from 'src/components/Helpers';
import React from 'react';
import { RevealTransition } from 'src/components/Transitions';
import config from 'config';

export interface PromptRulesEngineOptions {
  pageSection: string;
  dispatch: Dispatch<AppState>;
  promptTransitionActive?: boolean;
  activatePromptTransition: (options: { height: number }) => void;
  closePrompt: () => void;
}
// TODO clean when POO feature flag will be removed
const ownerPrompts: Rule<JSX.Element, PromptRulesEngineOptions>[] = config
  .FEATURE.PAGE_OWNER_ADMIN
  ? [PageStrandedPageOwner]
  : [
      DonationsRefunded,
      DocumentsManualVerification,
      BankDetailsVerified,
      CountdownToRefunding,
      ActivateYourPage,
      PageLive,
      ShareWithFriends,
      SayThanks,
      RecurringDonationsPrompt,
      PageClosedFullyFundedOwner,
      PageClosedTargetNotReachedOwner,
    ];

const supporterPrompts: Rule<JSX.Element, PromptRulesEngineOptions>[] = [
  PageClosedTargetNotReachedSupporter,
  PageClosedFullyFundedSupporter,
  DonationsRefundedSupporter,
  PageFrozenSupporter,
  GiveFeedback,
  PageStranded,
];

const promptRulesEngine = (
  appState: AppState,
  isOwner: boolean,
  {
    pageSection,
    dispatch,
    promptTransitionActive,
    activatePromptTransition,
    closePrompt,
  }: PromptRulesEngineOptions
) => {
  try {
    const prompt = rulesEngine(
      isOwner ? [...ownerPrompts] : [...supporterPrompts],
      appState,
      {
        pageSection,
        dispatch,
        promptTransitionActive,
        activatePromptTransition,
        closePrompt,
      }
    );

    if (prompt.matched && prompt.result) {
      const style: React.CSSProperties = {
        position: 'absolute',
        width: '100%',
        zIndex: promptTransitionActive ? 0 : -1,
      };

      // hack: without the wrapping section React 16.0.0 tries to merge the transition divs, breaking the layout
      return (
        <MeasureSize style={style} onMeasure={activatePromptTransition}>
          <RevealTransition
            active={promptTransitionActive}
            delay="0.5s"
            duration="1s"
          >
            {prompt.result}
          </RevealTransition>
        </MeasureSize>
      );
    }

    return null;
  } catch (e) {
    return null;
  }
};

export default promptRulesEngine;
