import React from 'react';

interface Props {
  touched?: boolean;
  error?: string;
}

const Result = ({ touched, error }: Props) => (
  <div>
    {touched &&
      error &&
      error.trim().length > 0 && (
        <div role="alert" className="jg-form-error-copy">
          {error}
        </div>
      )}
  </div>
);

export default Result;
