import React from 'react';
import styles from './CharityEndorsed.scss';
import Svg from '@justgiving/svg';
import tick from './svgs/endorsed-tick-il.svg';

interface Props {
  charityLogo: string;
  displayTick: boolean;
  className?: string;
  marginAuto?: boolean;
  smallIcon?: boolean;
}

const EndorsingCharityLogo: React.StatelessComponent<Props> = ({
  charityLogo,
  displayTick,
  marginAuto,
  smallIcon,
  className = '',
}) => {
  return (
    <>
      <div
        className={`${
          styles['logo-wrapper']
        } jg-bd jg-bd--solid jg-bd--very-light-grey jg-relative jg-background--white ${className} ${!marginAuto &&
          'jg-space-mn'}`}
      >
        <img src={charityLogo} className="jg-space-phxs" />

        {displayTick && (
          <Svg
            markup={tick}
            className={`${
              smallIcon ? styles['small-tick'] : styles.tick
            } jg-fill--mountain-meadow ${
              smallIcon ? 'jg-icon--medium-small' : 'jg-icon--medium'
            } jg-display-absolute`}
          />
        )}
      </div>
    </>
  );
};

export default EndorsingCharityLogo;
