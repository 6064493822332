import { Formik, FormikProps } from 'formik';
import {
  AnalyticsLoadingButton as LoadingButton,
  AnalyticsPageView as ModalView,
} from 'src/components/Analytics';

import Alert from 'src/components/Alert';
import { ClickAnalyticsOptions } from 'src/redux/modules/analytics';
import ClickableElement from 'src/components/ClickableElement';
import { FormattedMessage } from 'react-intl';
import Modal from 'src/components/Modal';
import { RECAPTCHA_ID } from 'config';
import React from 'react';
import Recaptcha from 'src/components/Recaptcha';
import SingleLineInputWithTextLimit from 'src/components/FormikFormFields/SingleLineInputWithTextLimit';
import Svg from '@justgiving/svg';
import TextareaWithCharacterCount from 'src/components/TextareaWithCharacterCount';
import sendEmail from './sendEmail-il.svg';
import validate from 'src/components/ContactOwner/messageFormValidator';

interface Props {
  closeContactOwner: () => void;
  onSubmit: (data: ContactOwnerFormData) => void;
  ownerName: string;
  recaptcha?: string;
  setRecaptcha: (response: string) => void;
  submissionError: boolean;
  pageViewSection: string;
  pageSection: string;
  subjectPlaceHolder?: string;
  messagePlaceholder?: string;
}

export interface ContactOwnerFormData {
  email: string;
  message: string;
  subject: string;
}
export class ContactOwnerModal extends React.Component<Props> {
  verifyCallback = (response: string) => {
    if (response) {
      this.props.setRecaptcha(response);
    }
  };

  render() {
    const {
      closeContactOwner,
      onSubmit,
      ownerName,
      submissionError,
      pageViewSection,
      pageSection,
      subjectPlaceHolder,
      messagePlaceholder,
    } = this.props;
    const closeAnalytics: ClickAnalyticsOptions = {
      event: 'click',
      eventValue: 'close contact page owner',
      pageSection,
      subtype: 'button',
    };

    return (
      <Modal
        isOpen
        colorTheme={'dark'}
        onClose={closeContactOwner}
        maintainPosition
        closeAnalyticsTracking={{ ...closeAnalytics }}
      >
        <ModalView
          eventValue="contact page owner"
          pageSection={pageViewSection}
          subtype="cfp"
        >
          <div
            id="contact-fundraiser-modal"
            className="jg-space-ptlg jg-space-pblg jg-space-phml"
          >
            <header>
              <Svg markup={sendEmail} className="jg-svg-sm" />
              <h2 className="jg-text--center">Send email</h2>
            </header>
            <Formik
              initialValues={{
                subject: '',
                message: '',
                email: '',
              }}
              validate={validate}
              onSubmit={(values: ContactOwnerFormData) => onSubmit(values)}
            >
              {({
                errors,
                touched,
                values,
                handleSubmit,
                handleChange,
                isSubmitting,
              }: FormikProps<ContactOwnerFormData>) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <p className="jg-text--center jg-space-mbmd">
                        <FormattedMessage
                          id="ContactOwnerModal.sendAMessage"
                          defaultMessage="Write {ownerName} a message"
                          values={{ ownerName }}
                        />
                      </p>
                      <SingleLineInputWithTextLimit
                        error={errors.subject}
                        labelText={
                          <FormattedMessage
                            id="ContactOwnerModal.subject"
                            defaultMessage="Subject"
                          />
                        }
                        maxLength={100}
                        name="subject"
                        touched={touched.subject}
                        placeholder={subjectPlaceHolder}
                      />
                      <SingleLineInputWithTextLimit
                        labelText={
                          <FormattedMessage
                            id="ContactOwnerModal.email"
                            defaultMessage="Email (optional)"
                          />
                        }
                        maxLength={100}
                        name="email"
                      />
                      <label className="jg-form-label" htmlFor="message">
                        Message
                      </label>
                      <TextareaWithCharacterCount
                        id="message"
                        characterLimit={1000}
                        error={errors.message}
                        touched={touched.message}
                        onChange={handleChange}
                        value={values.message}
                        placeholder={messagePlaceholder}
                      />
                      <Recaptcha
                        sitekey={RECAPTCHA_ID}
                        verifyCallback={this.verifyCallback}
                      />
                    </div>
                    {submissionError && (
                      <div className="jg-space-mtmd">
                        <Alert
                          id="contact-owner-email-failure"
                          type="red"
                          displayType="inline"
                          persistent
                        >
                          <FormattedMessage
                            id="ContactOwnerModal.submissionError"
                            defaultMessage="It looks like there's been a problem sending your message. Please try again."
                          />
                        </Alert>
                      </div>
                    )}
                    <div className="jg-background--white ja-tac jg-space-phlg jg-space-ptmd">
                      <LoadingButton
                        className="jg-btn jg-btn--brand"
                        disabled={!this.props.recaptcha}
                        loading={isSubmitting && !submissionError}
                        value={
                          <FormattedMessage
                            id="ContactOwnerModal.sendButton"
                            defaultMessage="Send and close"
                          />
                        }
                        analyticsOptions={{
                          event: 'click',
                          subtype: 'button',
                          eventValue: 'message page owner',
                          pageSection,
                        }}
                      />

                      <ClickableElement
                        className="jg-text--link jg-text--center jg-space-ptmd"
                        onClick={closeContactOwner}
                        analyticsOptions={{ ...closeAnalytics }}
                      >
                        <FormattedMessage
                          id="ContactOwnerModal.cancel"
                          defaultMessage="Cancel"
                        />
                      </ClickableElement>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </ModalView>
      </Modal>
    );
  }
}

export default ContactOwnerModal;
