import CSSModules from 'react-css-modules';
import React from 'react';
import Svg from '@justgiving/svg';
import quoteIcon from './svgs/quote-il.svg';
import styles from './RichEditor.scss';

const RichQuoteNode: React.StatelessComponent = ({
  children,
  ...attributes
}) => {
  return (
    <div className={`${styles.quote} jg-space-mbml`}>
      <Svg
        markup={quoteIcon}
        className={`${styles['quote-marks']} jg-icon jg-display-i`}
      />
      <p className="jg-space-mbn" {...attributes}>
        {children}
      </p>
    </div>
  );
};

export default CSSModules(RichQuoteNode, styles);
