import React from 'react';
import config from 'config';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { Rule } from 'src/helpers/rulesEngine';
import { activatePage } from 'src/redux/modules/page';
import { FormattedMessage } from 'react-intl';
import { AnalyticsLoadingButton as LoadingButton } from 'src/components/Analytics';

const ActivateYourPage: Rule<JSX.Element, PromptRulesEngineOptions> = (
  state,
  options
) => {
  const { status } = state.page;
  const actionKey = 'tip_publish';

  const completeActivation = () => {
    options!.dispatch(activatePage());
  };

  const alreadyPublished = state.completedActions.keys.includes(actionKey);

  if ((status === 'Preview' || status === 'Approved') && !alreadyPublished) {
    return {
      matched: true,
      result: (
        <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-pvmd jg-space-phms@xs jg-space-phn@md row">
          <div className="jg-text--center jg-background--white col-xs-12 col-md-4 offset-md-4">
            <h1 className="jg-h1 jg-text--center jg-space-mbms">
              <FormattedMessage
                id="ActivateYourPage.startReceivingDonations"
                defaultMessage="Start receiving donations"
              />
            </h1>

            <p className="jg-space-mbmd">
              <FormattedMessage
                id="ActivateYourPage.changePageDuration"
                defaultMessage="You can change your page duration at any time from {minPageLife} days up to one year from today."
                values={{ minPageLife: config.MIN_PAGE_LIFE }}
              />
            </p>
            <div className="jg-space-mbmd">
              <LoadingButton
                id="crowdfunding-launch-button"
                loading={state.page.isActivating}
                value="Activate your Page"
                onClick={completeActivation}
                analyticsOptions={{
                  event: 'link click',
                  subtype: 'button',
                  eventValue: 'launch page',
                  pageSection: 'page creation > preview and activate',
                }}
              />
            </div>
            <p>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://help.justgiving.com/hc/en-us/articles/204606811-Can-I-extend-my-Crowdfunding-page-"
              >
                <FormattedMessage
                  id="ActivateYourPage.learnMoreLink"
                  defaultMessage="Learn more"
                />
              </a>
            </p>
          </div>
        </div>
      ),
    };
  }

  return { matched: false };
};

export default ActivateYourPage;
