import React from 'react';
import styles from './TwitterShareButton.scss';
import CSSModules from 'react-css-modules';
import { ShareActionOptions } from 'src/redux/modules/page';
import TwitterShare from '../SocialShare/TwitterShare';

export interface Props {
  shareOnTwitter: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  isActive: boolean;
  isOwner: boolean;
  targetCurrency: Currency;
  pageSection: string;
}

const TwitterShareButton: React.StatelessComponent<Props> = ({
  pageSection,
  shareOnTwitter,
  socialShareUrl,
  targetAmount,
  pitchFor,
  isActive,
  isOwner,
  targetCurrency,
}) => {
  return (
    <div styleName="share-button-container">
      <TwitterShare
        shareOnTwitter={shareOnTwitter}
        socialShareUrl={socialShareUrl}
        targetAmount={targetAmount}
        pitchFor={pitchFor}
        isActive={isActive}
        isOwner={isOwner}
        targetCurrency={targetCurrency}
        location={pageSection}
        className={styles.twitter}
        buttonText="Twitter"
      />
    </div>
  );
};

export default CSSModules(TwitterShareButton, styles);
