'use strict';

var _typeof =
  typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
    ? function(obj) {
        return typeof obj;
      }
    : function(obj) {
        return obj &&
          typeof Symbol === 'function' &&
          obj.constructor === Symbol &&
          obj !== Symbol.prototype
          ? 'symbol'
          : typeof obj;
      };

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var React = require('react');
var PropTypes = require('prop-types');

var ALL_INITIALIZERS = [];
var READY_INITIALIZERS = [];

function isWebpackReady(getModuleIds) {
  if (
    (typeof __webpack_modules__ === 'undefined'
      ? 'undefined'
      : _typeof(__webpack_modules__)) !== 'object'
  ) {
    return false;
  }

  return getModuleIds().every(function(moduleId) {
    return (
      typeof moduleId !== 'undefined' &&
      typeof __webpack_modules__[moduleId] !== 'undefined'
    );
  });
}

function load(loader) {
  var promise = loader();

  var state = {
    loading: true,
    loaded: null,
    error: null,
  };

  state.promise = promise
    .then(function(loaded) {
      state.loading = false;
      state.loaded = loaded;
      return loaded;
    })
    .catch(function(err) {
      state.loading = false;
      state.error = err;
      throw err;
    });

  return state;
}

function loadMap(obj) {
  var state = {
    loading: false,
    loaded: {},
    error: null,
  };

  var promises = [];

  try {
    Object.keys(obj).forEach(function(key) {
      var result = load(obj[key]);

      if (!result.loading) {
        state.loaded[key] = result.loaded;
        state.error = result.error;
      } else {
        state.loading = true;
      }

      promises.push(result.promise);

      result.promise
        .then(function(res) {
          state.loaded[key] = res;
        })
        .catch(function(err) {
          state.error = err;
        });
    });
  } catch (err) {
    state.error = err;
  }

  state.promise = Promise.all(promises)
    .then(function(res) {
      state.loading = false;
      return res;
    })
    .catch(function(err) {
      state.loading = false;
      throw err;
    });

  return state;
}

function resolve(obj) {
  return obj && obj.__esModule ? obj.default : obj;
}

function render(loaded, props) {
  return React.createElement(resolve(loaded), props);
}

function createLoadableComponent(loadFn, options) {
  var _class;
  var _temp;

  if (!options.loading) {
    throw new Error('react-loadable requires a `loading` component');
  }

  var opts = Object.assign(
    {
      loader: null,
      loading: null,
      delay: 200,
      timeout: null,
      render: render,
      webpack: null,
      modules: null,
    },
    options
  );

  var res = null;

  function init() {
    if (!res) {
      res = loadFn(opts.loader);
    }
    return res.promise;
  }

  ALL_INITIALIZERS.push(init);

  if (typeof opts.webpack === 'function') {
    READY_INITIALIZERS.push(function() {
      if (isWebpackReady(opts.webpack)) {
        return init();
      }
    });
  }

  return (
    (_temp = _class = (function(_React$Component) {
      _inherits(LoadableComponent, _React$Component);

      function LoadableComponent(props) {
        _classCallCheck(this, LoadableComponent);

        var _this = _possibleConstructorReturn(
          this,
          _React$Component.call(this, props)
        );

        _this.retry = function() {
          _this.setState({ error: null, loading: true, timedOut: false });
          res = loadFn(opts.loader);
          _this._loadModule();
        };

        init();

        if (__SERVER__ || !res) {
          res = loadFn(opts.loader);
        }

        _this.state = {
          error: res.error,
          pastDelay: false,
          timedOut: false,
          loading: res.loading,
          loaded: res.loaded,
        };
        return _this;
      }

      LoadableComponent.preload = function preload() {
        return init();
      };

      LoadableComponent.prototype.componentWillMount = function componentWillMount() {
        this._mounted = true;
        this._loadModule();
      };

      LoadableComponent.prototype._loadModule = function _loadModule() {
        var _this2 = this;

        if (this.context.loadable && Array.isArray(opts.modules)) {
          opts.modules.forEach(function(moduleName) {
            _this2.context.loadable.report(moduleName);
          });
        }

        if (!res.loading) {
          return;
        }

        if (typeof opts.delay === 'number') {
          if (opts.delay === 0) {
            this.setState({ pastDelay: true });
          } else {
            this._delay = setTimeout(function() {
              _this2.setState({ pastDelay: true });
            }, opts.delay);
          }
        }

        if (typeof opts.timeout === 'number') {
          this._timeout = setTimeout(function() {
            _this2.setState({ timedOut: true });
          }, opts.timeout);
        }

        var update = function update() {
          if (!_this2._mounted) {
            return;
          }

          _this2.setState({
            error: res.error,
            loaded: res.loaded,
            loading: res.loading,
          });

          _this2._clearTimeouts();
        };

        res.promise
          .then(function() {
            update();
          })
          .catch(function(err) {
            update();
          });
      };

      LoadableComponent.prototype.componentWillUnmount = function componentWillUnmount() {
        this._mounted = false;
        this._clearTimeouts();
      };

      LoadableComponent.prototype._clearTimeouts = function _clearTimeouts() {
        clearTimeout(this._delay);
        clearTimeout(this._timeout);
      };

      LoadableComponent.prototype.render = function render() {
        if (this.state.loading || this.state.error) {
          return React.createElement(opts.loading, {
            isLoading: this.state.loading,
            pastDelay: this.state.pastDelay,
            timedOut: this.state.timedOut,
            error: this.state.error,
            retry: this.retry,
          });
        } else if (this.state.loaded) {
          return opts.render(this.state.loaded, this.props);
        } else {
          return null;
        }
      };

      return LoadableComponent;
    })(React.Component)),
    (_class.contextTypes = {
      loadable: PropTypes.shape({
        report: PropTypes.func.isRequired,
      }),
    }),
    _temp
  );
}

function Loadable(opts) {
  return createLoadableComponent(load, opts);
}

function LoadableMap(opts) {
  if (typeof opts.render !== 'function') {
    throw new Error('LoadableMap requires a `render(loaded, props)` function');
  }

  return createLoadableComponent(loadMap, opts);
}

Loadable.Map = LoadableMap;

var Capture = (function(_React$Component2) {
  _inherits(Capture, _React$Component2);

  function Capture() {
    _classCallCheck(this, Capture);

    return _possibleConstructorReturn(
      this,
      _React$Component2.apply(this, arguments)
    );
  }

  Capture.prototype.getChildContext = function getChildContext() {
    return {
      loadable: {
        report: this.props.report,
      },
    };
  };

  Capture.prototype.render = function render() {
    return React.Children.only(this.props.children);
  };

  return Capture;
})(React.Component);

Capture.propTypes = {
  report: PropTypes.func.isRequired,
};
Capture.childContextTypes = {
  loadable: PropTypes.shape({
    report: PropTypes.func.isRequired,
  }).isRequired,
};

Loadable.Capture = Capture;

function flushInitializers(initializers) {
  var promises = [];

  while (initializers.length) {
    var init = initializers.pop();
    promises.push(init());
  }

  return Promise.all(promises).then(function() {
    if (initializers.length) {
      return flushInitializers(initializers);
    }
  });
}

Loadable.preloadAll = function() {
  return new Promise(function(resolve, reject) {
    flushInitializers(ALL_INITIALIZERS).then(resolve, reject);
  });
};

Loadable.preloadReady = function() {
  return new Promise(function(resolve, reject) {
    // We always will resolve, errors should be handled within loading UIs.
    flushInitializers(READY_INITIALIZERS).then(resolve, resolve);
  });
};
// JG-specific: use ES6 syntax
export default Loadable;
