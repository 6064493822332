import React from 'react';
import styles from './EmailShareButton.scss';
import CSSModules from 'react-css-modules';
import { ShareActionOptions } from 'src/redux/modules/page';
import EmailShare from '../SocialShare/EmailShare';

export interface Props {
  shareByEmail: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  isActive: boolean;
  isOwner: boolean;
  targetCurrency: Currency;
  pageSection: string;
  ownerName: string;
}

const EmailShareButton: React.StatelessComponent<Props> = ({
  pageSection,
  shareByEmail,
  socialShareUrl,
  targetAmount,
  pitchFor,
  isActive,
  isOwner,
  targetCurrency,
  ownerName,
}) => {
  return (
    <div styleName="share-button-container">
      <EmailShare
        shareByEmail={shareByEmail}
        socialShareUrl={socialShareUrl}
        targetAmount={targetAmount}
        pitchFor={pitchFor}
        ownerName={ownerName}
        isActive={isActive}
        targetCurrency={targetCurrency}
        location={pageSection}
        buttonText="Email"
        className={styles.email}
        messageType={isOwner ? 'ownerShare' : 'supporterShare'}
      />
    </div>
  );
};

export default CSSModules(EmailShareButton, styles);
