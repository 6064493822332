import createLogger from 'redux-logger';
import { applyMiddleware, createStore, compose } from 'redux';
import reducers from '../redux/modules';

function hmr(store) {
  if (module.hot) {
    module.hot.accept('../redux/modules', () => {
      const nextRootReducer = require('../redux/modules').default;

      store.replaceReducer(nextRootReducer);
    });
  }
}

export default function create(middleware, data) {
  if (__CLIENT__ && __DEVELOPMENT__) {
    middleware.push(createLogger({ collapsed: true }));
  }

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancers = composeEnhancers(applyMiddleware(...middleware));

  const store = createStore(reducers, data, enhancers);

  hmr(store);

  return store;
}
