import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import PageDeactivatedContent from './PageDeactivatedContent';
import { CountdownRulesEngineOptions } from 'src/components/Prompt/CountdownToRefundingFlow/countdownToRefundingEngine';

const PageDeactivated: Rule<JSX.Element, CountdownRulesEngineOptions> = (
  state,
  options
) => {
  if (
    options &&
    state.page.status === 'Suspended' &&
    options.timeRemainingFalseSuspension.hasCountdownFinished &&
    !state.page.refundedDate
  ) {
    return {
      matched: true,
      result: (
        <PageDeactivatedContent
          pageName={options.pageName}
          refundDate={options.refundDate}
        />
      ),
    };
  }

  return { matched: false };
};

export default PageDeactivated;
