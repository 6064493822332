import CSSModules from 'react-css-modules';
import FacebookShare from 'src/components/FacebookShare/FacebookShare';
import React from 'react';
import { generateHash } from 'src/helpers/hashId';
import styles from './RichUpdatesItemShare.scss';

interface Props {
  updateId: number;
  message?: string;
  type: string;
}

const RichUpdatesItemShare: React.StatelessComponent<Props> = ({
  updateId,
  message,
}) => {
  const shareHash = generateHash();

  return (
    <div className={styles.container}>
      <p className={styles.text}>Share this update to help us raise more</p>
      <div className={styles['facebook-container']}>
        <FacebookShare
          customStyle="jg-btn jg-btn--facebook"
          isActive
          isUpdate
          location="updates"
          buttonText="Share"
          updateId={updateId}
          quote={message}
          shareHash={shareHash}
        />
      </div>
    </div>
  );
};

export default CSSModules(RichUpdatesItemShare, styles);
