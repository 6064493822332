import { get } from './common';

export interface CategoriesResponse {
  categories: CategoryResource[];
}

export interface CategoryResource {
  id: number;
  name: string;
  url: string;
}

export async function getAll(): Promise<CategoriesResponse> {
  const response = await get<CategoriesResponse>('categories', {
    timingName: 'categories',
  });

  if (!response) {
    throw new Error('Failed to fetch categories.');
  }

  return response;
}
