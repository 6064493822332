import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Modal from 'src/components/Modal';
import {
  isLocalStorageAvailable,
  isSessionStorageAvailable,
  isPropertyInLocalAndSessionStorage,
} from 'src/helpers/browser';
import { AppState } from 'src/redux/modules';
import { PageStatus } from 'src/helpers/api/pages';
import { Dispatch, bindActionCreators } from 'redux';
import { analyticsClick, AnalyticsClick } from 'src/redux/modules/analytics';
import {
  ShareActionOptions,
  shareByWhatsApp as shareByWhatsAppAction,
  shareBySms as shareBySmsAction,
} from 'src/redux/modules/page';
import EncourageShare from 'src/components/EncourageShare/EncourageShare';

const propertyName = 'isSharePromptDismissed';

type OwnProps = RouteComponentProps<void> & {
  isEnabled: boolean;
  isDonatePopupOpen?: boolean;
};

interface StateProps {
  id: string;
  status: PageStatus;
  ownerName: string;
  renderAfter: number;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  targetCurrency: Currency;
  isOwner: boolean;
  pageName: string;
  ownerProfileImage?: string;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    id: state.page.id,
    status: state.page.status,
    ownerName: state.page.ownerName,
    socialShareUrl: state.page.socialShareUrl,
    targetAmount: state.page.targetAmount,
    pitchFor: state.page.pitchFor,
    targetCurrency: state.page.targetCurrency,
    renderAfter: state.page.renderAfter,
    isOwner: state.page.isOwnerRequested,
    pageName: state.page.name,
    ownerProfileImage: state.page.ownerProfileImageUrl,
  };
}

interface DispatchProps {
  analyticsClick: AnalyticsClick;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareBySms: (options: ShareActionOptions) => void;
}

function mapDispatchToProps(dispatch: Dispatch<AppState>): DispatchProps {
  return bindActionCreators(
    {
      analyticsClick,
      shareByWhatsApp: shareByWhatsAppAction,
      shareBySms: shareBySmsAction,
    },
    dispatch
  );
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  showEncourageShareModal: boolean;
  currentShowModalTimeout?: number;
}

export class EncourageShareContainer extends React.Component<Props, State> {
  state = {
    showEncourageShareModal: false,
    currentShowModalTimeout: undefined,
  };

  handleModalClose = () => {
    const { id } = this.props;

    if (isSessionStorageAvailable() && isLocalStorageAvailable()) {
      window.localStorage.setItem(`${propertyName}:${id}`, 'true');
      window.sessionStorage.setItem(propertyName, 'true');
    }

    this.setState({
      showEncourageShareModal: false,
    });

    this.props.analyticsClick({
      event: 'click',
      subtype: 'skip > share prompt',
      pageSection: 'page > share prompt',
      eventValue: 'dismiss',
    });
  };

  async componentDidMount() {
    this.startNewShowModalTimeout();
  }

  async componentDidUpdate(prevProps: Props) {
    // Start new timeout every time the isEnabled flag is toggled
    if (this.props.isEnabled !== prevProps.isEnabled) {
      // When the share container isEnabled then start new timeout
      // Else clear the current timeout when disabled
      if (this.props.isEnabled) {
        this.startNewShowModalTimeout();
      } else {
        clearTimeout(this.state.currentShowModalTimeout);
      }
    }
  }

  showEncourageShareModal = () => {
    const {
      status,
      id,
      isOwner,
      pageName,
      location: { pathname },
    } = this.props;

    this.setState({
      showEncourageShareModal:
        !isPropertyInLocalAndSessionStorage({
          localPropertyName: `${propertyName}:${id}`,
          sessionPropertyName: propertyName,
        }) &&
        status === 'Active' &&
        !isOwner &&
        pathname === `/${pageName}` &&
        this.props.isEnabled &&
        !this.props.isDonatePopupOpen,
    });
  };

  async startNewShowModalTimeout() {
    const { renderAfter } = this.props;

    clearTimeout(this.state.currentShowModalTimeout);

    const newShowModalTimeout = window.setTimeout(() => {
      this.showEncourageShareModal();
    }, renderAfter);

    this.setState({ currentShowModalTimeout: newShowModalTimeout });
  }

  render() {
    const {
      ownerName,
      socialShareUrl,
      targetAmount,
      pitchFor,
      targetCurrency,
      shareByWhatsApp,
      shareBySms,
      ownerProfileImage,
    } = this.props;
    const { showEncourageShareModal } = this.state;

    if (!showEncourageShareModal) {
      return null;
    }

    return (
      <Modal
        isOpen
        onClose={this.handleModalClose}
        colorTheme="dark"
        large
        maintainPosition
      >
        <EncourageShare
          ownerName={ownerName}
          socialShareUrl={socialShareUrl}
          targetAmount={targetAmount}
          pitchFor={pitchFor}
          targetCurrency={targetCurrency}
          onCloseModal={this.handleModalClose}
          shareByWhatsApp={shareByWhatsApp}
          shareBySms={shareBySms}
          ownerProfileImage={ownerProfileImage}
        />
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EncourageShareContainer)
);
