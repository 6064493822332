import React from 'react';
import styles from './LinkedInShareButton.scss';
import CSSModules from 'react-css-modules';
import { ShareActionOptions } from 'src/redux/modules/page';
import { generateHash } from '../../helpers/hashId';
import { buildUrl } from 'src/helpers/url';
import { openCenteredPopup } from 'src/helpers/window';

export interface Props {
  shareOnLinkedIn: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  isActive: boolean;
  pageSection: string;
  ownerName: string;
  buttonText?: string;
}

const LinkedInShareButton: React.StatelessComponent<Props> = ({
  pageSection,
  shareOnLinkedIn,
  socialShareUrl,
  isActive,
  buttonText,
}) => {
  const handleLinkedInShare = () => {
    const shareHash = generateHash();

    shareOnLinkedIn({
      shareHash,
      location: pageSection,
    });

    const pageUrl = buildUrl(socialShareUrl, {
      utm_term: shareHash,
    });

    if (__CLIENT__) {
      openCenteredPopup(
        `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          pageUrl
        )}`,
        1000,
        760
      );
    }
  };

  return (
    <div styleName="share-button-container">
      <button
        type="button"
        disabled={!isActive}
        className={styles.linkedin}
        onClick={handleLinkedInShare}
      >
        {buttonText || 'LinkedIn'}
      </button>
    </div>
  );
};

export default CSSModules(LinkedInShareButton, styles);
