import { AsyncConnectActions } from 'src/redux/modules/asyncConnect/actions';

const initalState = {};

export default (state = initalState, action: AsyncConnectActions) => {
  switch (action.type) {
    case '@jg/AsyncConnectBeginLoad':
      return {
        ...state,
        [action.payload]: false,
      };
    case '@jg/AsyncConnectEndLoad':
      return {
        ...state,
        [action.payload]: true,
      };
    default:
      return state;
  }
};
