import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import DonationsRefundedSupporterContent from './DonationsRefundedSupporterContent';
import { CountdownRulesEngineOptions } from 'src/components/Prompt/CountdownToRefundingFlow/countdownToRefundingEngine';

const DonationsRefundedSupporter: Rule<
  JSX.Element,
  CountdownRulesEngineOptions
> = state => {
  if (state.page.status === 'Suspended' && state.page.refundedDate) {
    return {
      matched: true,
      result: <DonationsRefundedSupporterContent />,
    };
  }

  return { matched: false };
};

export default DonationsRefundedSupporter;
