import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from 'src/redux/modules';
import Updates from 'src/components/Updates/Updates';
import { UpdateResource } from 'src/helpers/api/updates';
import {
  getUpdates,
  loadUpdates,
  deleteRichUpdate,
  watchingVideoToggle,
} from 'src/redux/modules/richUpdates';
import { PageState } from 'src/redux/modules/page';
import { scrolledToUpdate } from 'src/redux/modules/richUpdates/actions';
import { isClosed, isActive } from 'src/helpers/page';

interface StateProps {
  activatedAt?: string;
  id: string;
  updatesPageNo: number;
  updates: UpdateResource[];
  totalCountUpdates: number;
  profileImage?: string;
  ownerName: string;
  report?: string;
  status: string;
  finishedAt?: string;
  isLoadingUpdates: boolean;
  isOwner: boolean;
  raised: number;
  isClosed: boolean;
  isActive: boolean;
  completeImage?: string;
  page: PageState;
  scrollTo: boolean;
}

function mapStateToProps(state: AppState): StateProps {
  const pageClosed = isClosed(state.page);

  return {
    activatedAt: state.page.activatedAt,
    id: state.page.id,
    updatesPageNo: state.richUpdates.updatesPageNo,
    updates: getUpdates(state.richUpdates),
    totalCountUpdates: state.richUpdates.totalCount,
    profileImage: state.page.ownerProfileImageUrl,
    ownerName: state.page.ownerName,
    report: state.page.report,
    status: state.page.status,
    finishedAt: state.page.finishedAt,
    isLoadingUpdates: state.richUpdates.isLoading,
    isOwner: state.page.isOwnerRequested,
    raised:
      (pageClosed ? state.page.amountRaised : state.page.amountPledged) || 0,
    isClosed: pageClosed,
    isActive: isActive(state.page),
    completeImage: state.page.completeImage,
    page: state.page,
    scrollTo: state.richUpdates.scrollTo,
  };
}

interface DispatchProps {
  loadUpdates: (
    options: { pageId: string; pageNo: number; pageSize: number }
  ) => void;
  deleteRichUpdate: (updateId: number) => void;
  watchingVideoToggle: () => void;
  scrolledToUpdate: () => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      loadUpdates,
      deleteRichUpdate,
      watchingVideoToggle,
      scrolledToUpdate,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Updates);
