import React from 'react';
import config from 'config';
import executeGraphql from './executeLiveStreamGraphql';
import styles from './LiveStream.scss';
import Sticky from '../StickyHeader/Sticky';

interface StreamEntry {
  mediaType: string;
  streamId: string;
  streamType: string;
}

interface StreamState {
  mediaId: string;
  entry?: StreamEntry;
}

const nostream = {
  mediaId: '',
  entry: undefined,
};

const COVER_PHOTO_OFFSET = 430;

class LiveStream extends React.Component<{ pageId?: string }, StreamState> {
  constructor(props: any) {
    super(props);

    this.state = { ...nostream };
  }

  componentDidMount() {
    if (!this.props.pageId) {
      this.setState({
        ...nostream,
      });
      return;
    }

    if (typeof window === 'undefined') {
      return;
    }

    executeGraphql(this.props.pageId)
      .then(({ data }) => {
        if (data && data.livestream) {
          const { mediaId = '', entries = [] } = data.livestream;
          const entry = entries.find(
            (e: StreamEntry) => e.mediaType === 'livestreamMedia'
          );
          this.setState({
            mediaId,
            entry,
          });
        } else {
          this.setState({
            ...nostream,
          });
        }
      })
      .catch(() => {
        this.setState({
          ...nostream,
        });
      });
  }

  render() {
    const { entry } = this.state;
    return entry ? (
      <Sticky offset={COVER_PHOTO_OFFSET} delay={50}>
        {({ sticky }) => (
          <iframe
            title={getStreamTitle(entry)}
            src={constructStreamSource(entry)}
            height="400"
            width="100%"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            className={sticky ? styles['stream--fixed'] : styles.stream}
            data-qa="feature-image-stream"
          />
        )}
      </Sticky>
    ) : null;
  }
}

export function getStreamTitle({ streamType }: { streamType: string }) {
  const s = streamType.charAt(0).toUpperCase() + streamType.slice(1);
  return `${s} stream`;
}

export function getStreamUrl(streamUrl: string, streamId: string) {
  return streamUrl.replace('{streamId}', streamId);
}

export function constructStreamSource({
  streamType,
  streamId,
}: {
  streamType: string;
  streamId: string;
}) {
  const streamTypeUpperCase = streamType.toUpperCase();
  if (!(streamTypeUpperCase in config.STREAMS)) {
    throw new Error(`Stream type '${streamType}' not recognised.`);
  }

  const isTwitch = streamTypeUpperCase === 'TWITCH';
  const streamUrlTemplate = isTwitch
    ? `${config.STREAMS[streamTypeUpperCase]}${window.location.host}`
    : config.STREAMS[streamTypeUpperCase];
  return getStreamUrl(streamUrlTemplate, streamId);
}

export default LiveStream;
