import React from 'react';
import { Button } from 'src/components/Analytics/';
import { FormattedMessage } from 'react-intl';
import thankYou from './thankYou-il.svg';
import Svg from '@justgiving/svg';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import {
  isLocalStorageAvailable,
  isPropertyInLocalStorage,
} from 'src/helpers/browser';
import { Rule } from 'src/helpers/rulesEngine';

const SayThanks: Rule<JSX.Element, PromptRulesEngineOptions> = (
  state,
  options
) => {
  const { id, status } = state.page;
  const localStorageKey = 'prompt_sayThanks';

  const isOnLocal = isPropertyInLocalStorage(`prompt:${id}_${localStorageKey}`);

  const setLocalStorage = () => {
    if (isLocalStorageAvailable()) {
      localStorage.setItem(`prompt:${id}_${localStorageKey}`, 'true');
    }
  };

  const handleClose = () => {
    setLocalStorage();
    options!.closePrompt();
  };

  if (status === 'Active' && !isOnLocal && state.supporters.totalCount > 0) {
    return {
      matched: true,
      result: (
        <div className="row jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey">
          <section className="jg-text--center jg-space-pvmd jg-position-relative col-xs-12 col-md-6 offset-md-3">
            <Svg markup={thankYou} className="jg-svg-sm" />
            <h2 className="jg-text--center jg-space-mbms">
              <FormattedMessage
                id="SayThanks.greatNews"
                defaultMessage="Great news!"
              />
            </h2>
            <p className="jg-space-mbmd jg-space-phsm">
              <FormattedMessage
                id="SayThanks.youCanSayThanks"
                defaultMessage="You can now thank your donors by sending them a message. Hit the 'Say thanks' button below their donation to get started."
              />
            </p>
            <Button
              className="jg-btn jg-btn--ghost jg-width--300"
              onClick={handleClose}
              analyticsOptions={{
                event: 'click',
                subtype: 'button',
                eventValue: 'okay got it',
                pageSection: 'page view > headline message > thank supporter',
              }}
            >
              <FormattedMessage
                id="SayThanks.okayGotIt"
                defaultMessage="Okay, got it"
              />
            </Button>
          </section>
        </div>
      ),
    };
  }

  return { matched: false };
};

export default SayThanks;
