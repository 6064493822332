import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';
import {
  getSettings,
  updateSupportsRecurringDonationsStatus,
} from 'src/helpers/api/settings';
import { SettingsState } from 'src/redux/modules/settings/reducer';

export type SettingsActions =
  | SetRecurringDonationsStatusRequest
  | SetRecurringDonationsStatusSuccess
  | SetRecurringDonationsStatusFailure
  | GetPageSettingsRequest
  | GetPageSettingsSuccess
  | GetPageSettingsFailure
  | UpdateThankYouMessage
  | SetNotifcationsStatus;

export interface GetPageSettingsRequest {
  type: 'GET_PAGE_SETTINGS_REQUEST';
}

export interface GetPageSettingsSuccess {
  type: 'GET_PAGE_SETTINGS_SUCCESS';
  payload: Partial<SettingsState>;
}

export interface GetPageSettingsFailure {
  type: 'GET_PAGE_SETTINGS_FAILURE';
}

export interface SetNotifcationsStatus {
  type: 'SET_NOTIFICATIONS_STATUS';
  payload: boolean;
}

export function getPageSettings() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<GetPageSettingsRequest>({
      type: 'GET_PAGE_SETTINGS_REQUEST',
    });

    try {
      const { id } = getState().page;
      const response = await getSettings(id);

      if (!response) {
        throw new Error('Empty response');
      }

      const { supportsRecurringPayments, ...payload } = response;

      dispatch<GetPageSettingsSuccess>({
        type: 'GET_PAGE_SETTINGS_SUCCESS',
        payload: {
          ...payload,
          supportsRecurringDonations: supportsRecurringPayments,
          isDonationRemindLaterAvailable: !supportsRecurringPayments,
        },
      });
    } catch (e) {
      dispatch<GetPageSettingsFailure>({
        type: 'GET_PAGE_SETTINGS_FAILURE',
      });
    }
  };
}

export interface SetRecurringDonationsStatusRequest {
  type: 'SET_RECURRING_DONATIONS_STATUS_REQUEST';
}

export interface SetRecurringDonationsStatusSuccess {
  type: 'SET_RECURRING_DONATIONS_STATUS_SUCCESS';
  payload: any;
}

export interface SetRecurringDonationsStatusFailure {
  type: 'SET_RECURRING_DONATIONS_STATUS_FAILURE';
  payload: any;
}

export function setRecurringDonationsStatus(
  shouldRecurringDonationsBeEnabled: boolean
) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<SetRecurringDonationsStatusRequest>({
      type: 'SET_RECURRING_DONATIONS_STATUS_REQUEST',
    });

    try {
      const { id } = getState().page;
      const { thankYouMessage } = getState().settings;

      await updateSupportsRecurringDonationsStatus(
        id,
        shouldRecurringDonationsBeEnabled,
        thankYouMessage
      );

      dispatch<SetRecurringDonationsStatusSuccess>({
        type: 'SET_RECURRING_DONATIONS_STATUS_SUCCESS',
        payload: shouldRecurringDonationsBeEnabled,
      });
    } catch (error) {
      dispatch<SetRecurringDonationsStatusFailure>({
        type: 'SET_RECURRING_DONATIONS_STATUS_FAILURE',
        payload: false,
      });

      throw error;
    }
  };
}

export interface UpdateThankYouMessage {
  type: 'UPDATE_THANK_YOU_MESSAGE';
  payload: string;
}

export function updateThankYouMessage(payload: string) {
  return {
    type: 'UPDATE_THANK_YOU_MESSAGE',
    payload,
  };
}

export function setNotificationsStatus(payload: boolean) {
  return {
    type: 'SET_NOTIFICATIONS_STATUS',
    payload,
  };
}
