import React from 'react';
import { AnalyticsPageView } from 'src/components/Analytics';
import { LessThanMedium, Medium } from '@justgiving/responsive';
import MobileEncourageShare from './MobileEncourageShare';
import DesktopEncourageShare from './DesktopEncourageShare';
import { ShareActionOptions } from 'src/redux/modules/page';

interface Props {
  ownerName: string;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  targetCurrency: Currency;
  onCloseModal: () => void;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareBySms: (options: ShareActionOptions) => void;
  ownerProfileImage?: string;
}

const EncourageShare: React.StatelessComponent<Props> = ({
  ownerName,
  socialShareUrl,
  targetAmount,
  pitchFor,
  targetCurrency,
  onCloseModal,
  shareByWhatsApp,
  shareBySms,
  ownerProfileImage,
}) => {
  return (
    <AnalyticsPageView
      eventValue="share prompt"
      pageSection="page > share prompt"
      subtype="share prompt"
    >
      <LessThanMedium>
        <MobileEncourageShare
          ownerName={ownerName}
          closeModal={onCloseModal}
          socialShareUrl={socialShareUrl}
          targetAmount={targetAmount}
          shareByWhatsApp={shareByWhatsApp}
          shareBySms={shareBySms}
          pitchFor={pitchFor}
          targetCurrency={targetCurrency}
          ownerProfileImage={ownerProfileImage}
        />
      </LessThanMedium>
      <Medium>
        <DesktopEncourageShare
          ownerName={ownerName}
          closeModal={onCloseModal}
          ownerProfileImage={ownerProfileImage}
        />
      </Medium>
    </AnalyticsPageView>
  );
};

export default EncourageShare;
