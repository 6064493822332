import React from 'react';
import format from 'date-fns/format';
import styles from '../PromptClosed.scss';
import Svg from '@justgiving/svg';
import timer from '@justgiving/icons/lib/timer.svg';
import FormattedCurrency from 'src/components/FormattedCurrency';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { Rule } from 'src/helpers/rulesEngine';
import { FormattedMessage } from 'react-intl';

const PageClosedFullyFundedOwner: Rule<
  JSX.Element,
  PromptRulesEngineOptions
> = state => {
  const { amountRaised, targetCurrency, finishedAt, status } = state.page;
  const { totalCount } = state.supporters;

  if (status === 'FullyFunded' || status === 'ReportGenerated') {
    return {
      matched: true,
      result: (
        <div
          className={`${
            styles['container-funded']
          } jg-bdb jg-bd--solid jg-bd--very-light-grey`}
        >
          <h1 className="jg-h1 jg-space-mbsm">
            <FormattedMessage
              id="PageClosedFullyFundedOwner.youDidIt"
              defaultMessage="You did it!"
            />
          </h1>
          <p className={styles.message}>
            <FormattedMessage
              id="PageClosedFullyFundedOwner.youRaised"
              defaultMessage="You raised {amountRaised} from {supporterCount}."
              values={{
                amountRaised: (
                  <b>
                    <FormattedCurrency
                      value={amountRaised}
                      currency={targetCurrency || 'GBP'}
                      minimumFractionDigits={0}
                    />
                  </b>
                ),
                supporterCount: (
                  <FormattedMessage
                    id="PageClosedFullyFundedOwner.supporterCount"
                    defaultMessage="{totalCount} {totalCount, plural, one {supporter} other {supporters}}"
                    values={{ totalCount }}
                    tagName="b"
                  />
                ),
              }}
            />
          </p>
          <div className={`${styles.closed}`}>
            <Svg
              className={`jg-fill--nobel jg-icon--medium-small jg-space-mbsm jg-space-mrxs ${
                styles.timer
              }`}
              markup={timer}
            />
            <p className="jg-space-mbn jg-text--small">
              <FormattedMessage
                id="PageClosedFullyFundedOwner.closedAt"
                defaultMessage="Closed {date}"
                values={{
                  date: format(finishedAt!, 'DD/MM/YYYY'),
                }}
              />
            </p>
          </div>
        </div>
      ),
    };
  }

  return { matched: false };
};

export default PageClosedFullyFundedOwner;
