import React from 'react';
import styles from './QRCodeShareButton.scss';
import CSSModules from 'react-css-modules';
import { ShareActionOptions } from 'src/redux/modules/page';
import QRCodeShare from '../SocialShare/QRCodeShare';

export interface Props {
  isActive: boolean;
  handleClick: () => void;
  shareQrCode: (options: ShareActionOptions) => void;
  pageSection: string;
}

const QRCodeShareButton: React.StatelessComponent<Props> = ({
  pageSection,
  handleClick,
  shareQrCode,
  isActive,
}) => {
  return (
    <div styleName="share-button-container">
      <QRCodeShare
        shareQrCode={shareQrCode}
        isActive={isActive}
        location={pageSection}
        buttonText="QR Code"
        classNames={styles.qr}
        handleClick={handleClick}
      />
    </div>
  );
};

export default CSSModules(QRCodeShareButton, styles);
