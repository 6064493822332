import React from 'react';
import { floor } from 'src/helpers/math';
import { formatCurrency } from 'src/helpers/formatter';

interface PageClosedProps {
  raised: number;
  targetCurrency: Currency;
  pitchFor: string;
}

export function PageClosedPitch({
  raised,
  targetCurrency,
  pitchFor,
}: PageClosedProps) {
  return (
    <Pitch
      text={'Iʼve raised'}
      amount={raised}
      targetCurrency={targetCurrency}
      pitchFor={pitchFor}
    />
  );
}

interface PageActiveProps {
  targetAmount: number;
  targetCurrency: Currency;
  pitchFor: string;
}

export function PageActivePitch({
  targetAmount,
  targetCurrency,
  pitchFor,
}: PageActiveProps) {
  return (
    <Pitch
      text="Iʼm raising"
      amount={targetAmount}
      targetCurrency={targetCurrency}
      pitchFor={pitchFor}
    />
  );
}

interface Props {
  text: string;
  amount: number;
  targetCurrency: Currency;
  pitchFor: string;
}

export const Pitch: React.StatelessComponent<Props> = ({
  text,
  amount,
  targetCurrency,
  pitchFor,
}) => {
  const formattedNumber = formatCurrency(floor(amount, 0), {
    currency: targetCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <span>
      {text} {formattedNumber} to {pitchFor}
    </span>
  );
};
