import React from 'react';
import CSSModules from 'react-css-modules';
import { getCoverImageUrl } from '../../helpers/image';
import styles from './CoverImage.scss';
import { CoverImage as CoverImageType } from 'src/helpers/image';

const maxSize = { width: 1200 * 1.7, height: 400 * 1.7 };
const imageTemplate = 'Size800w';

interface Props {
  coverImage?: CoverImageType;
}

interface State {
  imageUrl: string;
}

class CoverImage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      imageUrl: getCoverImageUrl(props.coverImage, maxSize, imageTemplate),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      imageUrl: getCoverImageUrl(nextProps.coverImage, maxSize, imageTemplate),
    });
  }

  render() {
    const { imageUrl } = this.state;

    return (
      <div styleName="image" style={{ backgroundImage: `url(${imageUrl})` }} />
    );
  }
}

export default CSSModules(CoverImage, styles);
