export function removeNewLines(text: string) {
  return text
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();
}

export function removeNullCharacters(text: string) {
  return text.replace(/\0/g, '');
}
