import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ErrorComponent from 'src/components/Error/Error';

const ErrorPageView = ({ error }) => <ErrorComponent error={error} />;

ErrorPageView.propTypes = {
  error: PropTypes.string,
};

export default connect(state => ({ error: state.page.failType }))(
  ErrorPageView
);
