import React, { Component } from 'react';
import { PersonalIdNumberData } from '.';

export interface FullSSNFormProps {
  additionalFirstInputClassName?: string;
  additionalSecondInputClassName?: string;
  additionalThirdInputClassName?: string;
  handleFocus: (id: string) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleValidation: () => void;
  handleValidationStatus: () => void;
  values: PersonalIdNumberData;
}

export interface State {
  ssn_1: string;
  ssn_2: string;
  ssn_3: string;
}

type Field = keyof State;

export type Props = FullSSNFormProps & React.HTMLProps<HTMLInputElement>;

class FullSSNForm extends Component<Props, State> {
  ref1 = React.createRef<HTMLInputElement>();
  ref2 = React.createRef<HTMLInputElement>();
  ref3 = React.createRef<HTMLInputElement>();

  state = {
    ssn_1: '',
    ssn_2: '',
    ssn_3: '',
  };

  handleInternalChange(field: Field, val: string) {
    const newState = { [field]: val };
    this.setState(newState as Pick<State, Field>, () => {
      if (
        this.state.ssn_1 &&
        this.state.ssn_2 &&
        this.state.ssn_3.length === 4
      ) {
        this.props.handleValidation();
        this.props.handleValidationStatus();
      }
    });
  }

  moveFocus(
    currentPosition: number,
    after: number,
    targetRef: React.RefObject<HTMLInputElement>
  ) {
    if (currentPosition === after) {
      if (targetRef && targetRef.current) {
        targetRef.current.focus();
      }
    }
  }

  render() {
    const {
      additionalFirstInputClassName,
      additionalSecondInputClassName,
      additionalThirdInputClassName,
      handleFocus,
      handleChange,
      handleBlur,
      handleValidation,
    } = this.props;

    return (
      <div className="row">
        <div className="col-xs-4">
          <input
            type="password"
            ref={this.ref1}
            className={`jg-form-control ${additionalFirstInputClassName || ''}`}
            maxLength={3}
            placeholder="xxx"
            onChange={event => {
              handleChange(event);
              this.handleInternalChange('ssn_1', event.target.value);
              this.moveFocus(event.target.value.length, 3, this.ref2);
            }}
            id="personalIdNumber_1"
            name="personalIdNumber_1"
            onFocus={() => handleFocus('personalIdNumber_1')}
            onBlur={handleBlur}
          />
        </div>
        <div className="col-xs-4">
          <input
            type="password"
            ref={this.ref2}
            className={`jg-form-control ${additionalSecondInputClassName ||
              ''}`}
            maxLength={2}
            placeholder="xx"
            onChange={event => {
              handleChange(event);
              this.handleInternalChange('ssn_2', event.target.value);
              this.moveFocus(event.target.value.length, 2, this.ref3);
            }}
            id="personalIdNumber_2"
            name="personalIdNumber_2"
            onFocus={() => handleFocus('personalIdNumber_2')}
            onBlur={handleBlur}
          />
        </div>
        <div className="col-xs-4">
          <input
            type="password"
            ref={this.ref3}
            className={`jg-form-control ${additionalThirdInputClassName || ''}`}
            maxLength={4}
            placeholder="xxxx"
            id="personalIdNumber_3"
            name="personalIdNumber_3"
            onFocus={() => handleFocus('personalIdNumber_3')}
            onChange={event => {
              handleChange(event);
              this.handleInternalChange('ssn_3', event.target.value);
            }}
            onBlur={() => handleValidation()}
          />
        </div>
      </div>
    );
  }
}

export default FullSSNForm;
