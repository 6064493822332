import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageHeader from 'src/components/Page/PageHeader';
import { AppState } from 'src/redux/modules';
import { CategoryResource } from 'src/helpers/api/categories';
import {
  goEdit,
  donate,
  clearRecaptcha,
  sharePageLink,
  contactOwner,
  PageState,
  ShareActionOptions,
} from 'src/redux/modules/page';
import {
  SupportersAvatarState,
  loadSupportersAvatar,
} from 'src/redux/modules/supportersAvatar';
import { isActive, isClosed, canPageReceiveDonations } from 'src/helpers/page';
import { ScrollToLocation } from 'src/redux/modules/page/navigation';
import { CharityState } from 'src/redux/modules/charity/reducer';

interface StateProps {
  categories: CategoryResource[];
  isActive: boolean;
  canPageReceiveDonations: boolean;
  isClosed: boolean;
  page: PageState;
  raised: number;
  totalCountSupporters: number;
  supportersAvatar: SupportersAvatarState;
  profileImage?: string;
  recaptcha: string;
  location?: string;
  supportsRecurringDonations: boolean;
  isDonationRemindLaterAvailable: boolean;
  charity: CharityState;
  recurringDonationsPitch?: string;
}

interface DispatchProps {
  goEdit: (section: ScrollToLocation) => void;
  donate: () => void;
  loadSupportersAvatar: (details: { pageId: string; size: number }) => void;
  clearRecaptcha: () => void;
  contactOwner: (data: any) => void;
  sharePageLink: (options: ShareActionOptions) => void;
}

interface OwnProps {
  store?: any;
  handleShareButtonClick: () => void;
  handleReminderModalToggle: (isOpen: boolean) => void;
}

function mapStateToProps(state: AppState): StateProps {
  const { page, supportersAvatar, settings } = state;
  const pageClosed = isClosed(page);
  return {
    categories: state.categories,
    isActive: isActive(page),
    canPageReceiveDonations: canPageReceiveDonations(page),
    isClosed: pageClosed,
    page,
    raised: (pageClosed ? page.amountRaised : page.amountPledged) || 0,
    totalCountSupporters: state.supporters.totalCount,
    supportersAvatar,
    recaptcha: page.recaptcha,
    profileImage: page.ownerProfileImageUrl,
    location: page.locationText,
    supportsRecurringDonations: settings.supportsRecurringDonations,
    charity: state.charity,
    recurringDonationsPitch: state.page.recurringDonationsPitch,
    isDonationRemindLaterAvailable: settings.isDonationRemindLaterAvailable,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      goEdit,
      donate,
      loadSupportersAvatar,
      clearRecaptcha,
      contactOwner,
      sharePageLink,
    },
    dispatch
  );
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PageHeader);
