import { AnalyticsMeta } from 'src/redux/modules/analytics';

export interface DonateAction {
  type: 'DONATE';
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function donate(): DonateAction {
  return {
    type: 'DONATE',
    meta: {
      analytics: {
        omitKinesis: true, // tracked server-side from /donate enpoint for Kinesis
        event: 'link click',
        subtype: 'button',
        event_value: 'donate',
        page_section: '',
        activity_type: 'crowdfunding_guid',
        actor_source: 'ownerUserId',
      },
    },
  };
}
