import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import DonationsRefundedContent from './DonationsRefundedContent';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';

const DonationsRefunded: Rule<
  JSX.Element,
  PromptRulesEngineOptions
> = state => {
  if (state.page.status === 'Suspended' && state.page.refundedDate) {
    return {
      matched: true,
      result: <DonationsRefundedContent />,
    };
  }

  return { matched: false };
};

export default DonationsRefunded;
