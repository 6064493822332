import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Supporters from 'src/components/Supporters/Supporters';
import { AppState } from 'src/redux/modules';
import {
  SupportersState,
  loadSupporters,
  thankSupporter,
} from 'src/redux/modules/supporters';
import { donate, PageState } from 'src/redux/modules/page';
import { isActive, isClosed, canPageReceiveDonations } from 'src/helpers/page';

interface StateProps {
  page: PageState;
  isActive: boolean;
  isClosed: boolean;
  canPageReceiveDonations: boolean;
  ownerName: string;
  profileImage?: string;
  pageId: string;
  supporterPageNo?: number;
  supporters: SupportersState;
  isOwner: boolean;
  targetCurrency: string;
  totalCountSupporters: number;
}

interface DispatchProps {
  donate: () => void;
  loadSupporters: (
    details: { pageId: string; pageNo: number; pageSize: number }
  ) => void;
  thankSupporter: (supporterId: number, thankYouMessage: string) => void;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    page: state.page,
    isActive: isActive(state.page),
    isClosed: isClosed(state.page),
    canPageReceiveDonations: canPageReceiveDonations(state.page),
    ownerName: state.page.ownerName,
    profileImage: state.page.ownerProfileImageUrl,
    pageId: state.page.id,
    supporterPageNo: state.supporters.supporterPageNo,
    supporters: state.supporters,
    isOwner: state.page.isOwnerRequested,
    targetCurrency: state.page.targetCurrency,
    totalCountSupporters: state.supporters.totalCount,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      donate,
      loadSupporters,
      thankSupporter,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Supporters);
