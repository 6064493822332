import { AnalyticsClick, analyticsClick } from 'src/redux/modules/analytics';
import { Dispatch, bindActionCreators } from 'redux';
import {
  PageState,
  ShareActionOptions,
  createPageLikeThis,
  crossSellBannerImpression,
  shareByEmail,
  shareBySms,
  shareByWhatsApp,
  shareOnLinkedIn,
  shareOnMessenger,
  shareOnTwitter,
  sharePageLink,
  shareQrCode,
  tipsPromptClosed,
} from 'src/redux/modules/page';
import { RouteComponentProps, withRouter } from 'react-router';
import { canPageReceiveDonations, isActive, isClosed } from 'src/helpers/page';
import renderRoutes, { RouteConfig } from 'src/helpers/renderRoutes';

import AboutFundraiserContainer from 'src/containers/page/AboutFundraiserContainer';
import { AppState } from 'src/redux/modules';
import CrossSellBanner from 'src/components/CrossSellBanner/CrossSellBanner';
import EncourageShareContainer from 'src/containers/page/EncourageShareContainer';
import {
  SUPPRESS_DONOR_GENERATED_CONTENT,
  SUPPRESS_FB_DONOR_GENERATED_CONTENT,
} from 'config';
import LastUpdated from 'src/components/LastUpdated/LastUpdated';
import MetaContainer from 'src/containers/page/MetaContainer';
import PageContent from 'src/components/Page/PageContent';
import PageContentWrapper from 'src/components/Page/PageContentWrapper';
import PageFooterContainer from 'src/containers/page/PageFooterContainer';
import PageHeaderContainer from 'src/containers/page/PageHeaderContainer';
import PageSidebar from 'src/components/Page/PageSidebar';
import PageWrapper from 'src/components/Page/PageWrapper';
import React from 'react';
import RecurringDonationsAwarenessBannerContainer from 'src/containers/page/RecurringDonationsAwarenessBannerContainer';
import RichStory from 'src/components/RichStory';
import ShareModal from 'src/components/ShareModal/ShareModal';
import SocialShareContainer from 'src/containers/page/SocialShareContainer';
import StickyHeaderDonorContainer from 'src/containers/page/StickyHeaderDonorContainer';
import { StoryNode } from 'src/helpers/api/pages';
import SupportMessage from 'src/components/SupportMessage/SupportMessage';
import { SupporterResource } from 'src/helpers/api/activities';
import SupportersContainer from 'src/containers/page/SupportersContainer';
import { TranslateYTransition } from 'src/components/Transitions';
import UpdateModalView from '../updates/UpdateModalView';
import { UpdateResource } from 'src/helpers/api/updates';
import UpdatesContainer from 'src/containers/page/UpdatesContainer';
import WhatIsCrowdfunding from 'src/components/WhatIsCrowdfunding/WhatIsCrowdfunding';
import { closeSharePrompts } from 'src/redux/modules/sharePrompts';
import { connect } from 'react-redux';
import { getCharityInfo } from 'src/redux/modules/charity/actions';
import { getPageSection } from 'src/helpers/analytics';
import { getPageSettings } from 'src/redux/modules/settings/actions';
import { getUpdates } from 'src/redux/modules/richUpdates';
import promptRulesEngine from 'src/helpers/prompts';
import { push } from 'react-router-redux';
import { scrollToUpdate as scrollToUpdateAction } from 'src/redux/modules/richUpdates/actions';
import { setScrollRestoration } from 'src/helpers/browser';
import { updateHeapPageId } from 'src/helpers/heap';
import DonatePopup from 'src/components/DonatePopup/DonatePopup';
import {
  DonatePopupProvider,
  DonatePopupContext,
} from 'src/components/DonatePopup/DonatePopupContext';

type MatchParams = {
  name: string;
  updateId?: string;
};

type OwnProps = RouteComponentProps<MatchParams> & { route: RouteConfig };

interface StateProps {
  page: PageState;
  supporters: SupporterResource[];
  isPageClosed: boolean;
  socialShareUrl: string;
  id: string;
  isLoadingUpdates: boolean;
  updates: UpdateResource[];
  richStory?: StoryNode[];
  isSharePromptsOpen: boolean;
  isShareWithFriendsPromptsOpen: boolean;
  isOwnerRequested: boolean;
  appState: AppState;
  isCreateWizardOpen: boolean;
  supportsRecurringDonations: boolean;
  ownerName: string;
  ownerProfileImageUrl?: string;
  pitchFor: string;
  isActive: boolean;
  targetAmount: number;
  pageName: string;
  totalCountSupporters: number;
  areDonationsEnabled: boolean;
}

const mapStateToProps = (state: AppState, props: OwnProps): StateProps => {
  return {
    id: state.page.id,
    isPageClosed: isClosed(state.page),
    page: state.page,
    supporters: state.supporters.items,
    socialShareUrl: state.page.socialShareUrl,
    isLoadingUpdates: state.richUpdates.isLoading,
    updates: getUpdates(state.richUpdates),
    isSharePromptsOpen: state.sharePrompts.isSharePromptsOpen,
    isShareWithFriendsPromptsOpen:
      state.shareWithFriendsPrompts.isShareWithFriendsPromptsOpen,
    richStory: state.page.richStory,
    isOwnerRequested: state.page.isOwnerRequested,
    appState: state,
    isCreateWizardOpen: state.page.isCreateWizardOpen,
    supportsRecurringDonations: state.settings.supportsRecurringDonations,
    ownerProfileImageUrl: state.page.ownerProfileImageUrl,
    ownerName: state.page.ownerName,
    pitchFor: state.page.pitchFor,
    isActive: isActive(state.page),
    targetAmount: Math.floor(state.page.targetAmount),
    pageName: state.page.name,
    totalCountSupporters: state.supporters.totalCount,
    areDonationsEnabled: canPageReceiveDonations(state.page),
  };
};

interface DispatchProps {
  onPageMount: (page: PageState) => void;
  tipsPromptClosed: () => void;
  createPageLikeThis: () => void;
  crossSellBannerImpression: () => void;
  scrollToUpdate: () => void;
  getPageSettings: () => void;
  getCharityInfo: (charityId: number) => void;
  dispatch: Dispatch<AppState>;
  analyticsClick: AnalyticsClick;
  shareOnTwitter: (options: ShareActionOptions) => void;
  shareByEmail: (options: ShareActionOptions) => void;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareOnMessenger: (options: ShareActionOptions) => void;
  shareOnLinkedIn: (options: ShareActionOptions) => void;
  sharePageLink: (options: ShareActionOptions) => void;
  shareBySms: (options: ShareActionOptions) => void;
  shareQrCode: (options: ShareActionOptions) => void;
  closeSharePrompts: () => void;
  push: (location: any) => void;
}

const mapDispatchToProps = (dispatch: any, props: OwnProps): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        // TODO: remove once analytics middleware gets enabled server-side
        onPageMount: page => ({ type: 'PAGE_MOUNT', payload: page }),
        tipsPromptClosed,
        createPageLikeThis,
        crossSellBannerImpression,
        scrollToUpdate: scrollToUpdateAction,
        getPageSettings,
        getCharityInfo,
        analyticsClick,
        shareOnTwitter,
        shareByEmail,
        shareByWhatsApp,
        shareOnMessenger,
        shareOnLinkedIn,
        sharePageLink,
        shareQrCode,
        closeSharePrompts,
        push,
        shareBySms,
      },
      dispatch
    ),
    dispatch,
  };
};

export type Props = OwnProps & StateProps & DispatchProps;

interface State {
  promptTransitionActive?: boolean;
  promptHeight?: number;
  shareModalOpen: boolean;
  shareModalOpenPageSection: string;
  isSetReminderModalOpen: boolean;
  updateImageUrl: string;
}

export class PageView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shareModalOpen: false,
      shareModalOpenPageSection: '',
      isSetReminderModalOpen: false,
      updateImageUrl: '',
    };
  }

  async componentDidMount() {
    const {
      page,
      onPageMount,
      match,
      scrollToUpdate,
      isPageClosed,
    } = this.props;

    // This is used by analytics for server-side rendered pages, which don't
    // receive PAGE_SUCCESS on the first page load on the client, but still
    // need to send analytics events like "page view".
    // TODO: remove once analytics middleware gets enabled server-side
    onPageMount(page);

    if (match.params.updateId !== undefined) {
      setScrollRestoration('manual');
      scrollToUpdate();
    }

    if (!isPageClosed) {
      this.props.getPageSettings();
    }

    if (page.charityId) {
      this.props.getCharityInfo(page.charityId);
    }

    updateHeapPageId(page);
  }

  componentWillUnmount() {
    setScrollRestoration('auto');
  }

  activatePromptTransition = ({ height }: { height: number }) => {
    this.setState({ promptTransitionActive: true, promptHeight: height });
  };

  closePrompt = () => {
    this.props.tipsPromptClosed();
    this.setState({ promptTransitionActive: false });
  };

  handleShareModalClose = () => {
    this.props.analyticsClick({
      event: 'click',
      subtype: 'button',
      eventValue: 'close share modal',
      pageSection: this.state.shareModalOpenPageSection,
    });
    this.setState({ shareModalOpen: false, shareModalOpenPageSection: '' });
  };

  handleShareButtonClick = (pageSection: string) => {
    this.props.analyticsClick({
      event: 'click',
      eventValue: 'open share modal',
      pageSection,
      subtype: 'button',
    });
    this.setState({
      shareModalOpen: true,
      shareModalOpenPageSection: pageSection,
    });
  };

  render() {
    const {
      page,
      route,
      isPageClosed,
      socialShareUrl,
      richStory,
      isOwnerRequested,
      supporters,
      updates,
      isCreateWizardOpen,
      supportsRecurringDonations,
    } = this.props;
    const { promptTransitionActive, promptHeight } = this.state;
    const showStickyHeader = !isOwnerRequested && !isPageClosed;
    const prompt = promptRulesEngine(this.props.appState, isOwnerRequested, {
      dispatch: this.props.dispatch,
      pageSection: getPageSection(this.props.location.pathname),
      activatePromptTransition: this.activatePromptTransition,
      closePrompt: this.closePrompt,
      promptTransitionActive: this.state.promptTransitionActive,
    });

    const showDonorContent =
      SUPPRESS_DONOR_GENERATED_CONTENT.indexOf(page.id) < 0;
    const showFbDonorContent =
      SUPPRESS_FB_DONOR_GENERATED_CONTENT.indexOf(page.id) < 0;
    return (
      <DonatePopupProvider>
        <MetaContainer />

        {showStickyHeader && (
          <StickyHeaderDonorContainer
            handleShareButtonClick={() =>
              this.handleShareButtonClick(
                'page > share and donate fixed header'
              )
            }
            id={page.id}
          />
        )}

        <div style={{ marginBottom: promptHeight }}>
          {!isCreateWizardOpen && <section>{prompt}</section>}

          <TranslateYTransition
            active={promptTransitionActive}
            endOffset={promptHeight}
            duration="0.5s"
            timingFunction="ease-in-out"
          >
            <PageWrapper>
              <PageHeaderContainer
                handleShareButtonClick={() =>
                  this.handleShareButtonClick('page > top')
                }
                handleReminderModalToggle={(isOpen: boolean) =>
                  this.setState({ isSetReminderModalOpen: isOpen })
                }
              />

              <PageContentWrapper>
                <PageContent>
                  <RichStory storyNodes={richStory} />
                  {supportsRecurringDonations && (
                    <div className="jg-space-mhmd jg-space-mhxl@lg jg-space-mbml jg-space-mbsm@lg">
                      <RecurringDonationsAwarenessBannerContainer />
                    </div>
                  )}
                  <SocialShareContainer />
                  {showDonorContent && <UpdatesContainer />}
                  {showDonorContent &&
                    showFbDonorContent && (
                      <SupportMessage socialShareUrl={socialShareUrl} />
                    )}
                  <LastUpdated
                    createdAt={page.createdAt}
                    activatedAt={page.activatedAt!}
                    supporters={supporters}
                    updates={updates}
                  />
                </PageContent>

                <PageSidebar>
                  <SupportersContainer />
                  <WhatIsCrowdfunding className="jg-bdt jg-bd--solid jg-bd--very-light-grey jg-space-ptlg jg-space-pmd jg-space-mtlg jg-space-mbmd" />
                </PageSidebar>
              </PageContentWrapper>
            </PageWrapper>

            {isPageClosed && (
              <CrossSellBanner
                crossSellBannerImpression={this.props.crossSellBannerImpression}
                createPageLikeThis={this.props.createPageLikeThis}
              />
            )}
            <div className="jg-bdt jg-bd--solid jg-bd--very-light-grey jg-space-pms jg-space-pblg">
              <AboutFundraiserContainer />
            </div>
            <DonatePopupContext.Consumer
              children={donatePopup => (
                <EncourageShareContainer
                  isEnabled={
                    !this.state.shareModalOpen &&
                    !this.state.isSetReminderModalOpen
                  }
                  isDonatePopupOpen={donatePopup.popupCheckoutMode !== 'closed'}
                />
              )}
            />
          </TranslateYTransition>
          <PageFooterContainer
            handleShareButtonClick={() =>
              this.handleShareButtonClick('page > sticky footer')
            }
          />
        </div>
        <UpdateModalView />
        <ShareModal
          pageSection={this.state.shareModalOpenPageSection}
          ownerProfileImageUrl={this.props.ownerProfileImageUrl}
          ownerName={this.props.ownerName}
          handleModalClose={this.handleShareModalClose}
          showModal={this.state.shareModalOpen}
          shareOnTwitter={this.props.shareOnTwitter}
          shareByEmail={this.props.shareByEmail}
          shareByWhatsApp={this.props.shareByWhatsApp}
          shareOnMessenger={this.props.shareOnMessenger}
          shareOnLinkedIn={this.props.shareOnLinkedIn}
          shareQrCode={this.props.shareQrCode}
          sharePageLink={this.props.sharePageLink}
          shareBySms={this.props.shareBySms}
          pitchFor={this.props.pitchFor}
          isOwner={this.props.isOwnerRequested}
          isActive={this.props.isActive}
          socialShareUrl={this.props.socialShareUrl}
          targetAmount={this.props.targetAmount}
          targetCurrency={this.props.page.targetCurrency}
          closeSharePrompts={this.props.closeSharePrompts}
          pageName={this.props.pageName}
          push={this.props.push}
        />
        <DonatePopup />
        {renderRoutes(route.routes)}
      </DonatePopupProvider>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PageView)
);
