import { add } from 'src/helpers/api/censures';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';
import { AnalyticsMeta } from 'src/redux/modules/analytics';

interface AddCensureRequestAction {
  type: 'ADD_CENSURE_REQUEST';
  meta: { analytics: AnalyticsMeta };
}

interface AddCensureSuccessAction {
  type: 'ADD_CENSURE_SUCCESS';
}

interface AddCensureFailureAction {
  type: 'ADD_CENSURE_FAILURE';
  error: true;
  payload: Error;
}

export function addCensure() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<AddCensureRequestAction>({
      type: 'ADD_CENSURE_REQUEST',
      meta: {
        analytics: {
          event: 'link click',
          subtype: 'button',
          event_value: 'Report this page',
          page_section: 'page > footer',
          activity_type: 'crowdfunding_guid',
        },
      },
    });

    const pageId = getState().page.id;
    const key = `Censure:${pageId}`;

    if (!isSessionStorageAvailable()) {
      return;
    }

    if (window.sessionStorage.getItem(key)) {
      return;
    }

    try {
      await add(pageId);

      dispatch<AddCensureSuccessAction>({ type: 'ADD_CENSURE_SUCCESS' });
      window.sessionStorage.setItem(key, JSON.stringify(true));
    } catch (error) {
      dispatch<AddCensureFailureAction>({
        type: 'ADD_CENSURE_FAILURE',
        error: true,
        payload: error,
      });
    }
  };
}

function isSessionStorageAvailable() {
  try {
    window.sessionStorage.setItem('sessionStorage', '1');
    window.sessionStorage.removeItem('sessionStorage');
    return true;
  } catch (e) {
    return false;
  }
}

type Actions =
  | AddCensureRequestAction
  | AddCensureSuccessAction
  | AddCensureFailureAction;

export default function reducer(state = {}, action: Actions) {
  switch (action.type) {
    default:
      return state;
  }
}
