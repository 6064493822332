import {
  createValidator,
  Rules,
  required,
  pattern,
} from 'src/helpers/validation';
import {
  SSNLastFourFormData,
  PersonalIdNumberData,
} from 'src/components/AdditionalDetails/';

const ssnLastFourRule: Rules<SSNLastFourFormData> = {
  ssnLast4: [
    required({
      message: 'Please enter the last 4 digits of your SSN',
      emptyValues: ['xxxx'],
    }),
    pattern(/^\d{4}?$/, {
      message:
        'Your SSN should only contain numbers. Please enter the last 4 digits of your SSN.',
    }),
  ],
};

const personalIdNumberRule: Rules<PersonalIdNumberData> = {
  personalIdNumber_1: [
    required({
      message: 'Please enter your Social Security Number',
      emptyValues: ['xxx'],
    }),
    pattern(/^[\d\- ]+$/, {
      message: 'Your SSN should only include numbers. Please enter your SSN.',
    }),
    pattern(/^\d{3}?$/, {
      message: 'Please enter the first 3 digits of your SSN.',
    }),
  ],
  personalIdNumber_2: [
    required({
      message: 'Please enter the 2 middle digits of your SSN.',
      emptyValues: ['xx'],
    }),
    pattern(/^[\d\- ]+$/, {
      message: 'Your SSN should only include numbers. Please enter your SSN.',
    }),
    pattern(/^\d{2}?$/, {
      message: 'Please enter the 2 middle digits of your SSN.',
    }),
  ],
  personalIdNumber_3: [
    required({
      message: 'Please enter the last 4 digits of your SSN.',
      emptyValues: ['xxxx'],
    }),
    pattern(/^[\d\- ]+$/, {
      message: 'Your SSN should only include numbers. Please enter your SSN.',
    }),
    pattern(/^\d{4}?$/, {
      message: 'Please enter the last 4 digits of your SSN.',
    }),
  ],
};

export const ssnLastFourValidation = createValidator(ssnLastFourRule);
export const personalIdNumberValidation = createValidator(personalIdNumberRule);
