import React from 'react';
import CSSModules from 'react-css-modules';
import Modal from 'src/components/Modal';
import EmailShare from 'src/components/SocialShare/EmailShare';
import WhatsAppShare from 'src/components/SocialShare/WhatsAppShare';
import { generateHash } from 'src/helpers/hashId';
import { buildUrl } from 'src/helpers/url';
import styles from './ShareWithFriendsPrompts.scss';
import { ShareActionOptions } from 'src/redux/modules/page';
import currencyMap from '../../helpers/currencyMap';
import { Trans } from 'react-i18next';
import MessengerShareButton from '../SocialShare/MessengerShareButton';
import CopyToClipboard from '@justgiving/copy-to-clipboard';
import { AnalyticsPageView } from 'src/components/Analytics';
import ShowMoreOptionsButton from './ShowMoreOptionsButton';

interface Props {
  socialShareUrl: string;
  currencySymbol: string;
  sharePageLink: (options: ShareActionOptions) => void;
  closeSharePrompts: () => void;
  isActive: boolean;
  raised?: number;
  shareByEmail: (options: ShareActionOptions) => void;
  targetAmount: number;
  targetCurrency: Currency;
  pitchFor: string;
  ownerName: string;
  isOwner: boolean;
  pageSection: string;
  push: (location: any) => void;
  pageName: string;
  shareOnMessenger: (options: ShareActionOptions) => void;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareOnTwitter: (options: ShareActionOptions) => void;
}

interface State {
  copyLinkUrl: string;
  copyLinkUtmTerm: string;
  linkCopied: boolean;
}

class SharePrompts extends React.Component<Props, State> {
  textInput: HTMLInputElement;
  state = {
    copyLinkUrl: '',
    copyLinkUtmTerm: '',
    linkCopied: false,
  };

  componentDidMount() {
    this.generateCopyLinkState(false);
  }

  onCopied = () => {
    const { pageSection, sharePageLink } = this.props;
    const { copyLinkUtmTerm } = this.state;

    sharePageLink({ shareHash: copyLinkUtmTerm, location: pageSection });

    this.generateCopyLinkState(true);
  };

  generateCopyLinkState = (linkCopied: boolean) => {
    const copyLinkUtmTerm = generateHash();
    const copyLinkUrl = buildUrl(this.getSocialShareUrl(), {
      utm_term: copyLinkUtmTerm,
    });

    this.setState({ copyLinkUrl, copyLinkUtmTerm, linkCopied });
  };

  getTargetAmountFormatted = () => {
    return `${currencyMap[this.props.targetCurrency] || currencyMap.GBP}${
      this.props.targetAmount
    }`;
  };

  getSocialShareUrl = () => {
    return `${this.props.socialShareUrl}/feedback`;
  };

  render() {
    const {
      closeSharePrompts,
      isActive,
      ownerName,
      pageSection,
      pitchFor,
      shareByEmail,
      shareByWhatsApp,
      shareOnTwitter,
      targetAmount,
      targetCurrency,
      shareOnMessenger,
    } = this.props;

    const { copyLinkUrl, linkCopied } = this.state;
    const socialShareUrl = this.getSocialShareUrl();

    return (
      <Modal
        isOpen
        contentLabel="Share your Page"
        onClose={closeSharePrompts}
        colorTheme="dark"
        maintainPosition
      >
        <AnalyticsPageView
          eventValue="share your page with friends"
          pageSection={pageSection}
          subtype="admin"
        >
          <div styleName="container">
            <div>
              <header className="jg-background--white-smoke jg-space-pms jg-bdb jg-bd--solid jg-bd--very-light-grey">
                <h1 className="jg-h3 jg-space-mbsm">
                  <Trans i18nKey="ShareWithFriendsModal.title" />
                </h1>
                <p styleName="social-actions">
                  <Trans i18nKey="ShareWithFriendsModal.subTitle" />
                </p>
              </header>
              <div styleName="social-actions" className="jg-space-pml">
                <EmailShare
                  shareByEmail={shareByEmail}
                  socialShareUrl={socialShareUrl}
                  targetAmount={targetAmount}
                  pitchFor={pitchFor}
                  ownerName={ownerName}
                  isActive={isActive}
                  targetCurrency={targetCurrency}
                  location={pageSection}
                  className="dna-button dna-space-mbm"
                  buttonText="Email"
                  customStyle="email"
                  messageType="shareWithFriends"
                />
                <WhatsAppShare
                  shareByWhatsApp={shareByWhatsApp}
                  socialShareUrl={socialShareUrl}
                  targetAmount={targetAmount}
                  pitchFor={pitchFor}
                  ownerName={ownerName}
                  isActive={isActive}
                  targetCurrency={targetCurrency}
                  location={pageSection}
                  className="dna-button-whatsapp dna-space-mbm"
                  buttonText="WhatsApp"
                  messageType="shareWithFriends"
                />
                <div className="jg-space-mbms">
                  <MessengerShareButton
                    socialShareUrl={socialShareUrl}
                    location={pageSection}
                    shareOnMessenger={shareOnMessenger}
                  />
                </div>
                <div className="jg-space-mbms">
                  <CopyToClipboard
                    text={copyLinkUrl}
                    copyMessage={
                      <Trans i18nKey="ShareWithFriendsModal.copyLink" />
                    }
                    copiedMessage={
                      <Trans i18nKey="ShareWithFriendsModal.copyLink" />
                    }
                    buttonClassName={`jg-btn jg-text--large-regular ${
                      styles['copy-to-clipboard-button']
                    }`}
                    inputClassName={`jg-form-control ${
                      styles['copy-to-clipboard-input']
                    }`}
                    onCopy={this.onCopied}
                    copied={linkCopied}
                  />
                </div>
                <ShowMoreOptionsButton
                  socialShareUrl={socialShareUrl}
                  targetAmount={targetAmount}
                  pitchFor={pitchFor}
                  targetCurrency={targetCurrency}
                  shareOnTwitter={shareOnTwitter}
                />
              </div>
            </div>
          </div>
        </AnalyticsPageView>
      </Modal>
    );
  }
}

export default CSSModules(SharePrompts, styles);
