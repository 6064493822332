import React from 'react';
import Svg from '@justgiving/svg';
import CSSModules from 'react-css-modules';
import styles from './ConfigurableDesktopTooltip.scss';
import { ClickAnalyticsOptions } from 'src/redux/modules/analytics';

interface Props {
  analyticsOptions?: ClickAnalyticsOptions;
  arrowPosition?: string | null;
  className?: string;
  displayInline?: boolean;
  icon: string;
  iconClassName?: string;
  tooltipPosition?: string;
}

class TooltipHoverTrigger extends React.Component<Props> {
  tooltip: HTMLDivElement;

  render() {
    const {
      arrowPosition,
      className,
      displayInline,
      icon,
      iconClassName,
      tooltipPosition,
    } = this.props;

    return (
      <div
        className={`jg-relative ${styles['tooltip-hover-wrapper']} ${
          displayInline ? 'jg-display-ib' : ''
        } ${className && className}`}
        ref={tooltip => {
          this.tooltip = tooltip!;
        }}
      >
        <Svg
          markup={icon}
          className={`jg-icon jg-fill--empress ${iconClassName &&
            iconClassName}`}
        />
        <div
          className={`${styles.tooltip} ${tooltipPosition} ${arrowPosition}`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default CSSModules(TooltipHoverTrigger, styles);
