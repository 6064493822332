import { execute, makePromise } from 'apollo-link';

import { GRAPHQL_API_URI } from 'config';
import { createHttpLink } from 'apollo-link-http';
import { getAuth } from 'src/helpers/auth';
import gql from 'graphql-tag';

export const LIVESTREAM_QUERY = gql`
  query LiveStream($legacyId: String!) {
    livestream(legacyId: $legacyId) {
      pageId
      mediaId
      entries {
        mediaType
        streamId
        streamType
      }
    }
  }
`;

const auth = getAuth();

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URI,
  credentials: 'include',
  headers: new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${auth.getAccessToken()}`,
  }),
  fetch,
  fetchOptions: {
    mode: 'cors',
  },
});

export default function executeLiveStreamGraphql(
  legacyId: string,
  link = httpLink
) {
  return makePromise(
    execute(link, {
      query: LIVESTREAM_QUERY,
      variables: {
        legacyId,
      },
    })
  );
}
