import { post } from './common';

export interface UnsubscribePayload {
  projectId: string;
  projectName: string;
  emailAddress: string;
  consumerId: number;
}

export function unsubscribe(payload: UnsubscribePayload) {
  return post(`projects/${payload.projectId}/subscriptions`, payload);
}
