import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AnalyticsPageView as AnalyticsPageViewType,
  analyticsPageView,
} from 'src/redux/modules/analytics';

type DispatchProps = {
  analyticsPageView: AnalyticsPageViewType;
};

type OwnProps = {
  pageSection: string;
  subtype: string;
  eventValue: string;
  actorSource?: string;
};

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = (dispatch: any): DispatchProps =>
  bindActionCreators(
    {
      analyticsPageView,
    },
    dispatch
  );

export class AnalyticsPageView extends React.Component<Props> {
  componentDidMount() {
    const analyticsOptions = {
      eventValue: this.props.eventValue,
      pageSection: this.props.pageSection,
      subtype: this.props.subtype,
    };

    this.props.analyticsPageView(
      !this.props.actorSource
        ? analyticsOptions
        : { ...analyticsOptions, actorSource: this.props.actorSource }
    );
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default connect(
  null,
  mapDispatchToProps
)(AnalyticsPageView);
