import { StripeVirtualAccountAction } from 'src/redux/modules/stripeVirtualAccount/actions';
import {
  StripeRequiredFields,
  AccountDisabledReason,
  StripeVirtualAccountStatus,
  PayoutScheduleInterval,
} from 'src/helpers/api/stripeVirtualAccount';

const initialState: StripeState = {
  isRequesting: false,
  payoutsEnabled: false,
  requiredFields: [],
  submittedAllRequiredFields: false,
  totalRequiredFields: 0,
  error: null,
  payoutScheduleInterval: 'Weekly',
};

type Action = StripeVirtualAccountAction;

export interface StripeState {
  isRequesting: boolean;
  payoutsEnabled: boolean;
  requiredFields: StripeRequiredFields;
  submittedAllRequiredFields: boolean;
  totalRequiredFields: number;
  legalEntityVerificationStatus?: StripeVirtualAccountStatus;
  accountDisabledReason?: AccountDisabledReason;
  payoutScheduleInterval: PayoutScheduleInterval;
  error: Error | null;
}

export default function reducer(
  state = initialState,
  action: Action
): StripeState {
  switch (action.type) {
    case 'STRIPE_VIRTUAL_ACCOUNT_STATUS_REQUEST':
      return {
        ...state,
        isRequesting: true,
      };

    case 'STRIPE_VIRTUAL_ACCOUNT_STATUS_FAILURE':
      return {
        ...state,
        isRequesting: false,
      };

    case 'STRIPE_VIRTUAL_ACCOUNT_STATUS_SUCCESS':
      return {
        ...state,
        isRequesting: false,
        payoutsEnabled: action.payload.payoutsEnabled,
        requiredFields: action.payload.requiredFields,
        submittedAllRequiredFields: action.payload.submittedAllRequiredFields,
        totalRequiredFields: action.payload.requiredFields.length,
        legalEntityVerificationStatus:
          action.payload.legalEntityVerificationStatus,
        accountDisabledReason: action.payload.accountDisabledReason,
        payoutScheduleInterval: action.payload.payoutScheduleInterval,
      };

    case 'SUBMIT_FIELD_ACTION':
      return {
        ...state,
        requiredFields: state.requiredFields.filter(
          requiredField => requiredField !== action.payload
        ),
        totalRequiredFields: Math.max(state.requiredFields.length - 1, 0),
      };

    case 'UPDATE_STRIPE_ACCOUNT_SUCCESS':
      return {
        ...state,
        legalEntityVerificationStatus: 'Pending',
        payoutScheduleInterval: action.payload.payoutScheduleInterval,
      };

    case 'SUBMIT_STRIPE_VERIFICATION_DOCUMENT_SUCCESS':
      return {
        ...state,
        legalEntityVerificationStatus: 'Pending',
      };

    case 'SUBMIT_STRIPE_BANK_ACCOUNT_INFORMATION_REQUEST':
      return {
        ...state,
        error: null,
      };

    case 'SUBMIT_STRIPE_BANK_ACCOUNT_INFORMATION_SUCCESS':
      return {
        ...state,
        error: null,
      };

    case 'SUBMIT_STRIPE_BANK_ACCOUNT_INFORMATION_FAILURE':
      return {
        ...state,
        error: action.payload,
      };

    case 'CLEAR_STRIPE_BANK_ACCOUNT_SUBMISSION_ERROR':
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}
