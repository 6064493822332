import { AnalyticsPageView, Button } from 'src/components/Analytics';
import {
  ClickAnalyticsOptions,
  analyticsClick,
} from 'src/redux/modules/analytics';
import {
  closeFeedbackThankYou,
  openContactOwner,
} from 'src/redux/modules/page';
import {
  isLocalStorageAvailable,
  isPropertyInLocalStorage,
} from 'src/helpers/browser';

import ClickableElement from 'src/components/ClickableElement';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import ThankYouForHelpingModal from 'src/components/ShareWithFriendsPrompts/ThankYouForHelpingModal';
import { Trans } from 'react-i18next';
import { canPageReceiveDonations } from 'src/helpers/page';
import i18next from 'i18next';
import moment from 'moment';
import styles from './GiveFeedback.scss';

const GiveFeedback: Rule<JSX.Element, PromptRulesEngineOptions> = (
  state,
  options
) => {
  const { id, status, name, isFeedbackThankYouWindowOpen } = state.page;

  const pageSection = 'page creation > give feedback';
  const localStorageKey = `prompt:${id}_giveFeedback:close`;
  const dateFormat = 'YYYY-MM-DD HH:mm';
  const isInLocalStorage = () =>
    isLocalStorageAvailable() && isPropertyInLocalStorage(localStorageKey);
  const setInLocalStorage = (value: string) =>
    isLocalStorageAvailable() && localStorage.setItem(localStorageKey, value);
  const getFromLocalStorage = () =>
    String(localStorage.getItem(localStorageKey));

  const isPromptDismissed = () => {
    if (isInLocalStorage()) {
      const value = getFromLocalStorage();
      const dateValue = moment.utc(value, dateFormat);
      if (dateValue.isValid()) {
        return moment.utc().isBefore(dateValue);
      }
      return true;
    }
    return false;
  };

  const close = () => {
    setInLocalStorage('true');
    options!.closePrompt();
  };

  const delay = () => {
    setInLocalStorage(
      moment
        .utc()
        .add(24, 'hours')
        .format(dateFormat)
        .toString()
    );
    options!.closePrompt();
  };

  const openContactOwnerModal = () => {
    const isFriend = true;
    options!.dispatch(
      openContactOwner(
        `${state.page.name}/feedback/contact`,
        'page view > send feedback',
        'page creation > send feedback',
        isFriend,
        i18next.t('GiveFeedback.subject.placeholder', {
          defaultValue: '',
        }),
        i18next.t('GiveFeedback.message.placeholder', {
          defaultValue: 'Let them know what you think...',
        })
      )
    );
  };

  const matched = () => {
    const routeMatched = state.routing
      .location!.pathname.toLowerCase()
      .match(new RegExp(`^/${name.toLowerCase()}/feedback[/|/contact|]*$`));

    return status === 'Active' && routeMatched && !isPromptDismissed();
  };

  const donate = (analytics: ClickAnalyticsOptions) => {
    options!.dispatch(analyticsClick(analytics));
  };

  const closeThankYouForHelpingModal = () => {
    options!.dispatch(closeFeedbackThankYou());
  };

  if (matched()) {
    return {
      matched: true,

      result: (
        <AnalyticsPageView
          eventValue="prompt displayed"
          pageSection="page view > give feedback"
          subtype="cfp"
        >
          <section
            className={`${
              styles.prompt
            } jg-bdb jg-bd--solid jg-bd--very-light-grey jg-text--center`}
          >
            <div className="container">
              <div className={`${styles.people} row`}>
                <div className="col-md-1 col-lg-3 col-xl-3" />
                <div
                  className={`col-md-10 col-lg-7 col-xl-6 ${
                    styles['col-info']
                  }`}
                >
                  <div
                    className="jg-background--white
                                 jg-space-ptmd jg-space-ptml@md
                                 jg-space-pbsm jg-space-pbml@md
                                 jg-space-phsm jg-space-phml@md
                                 jg-bd jg-bd--solid jg-bd--very-light-grey jg-bd--radius-3px"
                  >
                    <Button
                      className={`${styles['grey-cross']} jg-space-mrsm`}
                      onClick={close}
                      analyticsOptions={{
                        event: 'click',
                        eventValue: 'x',
                        pageSection,
                        subtype: 'button',
                      }}
                    />
                    <div
                      className={`jg-display-flex jg-flex-column jg-flex-ais ${
                        styles.messages
                      }`}
                    >
                      <p className="jg-h5">
                        <Trans i18nKey="GiveFeedback.title" />
                      </p>
                      <p className={`jg-text--label ${styles['prompt-text']}`}>
                        <Trans i18nKey="GiveFeedback.text" />
                      </p>
                    </div>
                    <div className="jg-display-flex jg-flex-row row">
                      <div className={`col-md-5 ${styles['col-btn-feedback']}`}>
                        <ClickableElement
                          onClick={openContactOwnerModal}
                          className="jg-btn jg-space-mrms jg-text--large"
                          inline
                          analyticsOptions={{
                            event: 'click',
                            subtype: 'button',
                            eventValue: 'give feedback',
                            pageSection,
                          }}
                        >
                          <Trans i18nKey="GiveFeedback.button" />
                        </ClickableElement>
                      </div>
                      <div className={`col-md-3 ${styles['col-feedback']}`}>
                        <Button
                          className={`jg-btn jg-btn--ghost jg-text--large-regular ${
                            styles['btn-feedback']
                          }`}
                          onClick={delay}
                          analyticsOptions={{
                            event: 'click',
                            eventValue: 'skip',
                            pageSection,
                            subtype: 'button',
                          }}
                        >
                          <Trans i18nKey="GiveFeedback.skip" />
                        </Button>
                        <ClickableElement
                          className={`jg-text--link ${styles['link-feedback']}`}
                          onClick={delay}
                          analyticsOptions={{
                            event: 'click',
                            eventValue: 'skip',
                            pageSection,
                            subtype: 'button',
                          }}
                        >
                          <Trans i18nKey="GiveFeedback.skip" />
                        </ClickableElement>
                      </div>
                      <div className="col-md-4" />
                    </div>
                  </div>
                </div>
                <div className="col-md-1 col-lg-2 col-xl-3" />
              </div>
            </div>
          </section>
          <ThankYouForHelpingModal
            pageName={state.page.name}
            donate={donate}
            canPageReceiveDonations={canPageReceiveDonations(state.page)}
            isOpen={isFeedbackThankYouWindowOpen}
            onClose={closeThankYouForHelpingModal}
            id={id}
          />
        </AnalyticsPageView>
      ),
    };
  }

  return { matched: false };
};

export default GiveFeedback;
