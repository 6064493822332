import i18n from 'i18next';
import i18nConfig from './config';

i18n.init(
  {
    ...i18nConfig,
  },
  err => {
    if (err) {
      // tslint:disable-next-line:no-console
      console.log('something went wrong loading', err);
    }
  }
);

i18n.changeLanguage(window.__i18n.locale);

i18n.addResourceBundle(
  window.__i18n.locale,
  'common',
  window.__i18n.resources,
  true
);

export default i18n;
