import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { FormattedMessage } from 'react-intl';
import Svg from '@justgiving/svg';
import image from './svg/supports-recurring-donations-il.svg';
import ClickableElement from 'src/components/ClickableElement';
import styles from './PageSupportsRecurringDonations.scss';

interface Props {
  isOwnerRequested: boolean;
  recurringDonationsPitch?: string;
}

const PageSupportsRecurringDonations: React.StatelessComponent<Props> = ({
  isOwnerRequested,
  recurringDonationsPitch,
}) => (
  <div
    className={`jg-background--white jg-text--center jg-space-pmd ${
      styles.container
    }`}
  >
    <Svg markup={image} className={`${styles.image} jg-space-mbsm`} />
    <p className="jg-text--large-regular">
      {isOwnerRequested ? (
        <FormattedMessage
          id="PageSupportsRecurringDonations.PO.pageAcceptsMonthlyDonations"
          defaultMessage="Your Page accepts monthly donations"
        />
      ) : (
        <FormattedMessage
          id="PageSupportsRecurringDonations.Donor.pageAcceptsMonthlyDonations"
          defaultMessage="This Page accepts monthly donations"
        />
      )}
    </p>

    {isOwnerRequested &&
      !recurringDonationsPitch && (
        <ScrollLink
          to="recurring-donations-awareness-banner"
          spy
          smooth
          offset={-150}
          duration={500}
        >
          <ClickableElement
            className="jg-text--link"
            inline
            analyticsOptions={{
              event: 'click',
              subtype: 'hyperlink',
              eventValue: 'find out more',
              pageSection: 'page > recurring donations',
            }}
          >
            <FormattedMessage
              id="PageSupportsRecurringDonations.boostDonations"
              defaultMessage="Boost your donations now"
            />
          </ClickableElement>
        </ScrollLink>
      )}

    {!isOwnerRequested && (
      <ScrollLink
        to="recurring-donations-awareness-banner"
        spy
        smooth
        offset={-150}
        duration={500}
      >
        <ClickableElement
          className="jg-text--link"
          inline
          analyticsOptions={{
            event: 'click',
            subtype: 'hyperlink',
            eventValue: 'find out more',
            pageSection: 'page > recurring donations',
          }}
        >
          <FormattedMessage
            id="PageSupportsRecurringDonations.findMore"
            defaultMessage="Find out more"
          />
        </ClickableElement>
      </ScrollLink>
    )}
  </div>
);

export default PageSupportsRecurringDonations;
