import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import supportersAvatar from './supportersAvatar';
import friends from './friends';
import bank from './bank';
import pageLink from './pageLink';
import story from './story';
import coverPhoto from './coverPhoto';
import coverPhotoEditorReducer from './photoEditor';
import page from './page';
import richUpdates from './richUpdates';
import supporters from './supporters';
import categories from './categories';
import sharePrompts from './sharePrompts';
import shareWithFriendsPrompts from './shareWithFriendsPrompts';
import completedActions from './completedActions';
import self from './self';
import subscriptions from './subscriptions';
import paymentDetails from './paymentDetails';
import censures from './censures';
import avatars from './avatars';
import carousel from './carousel';
import dashboard from './dashboard';
import funds from './funds';
import asyncConnect from './asyncConnect';
import stripeVirtualAccount from './stripeVirtualAccount';
import settings from './settings';
import consents from './consents';
import charity from './charity';

const appReducer = combineReducers({
  routing,
  form,
  page,
  coverPhoto,
  createCoverPhotoEditor: coverPhotoEditorReducer.bind(null, 'create'),
  editCoverPhotoEditor: coverPhotoEditorReducer.bind(null, 'edit'),
  editStripeVerificationDocument: coverPhotoEditorReducer.bind(
    null,
    'stripeVerification'
  ),
  story,
  payment: bank,
  pageLink,
  richUpdates,
  supporters,
  supportersAvatar,
  categories,
  sharePrompts,
  shareWithFriendsPrompts,
  self,
  subscriptions,
  paymentDetails,
  friends,
  censures,
  avatars,
  carousel,
  completedActions,
  dashboard,
  funds,
  stripeVirtualAccount,
  asyncConnect,
  settings,
  consents,
  charity,
  // these reducers are needed so we can inject values into the store during
  // server-side rendering
  location: (s = {}) => s,
});

export default (state, action) => {
  if (action.type === 'DELETE_PAGE_SUCCESS') {
    return appReducer({ routing: state.routing }, action);
  }

  return appReducer(state, action);
};
