import {
  changeProfilePicture,
  uploadProfilePicFacebook,
} from 'src/helpers/api/iam';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { AnalyticsMeta } from 'src/redux/modules/analytics';

export interface ProfileImageRequestAction {
  type: 'PROFILE_IMAGE_REQUEST';
  meta: {
    analytics: AnalyticsMeta;
  };
}

export interface ProfileImageSuccessAction {
  type: 'PROFILE_IMAGE_SUCCESS';
  payload: string;
}

export interface ProfileImageFailureAction {
  type: 'PROFILE_IMAGE_FAILURE';
  error: true;
  payload: Error;
}

export function updateProfilePicture(imagePayload: File) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<ProfileImageRequestAction>({
      type: 'PROFILE_IMAGE_REQUEST',
      meta: {
        analytics: {
          event: 'link click',
          subtype: 'button',
          event_value: 'save and continue',
          page_section: 'admin > tips > profile photo',
          activity_type: 'crowdfunding_guid',
          content_type: 'crowdfunding_guid',
        },
      },
    });

    const userId = getState().page.ownerUserId;
    try {
      const newProfileImage = await changeProfilePicture({
        userId,
        imagePayload,
      });
      dispatch<ProfileImageSuccessAction>({
        type: 'PROFILE_IMAGE_SUCCESS',
        payload: newProfileImage,
      });
    } catch (err) {
      dispatch<ProfileImageFailureAction>({
        type: 'PROFILE_IMAGE_FAILURE',
        payload: err,
        error: true,
      });
    }
  };
}

export function uploadFacebookProfilePicture(imageUrl: string) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<ProfileImageRequestAction>({
      type: 'PROFILE_IMAGE_REQUEST',
      meta: {
        analytics: {
          event: 'link click',
          subtype: 'button',
          event_value: 'save and continue',
          page_section: 'admin > tips > profile photo',
          activity_type: 'crowdfunding_guid',
          content_type: 'crowdfunding_guid',
        },
      },
    });

    const userId = getState().page.ownerUserId;
    try {
      const newProfileImage = await uploadProfilePicFacebook({
        userId,
        imageUrl,
      });
      dispatch<ProfileImageSuccessAction>({
        type: 'PROFILE_IMAGE_SUCCESS',
        payload: newProfileImage,
      });
    } catch (err) {
      dispatch<ProfileImageFailureAction>({
        type: 'PROFILE_IMAGE_FAILURE',
        payload: err,
        error: true,
      });
    }
  };
}
