import {
  GetConsentStatementsActions,
  UpdateUserConsentActions,
} from './actions';
import { ConsentStatement } from './consentStatement';

export interface ConsentsState {
  consentStatements: ConsentStatement[];
  userConsentStatements: ConsentStatement[];
  error?: Error | null;
  isLoading: boolean;
}

const initialState: ConsentsState = {
  consentStatements: [],
  userConsentStatements: [],
  isLoading: false,
  error: null,
};

type Action = GetConsentStatementsActions | UpdateUserConsentActions;

export default function reducer(
  state = initialState,
  action: Action
): ConsentsState {
  switch (action.type) {
    case 'GET_CONSENT_STATEMENTS_REQUEST':
    case 'GET_USER_CONSENT_STATEMENTS_REQUEST':
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case 'GET_CONSENT_STATEMENTS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        consentStatements: action.payload,
        error: null,
      };
    case 'GET_USER_CONSENT_STATEMENTS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        userConsentStatements: action.payload,
        error: null,
      };
    case 'GET_CONSENT_STATEMENTS_FAILURE':
    case 'GET_USER_CONSENT_STATEMENTS_FAILURE':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case 'UPDATE_USER_CONSENT':
      const { consentStatements } = state;
      return {
        ...state,
        consentStatements: consentStatements.map(
          (consentStatement: ConsentStatement, index: number) =>
            index === parseInt(action.payload.index, 10)
              ? { ...consentStatement, consentGiven: action.payload.checked }
              : consentStatement
        ),
      };
    default:
      return state;
  }
}
