import compareAsc from 'date-fns/compare_asc';
import format from 'date-fns/format';
import isValid from 'date-fns/is_valid';
import { Value, Rule, OneOfRule, Message } from '.';
import { getVideoId } from 'src/helpers/youTubeParser';
import { convertDateToUTC, getConsentDOB } from 'src/helpers/date';

interface RuleOptions {
  message?: Message;
  emptyValues?: Value[];
}

function isEmpty(value: Value): value is null {
  return (
    value === null ||
    typeof value === 'undefined' ||
    (typeof value === 'string' && value.trim() === '') ||
    (typeof value === 'boolean' && value === false)
  );
}

export function required({
  message = 'Required',
  emptyValues = [],
}: RuleOptions = {}): Rule {
  return value =>
    isEmpty(value) || emptyValues.indexOf(value) !== -1 ? message : null;
}

export function oneOfRequired(
  id: string,
  {
    message = 'One of the fields is required',
    emptyValues = [],
  }: RuleOptions = {}
): OneOfRule {
  return value => ({
    id,
    message,
    failed: isEmpty(value) || emptyValues.indexOf(value) !== -1,
  });
}

export function minLength(
  length: number,
  { message = `Please enter at least ${length} characters` }: RuleOptions = {}
): Rule {
  return value => (!isEmpty(value) && value.length < length ? message : null);
}

export function maxLength(
  length: number,
  { message = `Please enter at most ${length} characters` }: RuleOptions = {}
): Rule {
  return value => (!isEmpty(value) && value.length > length ? message : null);
}

export function pattern(
  expression: RegExp,
  { message = 'Invalid value' }: RuleOptions = {}
): Rule {
  return value => (!isEmpty(value) && !expression.test(value) ? message : null);
}

export function minNumber(
  min: number,
  { message = `Number must be at least ${min}` }: RuleOptions = {}
): Rule {
  return value =>
    !isEmpty(value) && parseInt(value, 10) < min ? message : null;
}

export function maxNumber(
  max: number,
  { message = `Number must be at most ${max}` }: RuleOptions = {}
): Rule {
  return value =>
    !isEmpty(value) && parseInt(value, 10) > max ? message : null;
}

export function minDate(
  min: string,
  { message = `Date must be same or after ${format(min)}` }: RuleOptions = {}
): Rule {
  return (value: string) => {
    const dob = convertDateToUTC(new Date(value));
    return !dob || (!isEmpty(value) && compareAsc(dob, min) === -1)
      ? message
      : null;
  };
}

export function maxDate(
  max: string,
  { message = `Date must be same or before ${format(max)}` }: RuleOptions = {}
): Rule {
  return (value: string) => {
    const dob = convertDateToUTC(new Date(value));
    return !dob || (!isEmpty(value) && compareAsc(dob, max) === 1)
      ? message
      : null;
  };
}

export function betweenDates(
  min: string,
  max: string,
  { message = `Date should be between` }: RuleOptions = {}
): Rule {
  return value => {
    if (isEmpty(value)) {
      return message;
    }

    return minDate(min, { message })(value) !== null &&
      maxDate(max, { message })(value) !== null
      ? message
      : null;
  };
}

export function notEqual(
  col: string[],
  { message = 'This value is reserved and cannot be used' }: RuleOptions = {}
): Rule {
  return value =>
    !isEmpty(value) &&
    col.filter(x => x.toLowerCase() === value.toLowerCase()).length >= 1
      ? message
      : null;
}

export function isValidYoutubeLink({
  message = 'Please paste your YouTube link',
}: RuleOptions = {}): Rule {
  return value => (isEmpty(value) || !getVideoId(value) ? message : null);
}

export function under18({
  message = 'under 18 years of age',
}: RuleOptions = {}): Rule {
  return (value: string) =>
    value && compareAsc(new Date(value), getConsentDOB(18)) === 1
      ? message
      : null;
}

export function validDate({
  message = 'Please enter a valid date',
}: RuleOptions = {}): Rule {
  return value => {
    if (!value) {
      return message;
    }

    const [day, month, year] = value.split('/');
    const d = new Date(year + '/' + month + '/' + day);
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    const isValidDate = Boolean(+d) && d.getDate() == day;

    return !isValidDate && !isValid(new Date(value)) ? message : null;
  };
}

const MAX_FILE_SIZE = 3 * 1024 * 1024;
export function validImageSize({
  message = 'Try to upload a smaller file. The limit is 3MB',
}: RuleOptions = {}): any {
  return (value: File) => {
    if (value && value.size >= MAX_FILE_SIZE) {
      return message;
    } else {
      return null;
    }
  };
}

const VALID_FILE_EXTENSION_REGEX = new RegExp('(.*?).(pdf|png|jpg)$');

export function validImageExtension({
  message = 'Try a different file type. You can only upload .png, .pdf or .jpg files',
}: RuleOptions = {}): any {
  return (value: File) => {
    if (value && !VALID_FILE_EXTENSION_REGEX.test(value.name)) {
      return message;
    } else {
      return null;
    }
  };
}
