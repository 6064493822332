import React from 'react';
import moment from 'moment';
import CSSModules from 'react-css-modules';
import styles from './AddToCalendarButton.scss';
import { Button } from '../Analytics';
import { addToCalendarHandler, CalendarType } from './helpers/addToCalendar';
import { buildUrl } from '../../helpers/url';
import appleCalendarIcon from './images/apple-calendar-icon.svg';
import googleCalendarIcon from './images/google-calendar-icon.svg';
import outlookCalendarIcon from './images/outlook-calendar-icon.svg';
import outlookWebCalendarIcon from './images/outlook-web-calendar-icon.svg';
import yahooCalendarIcon from './images/yahoo-calendar-icon.svg';
import currencyMap from '../../helpers/currencyMap';
import { generateHash } from '../../helpers/hashId';

export interface Props {
  buttonText?: string;
  pageSection: string;
  ownerName: string;
  targetCurrency: Currency;
  targetAmount: number;
  pitchFor: string;
  isActive: boolean;
  calendarType: CalendarType;
  socialShareUrl: string;
  startTime?: Date;
  endTime?: Date;
  classNames?: string;
}

const AddToCalendarButton: React.StatelessComponent<Props> = ({
  pageSection,
  ownerName,
  targetAmount,
  targetCurrency,
  pitchFor,
  isActive,
  calendarType,
  startTime,
  endTime,
  buttonText,
  classNames,
  socialShareUrl,
}) => {
  const handleButtonClick = () => {
    const currentDate = moment();
    const firstDayOfMonth =
      currentDate.date() >= 28
        ? currentDate.startOf('month').add('1', 'month')
        : currentDate.startOf('month');
    const defaultReminderDate = firstDayOfMonth
      .add('27', 'days')
      .hour(12)
      .minute(0)
      .second(0);
    const targetAmountFormatted = `${currencyMap[targetCurrency] ||
      '£'}${targetAmount}`;
    const shareHash = generateHash();
    const url = buildUrl(socialShareUrl, {
      utm_source: `${calendarType.toLowerCase()}-reminder`,
      utm_term: shareHash,
    });
    const doubleLineSpacing =
      calendarType === CalendarType.Apple ||
      calendarType === CalendarType.Outlook
        ? '\\n\\n'
        : '\n\n';
    const event = {
      startTime: startTime || defaultReminderDate.toDate(),
      endTime: endTime || defaultReminderDate.add('2', 'hours').toDate(),
      location: 'JustGiving',
      title: `Donate to ${ownerName} 🎗️`,
      description: `Reminder to donate to ${ownerName} who is raising ${targetAmountFormatted} to ${pitchFor}.${doubleLineSpacing}${url}`,
    };

    addToCalendarHandler(event, calendarType);
  };

  const getCalendarIcon = () => {
    switch (calendarType) {
      case CalendarType.Apple:
        return appleCalendarIcon;
      case CalendarType.Google:
        return googleCalendarIcon;
      case CalendarType.Outlook:
        return outlookCalendarIcon;
      case CalendarType.OutlookWeb:
        return outlookWebCalendarIcon;
      case CalendarType.Yahoo:
        return yahooCalendarIcon;
      default:
        return '';
    }
  };

  const label = buttonText || calendarType;

  return (
    <Button
      type="button"
      onClick={handleButtonClick}
      className={`${styles.reminder} ${classNames || ''}`}
      isDisabled={!isActive}
      analyticsOptions={{
        event: 'click',
        eventValue: `add ${calendarType.toLowerCase()} reminder`,
        pageSection,
        subtype: 'button',
      }}
    >
      <img src={getCalendarIcon()} alt={`${label} logo`} />
      {label}
    </Button>
  );
};

export default CSSModules(AddToCalendarButton, styles);
