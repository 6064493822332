export const TIP_SELECTED = 'TIP_SELECTED';
export const SKIP_SLIDE = 'SKIP_SLIDE';
export const CLOSE_SHARE_MODAL = 'CLOSE_SHARE_MODAL';
export const VIEW_SLIDE = 'VIEW_SLIDE';

export const OPEN_FUNDS_MODAL = 'OPEN_FUNDS_MODAL';
export const CLOSE_FUNDS_MODAL = 'CLOSE_FUNDS_MODAL';
export const MODIFY_TARGET = 'MODIFY_TARGET';
export const MORE_ABOUT_FEES = 'MORE_ABOUT_FEES';

export const STAT_REQUEST = 'STAT_REQUEST';
export const STAT_REQUEST_SUCCESS = 'STAT_REQUEST_SUCCESS';
export const STAT_REQUEST_FAILURE = 'STAT_REQUEST_FAILURE';

export const UPLOAD_PROFILE_PHOTO = 'UPLOAD_PROFILE_PHOTO';
