import React from 'react';
import { Switch, Route, RouteProps } from 'react-router';
import Redirect from 'src/helpers/Redirect';

export interface RouteConfig {
  path: string;
  exact?: boolean;
  strict?: boolean;
  component: any;
  routes: (RouteConfig | RedirectConfig)[];
}

interface RedirectConfig {
  path: string;
  redirectTo: string;
}

function isRedirect(
  route: RouteConfig | RedirectConfig
): route is RedirectConfig {
  return !!(route as any).redirectTo;
}

const renderRoute = (i: number, route: RouteConfig, componentProps: any) => (
  <Route
    key={i}
    path={route.path}
    exact={route.exact}
    strict={route.strict}
    render={(props: RouteProps) => (
      <route.component {...componentProps} {...props} route={route} />
    )}
  />
);

const renderRedirect = (i: number, { path, redirectTo }: RedirectConfig) => (
  <Redirect key={i} from={path} to={redirectTo} />
);

const renderRoutes = (
  routes: (RouteConfig | RedirectConfig)[],
  componentProps = {}
) =>
  routes ? (
    <Switch>
      {routes.map(
        (route, i) =>
          isRedirect(route)
            ? renderRedirect(i, route)
            : renderRoute(i, route, componentProps)
      )}
    </Switch>
  ) : null;

export default renderRoutes;
