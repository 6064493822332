import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import PageFrozenSupporterContent from './PageFrozenSupporterContent';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';

const PageFrozenSupporter: Rule<
  JSX.Element,
  PromptRulesEngineOptions
> = state => {
  if (
    state.page.status === 'Suspended' &&
    !state.page.isOwnerRequested &&
    !state.page.refundedDate
  ) {
    return {
      matched: true,
      result: <PageFrozenSupporterContent />,
    };
  }

  return { matched: false };
};

export default PageFrozenSupporter;
