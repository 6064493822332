import {
  RichUpdatesActions,
  UpdatesById,
} from 'src/redux/modules/richUpdates/actions';
import union from 'lodash/union';
import { UpdateResource } from 'src/helpers/api/updates';

const DEFAULT_PAGE_SIZE = 3;

export interface RichUpdatesState {
  isPublishing: boolean;
  publishingError: Error | null;
  totalCount: number;
  updatesPageNo: number;
  isLoading: boolean;
  allUpdateIds: number[];
  updatesById: { [key: number]: UpdateResource };
  isWatchingVideo: boolean;
  scrollTo: boolean;
  linkedUpdate: UpdateResource;
}

const initialState: RichUpdatesState = {
  isPublishing: false,
  publishingError: null,
  totalCount: 0,
  updatesPageNo: 0,
  isLoading: false,
  allUpdateIds: [],
  updatesById: {},
  isWatchingVideo: false,
  scrollTo: false,
  linkedUpdate: {
    createdAt: '',
    friends: [],
    id: 0,
    media: [],
    message: '',
    projectId: '',
    type: 0,
  },
};

export default function reducer(
  state = initialState,
  action: RichUpdatesActions
): RichUpdatesState {
  switch (action.type) {
    case 'PUBLISH_RICH_UPDATE_REQUEST':
      return {
        ...state,
        isPublishing: true,
        publishingError: null,
      };

    case 'PUBLISH_RICH_UPDATE_SUCCESS':
      return {
        ...state,
        isPublishing: false,
        publishingError: null,
        updatesById: {
          ...state.updatesById,
          [action.payload.id]: action.payload,
        },
        allUpdateIds: [action.payload.id, ...state.allUpdateIds],
        totalCount: state.totalCount + 1,
        scrollTo: true,
      };

    case 'SCROLLED_TO_UPDATE':
      return {
        ...state,
        scrollTo: false,
      };

    case 'SCROLL_TO_UPDATE':
      return {
        ...state,
        scrollTo: true,
      };

    case 'PUBLISH_RICH_UPDATE_FAILURE':
      return {
        ...state,
        isPublishing: false,
        publishingError: action.payload,
      };

    case 'CLEAR_RICH_UPDATE_PUBLISHING_ERROR':
      return {
        ...state,
        publishingError: null,
      };

    case 'RICH_UPDATES_REQUEST':
      return { ...state, isLoading: true };

    case 'RICH_UPDATES_SUCCESS':
      return {
        ...state,
        updatesById: { ...state.updatesById, ...action.payload.updatesById },
        allUpdateIds: union(state.allUpdateIds, action.payload.allUpdateIds),
        updatesPageNo: action.payload.pageNo,
        totalCount: action.payload.totalCount,
        isLoading: false,
        linkedUpdate: action.payload.linkedUpdate,
      };

    case 'RICH_UPDATES_FAILURE':
      return { ...state, isLoading: false };

    case 'RICH_UPDATES_DELETE_UPDATE_SUCCESS':
      const filteredAllUpdateIds = state.allUpdateIds.filter(
        (id: number) => id !== action.payload.updateId
      );

      if (
        action.payload &&
        action.payload.nextUpdate &&
        !filteredAllUpdateIds.includes(action.payload.nextUpdate.id)
      ) {
        filteredAllUpdateIds.push(action.payload.nextUpdate.id);
      }

      const newUpdatesById = filteredAllUpdateIds.reduce(
        (object: UpdatesById, id: number) => {
          // If nextUpdate isn't in the store, add it to the list
          object[id] = state.updatesById[id] || action.payload.nextUpdate;
          return object;
        },
        {}
      );

      return {
        ...state,
        updatesById: newUpdatesById,
        allUpdateIds: filteredAllUpdateIds,
        updatesPageNo: Math.max(
          Math.ceil(filteredAllUpdateIds.length / DEFAULT_PAGE_SIZE),
          1
        ),
        totalCount: state.totalCount - 1,
      };
    case 'WATCHING_VIDEO_TOGGLE':
      return {
        ...state,
        isWatchingVideo: !state.isWatchingVideo,
      };
    default:
      return state;
  }
}
