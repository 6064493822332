import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import ReactDOM from 'react-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import { AppContainer } from 'react-hot-loader';
import { I18nextProvider } from 'react-i18next';
import App from 'src/containers/App';
import i18n from 'src/i18n/client';
import { ExperimentContext } from '@justgiving/experiments';
import { CustomOptimizelyProvider } from 'src/Optimizely/CustomOptimizelyProvider';

export default function renderApp(store, history) {
  const root = document.getElementById('content');

  const render = Component => {
    ReactDOM.hydrate(
      <CustomOptimizelyProvider>
        <I18nextProvider i18n={i18n}>
          <IntlProvider locale="en-GB" defaultLocale="en-GB">
            <Provider store={store} key="provider">
              <ConnectedRouter history={history}>
                <AppContainer>
                  <Component />
                </AppContainer>
              </ConnectedRouter>
            </Provider>
          </IntlProvider>
        </I18nextProvider>
        ,
      </CustomOptimizelyProvider>,
      root
    );
  };

  render(App);

  if (module.hot) {
    module.hot.accept('../containers/App', () => {
      render(require('../containers/App').default);
    });
  }

  if (process.env.NODE_ENV !== 'production') {
    window.React = React; // enable debugger
  }
}

if ('ReactIntlLocaleData' in window) {
  Object.keys(window.ReactIntlLocaleData).forEach(lang => {
    addLocaleData(window.ReactIntlLocaleData[lang]);
  });
}
