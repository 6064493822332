import { FB_APP_ID, FB_APP_NAMESPACE } from 'config';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppState } from 'src/redux/modules';
import { Helmet } from 'react-helmet';
import { PageImageResource } from 'src/helpers/api/pages';
import React from 'react';
import { connect } from 'react-redux';
import currencyMap from 'src/helpers/currencyMap';
import { getImageUrl } from 'src/helpers/image';

interface StateProps {
  pitchFor: string;
  targetCurrency: Currency;
  targetAmount: number;
  socialShareUrl: string;
  ownerName: string;
  finishedAt?: string;
  longitude: number;
  latitude: number;
  activatedAt?: string;
  initialImage?: PageImageResource;
  socialShareImage?: string;
  updateImageUrl?: string;
}
type Props = StateProps &
  RouteComponentProps<{
    name: string;
    updateId?: string;
  }>;

function mapStateToProps(state: AppState): StateProps {
  return {
    pitchFor: state.page.pitchFor,
    targetCurrency: state.page.targetCurrency,
    targetAmount: state.page.targetAmount,
    socialShareUrl: state.page.socialShareUrl,
    ownerName: state.page.ownerName,
    finishedAt: state.page.finishedAt,
    longitude: state.page.longitude,
    latitude: state.page.latitude,
    activatedAt: state.page.activatedAt,
    initialImage: state.page.initialImage,
    socialShareImage: state.page.socialShareImage,
    updateImageUrl:
      state.richUpdates.linkedUpdate &&
      state.richUpdates.linkedUpdate.media &&
      (state.richUpdates.linkedUpdate.media.length > 0
        ? state.richUpdates.linkedUpdate.media[0].path
        : ''),
  };
}

const MetaContainer: React.StatelessComponent<Props> = ({
  pitchFor,
  targetAmount,
  targetCurrency,
  socialShareUrl,
  initialImage,
  socialShareImage,
  ownerName,
  latitude,
  longitude,
  finishedAt,
  activatedAt,
  match,
  updateImageUrl,
}) => {
  const socialShareImageUrl =
    updateImageUrl || (socialShareImage && getImageUrl(socialShareImage));
  const { updateId } = match.params;
  const pageUrl = updateId
    ? `${socialShareUrl}/updates/${updateId}`
    : socialShareUrl;

  return (
    <Helmet
      title={`Crowdfunding to ${pitchFor} on JustGiving`}
      meta={[
        {
          content: `Help raise ${currencyMap[targetCurrency] ||
            '£'}${targetAmount} to ${pitchFor} – JustGiving Crowdfunding`,
        },
        {
          name: 'description',
          content: `Iʼm raising money to ${pitchFor}. Support this JustGiving Crowdfunding Page.`,
        },

        { property: 'fb:app_id', content: FB_APP_ID },

        {
          property: 'og:title',
          content: `Help raise ${currencyMap[targetCurrency] ||
            '£'}${targetAmount} to ${pitchFor}`,
        },
        {
          property: 'og:description',
          content: `Iʼm raising money to ${pitchFor}. Support this JustGiving Crowdfunding Page.`,
        },
        {
          property: 'og:type',
          content: `${FB_APP_NAMESPACE}:yimby_project`,
        },
        { property: 'og:url', content: pageUrl },
        {
          property: 'og:image',
          content: socialShareImageUrl,
        },
        {
          property: 'og:image:width',
          // TODO: width will be set for old projects only, at some point we should remove this
          content: (initialImage && initialImage.width) || 800,
        },
        {
          property: 'og:image:height',
          // TODO: height will be set for old projects only, at some point we should remove this
          content: (initialImage && initialImage.height) || 450,
        },
        {
          property: `${FB_APP_NAMESPACE}:project_owner`,
          content: ownerName,
        },
        { property: `${FB_APP_NAMESPACE}:pitchfor`, content: pitchFor },
        { property: `${FB_APP_NAMESPACE}:pitchbecause` },
        {
          property: `${FB_APP_NAMESPACE}:targetamount`,
          content: targetAmount,
        },
        {
          property: `${FB_APP_NAMESPACE}:location:latitude`,
          content: latitude,
        },
        {
          property: `${FB_APP_NAMESPACE}:location:longitude`,
          content: longitude,
        },
        { property: `${FB_APP_NAMESPACE}:enddate`, content: finishedAt },
        {
          property: `${FB_APP_NAMESPACE}:activationdate`,
          content: activatedAt,
        },

        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: `Donate to ${pitchFor}` },
        {
          name: 'twitter:description',
          content: `Iʼm raising money to ${pitchFor}. Support this JustGiving Crowdfunding Page.`,
        },
        {
          name: 'twitter:image',
          content: socialShareImageUrl,
        },
        {
          name: 'twitter:image:src',
          content: socialShareImageUrl,
        },
        {
          name: 'twitter:image:alt',
          content: pitchFor,
        },
      ]}
    />
  );
};

export default withRouter(connect(mapStateToProps)(MetaContainer));
