import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'src/components/Analytics';
import snowflake from 'src/components/Prompt/CountdownToRefundingFlow/svg/snowflake.svg';
import Svg from '@justgiving/svg';

const PageFrozenSupporterContent: React.StatelessComponent = () => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-8 offset-md-2">
        <Svg markup={snowflake} className="jg-svg-sm" />
        <h2>
          <FormattedMessage
            id="PageFrozenSupporterContent.pageFrozen"
            defaultMessage="This Page has been temporarily frozen"
          />
        </h2>
        <p className="jg-space-mbml">
          <FormattedMessage
            id="PageFrozenSupporterContent.weNeedMoreInfo"
            defaultMessage="Looks like we still need a few details from the page owner before we can get this Page up and running again. If you've already donated, don't worry - your donation is safe. If you'd like to donate, please keep checking the Page for updates."
          />
        </p>
        <Link
          href="https://help.justgiving.com/hc/en-us/articles/360000300338"
          target="_blank"
          rel="noreferrer noopener"
          analyticsOptions={{
            event: 'link click',
            eventValue: 'learn more',
            pageSection: 'admin > page view > supporter prompt > page frozen',
            subtype: 'button',
          }}
        >
          <FormattedMessage
            id="PageFrozenSupporterContent.learnMoreLink"
            defaultMessage="Learn more about this"
          />
        </Link>
      </div>
    </div>
  );
};

export default PageFrozenSupporterContent;
