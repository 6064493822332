import React from 'react';
import Modal from '../Modal';
import styles from './ShareModal.scss';
import CSSModules from 'react-css-modules';
import ClickableElement from '../ClickableElement';
import Svg from '@justgiving/svg';
import closeIcon from '@justgiving/icons/lib/close.svg';
import { OwnerAvatar } from '../Avatar';
import { AnalyticsPageView } from '../Analytics';
import FacebookShareButton from './FacebookShareButton';
import TwitterShareButton from './TwitterShareButton';
import EmailShareButtton from './EmailShareButton';
import { ShareActionOptions } from 'src/redux/modules/page';
import WhatsAppShareButton from './WhatsAppShareButton';
import MessengerShareButton from './MessengerShareButton';
import CopyLinkShareButton from './CopyLinkShareButton';
import QRCodeShareButton from './QRCodeShareButton';
import SmsShareButton from './SmsShareButton';
import { isDesktopViewport } from 'src/helpers/browser';
import LinkedInShareButton from './LinkedInShareButton';

export interface ShareModalProps {
  showModal: boolean;
  ownerName: string;
  pageSection: string;
  handleModalClose: () => void;
  ownerProfileImageUrl?: string;
  shareOnTwitter: (options: ShareActionOptions) => void;
  shareByEmail: (options: ShareActionOptions) => void;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareOnMessenger: (options: ShareActionOptions) => void;
  shareOnLinkedIn: (options: ShareActionOptions) => void;
  sharePageLink: (options: ShareActionOptions) => void;
  shareQrCode: (options: ShareActionOptions) => void;
  shareBySms: (options: ShareActionOptions) => void;
  targetAmount: number;
  targetCurrency: Currency;
  pitchFor: string;
  socialShareUrl: string;
  isOwner: boolean;
  isActive: boolean;
  closeSharePrompts: () => void;
  pageName: string;
  push: (location: any) => void;
}

class ShareModal extends React.Component<ShareModalProps> {
  handleQrCodeShare = () => {
    const { closeSharePrompts, pageName } = this.props;
    closeSharePrompts();
    this.props.push(`/${pageName}/qrcode`);
  };

  createEmailShareButton(socialShareSection: string) {
    return (
      <EmailShareButtton
        shareByEmail={this.props.shareByEmail}
        socialShareUrl={this.props.socialShareUrl}
        targetAmount={this.props.targetAmount}
        pitchFor={this.props.pitchFor}
        ownerName={this.props.ownerName}
        isActive={this.props.isActive}
        targetCurrency={this.props.targetCurrency}
        pageSection={socialShareSection}
        isOwner={this.props.isOwner}
      />
    );
  }

  render() {
    const {
      showModal,
      ownerName,
      ownerProfileImageUrl,
      handleModalClose,
      pageSection,
      shareOnTwitter,
      targetAmount,
      targetCurrency,
      pitchFor,
      socialShareUrl,
      isOwner,
      isActive,
      shareByWhatsApp,
      shareOnMessenger,
      shareBySms,
      shareOnLinkedIn,
    } = this.props;
    const socialShareSection = `${pageSection} > share modal`;
    const isMobile = !isDesktopViewport();

    return (
      <Modal
        classNames={styles.modal}
        isOpen={showModal}
        shouldCloseOnOverlayClick={false}
      >
        <AnalyticsPageView
          eventValue="share modal"
          pageSection={pageSection}
          subtype="cfp"
        >
          <header>
            <ClickableElement
              onClick={handleModalClose}
              inline
              className={styles.close}
            >
              <Svg
                markup={closeIcon}
                className="jg-icon--medium jg-fill--nobel"
              />
            </ClickableElement>
          </header>
          <div className="row" styleName="top-container">
            <div className="col-md-3">
              <div styleName="avatar">
                <OwnerAvatar
                  src={ownerProfileImageUrl}
                  alt={ownerName}
                  size="xlarge"
                />
              </div>
            </div>
            <div className="col-md-9" styleName="description">
              <h2 className="jg-h2" styleName="title">
                {`Help ${ownerName}`}
              </h2>
              <p>
                Sharing this page with your friends could help raise up to 3x
                more in donations
              </p>
            </div>
          </div>
          <div styleName="button-container">
            <FacebookShareButton pageSection={socialShareSection} />
            <TwitterShareButton
              pageSection={socialShareSection}
              shareOnTwitter={shareOnTwitter}
              targetAmount={targetAmount}
              targetCurrency={targetCurrency}
              isActive={isActive}
              pitchFor={pitchFor}
              socialShareUrl={socialShareUrl}
              isOwner={isOwner}
            />
            {!isMobile && this.createEmailShareButton(socialShareSection)}
            <WhatsAppShareButton
              shareByWhatsApp={shareByWhatsApp}
              socialShareUrl={socialShareUrl}
              targetAmount={targetAmount}
              pitchFor={pitchFor}
              ownerName={ownerName}
              isActive={isActive}
              targetCurrency={targetCurrency}
              pageSection={socialShareSection}
              isOwner={isOwner}
            />
            <MessengerShareButton
              shareOnMessenger={shareOnMessenger}
              socialShareUrl={socialShareUrl}
              ownerName={ownerName}
              isActive={isActive}
              targetCurrency={targetCurrency}
              pageSection={socialShareSection}
            />
            <LinkedInShareButton
              shareOnLinkedIn={shareOnLinkedIn}
              socialShareUrl={socialShareUrl}
              ownerName={ownerName}
              isActive={isActive}
              pageSection={pageSection}
            />
            <CopyLinkShareButton
              copyText="Copy link"
              copiedText="Copied"
              isDisabled={!this.props.isActive}
              pageSection={socialShareSection}
              socialShareUrl={this.props.socialShareUrl}
              sharePageLink={this.props.sharePageLink}
            />
            {isMobile && this.createEmailShareButton(socialShareSection)}
            <QRCodeShareButton
              isActive={isActive}
              handleClick={this.handleQrCodeShare}
              shareQrCode={this.props.shareQrCode}
              pageSection={socialShareSection}
            />
            <SmsShareButton
              shareBySms={shareBySms}
              socialShareUrl={socialShareUrl}
              targetAmount={targetAmount}
              pitchFor={pitchFor}
              ownerName={ownerName}
              isOwner={isOwner}
              targetCurrency={targetCurrency}
              isActive={isActive}
              pageSection={socialShareSection}
            />
          </div>
        </AnalyticsPageView>
      </Modal>
    );
  }
}

export default CSSModules(ShareModal, styles);
