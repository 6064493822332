import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import { detectIE, getUserAgent, isEdgeUserAgent } from 'src/helpers/browser';
import { Button, Link, AnalyticsPageView } from 'src/components/Analytics';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { openSharePrompts } from 'src/redux/modules/sharePrompts';
import styles from 'src/components/Prompt/PageLive/PageLive.scss';
import { FormattedMessage } from 'react-intl';
import { completeAction } from 'src/redux/modules/completedActions';

const PageLive: Rule<JSX.Element, PromptRulesEngineOptions> = (
  state,
  options
) => {
  const { name, status } = state.page;
  const actionKey = 'prompt_pageLive';

  const hasUserClosedPrompt = state.completedActions.keys.includes(actionKey);

  const closePrompt = () => {
    options!.dispatch(completeAction(state.page.id, actionKey));
    options!.closePrompt();
  };

  const showPrompt = status === 'Active' && !hasUserClosedPrompt;

  if (showPrompt) {
    return {
      matched: true,
      result: (
        <AnalyticsPageView
          eventValue="prompt displayed"
          pageSection="admin > page creation > your page is ready"
          subtype="admin"
        >
          <div className="container-carousel jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd@xs">
            <section
              styleName="container"
              className={`${styles.container} jg-text--center jg-space-pvmd`}
            >
              <button className={styles['grey-cross']} onClick={closePrompt} />
              <div className="row">
                <div className="col-xs-12 col-md-6 offset-md-3">
                  <div className={styles['green-circle']}>
                    <svg width="48px" height="48px" viewBox="0 0 48 48">
                      <path
                        className={`${
                          detectIE(getUserAgent()) || isEdgeUserAgent()
                            ? styles['tick-complete']
                            : styles['tick-complete-animated']
                        }`}
                        d="M 5 30 L 17 40 L 43 10"
                        strokeWidth="8px"
                        fill="transparent"
                        strokeLinecap="butt"
                      />
                    </svg>
                  </div>
                  <h1 className="jg-h1 jg-text--center jg-space-mbms">
                    <FormattedMessage
                      id="PageLive.pageReady"
                      defaultMessage="Your Page is ready!"
                    />
                  </h1>

                  <p className="jg-space-mbmd">
                    <FormattedMessage
                      id="PageLive.sharePage"
                      defaultMessage="Invite friends and family to your Page and watch donations roll in"
                    />
                  </p>

                  <div className="row jg-space-mbmd">
                    <Button
                      className="jg-btn jg-btn--brand col-xs-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3"
                      onClick={() =>
                        options!.dispatch(
                          openSharePrompts(options!.pageSection)
                        )
                      }
                      analyticsOptions={{
                        event: 'click',
                        subtype: 'button',
                        eventValue: 'share page',
                        pageSection: 'page creation > share your page',
                      }}
                    >
                      <FormattedMessage
                        id="PageLive.shareButton"
                        defaultMessage="Share your Page"
                        tagName="ladda-label"
                      />
                    </Button>
                  </div>
                  <Link to={`/${name}/dashboard`} className="jg-text--link">
                    <FormattedMessage
                      id="PageLive.dashboardLink"
                      defaultMessage="Tips to improve your Page"
                    />
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </AnalyticsPageView>
      ),
    };
  }

  return { matched: false };
};

export default PageLive;
