import React from 'react';
import { OwnerAvatar } from '../Avatar';
import icon from './desktop_share.svg';

interface Props {
  ownerProfileImage?: string;
  ownerName: string;
}

const DesktopSharePopup: React.StatelessComponent<Props> = ({
  children,
  ownerProfileImage,
  ownerName,
}) => (
  <div className="row jg-space-phlg jg-space-ptlg jg-space-pbmd jg-space-mhn">
    <div className="col-md-6">{children}</div>
    <div className="col-md-5 offset-md-1 jg-display-flex jg-flex-aic jg-space-mblg">
      {ownerProfileImage ? (
        <OwnerAvatar
          template="Size258w"
          src={ownerProfileImage}
          alt={ownerName}
          size="share-prompt-profile"
        />
      ) : (
        <img src={icon} alt="People sharing the page via Facebook" />
      )}
    </div>
  </div>
);

export default DesktopSharePopup;
