import { Email } from './Email';

export class OwnerShareEmail extends Email {
  getSubject = () => {
    return super.i18nWithEnrichment(
      'Share.email.owner.subject',
      'Please donate to my Crowdfunding Page'
    );
  };

  getBody = () => {
    return super.i18nWithEnrichment(
      'Share.email.owner.body',
      "Hello,\nI'm raising {{targetAmountFormatted}} to {{pitchFor}}.\nPlease donate to my JustGiving Crowdfunding Page and help make it happen:\n\n{{- pageUrl}}\n\nThanks for your support\n\n\nPS. With JustGiving Crowdfunding anyone can raise money to fund their own project - anything from setting up a foodbank, to buying a wheelchair for a relative or even saving a local football club. Want to raise money to make good things happen? Start your Crowdfunding Page today.\n\n{{- homeUrl}}"
    );
  };
}
