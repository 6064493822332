import React from 'react';
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import { ROOT_URL } from 'config';
import styles from './CrossSellBanner.scss';

interface Props {
  createPageLikeThis: () => void;
  crossSellBannerImpression: () => void;
}

class CrossSellBanner extends React.Component<Props> {
  componentDidMount() {
    this.props.crossSellBannerImpression();
  }

  render() {
    const styleProp = {
      styleName: 'action-about',
    };
    return (
      <section styleName="container">
        <h1 className="jg-h4 jg-space-mbmd">Great people make things happen</h1>
        <p className="jg-text">
          Do you know anyone in need or maybe want to help a local community
          cause?
        </p>
        <p className="jg-text jg-space-mbmd">
          Create you own page and donʼt let that cause go unfunded!
        </p>
        <div styleName="white">
          <a
            href={`${ROOT_URL}/create-page/crowdfunding/category`}
            className="jg-btn jg-btn--brand"
            styleName="action-create "
            onClick={this.props.createPageLikeThis}
          >
            Start a new Page
          </a>
        </div>
        <Link to={'/'} {...styleProp}>
          About Crowdfunding
        </Link>
      </section>
    );
  }
}

export default CSSModules(CrossSellBanner, styles);
