import React from 'react';
import { useDonatePopup } from 'src/components/DonatePopup/DonatePopupContext';
import DonatePopupRefocusModal from 'src/components/DonatePopup/DonatePopupRefocusModal';
import DonatePopupBlockedModal from 'src/components/DonatePopup/DonatePopupBlockedModal';

const DonatePopup = () => {
  const { popupCheckoutMode } = useDonatePopup();

  if (popupCheckoutMode === 'open') {
    return <DonatePopupRefocusModal />;
  }
  if (popupCheckoutMode === 'blocked') {
    return <DonatePopupBlockedModal />;
  }
  return null;
};

export default DonatePopup;
