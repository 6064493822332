import { getAll, CategoryResource } from 'src/helpers/api/categories';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';

interface CategoriesRequestAction {
  type: 'CATEGORIES_REQUEST';
}

interface CategoriesSuccessAction {
  type: 'CATEGORIES_SUCCESS';
  payload: { categories: CategoryResource[] };
}

interface CategoriesFailureAction {
  type: 'CATEGORIES_FAILURE';
  error: true;
  payload: Error;
}

export function loadCategories() {
  return async (dispatch: Dispatch<AppState>) => {
    dispatch<CategoriesRequestAction>({
      type: 'CATEGORIES_REQUEST',
    });

    try {
      const data = await getAll();

      dispatch<CategoriesSuccessAction>({
        type: 'CATEGORIES_SUCCESS',
        payload: { categories: data.categories },
      });
    } catch (err) {
      dispatch<CategoriesFailureAction>({
        type: 'CATEGORIES_FAILURE',
        error: true,
        payload: err,
      });
    }
  };
}

export type CategoriesState = CategoryResource[];

export default function reducer(
  state: CategoriesState = [],
  action: CategoriesSuccessAction
): CategoriesState {
  switch (action.type) {
    case 'CATEGORIES_SUCCESS':
      return action.payload.categories;
    default:
      return state;
  }
}
