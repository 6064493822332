import React from 'react';
import CSSModules from 'react-css-modules';
import AvatarEditor, { ImageState } from '@justgiving/react-avatar-editor';
import Slider from '../Slider';
import styles from './ImageEditor.scss';
import ClickableElement from 'src/components/ClickableElement';
import Svg from '@justgiving/svg';
import rotateIcon from '@justgiving/icons/lib/rotate.svg';
import deleteIcon from '@justgiving/icons/lib/delete.svg';

export interface CropData {
  position: { x: number; y: number };
  scale: number;
  rotate: number;
}

interface Props {
  image: string;
  position: { x: number; y: number };
  scale: number;
  rotate: number;
  pageSection: string;
  ref?: (c: any) => void;
  onCropChange: (data: CropData) => void;
  onRemoveImage: () => void;
  onRotateImage: (data: CropData) => void;
}

interface State {
  width: number;
  minScale: number;
  maxScale: number;
}

class ImageEditor extends React.Component<Props, State> {
  container: HTMLDivElement;
  editor: typeof AvatarEditor;

  constructor(props: Props) {
    super(props);

    this.state = { minScale: 1, maxScale: 1, width: 0 };

    this.scale = this.scale.bind(this);
    this.rotate = this.rotate.bind(this);
    this.onLoadSuccess = this.onLoadSuccess.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.adjustWidth = this.adjustWidth.bind(this);
  }

  componentDidMount() {
    this.adjustWidth();

    window.addEventListener('resize', this.adjustWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustWidth);
  }

  adjustWidth() {
    this.setState({ width: this.container ? this.container.offsetWidth : 0 });
  }

  onLoadSuccess(imageState: ImageState) {
    const { width, height } = this.editor.getDimensions();

    const minScale = Math.min(
      width / imageState.width,
      height / imageState.height,
      1
    );
    const maxScale = Math.max(
      imageState.resource.width / imageState.width,
      imageState.resource.height / imageState.height,
      1
    );

    this.setState({ minScale, maxScale });
  }

  handlePositionChange(position: { x: number; y: number }) {
    this.props.onCropChange({
      position,
      rotate: this.props.rotate,
      scale: this.props.scale,
    });
  }

  rotate() {
    this.props.onRotateImage({
      position: this.props.position,
      rotate: this.props.rotate + 90,
      scale: this.props.scale,
    });
  }

  scale(scale: number) {
    this.props.onCropChange({
      position: this.props.position,
      rotate: this.props.rotate,
      scale,
    });
  }

  render() {
    const { width, minScale, maxScale } = this.state;
    const { image, position, rotate, scale, onRemoveImage } = this.props;

    // width is the total width including border, which we want
    // to be 10% of the crop area width on every side
    const cropWidth = width / 1.1;
    const cropHeight = cropWidth / (16 / 9);
    const border = cropWidth * 0.05;

    const isVertical = rotate % 180 !== 0;

    const refProps = {
      ref: (c: any) => {
        this.editor = c;
      },
    };
    return (
      <div
        styleName="container"
        ref={r => {
          this.container = r!;
        }}
      >
        {width && (
          <AvatarEditor
            {...refProps}
            image={image}
            width={isVertical ? cropHeight : cropWidth}
            height={isVertical ? cropWidth : cropHeight}
            border={border}
            position={position}
            scale={scale}
            rotate={rotate}
            color={[204, 204, 204, 0.5]}
            crossOrigin="anonymous"
            onLoadSuccess={this.onLoadSuccess}
            onPositionChange={this.handlePositionChange}
          />
        )}
        <div>
          {!(minScale === 1 && maxScale === 1) && (
            <div>
              <Slider
                min={minScale}
                max={maxScale}
                value={scale}
                step={0.001}
                onChange={this.scale}
              />
              <div className="jg-text--center">
                {scale < 1
                  ? 'Try zooming in so your photo fills the frame'
                  : 'Zoom and drag to get the crop you want'}
              </div>
            </div>
          )}
          <div
            className="jg-space-mvmd jg-space-mbn@md jg-text--center"
            styleName="button-container"
          >
            <ClickableElement
              inline={true}
              onClick={this.rotate}
              className="jg-space-mbmd jg-space-mbn@sm jg-space-mrmd@xs jg-space-mrlg@md qa-rotate-button"
              analyticsOptions={{
                event: 'click',
                subtype: 'button',
                eventValue: 'rotate',
                pageSection: this.props.pageSection,
              }}
            >
              <Svg
                markup={rotateIcon}
                className="jg-icon jg-fill--nobel jg-space-mrsm jg-display-ib"
              />
              <span
                className="jg-text--link jg-display-ib"
                styleName="v-align-middle"
              >
                Rotate photo
              </span>
            </ClickableElement>
            <ClickableElement
              inline={true}
              onClick={onRemoveImage}
              className="qa-remove-button"
              analyticsOptions={{
                event: 'click',
                subtype: 'button',
                eventValue: 'remove',
                pageSection: this.props.pageSection,
              }}
            >
              <Svg
                markup={deleteIcon}
                className="jg-icon jg-fill--nobel jg-space-mrsm jg-display-ib"
              />
              <span
                className="jg-text--link jg-display-ib"
                styleName="v-align-middle"
              >
                Remove photo
              </span>
            </ClickableElement>
          </div>
        </div>
      </div>
    );
  }
}

export default CSSModules(ImageEditor, styles);
