import React from 'react';
import JGAlert from '@justgiving/alert';
import '@justgiving/alert/src/Alert.scss';
import { isLocalStorageAvailable } from 'src/helpers/browser';

interface Props {
  id: string;
  type: 'red' | 'green' | 'amber' | 'info';
  page?: {
    id: string;
  };
  autoCloseMs?: number;
  onClose?: () => void;
  analyticsClick?: () => void;
  displayType?: 'inline' | '';
  persistent?: boolean;
}

type AlertTypes = 'error' | 'warning' | 'success' | 'info';

const typeMap = {
  red: 'error',
  green: 'success',
  amber: 'warning',
  info: 'info',
};

class Alert extends React.Component<Props> {
  handleClose = () => {
    if (this.props.page && isLocalStorageAvailable()) {
      localStorage.setItem(
        `alert:${this.props.page.id}_${this.props.id}`,
        'true'
      );
    }

    if (this.props.analyticsClick) {
      this.props.analyticsClick();
    }

    this.props.onClose!();
  };

  render() {
    const {
      type,
      onClose,
      autoCloseMs,
      displayType = '',
      persistent,
    } = this.props;

    return (
      <section>
        <JGAlert
          type={typeMap[type] as AlertTypes}
          onClose={!persistent && onClose ? this.handleClose : undefined}
          autoClose={autoCloseMs && autoCloseMs > 0 ? autoCloseMs : undefined}
          displayType={displayType}
        >
          {this.props.children}
        </JGAlert>
      </section>
    );
  }
}

export default Alert;
