import React from 'react';
import { CountdownRulesEngineOptions } from 'src/components/Prompt/CountdownToRefundingFlow/countdownToRefundingEngine';
import { Rule } from 'src/helpers/rulesEngine';
import AddBankDetailsImmediatelyContent from './AddBankDetailsImmediatelyContent';

const AddBankDetailsImmediately: Rule<
  JSX.Element,
  CountdownRulesEngineOptions
> = (state, options) => {
  if (options) {
    const { timeRemainingFrozen } = options;

    if (
      timeRemainingFrozen.daysRemaining === 0 &&
      !timeRemainingFrozen.hasCountdownFinished &&
      state.page.status !== 'Suspended'
    ) {
      return {
        matched: true,
        result: (
          <AddBankDetailsImmediatelyContent
            pageName={options.pageName}
            suspensionDate={options.frozenDate}
          />
        ),
      };
    }
  }

  return { matched: false };
};

export default AddBankDetailsImmediately;
