import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StripeVerificationDocumentForm } from 'src/components/AdditionalDetails/';
import { submitStripeVerificationDocument } from 'src/redux/modules/stripeVirtualAccount/actions';
import {
  CropData,
  updateCrop,
  rotateImage,
  removeImage,
} from 'src/redux/modules/photoEditor';
import { AppState } from 'src/redux/modules';

type DispatchProps = {
  submitStripeVerificationDocument: typeof submitStripeVerificationDocument;
  updateCrop: (cropData: CropData) => void;
  rotateImage: (cropData: CropData) => void;
  removeImage: () => void;
};

interface OwnProps {
  onSubmit: () => void;
}

interface StateProps {
  cropData?: CropData;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      submitStripeVerificationDocument,
      updateCrop: updateCrop.bind(null, 'stripeVerification'),
      rotateImage: rotateImage.bind(null, 'stripeVerification'),
      removeImage: removeImage.bind(null, 'stripeVerification'),
    },
    dispatch
  );
}

function mapStateToProps(state: AppState): StateProps {
  return {
    cropData: state.editStripeVerificationDocument.cropData,
  };
}

const StripeVerificationDocumentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeVerificationDocumentForm);

export default StripeVerificationDocumentContainer as React.ComponentClass<
  OwnProps
>;
