import React from 'react';
import ReactDOM from 'react-dom';
import { ClickAnalyticsOptions } from 'src/redux/modules/analytics';
import ModalInner from 'src/components/Modal/ModalInner';

interface Props {
  colorTheme?: 'light' | 'dark';
  contentLabel?: string;
  isOpen?: boolean;
  onClose?: () => void;
  shouldCloseOnOverlayClick?: boolean;
  closeAnalyticsTracking?: ClickAnalyticsOptions;
  scrollToTop?: boolean;
  scrollToTopOnClose?: boolean;
  setPositionAbsolute?: boolean;
  noBackground?: boolean;
  maintainPosition?: boolean;
  large?: boolean;
  classNames?: string;
  onOverlayClick?: (event: React.MouseEvent) => void;
  onModalClick?: (event: React.MouseEvent) => void;
}

interface State {
  scrolled: any;
  top: any;
}

class Modal extends React.Component<Props, State> {
  elem: HTMLDivElement;

  constructor(props: Props) {
    super(props);

    if (__CLIENT__) {
      this.elem = document.createElement('div');
      document.body.appendChild(this.elem);
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.elem);
  }

  render() {
    if (!this.props.isOpen || !this.elem) {
      return null;
    }

    return ReactDOM.createPortal(<ModalInner {...this.props} />, this.elem);
  }
}

export default Modal;
