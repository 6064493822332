import React from 'react';
import Svg from '@justgiving/svg';
import CSSModules from 'react-css-modules';
import styles from './ConfigurableDesktopTooltip.scss';
import ClickableElement from 'src/components/ClickableElement/ClickableElement';
import { ClickAnalyticsOptions } from 'src/redux/modules/analytics';

interface Props {
  analyticsOptions?: ClickAnalyticsOptions;
  arrowPosition?: string | null;
  className?: string;
  displayInline?: boolean;
  icon: string;
  iconClassName?: string;
  onClick?: () => void;
  tooltipPosition?: string;
}

interface State {
  isTooltipShowing: boolean;
}

class TooltipClickTrigger extends React.Component<Props, State> {
  tooltip: HTMLDivElement;

  constructor(props: Props) {
    super(props);

    this.state = {
      isTooltipShowing: false,
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideTooltip);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideTooltip);
  }

  handleClickOutsideTooltip = (e: MouseEvent) => {
    if (
      this.state.isTooltipShowing &&
      this.tooltip &&
      !this.tooltip.contains(e.target as Node)
    ) {
      this.setState({ isTooltipShowing: false });
    }
  };

  toggleTooltip = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }

    this.setState({
      isTooltipShowing: !this.state.isTooltipShowing,
    });
  };

  render() {
    const {
      analyticsOptions,
      arrowPosition,
      className,
      displayInline,
      icon,
      iconClassName,
      tooltipPosition,
    } = this.props;

    return (
      <div
        className={`jg-relative ${styles['tooltip-click-wrapper']} ${
          displayInline ? 'jg-display-ib' : ''
        } ${className ? className : ''}`}
        ref={tooltip => {
          this.tooltip = tooltip!;
        }}
        id="myId"
      >
        <ClickableElement
          analyticsOptions={analyticsOptions}
          inline
          className={`qa-info-button`}
          onClick={this.toggleTooltip}
        >
          <Svg
            markup={icon}
            className={`jg-icon jg-fill--empress ${iconClassName &&
              iconClassName}`}
          />
        </ClickableElement>

        <div
          className={`${styles.tooltip} ${tooltipPosition} ${arrowPosition}
        ${this.state.isTooltipShowing ? styles['tooltip--open'] : ''}`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default CSSModules(TooltipClickTrigger, styles);
