export default function constructSupportersList(
  list: { imageProfile?: string }[]
) {
  const newList: { imageProfile?: string }[] = [];

  if (list === null) {
    return newList;
  }

  const supportersWithImageProfile = list.filter(s => s.imageProfile);

  if (supportersWithImageProfile.length < 3) {
    return newList;
  }

  newList.push(...supportersWithImageProfile.splice(0, 5));

  if (newList.length >= list.length) {
    return newList;
  }

  while (newList.length < 5 && newList.length < list.length) {
    newList.push({});
  }

  return newList;
}
