import React from 'react';
import { Trans, withI18n, WithI18n } from 'react-i18next';
import clock from 'src/components/Prompt/DocumentsManualVerification/clock.svg';
import Svg from '@justgiving/svg';

const DocumentsManualVerificationContent: React.StatelessComponent<
  WithI18n
> = () => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-8 offset-md-2">
        <Svg markup={clock} className="jg-svg-sm" />
        <h2>
          <Trans i18nKey="DocumentsManualVerificationContent.title">
            We're currently checking your documents
          </Trans>
        </h2>
        <p className="jg-space-mbml">
          <Trans i18nKey="DocumentsManualVerificationContent.subtitle">
            Our team is busy reviewing your documents and they'll get in touch
            with you soon. Make sure you check your emails regularly.
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default withI18n()(DocumentsManualVerificationContent);
