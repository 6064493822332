import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import PageStrandedContent from './PageStrandedContent';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';

const PageStranded: Rule<JSX.Element, PromptRulesEngineOptions> = state => {
  if (
    state.page.status === 'Stranded' &&
    !state.page.isOwnerRequested &&
    !state.page.refundedDate
  ) {
    return {
      matched: true,
      result: <PageStrandedContent />,
    };
  }

  return { matched: false };
};

export default PageStranded;
