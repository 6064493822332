import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import tick from '@justgiving/icons/lib/tick.svg';
import Svg from '@justgiving/svg';

const StripeVerificationDocumentTips: React.StatelessComponent = () => {
  return (
    <div className="jg-tal">
      <div className="jg-space-mbmd">
        <div className={`jg-display-flex`}>
          <Svg
            markup={tick}
            className="jg-icon jg-fill--mountain-meadow jg-space-mrms"
          />
          <h4>
            <FormattedMessage
              id="StripeVerificationDocumentForm.makeSureItsVisible"
              defaultMessage={`Make sure it's visible`}
            />
          </h4>
        </div>
        <div>
          <p>
            <FormattedMessage
              id="StripeVerificationDocumentForm.makeSureItsVisibleIntro"
              defaultMessage={`Ensure your document's details are clear. We'll need to see: `}
            />
          </p>
          <ul className="jg-list-style--disc jg-list-position--inside">
            <li>
              <FormattedMessage
                id="StripeVerificationDocumentForm.yourFullName"
                defaultMessage={`Your full name`}
              />
            </li>
            <li>
              <FormattedMessage
                id="StripeVerificationDocumentForm.yourDoB"
                defaultMessage={`Your date of birth`}
              />
            </li>
            <li>
              <FormattedMessage
                id="StripeVerificationDocumentForm.yourHomeAddress"
                defaultMessage={`Your home address`}
              />
            </li>
            <li>
              <FormattedMessage
                id="StripeVerificationDocumentForm.docExpiryDate"
                defaultMessage={`The document's expiry date`}
              />
            </li>
            <li>
              <FormattedMessage
                id="StripeVerificationDocumentForm.docIssueNumber"
                defaultMessage={`The document's issue number`}
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="jg-space-mbmd">
        <div className="jg-display-flex">
          <Svg
            markup={tick}
            className="jg-icon jg-fill--mountain-meadow jg-space-mrms"
          />
          <h4>
            <FormattedMessage
              id="StripeVerificationDocumentForm.makeSureItsClear"
              defaultMessage={`Make sure it's clear`}
            />
          </h4>
        </div>
        <div>
          <p>
            <FormattedMessage
              id="StripeVerificationDocumentForm.makeSureItsClearIntro"
              defaultMessage={`When taking a photo of your document, make sure it is well lit, the photo isn't blurry and nothing is covering any of the details.`}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default StripeVerificationDocumentTips;
