import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import CSSModules from 'react-css-modules';
import { OwnerAvatar } from '../Avatar';
import styles from './Owner.scss';
import { PageClosedPitch, PageActivePitch } from './Pitch';

interface Props {
  clearRecaptcha: () => void;
  contactOwner: (data: any) => void;
  id: string;
  isOwner: boolean;
  name: string;
  ownerBio?: string;
  ownerName: string;
  pitchFor: string;
  profileImage?: string;
  recaptcha: string;
  targetAmount: number;
  targetCurrency: Currency;
  isClosed: boolean;
  raised: number;
}

class PageOwner extends React.Component<Props> {
  renderPitchMessage() {
    const {
      isClosed,
      raised,
      targetAmount,
      targetCurrency,
      pitchFor,
    } = this.props;

    if (isClosed) {
      return (
        <PageClosedPitch
          raised={raised}
          targetCurrency={targetCurrency}
          pitchFor={pitchFor}
        />
      );
    }

    return (
      <PageActivePitch
        targetAmount={targetAmount}
        targetCurrency={targetCurrency}
        pitchFor={pitchFor}
      />
    );
  }

  render() {
    const { ownerName, profileImage } = this.props;

    return (
      <div styleName="owner">
        <div styleName="owner-info">
          <ScrollLink
            to="about-fundraiser"
            spy
            smooth
            offset={-60}
            duration={500}
          >
            <OwnerAvatar src={profileImage} alt={ownerName} size="large" />
            <h2 styleName="name">{ownerName}</h2>
          </ScrollLink>
        </div>
        <div styleName="owner-raising-for">
          <h1 id="pitch" className="jg-h1 jg-space-mbn">
            {this.renderPitchMessage()}
          </h1>
        </div>
      </div>
    );
  }
}

export default CSSModules(PageOwner, styles);
