import {
  Rule,
  Value,
  Result,
  OneOfRule,
  OneOfRuleResult,
  Rules,
  Errors,
  OneOfErrors,
} from '.';

function isOneOfRuleResult(
  result: Result | OneOfRuleResult
): result is OneOfRuleResult {
  return !!result && typeof result === 'object' && !!result.id;
}

export default function validate<T>(
  rules: Rules<T>,
  values: RecursivePartial<T>
): Errors {
  if (!rules || typeof rules !== 'object') {
    throw new Error('rules must be an object');
  }

  if (!values || typeof values !== 'object') {
    throw new Error('values must be an object');
  }

  const errors: Errors = {};
  const oneOfErrors: OneOfErrors = {};

  for (const [propName, rulesForProp] of Object.entries(rules)) {
    const value = (values as any)[propName];

    if (Array.isArray(rulesForProp)) {
      const result = evaluateRules(rulesForProp, value);

      if (isOneOfRuleResult(result)) {
        if (!oneOfErrors[result.id]) {
          oneOfErrors[result.id] = [];
        }

        oneOfErrors[result.id].push({ ...result, propName, value });
      } else {
        errors[propName] = getErrorMessage(result, value, values);
      }
    } else if (typeof rulesForProp === 'object') {
      errors[propName] = validate(rulesForProp, value || {});
    } else {
      throw new Error(
        `Invalid validation rules object. ${propName} is of type` +
          `${typeof rulesForProp} but only arrays and objects are valid.`
      );
    }
  }

  for (const group of Object.values(oneOfErrors)) {
    if (group.every(a => a.failed)) {
      for (const err of group) {
        errors[err.propName] = getErrorMessage(err.message, err.value, values);
      }
    }
  }

  // Clean up any null errors
  return Object.keys(errors).reduce((errorAccum: Errors, key) => {
    const error = errors[key];
    if (error !== null) {
      errorAccum[key] = error;
    }
    return errorAccum;
  }, {});
}

function evaluateRules(
  rules: (Rule | OneOfRule)[],
  value: Value
): Result | OneOfRuleResult {
  for (const rule of rules) {
    const result = rule(value);

    if (result !== null) {
      return result;
    }
  }

  return null;
}

function getErrorMessage<T>(
  result: Result,
  value: Value,
  values: T
): string | null {
  if (typeof result === 'function') {
    return result(value, values);
  }

  return result;
}
