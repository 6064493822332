import { AnalyticsMeta } from 'src/redux/modules/analytics';

interface OpenShareWithFriendsPromptsAction {
  type: 'OPEN_SHARE_WITH_FRIENDS_PROMPTS';
  payload: string;
}

export function openShareWithFriendsPrompts(
  pageSection: string
): OpenShareWithFriendsPromptsAction {
  return {
    type: 'OPEN_SHARE_WITH_FRIENDS_PROMPTS',
    payload: pageSection,
  };
}

interface CloseShareWithFriendsPromptsAction {
  type: 'CLOSE_SHARE_WITH_FRIENDS_PROMPTS';
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function closeShareWithFriendsPrompts(): CloseShareWithFriendsPromptsAction {
  return {
    type: 'CLOSE_SHARE_WITH_FRIENDS_PROMPTS',
    meta: {
      analytics: {
        event: 'click',
        subtype: 'dismiss',
        event_value: 'Go to my page',
        page_section:
          'page > page creation > share with friends prompt > All done',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export interface ShareWithFriendsPromptsState {
  isShareWithFriendsPromptsOpen: boolean;
  hasShared: boolean;
  pageSection: string;
}

export default function reducer(
  state: ShareWithFriendsPromptsState = {
    isShareWithFriendsPromptsOpen: false,
    hasShared: false,
    pageSection: '',
  },
  action: OpenShareWithFriendsPromptsAction | CloseShareWithFriendsPromptsAction
): ShareWithFriendsPromptsState {
  switch (action.type) {
    case 'OPEN_SHARE_WITH_FRIENDS_PROMPTS':
      return {
        ...state,
        isShareWithFriendsPromptsOpen: true,
        hasShared: false,
        pageSection: action.payload,
      };
    case 'CLOSE_SHARE_WITH_FRIENDS_PROMPTS':
      return {
        ...state,
        isShareWithFriendsPromptsOpen: false,
        hasShared: true,
        pageSection: '',
      };
    default:
      return state;
  }
}
