const ausStates = [
  {
    name: 'New South Wales',
    abbreviation: 'NSW',
  },
  {
    name: 'Victoria',
    abbreviation: 'VIC',
  },
  {
    name: 'Queensland',
    abbreviation: 'QLD',
  },
  {
    name: 'Tasmania',
    abbreviation: 'TAS',
  },
  {
    name: 'South Australia',
    abbreviation: 'SA',
  },
  {
    name: 'Western Australia',
    abbreviation: 'WA',
  },
  {
    name: 'Northern Territory',
    abbreviation: 'NT',
  },
  {
    name: 'Australian Capital Territory',
    abbreviation: 'ACT',
  },
];

export default ausStates;
