import React from 'react';
import { GBAddress } from 'src/components/ConfirmIdentityForm/GBAddress';
import { AUAddress } from 'src/components/ConfirmIdentityForm/AUAddress';
import PersonalIdNumberContainer from 'src/containers/forms/PersonalIdNumberContainer';
import SSNLastFourContainer from 'src/containers/forms/SSNLastFourContainer';
import StripeVerificationDocumentContainer from 'src/containers/forms/StripeVerificationDocumentContainer';

export const countryAddressFields: { [key: string]: any } = {
  GB: GBAddress,
  AU: AUAddress,
};

export const countryCodes: { [key: string]: string } = {
  GB: 'United Kingdom',
  US: 'United States',
  AU: 'Australia',
};

export interface SupportedCountry {
  value: Country;
  label: string;
}

type VirtualAccountRequiredFieldProps = {
  onSubmit: () => void;
};

export const stripeVirtualAccountRequiredFieldsMap: {
  [key: string]:
    | any
    | React.ComponentClass<VirtualAccountRequiredFieldProps>
    | React.StatelessComponent<VirtualAccountRequiredFieldProps>;
} = {
  'legal_entity.ssn_last_4': SSNLastFourContainer,
  'legal_entity.personal_id_number': PersonalIdNumberContainer,
  'legal_entity.verification.document': StripeVerificationDocumentContainer,
};

export const stripeVirtualAccountRequiredFields = Object.keys(
  stripeVirtualAccountRequiredFieldsMap
).map(requiredField => requiredField);
