import decode from 'jwt-decode';
import config from 'config';
import { makeRequest } from './common';
import { getAuth, refreshAccessToken } from 'src/helpers/auth';

export const ERROR_INVALID_CREDENTIALS = 'ERROR_INVALID_CREDENTIALS';
export const ERROR_EMAIL_ADDRESS_IN_USE = 'ERROR_EMAIL_ADDRESS_IN_USE';
export const ERROR_NO_CONSUMER_PERMISSIONS = 'ERROR_NO_CONSUMER_PERMISSIONS';

export interface ChangeNameOptions {
  firstName: string;
  lastName: string;
}

export async function changeName(
  userId: string,
  { firstName, lastName }: ChangeNameOptions
) {
  await refreshAccessToken();

  const auth = getAuth();
  const refreshToken = auth.getRefreshToken();

  await makeRequest({
    baseUri: config.IAM_API_URI,
    path: `/users/${userId}/change-name`,
    method: 'post',
    throwOnError: true,
    data: {
      userGuid: userId,
      Title: 'Other',
      FirstName: firstName,
      LastName: lastName,
      RefreshToken: refreshToken,
    },
    withBearerAuthHeader: true,
  });
}

interface ChangeProfilePictureOptions {
  userId: string;
  imagePayload: File;
}

export async function changeProfilePicture({
  userId,
  imagePayload,
}: ChangeProfilePictureOptions): Promise<string> {
  await refreshAccessToken();

  const auth = getAuth();
  const refreshToken = auth.getRefreshToken();

  const body = new FormData();
  body.append('ProfileImage', imagePayload);
  body.append('RefreshToken', refreshToken!);

  const res = await makeRequest({
    baseUri: config.IAM_API_URI,
    path: `/users/${userId}/profile-image`,
    method: 'put',
    throwOnError: true,
    data: body,
    withBearerAuthHeader: true,
  });

  return res.body.Result;
}

interface UploadProfilePicFacebookOptions {
  userId: string;
  imageUrl: string;
}

export async function uploadProfilePicFacebook({
  userId,
  imageUrl,
}: UploadProfilePicFacebookOptions) {
  await refreshAccessToken();

  const auth = getAuth();
  const refreshToken = auth.getRefreshToken();

  const res = await makeRequest({
    baseUri: config.IAM_API_URI,
    path: `/users/${userId}/profile-image/url`,
    method: 'put',
    throwOnError: true,
    data: {
      ProfileImageUrl: imageUrl,
      RefreshToken: refreshToken,
    },
    withBearerAuthHeader: true,
  });

  return res.body.Result;
}

export function isLoggedIn() {
  try {
    const auth = getAuth();
    const authCookie = auth.getParsedAuthCookie();

    return authCookie ? hasConsumerPermissions(authCookie.accessToken) : false;
  } catch {
    return false;
  }
}

interface JWT {
  iss: string;
  sub: string;
  aud: string;
  exp: string;
  nbf: string;
  src: string;
  ver: string;
  role: string;
  given_name: string;
  family_name: string;
  picture: string;
  permissions: string | string[];
  unique_name: string;
}

function hasConsumerPermissions(acessToken: string) {
  try {
    const jwt = decode<JWT>(acessToken);
    const permissions = formatPermissions(jwt.permissions);

    return (
      permissions.find(permission => permission.groupType === 'consumer') !==
      undefined
    );
  } catch {
    return false;
  }
}

type Permission = {
  groupGuid: string;
  groupType: string;
  groupTypeExternalId: string;
  role: string[];
};

const formatPermissions = (permissions: string | string[]): Permission[] => {
  return typeof permissions === 'string'
    ? [parsePermission(permissions)]
    : permissions.map(parsePermission);
};

const parsePermission = (permission: string): Permission => {
  const [groupGuid, groupType, groupTypeExternalId, role] = permission.split(
    ','
  );

  return {
    groupGuid,
    groupType,
    groupTypeExternalId,
    role: role.split('::'),
  };
};

export async function getUserInfo() {
  try {
    const auth = getAuth();
    const authCookie = auth.getParsedAuthCookie();
    const jwt = decode<JWT>(authCookie.accessToken);

    return { firstName: jwt.given_name, lastName: jwt.family_name };
  } catch {
    return null;
  }
}
