import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FB_APP_ID } from 'config';
import { generateHash } from '../../helpers/hashId';
import { ShareActionOptions } from 'src/redux/modules/page';
import { isDesktopViewport } from 'src/helpers/browser';
import styles from './MessengerShareButton.scss';
import CSSModules from 'react-css-modules';
import { buildUrl } from 'src/helpers/url';

interface Props {
  socialShareUrl: string;
  location: string;
  shareOnMessenger: (options: ShareActionOptions) => void;
}

class MessengerShareButton extends Component<Props> {
  static propTypes = {
    shareOnMessenger: PropTypes.func,
    socialShareUrl: PropTypes.string,
    location: PropTypes.string,
  };

  handleMessengerShare = () => {
    const { socialShareUrl, shareOnMessenger, location } = this.props;

    const shareHash = generateHash();

    shareOnMessenger({
      shareHash,
      location,
    });

    const pageUrl = buildUrl(socialShareUrl, {
      utm_term: shareHash,
    });

    if (__CLIENT__) {
      const isMobile = !isDesktopViewport();
      if (isMobile) {
        window.location.href = `fb-messenger://share?link=${encodeURIComponent(
          pageUrl
        )}&app_id=${FB_APP_ID}`;
      } else {
        FB.ui(
          {
            method: 'send',
            link: pageUrl,
          },
          () => {}
        );
      }
    }
  };

  render() {
    return (
      <button
        type="button"
        className="jg-btn jg-btn--messenger"
        onClick={this.handleMessengerShare}
        styleName="page-owner-messenger-share-button"
      >
        Messenger
      </button>
    );
  }
}

export default CSSModules(MessengerShareButton, styles);
