import * as React from 'react';
import { Formik, Form, FormikBag, FormikProps } from 'formik';
import {
  AnalyticsLoadingButton as LoadingButton,
  Button,
  AnalyticsPageView,
} from 'src/components/Analytics';
import { FormattedMessage } from 'react-intl';
import { personalIdNumberValidation } from './additionalStripeDetailsValidator';
import Alert from 'src/components/Alert';
import FullSSNForm from './FullSSNForm';

export interface PersonalIdNumberData {
  personalIdNumber_1: string;
  personalIdNumber_2: string;
  personalIdNumber_3: string;
}

interface Props {
  updateStripeAccountDetails: (value: SSNValue) => void;
  onSubmit: () => void;
}

interface State {
  isValidated: boolean;
}

export interface SSNValue {
  personalIdNumber: string;
}

class PersonalIdNumberForm extends React.Component<Props, State> {
  state = { isValidated: false };

  handleSubmit = async (
    values: PersonalIdNumberData,
    formikBag: FormikBag<Props, PersonalIdNumberData>
  ) => {
    const ssnValue = {
      personalIdNumber: `${values.personalIdNumber_1}-${
        values.personalIdNumber_2
      }-${values.personalIdNumber_3}`,
    };
    try {
      formikBag.setStatus(null);
      await this.props.updateStripeAccountDetails(ssnValue);
      this.props.onSubmit();
    } catch (error) {
      formikBag.setSubmitting(false);
      formikBag.setStatus(error);
    }
  };

  handleValidationStatus = () => {
    this.setState({
      isValidated: true,
    });
  };

  render() {
    return (
      <AnalyticsPageView
        eventValue="verify identity - full ssn"
        pageSection="admin > verify identity"
        subtype="admin"
      >
        <div className="container jg-space-phsm jg-space-ptsm jg-space-phxxl@md">
          <Formik
            initialValues={{
              personalIdNumber_1: '',
              personalIdNumber_2: '',
              personalIdNumber_3: '',
            }}
            onSubmit={this.handleSubmit}
            validate={personalIdNumberValidation}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
              handleChange,
              handleBlur,
              values,
              errors,
              isSubmitting,
              isValid,
              touched,
              setFieldTouched,
              status,
              validateForm,
            }: FormikProps<PersonalIdNumberData>) => {
              const firstInputErrorDisplay =
                touched.personalIdNumber_1 && errors.personalIdNumber_1;
              const secondInputErrorDisplay =
                touched.personalIdNumber_2 && errors.personalIdNumber_2;
              const thirdInputErrorDisplay =
                touched.personalIdNumber_3 && errors.personalIdNumber_3;
              return (
                <Form>
                  <div className="jg-text--center jg-space-mbmd">
                    <p className="jg-text--large">
                      <b>
                        <FormattedMessage
                          id="PersonalIdNumberForm.pleaseProvideText"
                          defaultMessage={`Please provide your Social Security Number (SSN)`}
                        />
                      </b>
                    </p>
                    <p>
                      <FormattedMessage
                        id="PersonalIdNumberForm.ssnMustMatchText"
                        defaultMessage={`The SSN must match the name and address you previously entered`}
                      />
                    </p>
                  </div>
                  <div className="jg-space-phlg@md">
                    <div className="jg-form-group">
                      <label
                        className="jg-form-label"
                        htmlFor="personalIdNumber_1"
                      >
                        <FormattedMessage
                          id="PersonalIdNumberForm.ssnFieldLabel"
                          defaultMessage={`Your Social Security Number`}
                        />
                      </label>

                      <FullSSNForm
                        additionalFirstInputClassName={`${
                          firstInputErrorDisplay ? 'jg-form-control--error' : ''
                        }`}
                        additionalSecondInputClassName={`${
                          secondInputErrorDisplay
                            ? 'jg-form-control--error'
                            : ''
                        }`}
                        additionalThirdInputClassName={`${
                          thirdInputErrorDisplay ? 'jg-form-control--error' : ''
                        }`}
                        handleFocus={setFieldTouched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleValidation={validateForm}
                        handleValidationStatus={this.handleValidationStatus}
                        values={values}
                      />
                      {Object.keys(errors).map(item => (
                        <span
                          className="jg-form-error-copy jg-text--small"
                          role="alert"
                          key={item}
                        >
                          {(errors as any)[item]}
                        </span>
                      ))}
                    </div>
                    <div>
                      {status && (
                        <div className="jg-space-mtsm jg-space-mbmd">
                          <Alert
                            id="ssn-last-four-alert"
                            type="red"
                            displayType="inline"
                            persistent
                          >
                            An error has occurred. Please try again.
                          </Alert>
                        </div>
                      )}
                      {this.state.isValidated && isValid ? (
                        <LoadingButton
                          loading={isSubmitting}
                          className="jg-btn jg-btn--brand jg-space-mbms"
                          value={
                            <FormattedMessage
                              id="PersonalIdNumberForm.confirmSSNButton"
                              defaultMessage={`Confirm your SSN`}
                            />
                          }
                          analyticsOptions={{
                            event: 'link click',
                            subtype: 'button',
                            eventValue: 'confirm ssn',
                            pageSection: 'admin > verify identity',
                          }}
                        />
                      ) : (
                        <div className="jg-space-mvms">
                          <Button
                            qaClassName="qa-confirm-identity-button"
                            isDisabled
                          >
                            {
                              <FormattedMessage
                                id="PersonalIdNumberForm.confirmSSNInactiveButton"
                                defaultMessage={`Confirm your SSN`}
                              />
                            }
                          </Button>
                        </div>
                      )}
                      <p className="jg-text--xsmall jg-color--nobel jg-text--center">
                        <FormattedMessage
                          id="PersonalIdNumberForm.disclaimerText"
                          defaultMessage={`JustGiving and the third party that helps us with identity verification will keep your information secure and will only use it for the purposes of verifying your identity`}
                        />
                      </p>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </AnalyticsPageView>
    );
  }
}

export default PersonalIdNumberForm;
