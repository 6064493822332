import React from 'react';
import format from 'date-fns/format';
import styles from '../PromptClosed.scss';
import Svg from '@justgiving/svg';
import timer from '@justgiving/icons/lib/timer.svg';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { Rule } from 'src/helpers/rulesEngine';
import { FormattedMessage } from 'react-intl';

const PageClosedTargetNotReachedSupporter: Rule<
  JSX.Element,
  PromptRulesEngineOptions
> = state => {
  const { finishedAt, status } = state.page;

  if (status === 'TargetNotReached') {
    return {
      matched: true,
      result: (
        <div className="jg-background--white-smoke jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-pmd row">
          <div className="jg-text--center col-xs-12 col-md-6 offset-md-3">
            <h1 className="jg-h1 jg-space-mbsm">
              <FormattedMessage
                id="PageClosedTargetNotReachedSupporter.pageClosed"
                defaultMessage="Page closed"
              />
            </h1>
            <p className="jg-space-mbmd">
              <FormattedMessage
                id="PageClosedTargetNotReachedSupporter.pageIsClosed"
                defaultMessage="This page is now closed. Take a look at other inspiring pages on JustGiving"
              />
            </p>
            <div className="row jg-space-mbmd">
              <a
                id="discovery-link"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.justgiving.com/discover"
                className={`jg-btn jg-btn--brand col-xs-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 jg-text-dec--none jg-color--white`}
              >
                <FormattedMessage
                  id="PageClosedTargetNotReachedSupporter.discoverMoreLink"
                  defaultMessage="Browse more pages"
                />
              </a>
            </div>
            <div className="jg-display-flex jg-flex-aic jg-flex-jcc">
              <Svg
                className={`jg-fill--nobel jg-icon--medium-small jg-space-mbsm jg-space-mrxs ${
                  styles.timer
                }`}
                markup={timer}
              />
              <p className="jg-space-mbn jg-text--small">
                <FormattedMessage
                  id="PageClosedTargetNotReachedSupporter.closedAt"
                  defaultMessage="Closed {date}"
                  values={{
                    date: format(finishedAt!, 'DD/MM/YYYY'),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      ),
    };
  }

  return { matched: false };
};

export default PageClosedTargetNotReachedSupporter;
