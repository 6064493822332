import defaultImage from '../../static/images/default-cover-image.svg';
import { round } from './math';
import config from 'config';

export function convertStringToSvg(svgString: string) {
  const svgDiv = document.createElement('div');
  svgDiv.innerHTML = svgString;
  return svgDiv.firstChild as Node;
}

export async function convertImageToBase64(
  imgpath: string,
  width?: number,
  height?: number
) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img;
      const imageWidth = width || naturalWidth;
      const imageHeight = height || naturalHeight;
      canvas.width = imageWidth;
      canvas.height = imageHeight;
      canvas.getContext('2d')!.drawImage(img, 0, 0, imageWidth, imageHeight);
      const dataUrl = canvas.toDataURL('image/png');
      resolve(dataUrl);
    };
    img.src = imgpath;

    img.onerror = () => reject(new Error('Could not load the image'));
  });
}

export function convertSvgToPngBase64(
  svg: Node,
  width: number,
  height: number,
  mimeType: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const svgData = new XMLSerializer().serializeToString(svg);

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    const img = document.createElement('img');

    img.onload = () => {
      ctx!.drawImage(img, 0, 0, width, height);
      const dataUrl = canvas.toDataURL(mimeType);
      resolve(dataUrl);
    };

    img.onerror = () => reject(new Error('Could not load the image'));

    img.src = `data:image/svg+xml;base64,${btoa(
      unescape(encodeURIComponent(svgData))
    )}`;
  });
}

export function getImageUrlWithTemplate(
  imageUrl?: string,
  template?: string
): string {
  if (!imageUrl) {
    return '';
  }

  if (!template) {
    return imageUrl;
  }

  return `${imageUrl}?template=${template}`;
}

export function getImageUrl(imageName: string) {
  return `${config.IMAGE_BASE_URI}/${imageName}`;
}

export function getCoverImageUrl(
  projectImage?: CoverImage,
  maxSize?: Size,
  template?: ImageTemplate
): string {
  if (projectImage && projectImage.name) {
    if (projectImage.width && projectImage.height) {
      return getCroppedCoverImageUrl(
        projectImage as CroppableCoverImage,
        maxSize
      );
    }

    return getImageUrlWithTemplate(getImageUrl(projectImage.name), template);
  }

  return defaultImage;
}

type CroppableCoverImage = {
  name: string;
  width: number;
  height: number;
  cropArea?: CropArea;
};

function getCroppedCoverImageUrl(
  coverImage: CroppableCoverImage,
  maxSize?: Size
): string {
  const imageSize = { width: coverImage.width, height: coverImage.height };
  const cropArea = getCropArea(coverImage);

  const { x1, y1, x2, y2, zoom } = calculateCoverImageProperties(
    imageSize,
    cropArea,
    maxSize
  );

  return `${config.IMAGE_BASE_URI}/${
    coverImage.name
  }?crop=${x1},${y1},${x2},${y2}&zoom=${zoom}`;
}

function calculateCoverImageProperties(
  imageSize: Size,
  cropArea: CropArea,
  maxSize: Size = cropArea
) {
  // This algorithm is very CF specific - it'll assume that we never need anything
  // above or below the crop area, but we need as much as we can (up to maxWidth)
  // to the left and right.

  // zoom out to make height of the crop area equal to maxHeight, but do
  // not go over 1 which would stretch the image
  const zoom = round(Math.min(maxSize.height / cropArea.height, 1), 3);

  if (maxSize.width / maxSize.height < cropArea.width / cropArea.height) {
    // edge case: "weird" crop area aspect ratio, just return crop area
    // coordinates without trying to borrow from the sides.
    return {
      zoom,
      x1: cropArea.left,
      y1: cropArea.top,
      x2: cropArea.left + cropArea.width,
      y2: cropArea.top + cropArea.height,
    };
  }

  const cropX1 = cropArea.left;
  const cropX2 = cropArea.left + cropArea.width;

  // calculate how much we can take from the outside of the crop area to
  // the left and right, without exceeding maxWidth
  const outsideLeft = cropX1;
  const outsideRight = imageSize.width - cropX2;
  // this will always be negative thanks to the width:height ratio check above
  const outsideLimit = (maxSize.width / zoom - cropArea.width) / 2;
  const maxOutside = Math.min(outsideLeft, outsideRight, outsideLimit);

  return {
    zoom,
    x1: cropX1 - maxOutside,
    y1: cropArea.top,
    x2: cropX2 + maxOutside,
    y2: cropArea.top + cropArea.height,
  };
}

function getCropArea(coverImage: CroppableCoverImage): CropArea {
  if (
    coverImage.cropArea &&
    coverImage.cropArea.width &&
    coverImage.cropArea.height
  ) {
    return coverImage.cropArea;
  }

  return {
    left: 0,
    top: 0,
    width: coverImage.width,
    height: coverImage.height,
  };
}

function convertDataUrlToBlob(dataUrl: string, mimeType: string) {
  const byteString = atob(dataUrl.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeType });
}

export type CropImageArgs = {
  image: string;
} & CropData;

export type GetCropAreaRectangleArgs = {
  size: { width: number; height: number };
} & CropData;

export interface CropData {
  position: { x: number; y: number };
  scale: number;
  rotate: number;
}

export interface CropAreaRectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface CoverImage {
  name: string;
  width?: number;
  height?: number;
  cropArea?: CropArea;
}

export interface Size {
  width: number;
  height: number;
}

export interface CropArea {
  left: number;
  top: number;
  width: number;
  height: number;
}

export type ImageTemplate = 'Size800w';

export async function cropImage({
  image,
  position,
  scale,
  rotate,
}: CropImageArgs): Promise<Blob> {
  const resource = await loadImage(image);
  const cropRect = getCropAreaRectangle({
    size: resource,
    position,
    scale,
    rotate,
  });

  const canvas = document.createElement('canvas');

  canvas.width = cropRect.width;
  canvas.height = cropRect.height;

  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  context.fillStyle = '#fff';
  context.fillRect(0, 0, canvas.width, canvas.height);

  context.translate(canvas.width / 2, canvas.height / 2);
  context.rotate((rotate * Math.PI) / 180);
  context.translate(-(canvas.width / 2), -(canvas.height / 2));

  if (rotate % 180 !== 0) {
    context.translate(
      (canvas.width - canvas.height) / 2,
      (canvas.height - canvas.width) / 2
    );
  }

  context.drawImage(resource, -cropRect.x, -cropRect.y);

  return resizeImage(canvas, { width: 800, height: 450 });
}

export function getCropAreaRectangle({
  size,
  position,
  scale,
  rotate,
}: GetCropAreaRectangleArgs): CropAreaRectangle {
  const imageAspect = size.width / size.height;
  const cropAreaAspect = rotate % 180 === 0 ? 16 / 9 : 9 / 16;

  const width = (1 / scale) * Math.min(1, cropAreaAspect / imageAspect);
  const height = (1 / scale) * Math.min(1, imageAspect / cropAreaAspect);
  const x = position.x - width / 2;
  const y = position.y - height / 2;

  // react-avatar-editor does not normalise/clamp the coordinates, so
  // we have to do it here again...
  let xMin = 0;
  let xMax = 1 - width;
  let yMin = 0;
  let yMax = 1 - height;

  if (width > 1 && height > 1) {
    xMin = (1 - width) / 2;
    xMax = (1 - width) / 2;
    yMin = (1 - height) / 2;
    yMax = (1 - height) / 2;
  } else if (width > 1) {
    xMin = (1 - width) / 2;
    xMax = (1 - width) / 2;
    yMin = 0;
    yMax = 1 - height;
  } else if (height > 1) {
    xMin = 0;
    xMax = 1 - width;
    yMin = (1 - height) / 2;
    yMax = (1 - height) / 2;
  }

  const rectangle = {
    x: Math.round(Math.max(xMin, Math.min(x, xMax)) * size.width),
    y: Math.round(Math.max(yMin, Math.min(y, yMax)) * size.height),
    width: Math.round(width * size.width),
    height: Math.round(height * size.height),
  };

  if (rotate % 180 !== 0) {
    return {
      ...rectangle,
      width: rectangle.height,
      height: rectangle.width,
    };
  }

  return rectangle;
}

function isDataURL(str: string) {
  if (str === null) {
    return false;
  }
  const regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+;=\-._~:@/?%\s]*\s*$/i;
  return !!str.match(regex);
}

function loadImage(imageUrl: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const imageObj = new Image();
    imageObj.onload = () => resolve(imageObj);
    if (!isDataURL(imageUrl)) {
      imageObj.crossOrigin = 'anonymous';
    }
    imageObj.onerror = reject;
    imageObj.src = imageUrl;
  });
}

function resizeImage(image: HTMLCanvasElement, size: Size) {
  const canvas = document.createElement('canvas');

  canvas.width = size.width;
  canvas.height = size.height;

  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  context.drawImage(image, 0, 0, size.width, size.height);

  return getImageFromCanvas(canvas);
}

export function getImageFromCanvas(
  canvas: HTMLCanvasElement,
  mimeType = 'image/jpeg'
) {
  return new Promise<Blob>((resolve, reject) => {
    if (canvas.toBlob) {
      canvas.toBlob(
        blob => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to convert image to blob'));
          }
        },
        mimeType,
        0.9
      );
    } else {
      resolve(convertDataUrlToBlob(canvas.toDataURL(mimeType), mimeType));
    }
  });
}

interface ProcessImageOptions {
  maxSize?: number;
}

type ProcessImageResult = { success: true; image: string } | { success: false };

export function processImage(
  pic: File,
  options: ProcessImageOptions
): Promise<ProcessImageResult> {
  return new Promise<ProcessImageResult>(resolve => {
    if (validateImage(pic, options)) {
      const reader = new FileReader();

      reader.readAsDataURL(pic);

      reader.addEventListener('load', () => {
        resolve({ success: true, image: reader.result as string });
      });
    } else {
      resolve({ success: false });
    }
  });
}

export function validateImage(
  image: File,
  { maxSize }: ProcessImageOptions
): boolean {
  if (!/image\/(jpg|jpeg|png)$/.test(image.type)) {
    return false;
  }

  if (maxSize && image.size > maxSize) {
    return false;
  }

  return true;
}

export function getImageName(image: string) {
  if (image) {
    return image.slice(image.lastIndexOf('/'));
  }

  return '';
}
