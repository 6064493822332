import format from 'date-fns/format';
import subDays from 'date-fns/sub_days';
import { getSupporters } from 'src/helpers/api/activities';
import { isAccountConnected } from 'src/helpers/payment';
import {
  TIP_SELECTED,
  SKIP_SLIDE,
  CLOSE_SHARE_MODAL,
  VIEW_SLIDE,
  OPEN_FUNDS_MODAL,
  CLOSE_FUNDS_MODAL,
  MODIFY_TARGET,
  MORE_ABOUT_FEES,
  STAT_REQUEST,
  STAT_REQUEST_SUCCESS,
  STAT_REQUEST_FAILURE,
  UPLOAD_PROFILE_PHOTO,
} from './constants';
import { transformStatDataForGraph } from 'src/helpers/graphTransforms';

export function getTipsState({ page, completedActions, paymentDetails }) {
  return {
    publish: [
      'Active',
      'Suspended',
      'TargetNotReached',
      'FullyFunded',
      'ReportGenerated',
    ].includes(page.status),
    personalise:
      !!page.ownerProfileImageUrl &&
      !!page.ownerBio &&
      !!page.locationText &&
      !!page.categoryId,
    promote: completedActions.keys.includes('tip_promote'),
    account: isAccountConnected(page, paymentDetails),
    page_duration: completedActions.keys.includes('tip_page_duration'),
    advertise_cause: completedActions.keys.includes('tip_advertise_cause'),
    personalise_image: completedActions.keys.includes('tip_personalise_image'),
  };
}

export function tipClick(tipName, isComplete) {
  return isComplete ? inactiveTipClick(tipName) : activeTipClick(tipName);
}

export function uploadProfilePhoto(type) {
  return {
    type: UPLOAD_PROFILE_PHOTO,
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: type,
        page_section: 'admin > tips > profile photo',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function activeTipClick(tipName) {
  return {
    type: TIP_SELECTED,
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: tipName,
        page_section: 'admin > tips',
        actor_id: 'on',
        actor_sources: 'check box',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function inactiveTipClick(tipName) {
  return {
    type: TIP_SELECTED,
    meta: {
      analytics: {
        event: 'click',
        subtype: 'inactive button',
        event_value: tipName,
        page_section: 'admin > tips',
        actor_id: 'off',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function skipSlide(slideName) {
  return {
    type: SKIP_SLIDE,
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: 'skip this',
        page_section: `admin > tips > ${slideName}`,
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function viewSlide(slideName) {
  return {
    type: VIEW_SLIDE,
    meta: {
      analytics: {
        event: 'page view',
        subtype: 'admin',
        event_value: slideName,
        page_section: 'admin > tips',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function closeShareModal(pageSection) {
  return {
    type: CLOSE_SHARE_MODAL,
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: 'x',
        page_section: `admin > tips > ${pageSection}`,
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function openFundsModal() {
  return {
    type: OPEN_FUNDS_MODAL,
    meta: {
      analytics: {
        event: 'page view',
        subtype: 'admin',
        event_value: 'understand funds',
        page_section: 'admin > tips',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function closeFundsModal(eventValue) {
  return {
    type: CLOSE_FUNDS_MODAL,
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: eventValue,
        page_section: 'admin > tips > understand funds',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function modifyTarget() {
  return {
    type: MODIFY_TARGET,
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'hyperlink',
        event_value: 'modify your target',
        page_section: 'admin > tips > understand funds',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function moreAboutFees() {
  return {
    type: MORE_ABOUT_FEES,
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'hyperlink',
        event_value: 'more about fees',
        page_section: 'admin > tips > understand funds',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export const calculcateTotalCount = events =>
  events.reduce((acc, event) => acc + event.count, 0);

function getStat({ type, startDate, endDate }, apiCall) {
  return async (dispatch, getState) => {
    dispatch({ type: STAT_REQUEST });
    const { id } = getState().page;
    try {
      const result = await apiCall({ projectId: id, startDate, endDate });

      const cleanedData = transformStatDataForGraph(result);

      dispatch({
        type: STAT_REQUEST_SUCCESS,
        payload: {
          type,
          value: calculcateTotalCount(result),
          data: cleanedData,
        },
      });
      return result;
    } catch (e) {
      dispatch({
        type: STAT_REQUEST_FAILURE,
        payload: {
          type,
        },
      });
    }
  };
}

const DEFAULT_START_DATE = format(subDays(new Date(), 30), 'YYYY-MM-DD');

export function getDonations({ startDate = DEFAULT_START_DATE }) {
  return async (dispatch, getState) => {
    dispatch({ type: STAT_REQUEST });
    const { id } = getState().page;
    try {
      const result = await getSupporters(id, 1, 1, startDate);
      dispatch({
        type: STAT_REQUEST_SUCCESS,
        payload: {
          type: 'donations',
          value: result.totalCount,
        },
      });
    } catch (e) {
      dispatch({
        type: STAT_REQUEST_FAILURE,
        payload: {
          type: 'donations',
        },
      });
    }
  };
}
