import React from 'react';
import format from 'date-fns/format';
import Svg from '@justgiving/svg';
import styles from './PageClosedTargetNotReached.scss';
import timer from '@justgiving/icons/lib/timer.svg';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { Rule } from 'src/helpers/rulesEngine';
import { FormattedMessage } from 'react-intl';

const PageClosedTargetNotReachedOwner: Rule<
  JSX.Element,
  PromptRulesEngineOptions
> = state => {
  const { finishedAt, status } = state.page;

  if (status === 'TargetNotReached') {
    return {
      matched: true,
      result: (
        <div className="jg-background--white-smoke jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-pmd row">
          <div className="jg-text--center col-md-6 offset-md-3 col-xs-12">
            <h1 className="jg-h1 jg-space-mbsm">
              <FormattedMessage
                id="PageClosedTargetNotReachedOwner.pageClosed"
                defaultMessage="Page closed"
              />
            </h1>
            <p className="jg-space-mbmn">
              <FormattedMessage
                id="PageClosedTargetNotReachedOwner.youDidNotHitTarget"
                defaultMessage="Unfortunately you didn’t hit your target."
              />
            </p>
            <p className="jg-space-mbmd">
              <FormattedMessage
                id="PageClosedTargetNotReachedOwner.perhapsWeCouldHelp"
                defaultMessage=" If you'd like to try again perhaps we could help?"
              />
            </p>
            <div className={`${styles['action-container']} jg-space-mbmd`}>
              <p>
                <a
                  id="inspiration-link"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.justgiving.com/discover"
                >
                  <FormattedMessage
                    id="PageClosedTargetNotReachedOwner.getInspirationLink"
                    defaultMessage="Get inspiration from others"
                  />
                </a>
              </p>
              <p className={`${styles.breakline} jg-space-mhms`} />
              <p>
                <a
                  id="representative-link"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://help.justgiving.com/hc/en-us/requests/new?ticket_form_id=137829"
                >
                  <FormattedMessage
                    id="PageClosedTargetNotReachedOwner.speakToAdviser"
                    defaultMessage="Speak to a JustGiving adviser"
                  />
                </a>
              </p>
            </div>
            <div className="jg-display-flex jg-flex-aic jg-flex-jcc">
              <Svg
                className={`jg-fill--nobel jg-icon--medium-small jg-space-mbsm jg-space-mrxs ${
                  styles.timer
                }`}
                markup={timer}
              />
              <p className="jg-space-mbn jg-text--small">
                <FormattedMessage
                  id="PageClosedTargetNotReachedOwner"
                  defaultMessage="Closed {date}"
                  values={{ date: format(finishedAt!, 'DD/MM/YYYY') }}
                />
              </p>
            </div>
          </div>
        </div>
      ),
    };
  }

  return { matched: false };
};

export default PageClosedTargetNotReachedOwner;
