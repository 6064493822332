import { get, put, ApiError } from './common';
import { PREFERENCES_API_URI, USER_CONSENTS } from 'config';
import { ConsentStatement } from '../../redux/modules/consents/consentStatement';
export interface PreferencesServiceResponse {
  [index: number]: ConsentStatement;
}

const options = {
  baseUri: PREFERENCES_API_URI,
  withCredentials: false,
  throwOnError: false,
};

const locale = USER_CONSENTS.LOCALE;

export function getConsentStatements(
  collectionLocationId: number
): Promise<PreferencesServiceResponse | null> {
  return get(`ConsentStatements/${collectionLocationId}/${locale}`, options);
}

interface UserConsentStatementsPayload {
  userConsentStatements: ConsentStatement[];
  userGuid: string;
  pageId: string;
}

export async function updateUserConsents(
  payload: UserConsentStatementsPayload
): Promise<PreferencesServiceResponse> {
  const response = await put(
    `users/${payload.userGuid}/consents`,
    {
      collectionLocationId:
        USER_CONSENTS.CROWDFUNDING_PAGE_COLLECTION_LOCATION_ID,
      transactionTypeId: 2,
      transactionId: payload.pageId,
      consents: payload.userConsentStatements.map(consent => ({
        ConsentStatementId: consent.id,
        ConsentStatementText: consent.text,
        EntityType: consent.entityType,
        ConsentGiven: true,
      })),
    },
    {
      baseUri: PREFERENCES_API_URI,
      throwOnError: true,
      withBearerAuthHeader: true,
    }
  );

  if (response.ok) {
    return response.body;
  }

  if (response.badRequest) {
    throw response.body;
  }

  throw new ApiError({ response });
}
