import { submitPaymentDetails } from 'src/helpers/api/verifications';
import { loadPaymentDetails } from 'src/redux/modules/paymentDetails';
import { VerifyBankAccountFormData } from 'src/containers/forms/AddBankAccountModalContainer';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';
import { convertDateToUTC } from 'src/helpers/date';
import { AccountVerificationFailureErrorMessages } from 'src/components/VerifyBankAccount/AccountVerificationFailure/AccountVerificationFailure';

export type AddBankAccountData = VerifyBankAccountFormData & {
  pageId: string;
  pageName: string;
};

type AccountOwner = {
  title: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  town: string;
  postCode: string;
  dateOfBirth: Date;
};

type AddBankAccountPayload = {
  projectId: string;
  sortCode: string;
  accountNumber: string;
  pageOwner: AccountOwner;
  recipient?: AccountOwner;
};

const mapAccountToPayload = account => {
  return {
    title: account.title,
    firstName: account.firstName,
    lastName: account.lastName,
    addressLine1: account.addressLine1,
    addressLine2: account.addressLine2,
    town: account.town,
    postCode: account.postCode,
    dateOfBirth: convertDateToUTC(new Date(account.dob)),
  };
};

export function addBankAccount(account: AddBankAccountData) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch({
      type: 'BANK_REQUEST',
      payload: account,
    });
    const recipientExist = account.bankAccountVerification === 'recipient';

    let payload: AddBankAccountPayload = {
      projectId: account.pageId,
      sortCode: account.sortCode,
      accountNumber: account.accountNumber,
      pageOwner: mapAccountToPayload(account.pageOwner),
    };

    const payloadRecipient = {
      recipient: mapAccountToPayload(account.recipient),
    };

    payload = recipientExist ? { ...payload, ...payloadRecipient } : payload;

    let result;

    try {
      result = await submitPaymentDetails(payload);
    } catch (error) {
      dispatch({
        type: 'BANK_FAILURE',
        error: true,
        payload: { message: 'serverError', error },
      });

      throw new Error('serverError');
    }

    if (result.approved) {
      const status = getState().page.status;

      dispatch({ type: 'BANK_SUCCESS', payload: { approved: true } });
      dispatch({
        type: 'UPDATE_SUCCESS',
        payload: {
          verificationStatus: 'Verified',
          status: status === 'Suspended' ? 'Active' : status,
        },
      });
      dispatch(loadPaymentDetails());
    } else {
      dispatch({
        type: 'BANK_FAILURE',
        error: true,
        payload: { message: result.reason },
      });

      throw new Error(result.reason);
    }
  };
}

export interface BankSuccessAction {
  type: 'BANK_SUCCESS';
}

export interface BankFailureAction {
  type: 'BANK_FAILURE';
  payload: { message: AccountVerificationFailureErrorMessages };
}

export interface BankRequestAction {
  type: 'BANK_REQUEST';
}

export type BankActions =
  | BankSuccessAction
  | BankFailureAction
  | BankRequestAction;

export default function reducer(state = {}, action: BankActions) {
  switch (action.type) {
    case 'BANK_REQUEST':
      return {
        ...state,
        isRequesting: true,
        errorMessage: null,
      };
    case 'BANK_SUCCESS':
      return {
        ...state,
        isRequesting: false,
        lastRequested: Date.now(),
        errorMessage: null,
      };
    case 'BANK_FAILURE':
      return {
        ...state,
        isRequesting: false,
        errorMessage: action.payload.message,
      };
    default:
      return state;
  }
}
