import React from 'react';

export interface TransitionProps {
  active?: boolean;
  before?: { [key: string]: string | number };
  after?: { [key: string]: string | number };
  property?: string;
  delay?: string;
  duration?: string;
  timingFunction?: string;
  onComplete?: () => {};
  willChange?: string;
}

export default class Transition extends React.Component<TransitionProps> {
  static defaultProps = {
    active: false,
    before: {},
    after: {},
    property: 'all',
    delay: '0s',
    duration: '1s',
    timingFunction: 'ease',
  };

  element: HTMLDivElement;

  componentDidMount() {
    if (this.element && this.props.active) {
      this.startTransition(this.props);

      Object.keys(this.props.after || {}).forEach(p => {
        (this.element.style as any)[p] = (this.props.after || {})[p];
      });
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.active !== this.props.active) {
      this.startTransition(nextProps);
    }
  }

  startTransition({ willChange, onComplete }: TransitionProps) {
    if (__CLIENT__ && this.element) {
      if (willChange) {
        (this.element.style as any).willChange = willChange;
      }

      if (willChange || onComplete) {
        this.element.addEventListener(
          'transitionend',
          this.handleTransitionEnd
        );
      }
    }
  }

  handleTransitionEnd = () => {
    const { willChange, onComplete } = this.props;

    if (willChange) {
      (this.element.style as any).willChange = 'auto';
    }

    if (onComplete) {
      onComplete();
    }

    this.element.removeEventListener('transitionend', this.handleTransitionEnd);
  };

  render() {
    const {
      children,
      active,
      before,
      after,
      property,
      delay,
      duration,
      timingFunction,
    } = this.props;

    const style = {
      transitionProperty: property,
      transitionDelay: delay,
      transitionDuration: duration,
      transitionTimingFunction: timingFunction,
      ...(active ? after : before),
    };

    return (
      <section
        ref={r => {
          this.element = r as HTMLDivElement;
        }}
        style={style}
      >
        {children}
      </section>
    );
  }
}
