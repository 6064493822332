import * as React from 'react';
import { Formik, Form, Field, FormikBag, FormikProps } from 'formik';
import {
  AnalyticsLoadingButton as LoadingButton,
  Button,
  AnalyticsPageView,
} from 'src/components/Analytics';
import { FormattedMessage } from 'react-intl';
import { ssnLastFourValidation } from './additionalStripeDetailsValidator';
import Alert from 'src/components/Alert';

export interface SSNLastFourFormData {
  ssnLast4: string;
}

interface Props {
  updateStripeAccountDetails: (values: SSNLastFourFormData) => void;
  onSubmit: () => void;
}

class SSNLastFourForm extends React.Component<Props> {
  handleSubmit = async (
    values: SSNLastFourFormData,
    formikBag: FormikBag<Props, SSNLastFourFormData>
  ) => {
    try {
      formikBag.setStatus(null);
      await this.props.updateStripeAccountDetails(values);
      this.props.onSubmit();
    } catch (error) {
      formikBag.setSubmitting(false);
      formikBag.setStatus(error);
    }
  };

  render() {
    return (
      <AnalyticsPageView
        eventValue="verify identity - 4 digit ssn"
        pageSection="admin > verify identity"
        subtype="admin"
      >
        <div className="container jg-space-phsm jg-space-ptsm jg-space-phxxl@md">
          <Formik
            initialValues={{
              ssnLast4: '',
            }}
            onSubmit={this.handleSubmit}
            validate={ssnLastFourValidation}
            validateOnChange
          >
            {({
              handleChange,
              handleBlur,
              values,
              errors,
              isSubmitting,
              isValid,
              setFieldValue,
              touched,
              setFieldTouched,
              status,
            }: FormikProps<SSNLastFourFormData>) => {
              return (
                <Form>
                  <div className="jg-text--center jg-space-mbmd">
                    <p className="jg-text--large">
                      <b>
                        <FormattedMessage
                          id="SSNLastFourForm.pleaseProvideText"
                          defaultMessage={`Please provide the last 4 digits of your Social Security Number (SSN)`}
                        />
                      </b>
                    </p>
                    <p className="">
                      <FormattedMessage
                        id="SSNLastFourForm.ssnMustMatchText"
                        defaultMessage={`The SSN must match the name and address you previously entered`}
                      />
                    </p>
                  </div>
                  <div className="jg-space-phlg@md">
                    <div className="jg-form-group">
                      <label className="jg-form-label" htmlFor="ssnLast4">
                        <FormattedMessage
                          id="SSNLastFourForm.ssnFieldLabel"
                          defaultMessage={`Last 4 digits of your SSN`}
                        />
                      </label>
                      <Field
                        className={`jg-form-control ${
                          touched.ssnLast4 && errors.ssnLast4
                            ? 'jg-form-control--error'
                            : ''
                        }`}
                        type="text"
                        id="ssnLast4"
                        name="ssnLast4"
                        placeholder="xxxx"
                        maxLength={4}
                        onFocus={() => {
                          setFieldTouched('ssnLast4');
                        }}
                      />
                      {touched.ssnLast4 &&
                        errors.ssnLast4 && (
                          <span
                            className="jg-form-error-copy jg-text--small"
                            role="alert"
                          >
                            {errors.ssnLast4}
                          </span>
                        )}
                    </div>
                    <div>
                      {status && (
                        <div className="jg-space-mtsm jg-space-mbmd">
                          <Alert
                            id="ssn-last-four-alert"
                            type="red"
                            displayType="inline"
                            persistent
                          >
                            An error has occurred. Please try again.
                          </Alert>
                        </div>
                      )}
                      {!isValid ? (
                        <div className="jg-space-mvms">
                          <Button
                            qaClassName="qa-confirm-identity-button"
                            isDisabled
                            analyticsOptions={{
                              event: 'link click',
                              subtype: 'button',
                              eventValue: 'confirm last 4 digits of ssn',
                              pageSection: 'admin > verify identity',
                            }}
                          >
                            {
                              <FormattedMessage
                                id="SSNLastFourForm.confirmSSNLastFourInactiveButton"
                                defaultMessage={`Confirm last 4 digits of SSN`}
                              />
                            }
                          </Button>
                        </div>
                      ) : (
                        <LoadingButton
                          loading={isSubmitting}
                          className="jg-btn jg-btn--brand jg-space-mbms"
                          value={
                            <FormattedMessage
                              id="SSNLastFourForm.confirmSSNLastFourButton"
                              defaultMessage={`Confirm last 4 digits of SSN`}
                            />
                          }
                          // Waiting for analytics spec from Adam

                          // analyticsOptions={{
                          //   event: 'link click',
                          //   subtype: 'button',
                          //   eventValue: 'confirm last 4 ssn',
                          //   pageSection:
                          //     'funds > additional details > last four ssn',
                          // }}
                        />
                      )}
                      <p className="jg-text--xsmall jg-color--nobel jg-text--center">
                        <FormattedMessage
                          id="SSNLastFourForm.disclaimerText"
                          defaultMessage={`JustGiving and the third party that helps us with identity verification will keep your information secure and will only use it for the purposes of verifying your identity`}
                        />
                      </p>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </AnalyticsPageView>
    );
  }
}

export default SSNLastFourForm;
