import React from 'react';

interface Props {
  openPanel: (index: number) => void;
  closePanel: () => void;
  index: number;
  isActive: boolean;
  className?: string;
  key?: string | number;
}

class CollapsePanel extends React.Component<Props> {
  render() {
    const children = React.Children.map(this.props.children, (child: any) => {
      return React.cloneElement(child, {
        togglePanel: this.props.isActive
          ? this.props.closePanel.bind(null, this.props.index)
          : this.props.openPanel.bind(null, this.props.index),
        isActive: this.props.isActive,
      });
    });

    return <div className={this.props.className || ''}>{children}</div>;
  }
}

export default CollapsePanel as React.ComponentClass<{
  initialOpenPanel?: boolean;
  className?: string;
  key?: string | number;
}>;
