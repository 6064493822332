import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './Link.scss';
import { connect } from 'react-redux';
import {
  analyticsClick as analyticsClickActionCreator,
  AnalyticsClick,
  ClickAnalyticsOptions,
} from 'src/redux/modules/analytics';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      analyticsClick: analyticsClickActionCreator as AnalyticsClick,
    },
    dispatch
  );

interface OwnProps {
  activeClassName?: string;
  analyticsOptions?: ClickAnalyticsOptions;
  className?: string;
  href?: string;
  isIndexLink?: boolean;
  rel?: string;
  target?: string;
  to?: any;
  onClick?: () => void;
}

interface DispatchProps {
  analyticsClick: AnalyticsClick;
}

type Props = OwnProps & DispatchProps;

class TrackableLink extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { analyticsClick, analyticsOptions, onClick } = this.props;
    if (analyticsOptions) {
      analyticsClick(analyticsOptions);
    }
    if (onClick) {
      onClick();
    }
  }

  render() {
    const {
      activeClassName,
      children,
      className,
      href,
      isIndexLink,
      rel,
      target,
      to,
    } = this.props;

    if (isIndexLink) {
      return (
        <NavLink
          activeClassName={activeClassName}
          className={className}
          onClick={this.handleClick}
          exact
          to={to}
        >
          {children}
        </NavLink>
      );
    }

    if (href) {
      return (
        <a
          className={className}
          href={href}
          onClick={this.handleClick}
          rel={rel}
          target={target}
        >
          {children}
        </a>
      );
    }
    return (
      <NavLink
        activeClassName={activeClassName}
        className={className}
        onClick={this.handleClick}
        to={to}
      >
        {children}
      </NavLink>
    );
  }
}

export default connect<void, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(CSSModules(TrackableLink, styles));
