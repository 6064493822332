import React from 'react';
import config from 'config';
import styles from './RecurringPaymentsButtons.scss';
import { FormattedMessage } from 'react-intl';

type Props = {
  name: string;
  pageSection: string;
  donate: (options: { pageSection: string }) => void;
};

const RecurringPaymentsButtons: React.StatelessComponent<Props> = ({
  name,
  pageSection,
  donate,
}) => (
  <p className={styles.text}>
    <a
      data-marker="recurring-payments-buttons-donate-button"
      href={`${
        config.CROWDFUNDING_BASE_URI
      }/${name}/donate?pageSection=${pageSection}&eventValue=single`}
      className={styles.link}
    >
      <FormattedMessage
        id="RecurringPaymentsButtons.donation"
        defaultMessage="Make a donation"
      />
    </a>{' '}
    <FormattedMessage
      id="RecurringPaymentsButtons.divider"
      defaultMessage="or"
    />{' '}
    <a
      data-marker="recurring-payments-buttons-monthly-donate-button"
      href={`${
        config.CROWDFUNDING_BASE_URI
      }/${name}/donate?pageSection=${pageSection}&paymentType=monthly&eventValue=monthly`}
      className={styles.link}
    >
      <FormattedMessage
        id="RecurringPaymentsButtons.monthlyDonation"
        defaultMessage="donate monthly"
      />
    </a>
  </p>
);

export default RecurringPaymentsButtons;
