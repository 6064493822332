/* tslint:disable */
// Picked up from master branch of https://github.com/leozdgao/react-async-script-loader
// which seems to be no longer maintained and haven't released one of the fixes
// we need that was merged a long time ago.

import T from 'prop-types';

import React, { Component } from 'react';
import hoistStatics from 'hoist-non-react-statics';

const loadedScript = [];
const pendingScripts = {};
let failedScript = [];

export function startLoadingScripts(scripts, onComplete = noop) {
  // sequence load
  const loadNewScript = src => {
    if (loadedScript.indexOf(src) < 0) {
      return taskComplete => {
        const callbacks = pendingScripts[src] || [];
        callbacks.push(taskComplete);
        pendingScripts[src] = callbacks;
        if (callbacks.length === 1) {
          return newScript(src)(err => {
            pendingScripts[src].forEach(cb => cb(err, src));
            delete pendingScripts[src];
          });
        }
      };
    }
  };
  const tasks = scripts.map(src => {
    if (Array.isArray(src)) {
      return src.map(loadNewScript);
    } else return loadNewScript(src);
  });

  series(...tasks)((err, src) => {
    if (err) {
      failedScript.push(src);
    } else {
      if (Array.isArray(src)) {
        src.forEach(addCache);
      } else addCache(src);
    }
  })(err => {
    removeFailedScript();
    onComplete(err);
  });
}

const addCache = entry => {
  if (loadedScript.indexOf(entry) < 0) {
    loadedScript.push(entry);
  }
};

const removeFailedScript = () => {
  if (failedScript.length > 0) {
    failedScript.forEach(script => {
      const node = document.querySelector(`script[src='${script}']`);
      if (node != null) {
        node.parentNode.removeChild(node);
      }
    });

    failedScript = [];
  }
};

const scriptLoader = (...scripts) => WrappedComponent => {
  class ScriptLoader extends Component {
    static propTypes = {
      onScriptLoaded: T.func,
    };

    static defaultProps = {
      onScriptLoaded: noop,
    };

    constructor(props, context) {
      super(props, context);

      this.state = {
        isScriptLoaded: false,
        isScriptLoadSucceed: false,
      };

      this._isMounted = false;
    }

    componentDidMount() {
      this._isMounted = true;
      startLoadingScripts(scripts, err => {
        if (this._isMounted) {
          this.setState(
            {
              isScriptLoaded: true,
              isScriptLoadSucceed: !err,
            },
            () => {
              if (!err) {
                this.props.onScriptLoaded();
              }
            }
          );
        }
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const props = {
        ...this.props,
        ...this.state,
      };

      return <WrappedComponent {...props} />;
    }
  }

  return hoistStatics(ScriptLoader, WrappedComponent);
};

export const isDefined = val => val != null;
export const isFunction = val => typeof val === 'function';
export const noop = _ => {};

export const newScript = src => cb => {
  const script = document.createElement('script');
  script.src = src;
  script.addEventListener('load', () => cb(null, src));
  script.addEventListener('error', () => cb(true, src));
  document.body.appendChild(script);
  return script;
};

const keyIterator = cols => {
  const keys = Object.keys(cols);
  let i = -1;
  return {
    next() {
      i++; // inc
      if (i >= keys.length) return null;
      else return keys[i];
    },
  };
};

// tasks should be a collection of thunk
export const parallel = (...tasks) => each => cb => {
  let hasError = false;
  let successed = 0;
  const ret = [];
  tasks = tasks.filter(isFunction);

  if (tasks.length <= 0) cb(null);
  else {
    tasks.forEach((task, i) => {
      const thunk = task;
      thunk((err, ...args) => {
        if (err) hasError = true;
        else {
          // collect result
          if (args.length <= 1) args = args[0];

          ret[i] = args;
          successed++;
        }

        if (isFunction(each)) each.call(null, err, args, i);

        if (hasError) cb(true);
        else if (tasks.length === successed) {
          cb(null, ret);
        }
      });
    });
  }
};

// tasks should be a collection of thunk
export const series = (...tasks) => each => cb => {
  tasks = tasks.filter(val => val != null);
  const nextKey = keyIterator(tasks);
  const nextThunk = () => {
    const key = nextKey.next();
    let thunk = tasks[key];
    if (Array.isArray(thunk))
      thunk = parallel.apply(null, thunk).call(null, each);
    return [+key, thunk]; // convert `key` to number
  };
  let key, thunk;
  let next = nextThunk();
  key = next[0];
  thunk = next[1];
  if (thunk == null) return cb(null);

  const ret = [];
  const iterator = () => {
    thunk((err, ...args) => {
      if (args.length <= 1) args = args[0];
      if (isFunction(each)) each.call(null, err, args, key);

      if (err) cb(err);
      else {
        // collect result
        ret.push(args);

        next = nextThunk();
        key = next[0];
        thunk = next[1];
        if (thunk == null) return cb(null, ret);
        // finished
        else iterator();
      }
    });
  };
  iterator();
};

export default scriptLoader;
