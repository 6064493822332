import { push } from 'react-router-redux';
import { DEFAULT_IMAGE_NAME } from 'config';
import { patch, PatchPagePayload } from 'src/helpers/api/pages';
import { uploadCoverImage } from 'src/helpers/api/images';
import {
  convertProblemToRichStory,
  transformStateToSave,
} from 'src/helpers/richEditor';
import { SerializedState } from 'slate';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';

export class SubmissionError extends Error {
  type: string;
  constructor(...args: any[]) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SubmissionError);
    }
    this.type = 'SubmissionError';
  }
}

export class RichStoryError extends Error {
  type: string;
  constructor(...args: any[]) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RichStoryError);
    }
    this.type = 'RichStoryError';
  }
}

export interface PatchPageData {
  name: string;
  id?: string;
  categoryId?: number | null;
  targetAmount?: number;
  targetCurrency?: Currency;
  care?: string;
  deadline?: string;
  money?: string;
  problem?: string;
  richStory?: SerializedState;
  completeImage?: string;
  report?: string;
  thankYou?: string;
  image?: {
    changed: boolean;
    blob?: Blob;
  };
  finishedAt?: string;
  latitude?: number;
  longitude?: number;
  locationText?: string;
  ownerName?: string;
  ownerBio?: string;
  pitchFor?: string;
  recurringDonationsPitch?: string;
}

export interface UpdateRequestAction {
  type: 'UPDATE_REQUEST';
  payload: {
    page: PatchPageData;
  };
}

export interface UpdateSuccessAction {
  type: 'UPDATE_SUCCESS';
  payload: PatchPagePayload;
}

export interface UpdateFailureAction {
  type: 'UPDATE_FAILURE';
  error: true;
  payload: Error;
}

export function updatePage(page: PatchPageData, redirect = true) {
  return async (dispatch: Dispatch<AppState>) => {
    dispatch<UpdateRequestAction>({
      type: 'UPDATE_REQUEST',
      payload: { page },
    });

    try {
      const payload: PatchPagePayload = {
        name: page.name,
        id: page.id,
        targetAmount: page.targetAmount,
        targetCurrency: page.targetCurrency,
        care: page.care,
        deadline: page.deadline,
        money: page.money,
        problem: page.problem,
        completeImage: page.completeImage,
        report: page.report,
        thankYou: page.thankYou,
        finishedAt: page.finishedAt,
        latitude: page.latitude,
        longitude: page.longitude,
        locationText: page.locationText,
        ownerName: page.ownerName,
        ownerBio: page.ownerBio,
        pitchFor: page.pitchFor,
        recurringDonationsPitch: page.recurringDonationsPitch,
      };

      // properties with undefined values will override the state and need to be removed
      removeUndefinedProperties(payload);

      if (page.categoryId) {
        // HACK: it might come back as a string, despite the typing,
        //       as there's still some JS in there...
        payload.categoryId = parseInt(page.categoryId as any, 10);
      }

      if (page.image && page.image.changed) {
        if (page.image.blob) {
          const response = await uploadCoverImage(page.image.blob);

          if (response.success) {
            payload.initialImage = { name: response.data.imageName };
            payload.socialShareImage = {
              name: response.data.socialShareImageName,
            };
          }
        } else {
          payload.initialImage = { name: DEFAULT_IMAGE_NAME };
          payload.socialShareImage = { name: DEFAULT_IMAGE_NAME };
        }
      }

      if (page.richStory) {
        payload.richStory =
          typeof page.richStory === 'string'
            ? convertProblemToRichStory(page.richStory)
            : await transformStateToSave(page.richStory);
      }

      await patch(payload);

      // HACK: 0 is a special value to let the server know that the value needs to be reset,
      // for the state purposes the lack of category is denoted by categoryId equal to null.
      if (payload.categoryId === 0) {
        payload.categoryId = null;
      }

      dispatch<UpdateSuccessAction>({ type: 'UPDATE_SUCCESS', payload });

      if (redirect) {
        dispatch(push(`/${page.name}`));
      }
    } catch (err) {
      dispatch<UpdateFailureAction>({
        type: 'UPDATE_FAILURE',
        error: true,
        payload: err,
      });

      if (
        err.response &&
        err.response.badRequest &&
        err.response.body &&
        err.response.body.message === 'emptyStory'
      ) {
        throw new RichStoryError('Please write your story');
      }

      throw new SubmissionError('An error has occurred. Please try again.');
    }
  };
}

function removeUndefinedProperties(obj: { [key: string]: any }) {
  return Object.keys(obj).forEach(
    key => obj[key] === undefined && delete obj[key]
  );
}
