import React, { ReactNode } from 'react';
import { ValidationResult } from 'src/helpers/validation';
import { Field, FieldProps } from 'formik';

type Props = {
  error?: string;
  inputClassname?: string;
  labelText: ReactNode;
  maxLength?: number;
  name: string;
  touched?: boolean;
  placeholder?: string;
};

const SingleLineInputWithTextLimit: React.StatelessComponent<Props> = ({
  error,
  inputClassname,
  labelText,
  maxLength,
  name,
  touched,
  placeholder = '',
}) => (
  <div className="jg-form-group">
    <label className="jg-form-label" htmlFor={name}>
      {labelText}
    </label>
    <Field
      name={name}
      render={({ field }: FieldProps) => (
        <input
          {...field}
          maxLength={maxLength}
          className={`jg-form-control ${
            touched && error ? 'jg-form-control---error' : ''
          } ${inputClassname}`}
          type="text"
          placeholder={placeholder}
        />
      )}
    />

    <ValidationResult touched={touched} error={error} />
  </div>
);

export default SingleLineInputWithTextLimit;
