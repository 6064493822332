import { GetCharityInfoSuccess } from 'src/redux/modules/charity/actions';

export interface Charity {
  id: number;
  name: string;
  logo: string;
  externalUrl: string;
  description: string;
  causeId: string;
  registrationNumber: string;
}

export type CharityState = Charity | null;

type CharityActions = GetCharityInfoSuccess; // replace any with interface of charity related actions

export default (state: CharityState = null, action: CharityActions) => {
  switch (action.type) {
    case 'GET_CHARITY_INFO_SUCCESS':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
