import CSSModules from 'react-css-modules';
import FacebookShare from 'src/components/FacebookShare/FacebookShare';
import React from 'react';
import { generateHash } from 'src/helpers/hashId';
import styles from './RichUpdatesItemShareAlert.scss';

interface Props {
  updateId: number;
  message?: string;
  type: string;
}

const RichUpdatesItemShareAlert: React.StatelessComponent<Props> = ({
  updateId,
  message,
}) => {
  const shareHash = generateHash();

  return (
    <div className={styles.container}>
      <p className="jg-text--regular">Spread the word even further</p>
      <p className="jg-space-mbmd">The more you share the more you raise!</p>
      <div className={styles['facebook-container']}>
        <FacebookShare
          customStyle="jg-btn jg-btn--facebook"
          isActive
          isUpdate
          location="updates"
          buttonText="Share on Facebook"
          updateId={updateId}
          quote={message}
          shareHash={shareHash}
        />
      </div>
    </div>
  );
};

export default CSSModules(RichUpdatesItemShareAlert, styles);
