import React from 'react';
import { Trans, withI18n } from 'react-i18next';
import format from 'date-fns/format';
import Svg from '@justgiving/svg';
import clock from '@justgiving/icons/lib/clock.svg';
import { getLastUpdated } from 'src/helpers/date';
import { SupporterResource } from 'src/helpers/api/activities';
import { UpdateResource } from 'src/helpers/api/updates';
import FormatDate from 'src/components/FormatDate';

export interface Props {
  createdAt: string;
  activatedAt: string;
  supporters: SupporterResource[];
  updates: UpdateResource[];
}

const LastUpdated: React.StatelessComponent<Props> = ({
  createdAt,
  activatedAt,
  supporters,
  updates,
}) => {
  const lastUpdated = getLastUpdated(
    createdAt,
    activatedAt,
    supporters,
    updates
  );
  const time = format(lastUpdated, 'HH.mm');

  return (
    <div className="jg-text--small jg-color--empress jg-space-mlms jg-space-mbsm">
      <Svg
        markup={clock}
        className="jg-display-i jg-icon--medium-small jg-fill--empress jg-space-mrxs"
      />
      <div className="jg-display-i">
        <Trans i18nKey="LastUpdated.message">
          Page last updated on: <FormatDate date={lastUpdated} /> {{ time }}
        </Trans>
      </div>
    </div>
  );
};

export default withI18n()(LastUpdated);
