import React from 'react';
import CSSModules from 'react-css-modules';
import { Trans } from 'react-i18next';
import styles from './ShowMoreOptionsButton.scss';
import ClickableElement from 'src/components/ClickableElement';
import TwitterShare from '../SocialShare/TwitterShare';
import FacebookShare from '../FacebookShare/FacebookShare';
import { ShareActionOptions } from 'src/redux/modules/page';

interface Props {
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  targetCurrency: Currency;
  shareOnTwitter: (options: ShareActionOptions) => void;
}

interface State {
  isOpen: boolean;
}

class ShowMoreOptionsButton extends React.Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);

    this.state = { isOpen: false };
  }

  onClick = (e: React.MouseEvent<HTMLElement>) => {
    this.setState({ isOpen: true });
  };

  render() {
    const {
      pitchFor,
      targetAmount,
      targetCurrency,
      socialShareUrl,
      shareOnTwitter,
    } = this.props;

    return (
      <>
        {!this.state.isOpen ? (
          <ClickableElement
            onClick={this.onClick}
            className="jg-text--link jg-text--center"
            analyticsOptions={{
              event: 'click',
              subtype: 'button',
              eventValue: 'show more options',
              pageSection:
                'admin > page creation > share your page with friends',
            }}
          >
            <Trans i18nKey="ShareWithFriendsModal.showMoreOptionsButton.title" />
          </ClickableElement>
        ) : (
          <div>
            <div className="jg-space-mbms">
              <FacebookShare
                buttonText="Facebook"
                location="admin > page creation > share your page with friends"
              />
            </div>
            <div className="jg-space-mbms">
              <TwitterShare
                className="jg-btn jg-btn--twitter"
                shareOnTwitter={shareOnTwitter}
                socialShareUrl={socialShareUrl}
                targetAmount={targetAmount}
                isOwner={true}
                pitchFor={pitchFor}
                isActive={true}
                location="admin > page creation > share your page with friends"
                targetCurrency={targetCurrency}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default CSSModules(ShowMoreOptionsButton, styles);
