import React from 'react';
import Svg from '@justgiving/svg';
import { FormattedMessage } from 'react-intl';
import image from '../RecurringDonations/svg/supports-recurring-donations-il.svg';
import ClickableElement from 'src/components/ClickableElement/ClickableElement';
import styles from './DonationReminder.scss';

interface Props {
  pageSection: string;
  handleClick: () => void;
}

const DonationReminder: React.StatelessComponent<Props> = ({
  pageSection,
  handleClick,
}) => {
  return (
    <div
      className={`jg-text--center jg-space-pmd ${styles['bottom-right']} ${
        styles.container
      }`}
    >
      <div className={`jg-space-pmd ${styles.body}`}>
        <Svg markup={image} className={`${styles.image} jg-space-mbsm`} />
        <p className="jg-text--large-regular">
          <FormattedMessage
            id="DonationReminder.donor.pageAcceptsDonationReminder"
            defaultMessage="Don't have time to donate right now?"
          />
        </p>
        <ClickableElement
          className={'jg-text--link'}
          inline
          analyticsOptions={{
            event: 'link click',
            subtype: 'button',
            eventValue: 'Set donation reminder',
            pageSection,
          }}
          onClick={handleClick}
        >
          <FormattedMessage
            id="DonationReminder.Link"
            defaultMessage="Set up a reminder"
          />
        </ClickableElement>
      </div>
    </div>
  );
};

export default DonationReminder;
