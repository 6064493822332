import React from 'react';
import { Medium } from '@justgiving/responsive';
import DonateButton from 'src/components/Page/DonateButton';
import { OwnerAvatar } from 'src/components/Avatar';
import { FormattedMessage } from 'react-intl';
import styles from './StickyHeader.scss';
import Sticky from 'src/components/StickyHeader/Sticky';
import ShareButton from '../ShareModal/ShareButton';

interface Props {
  ownerName: string;
  ownerProfileImageUrl?: string;
  pageName: string;
  canPageReceiveDonations: boolean;
  donate: () => void;
  handleShareButtonClick: () => void;
  disableShare: boolean;
  id: string;
}

class StickyHeader extends React.Component<Props> {
  calculateWindowHeight() {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    );
  }

  render() {
    const {
      ownerName,
      ownerProfileImageUrl,
      pageName,
      canPageReceiveDonations,
      donate,
      handleShareButtonClick,
      disableShare,
      id,
    } = this.props;

    return (
      <Medium>
        <Sticky offset={__CLIENT__ ? this.calculateWindowHeight() * 0.5 : 0}>
          {({ sticky }) => (
            <div
              className={`jg-bdb jg-bd--solid jg-bd--very-light-grey jg-background--white ${
                styles.header
              } ${sticky ? styles['header--show'] : ''}`}
            >
              <section className="container jg-space-pvms">
                <div className="row jg-space-phsm@md jg-space-ph@lg">
                  <div className="col-lg-6 col-xl-8">
                    <div className="row jg-flex-aic jg-flex-nowrap">
                      <OwnerAvatar
                        src={ownerProfileImageUrl}
                        alt={ownerName}
                        size="small"
                      />
                      <p className="jg-space-mbn jg-space-mlmd">
                        <strong className="jg-display-i">{ownerName}</strong>{' '}
                        <FormattedMessage
                          id="StickyHeader.isCrowdfunding"
                          defaultMessage="is crowdfunding"
                        />
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-xl-4">
                    <div className="row">
                      <div className="col-md-6">
                        {canPageReceiveDonations && (
                          <DonateButton
                            donate={donate}
                            isActive={canPageReceiveDonations}
                            name={pageName}
                            pageSection="page > share and donate fixed header"
                            id={id}
                          />
                        )}
                      </div>
                      {!disableShare && (
                        <div className="col-md-6">
                          <ShareButton
                            handleShareClick={handleShareButtonClick}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </Sticky>
      </Medium>
    );
  }
}

export default StickyHeader;
