import React from 'react';
import CSSModules from 'react-css-modules';
import config from 'config';
import { Link } from 'src/components/Analytics';
import RichUpdatesCreatorLink from './RichUpdatesCreatorLink';
import { OwnerAvatar } from 'src/components/Avatar';
import update from '@justgiving/icons/lib/update.svg';
import care from '@justgiving/icons/lib/care.svg';
import progress from '@justgiving/icons/lib/progress.svg';
import styles from './RichUpdatesCreator.scss';

interface Props {
  pageName: string;
  avatarImageUrl?: string;
}

const RichUpdatesCreator: React.StatelessComponent<Props> = ({
  pageName,
  avatarImageUrl,
}) => (
  <div>
    {!config.RICH_UPDATES_UPDATE_ONLY && (
      <div className="jg-bdb jg-bd--solid jg-bd--very-light-grey">
        <div className="row jg-space-pmd">
          <div className={`col-sm-4 ${styles.link}`}>
            <RichUpdatesCreatorLink
              path={`/${pageName}/update`}
              exact
              type="update"
              title="Update"
              icon={update}
            />
          </div>

          <div className={`col-sm-4 ${styles.link}`}>
            <RichUpdatesCreatorLink
              path={`/${pageName}/update/thank`}
              type="thank"
              title="Thanks"
              icon={care}
            />
          </div>

          <div className={`col-sm-4 ${styles.link}`}>
            <RichUpdatesCreatorLink
              path={`/${pageName}/update/milestone`}
              type="milestone"
              title="Milestone"
              icon={progress}
            />
          </div>
        </div>
      </div>
    )}
    <Link
      to={`/${pageName}/update`}
      className={`row jg-space-pmd ${styles.footer}`}
      isIndexLink
      analyticsOptions={{
        event: 'link click',
        subtype: 'button',
        eventValue: 'post an update',
        pageSection: 'admin > page view > updates',
      }}
    >
      <div className={`col-sm-12 col-md-7 ${styles['avatar-container']}`}>
        <OwnerAvatar
          className={`jg-space-mrmd ${styles.avatar}`}
          src={avatarImageUrl}
          alt="Owner avatar"
        />
        <p
          className={`jg-text--light jg-text--large jg-space-mbn ${
            styles.placeholder
          }`}
        >
          Tell your supporters how you're getting on...
        </p>
      </div>
      <div className={`col-md-5 jg-space-prxs ${styles['action-container']}`}>
        <button className={`jg-btn is-disabled jg-pull-right ${styles.action}`}>
          Post
        </button>
      </div>
    </Link>
  </div>
);

export default CSSModules(RichUpdatesCreator, styles);
