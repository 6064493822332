import React from 'react';
import { Field, FieldProps, FormikTouched, FormikErrors } from 'formik';
import Select from 'src/components/Select';
import ausStates from './ausStates';
import sortBy from 'lodash/sortBy';

interface FormData {
  addressLine1: string;
  city: string;
  postalCode: string;
  state: string;
}

interface Props {
  touched: FormikTouched<FormData>;
  errors: FormikErrors<FormData>;
}

const sortedStates = sortBy(ausStates, ['name']);

const AUAddress: React.StatelessComponent<Props> = ({ touched, errors }) => {
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <div className="jg-form-group">
            <label className="jg-form-label" htmlFor="addressLine1">
              Address line 1
            </label>
            <Field
              className={`jg-form-control ${
                touched.addressLine1 && errors.addressLine1
                  ? 'jg-form-control--error'
                  : ''
              }`}
              type="text"
              id="addressLine1"
              name="addressLine1"
            />
            {touched.addressLine1 &&
              errors.addressLine1 && (
                <span
                  className="jg-form-error-copy jg-text--small"
                  role="alert"
                >
                  {errors.addressLine1}
                </span>
              )}
          </div>
          <div className="jg-form-group">
            <label className="jg-form-label" htmlFor="addressLine2">
              Address line 2 (optional)
            </label>
            <Field
              className="jg-form-control"
              type="text"
              id="addressLine2"
              name="addressLine2"
            />
          </div>
          <div className="jg-form-group">
            <label className="jg-form-label" htmlFor="city">
              City
            </label>
            <Field
              className={`jg-form-control ${
                touched.city && errors.city ? 'jg-form-control--error' : ''
              }`}
              type="text"
              id="city"
              name="city"
            />
            {touched.city &&
              errors.city && (
                <span
                  className="jg-form-error-copy jg-text--small"
                  role="alert"
                >
                  {errors.city}
                </span>
              )}
          </div>
        </div>
      </div>
      <div className="row jg-space-mn">
        <div className="col-xs-6 col-md-4 jg-space-pn">
          <div className="jg-form-group">
            <label className="jg-form-label" htmlFor="state">
              State
            </label>

            <Field name="state">
              {({ field, form }: FieldProps) => (
                <Select
                  items={sortedStates.map(state => ({
                    value: state.abbreviation,
                    label: state.name,
                    isSelected: state.abbreviation === field.value,
                  }))}
                  placeholder="Select a state"
                  onChange={selectedState => {
                    form.setFieldValue('state', selectedState.value);
                  }}
                />
              )}
            </Field>
            {touched.state &&
              errors.state && (
                <span
                  className="jg-form-error-copy jg-text--small"
                  role="alert"
                >
                  {errors.state}
                </span>
              )}
          </div>
          <div className="jg-form-group jg-space-mbn">
            <label className="jg-form-label" htmlFor="postalCode">
              Postcode
            </label>
            <Field
              className={`jg-form-control ${
                touched.postalCode && errors.postalCode
                  ? 'jg-form-control--error'
                  : ''
              }`}
              type="text"
              id="postalCode"
              name="postalCode"
            />
          </div>
        </div>
        <div className="col-xs-12 jg-space-pn">
          {touched.postalCode &&
            errors.postalCode && (
              <span className="jg-form-error-copy jg-text--small" role="alert">
                {errors.postalCode}
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

export default AUAddress;
