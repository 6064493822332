import { get, post } from './common';

export interface CompletedAction {
  key: string;
  projectId: string;
  createdAt: string;
}

export async function getCompletedActions(
  pageId: string
): Promise<CompletedAction[]> {
  const data = await get<CompletedAction[]>(
    `projects/${pageId}/completedActions`,
    { timingName: 'completedActions' }
  );

  if (data === null) {
    return [];
  }

  return data;
}

export async function setActionAsComplete(pageId: string, key: string) {
  return post(`projects/${pageId}/completedActions`, { key });
}
