import React from 'react';
import CSSModules from 'react-css-modules';
import { generateHash } from '../../helpers/hashId';
import currencyMap from '../../helpers/currencyMap';
import styles from './WhatsAppShare.scss';
import { ShareActionOptions } from 'src/redux/modules/page';
import { isMobileUserAgent } from 'src/helpers/browser';
import i18next from 'i18next';

interface Props {
  shareByWhatsApp: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  ownerName: string;
  isActive: boolean;
  customStyle?: string;
  location: string;
  buttonText?: string | React.ReactNode;
  className?: string;
  targetCurrency: Currency;
  styles?: Styles;
  messageType: ShareMessageTypes;
}

class WhatsAppShare extends React.Component<Props> {
  static propTypes = {};

  handleWhatsAppShare = () => {
    const { location, shareByWhatsApp } = this.props;

    const shareHash = generateHash();

    shareByWhatsApp({ shareHash, location });

    const message = this.buildMessage(shareHash);

    if (__CLIENT__) {
      if (isMobileUserAgent()) {
        window.location.href = `whatsapp://send?text=${encodeURIComponent(
          message
        )}`;
      } else {
        window.open(
          `https://web.whatsapp.com/send?text=${encodeURIComponent(message)}`,
          '_blank'
        );
      }
    }
  };

  buildMessage = (shareHash: string) => {
    const { messageType, ownerName, pitchFor } = this.props;

    let message;

    switch (messageType) {
      case 'shareWithFriends':
        message = i18next.t('Share.whatsApp.friend.message', {
          defaultValue: `I've created a JustGiving page to raise {{targetAmountFormatted}} to {{pitchFor}} and it would be great if you could help me get started:\n\n{{- pageUrl}}\n\nPlease donate and share, and let me know if you have any suggestions.`,
          targetAmountFormatted: this.getTargetAmountFormatted(),
          pitchFor,
          pageUrl: this.getPageUrl(shareHash),
          ownerName,
        });
      case 'ownerShare':
        message = i18next.t('Share.whatsApp.owner.message', {
          defaultValue: `Can you help me raise {{targetAmountFormatted}} to {{pitchFor}}. Please donate to my JustGiving Crowdfunding Page: {{- pageUrl}}`,
          targetAmountFormatted: this.getTargetAmountFormatted(),
          pitchFor,
          pageUrl: this.getPageUrl(shareHash),
          ownerName,
        });
      default:
        message = i18next.t('Share.whatsApp.supporter.message', {
          defaultValue: `Can you help {{ownerName}} raise {{targetAmountFormatted}} to {{pitchFor}}. Please donate to their JustGiving Crowdfunding Page: {{- pageUrl}}`,
          targetAmountFormatted: this.getTargetAmountFormatted(),
          pitchFor,
          pageUrl: this.getPageUrl(shareHash),
          ownerName,
        });
    }

    return message;
  };

  getTargetAmountFormatted = () => {
    return `${currencyMap[this.props.targetCurrency] || currencyMap.GBP}${
      this.props.targetAmount
    }`;
  };

  getPageUrl = (shareHash: string) => {
    return `${this.props.socialShareUrl}?utm_term=${shareHash}`;
  };

  render() {
    const { isActive, customStyle, buttonText, className } = this.props;

    return (
      <button
        type="button"
        disabled={!isActive}
        className={className}
        styleName={customStyle}
        onClick={this.handleWhatsAppShare}
      >
        {buttonText || 'WhatsApp'}
      </button>
    );
  }
}

export default CSSModules(WhatsAppShare, styles);
