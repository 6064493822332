import React from 'react';
import { Element, scroller } from 'react-scroll';
import CSSModules from 'react-css-modules';
import { distanceInWordsToNow } from 'src/helpers/date';
import LoadingButton from 'src/components/LoadingButton/';
import styles from './Updates.scss';
import RichUpdatesCreator from 'src/components/RichUpdates/RichUpdatesCreator';
import RichUpdatesTimelineItem from 'src/components/RichUpdates/RichUpdatesTimelineItem';
import RichUpdatesPlaceholder from 'src/components/RichUpdates/RichUpdatesPlaceholder';
import RichUpdatesItem from 'src/components/RichUpdates/RichUpdatesItem';
import { UpdateResource } from 'src/helpers/api/updates';
import { canPostUpdates } from 'src/helpers/page';
import { PageState } from 'src/redux/modules/page';

interface Props {
  activatedAt?: string;
  id: string;
  updatesPageNo: number;
  updates: UpdateResource[];
  loadUpdates: (
    options: { pageId: string; pageNo: number; pageSize: number }
  ) => void;
  totalCountUpdates: number;
  profileImage?: string;
  ownerName: string;
  report?: string;
  status: string;
  finishedAt?: string;
  isLoadingUpdates: boolean;
  isOwner: boolean;
  deleteRichUpdate: (updateId: number) => void;
  raised: number;
  isClosed: boolean;
  watchingVideoToggle: () => void;
  isActive: boolean;
  completeImage?: string;
  page: PageState;
  scrollTo: boolean;
  scrolledToUpdate: () => void;
}

class Updates extends React.Component<Props> {
  handleLoadUpdates = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const { id, updatesPageNo, loadUpdates } = this.props;
    const pageSize = 3;
    const loadDetails = {
      pageId: id,
      pageNo: updatesPageNo + 1,
      pageSize,
    };

    loadUpdates(loadDetails);
  };

  allUpdatesShowing() {
    const { updates, totalCountUpdates, isLoadingUpdates } = this.props;
    const hasMore = updates.length < totalCountUpdates;

    return (
      hasMore && (
        <div styleName="action-box">
          <LoadingButton
            loading={isLoadingUpdates}
            onClick={this.handleLoadUpdates}
            className={['jg-btn--ghost', styles.action]}
            color="purple"
            value="Show more"
          />
        </div>
      )
    );
  }

  hasUpdates = (updates: UpdateResource[]) => !!(updates && updates.length > 0);

  renderReport = () => {
    const {
      ownerName,
      profileImage,
      finishedAt,
      completeImage,
      report,
      isOwner,
      deleteRichUpdate,
      watchingVideoToggle,
    } = this.props;

    const reportDate = distanceInWordsToNow(finishedAt!);

    return (
      <li id="0" className={styles.item}>
        <RichUpdatesTimelineItem
          ownerProfileImageUrl={profileImage}
          ownerName={ownerName}
          date={reportDate}
        >
          <RichUpdatesItem
            id={0}
            ownerProfileImageUrl={profileImage}
            date={reportDate}
            media={[{ type: 1, path: completeImage || '' }]}
            message={report || ''}
            ownerName={ownerName}
            isOwner={isOwner}
            isLowReachAlertVisible={false}
            deleteRichUpdate={deleteRichUpdate}
            watchingVideoToggle={watchingVideoToggle}
          />
        </RichUpdatesTimelineItem>
      </li>
    );
  };

  renderLatestTimelineItem() {
    const {
      page,
      profileImage,
      isActive,
      isClosed,
      updates,
      ownerName,
    } = this.props;

    if (page.isOwnerRequested) {
      if (canPostUpdates(page)) {
        return (
          <RichUpdatesTimelineItem
            ownerProfileImageUrl={profileImage}
            ownerName={ownerName}
          >
            <RichUpdatesCreator
              pageName={page.name}
              avatarImageUrl={profileImage}
            />
          </RichUpdatesTimelineItem>
        );
      } else if (!isActive && !isClosed) {
        return (
          <RichUpdatesTimelineItem
            ownerProfileImageUrl={profileImage}
            ownerName={ownerName}
          >
            <RichUpdatesPlaceholder
              title="No updates yet"
              subtitle="You will be able to write updates here once your page is live"
            />
          </RichUpdatesTimelineItem>
        );
      }
    } else {
      if (!this.hasUpdates(updates)) {
        return (
          <RichUpdatesTimelineItem
            ownerProfileImageUrl={profileImage}
            ownerName={ownerName}
          >
            <RichUpdatesPlaceholder title="Updates appear here" />
          </RichUpdatesTimelineItem>
        );
      }
    }

    return null;
  }

  renderUpdatesList = (updates: UpdateResource[]) => {
    const {
      ownerName,
      isOwner,
      deleteRichUpdate,
      profileImage,
      watchingVideoToggle,
    } = this.props;

    return updates.map((update, index) => {
      const updateDate = distanceInWordsToNow(update.createdAt);

      return (
        <li key={index} id={update.id.toString()} className={styles.item}>
          <RichUpdatesTimelineItem
            ownerProfileImageUrl={profileImage}
            ownerName={ownerName}
            date={updateDate}
          >
            <RichUpdatesItem
              date={updateDate}
              deleteRichUpdate={deleteRichUpdate}
              id={update.id}
              isLowReachAlertVisible={
                update.sharedToFacebook !== undefined &&
                !update.sharedToFacebook &&
                index === 0
              }
              isOwner={isOwner}
              message={update.message}
              media={update.media}
              ownerName={ownerName}
              ownerProfileImageUrl={profileImage}
              type={update.type}
              watchingVideoToggle={watchingVideoToggle}
            />
          </RichUpdatesTimelineItem>
        </li>
      );
    });
  };

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.scrollTo) {
      scroller.scrollTo('updates', {
        duration: 800,
        smooth: true,
      });
      nextProps.scrolledToUpdate();
    }
  }

  render() {
    const {
      activatedAt,
      status,
      ownerName,
      updates,
      totalCountUpdates,
    } = this.props;
    // TODO: Refactor once final thank you is part of the updates list
    const hasPostedFinalUpdate = status === 'ReportGenerated';
    const updatesCount = totalCountUpdates + (hasPostedFinalUpdate ? 1 : 0);

    return (
      <Element name="updates">
        <div styleName="container" className="jg-space-phms@md">
          <div className="jg-display-flex jg-space-pvms jg-space-pbn@md jg-space-mbmd@md">
            <h2 className="jg-h2 jg-flex-g1">Updates</h2>
            <h2 className="jg-h2">{updatesCount}</h2>
          </div>

          {this.renderLatestTimelineItem()}

          <div styleName="updates-list">
            <div
              className="col-sm-3 col-lg-2"
              styleName="updates-timeline-container"
            >
              <span styleName="updates-timeline-marker" />
            </div>

            <ul styleName="updates-list">
              {hasPostedFinalUpdate && this.renderReport()}
              {this.hasUpdates(updates) && this.renderUpdatesList(updates)}
            </ul>

            <div styleName="updates-first-item-container">
              <div styleName="updates-first-item-marker-container">
                <span styleName="updates-first-item-marker" />
                <span className="jg-text--xsmall-light" styleName="date">
                  {distanceInWordsToNow(activatedAt!)}
                </span>
              </div>
              <div styleName="updates-first-item-description">
                <p>
                  <span>{ownerName}</span> started crowdfunding
                </p>
              </div>
            </div>
          </div>

          {this.allUpdatesShowing()}
        </div>
      </Element>
    );
  }
}

export default CSSModules(Updates, styles);
