import {
  ActivateFailureAction,
  ActivateRequestAction,
  ActivateSuccessAction,
} from 'src/redux/modules/page/activatePage';
import {
  ChangeModeAction,
  ResetScrollAction,
  ScrollToAction,
  ScrollToLocation,
} from 'src/redux/modules/page/navigation';
import {
  ClearRecaptchaAction,
  CloseContactOwnerAction,
  CloseFeedbackThankYouAction,
  ContactOwnerFailureAction,
  ContactOwnerRequestAction,
  ContactOwnerSuccessAction,
  OpenContactOwnerAction,
  OpenFeedbackThankYouAction,
  SetRecaptchaAction,
} from 'src/redux/modules/page/contactOwner';
import {
  CloseDeletePageModalAction,
  CloseDeletePageSuccessAlertAction,
  DeletePageFailureAction,
  DeletePageRequestAction,
  DeletePageSuccessAction,
  OpenDeletePageModalAction,
} from 'src/redux/modules/page/deletePage';
import {
  CloseEncourageShareAction,
  OpenEncourageShareAction,
} from 'src/redux/modules/page/encourageShare';
import {
  CloseShareMenuAction,
  OpenShareMenuAction,
} from 'src/redux/modules/page/shareMenu';
import {
  FundsReleaseMode,
  PageImageResource,
  PageStatus,
  StoryNode,
  VerificationStatus,
} from 'src/helpers/api/pages';
import {
  PageFailureAction,
  PageRequestAction,
  PageSuccessAction,
} from 'src/redux/modules/page/loadPage';
import {
  RemoveErrorsAfterModalClosingAction,
  SubmitIdAndBankStatementAction,
  SubmitIdAndBankStatementFailureAction,
  SubmitIdAndBankStatementSuccessAction,
} from './manualVerification';
import {
  ShareBySmsAction,
  ShareOnEmailAction,
  ShareOnFacebookAction,
  ShareOnFacebookClosedAction,
  ShareOnMessangerAction,
  ShareOnTwitterAction,
  ShareOnWhatsAppAction,
  SharePageLinkAction,
  ShareQrCodeAction,
} from 'src/redux/modules/page/share';
import {
  UpdateFailureAction,
  UpdateRequestAction,
  UpdateSuccessAction,
} from 'src/redux/modules/page/updatePage';

import { BankActions } from '../bank';
import { ProfileImageSuccessAction } from 'src/redux/modules/page/profilePicture';
import { UpdateVerificatonStatusAction } from 'src/redux/modules/page/verification';
import { currencyToCountryCodeMap } from 'src/helpers/currencyMap';

// HACK: this should go to create module, which is currently using JS.
interface CreateStartAction {
  type: 'CREATE_START';
}

// HACK: this should go to pagelink module, which is currently using JS.
export interface LinkSuccessAction {
  type: 'LINK_SUCCESS';
  payload: { id: string; projectId: string; name: string };
}

export type PageState = {
  id: string;
  name: string;

  categoryId?: number | null;
  initialImage?: PageImageResource;

  status: PageStatus;

  amountPledged?: number;
  amountRaised: number;

  createdAt: string;
  activatedAt?: string;
  finishedAt?: string;

  latitude: number;
  longitude: number;
  locationText?: string;

  isOwnerRequested: boolean;
  ownerUserId: string;
  ownerBio?: string;
  ownerEmailAddress: string;
  ownerName: string;

  pitchBecause: string;
  pitchFor: string;
  targetAmount: number;
  targetCurrency: Currency;

  care?: string;
  deadline?: string;
  money?: string;
  problem?: string;

  completeImage?: string;
  report?: string;

  thankYou?: string;

  sunset: boolean;

  facebookShareImage?: string;
  socialShareUrl: string;
  socialShareImage?: string;
  facebookCommentsUrl: string;

  verificationStatus: VerificationStatus;
  donationCauseId: string;

  fundsReleaseMode: FundsReleaseMode;
  isReviewed: boolean;

  ownerProfileImageUrl?: string;
  isUpdating: boolean;
  scrollTo?: ScrollToLocation;
  isDeletePageModalOpen: boolean;
  hasPageDeletionFailed: boolean;
  richStory?: StoryNode[];
  story?: string;
  mode: number;
  isCreateWizardOpen: boolean;
  isRequesting: boolean;
  isActivating: boolean;
  failType?: string;
  error?: string | boolean;
  isEncourageShareOpen: boolean;
  renderAfter: number;
  isShareMenuOpen: boolean;
  isContactingOwnerWindowOpen: boolean;
  isFeedbackOwnerWindowOpen: boolean;
  isFeedbackThankYouWindowOpen: boolean;
  contactOwnerPageViewSection: string;
  contactOwnerPageSection: string;
  contactOwnerSubjectPlaceHolder?: string;
  contactOwnerMessagePlaceholder?: string;

  recaptcha?: any;
  details?: any; // SHARE_ON_FB
  hasPageBeenDeleted: boolean;
  stripeVirtualAccountGuid?: string;

  country: Country;

  tipScheme: TipScheme;

  charityId: number | null;

  firstDonationDate?: string;
  refundedDate?: string;

  recurringDonationsPitch?: string;

  rateLimitReached: boolean;
  totalVerificationsFailed?: number;
  pageOwnerOptimizationFeatureToggle: boolean;
};

export type TipScheme = 'TipJarExperiment' | 'TipJar2.2' | null | undefined;

type Actions =
  | PageRequestAction
  | PageSuccessAction
  | PageFailureAction
  | ActivateRequestAction
  | ActivateSuccessAction
  | ActivateFailureAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | ContactOwnerRequestAction
  | ContactOwnerSuccessAction
  | ContactOwnerFailureAction
  | OpenContactOwnerAction
  | CloseContactOwnerAction
  | OpenFeedbackThankYouAction
  | CloseFeedbackThankYouAction
  | OpenShareMenuAction
  | CloseShareMenuAction
  | DeletePageRequestAction
  | DeletePageSuccessAction
  | DeletePageFailureAction
  | CloseDeletePageSuccessAlertAction
  | ShareOnFacebookAction
  | ShareOnFacebookClosedAction
  | ShareOnTwitterAction
  | ShareOnEmailAction
  | ShareOnWhatsAppAction
  | ShareBySmsAction
  | ShareQrCodeAction
  | ShareOnMessangerAction
  | SharePageLinkAction
  | OpenDeletePageModalAction
  | CloseDeletePageModalAction
  | OpenEncourageShareAction
  | CloseEncourageShareAction
  | SetRecaptchaAction
  | ClearRecaptchaAction
  | ChangeModeAction
  | ScrollToAction
  | ResetScrollAction
  | ProfileImageSuccessAction
  | UpdateVerificatonStatusAction
  | CreateStartAction
  | LinkSuccessAction
  | BankActions
  | SubmitIdAndBankStatementAction
  | SubmitIdAndBankStatementFailureAction
  | SubmitIdAndBankStatementSuccessAction
  | RemoveErrorsAfterModalClosingAction;

const initialState: PageState = {
  id: '',
  name: '',
  status: 'Lead',
  createdAt: '',
  latitude: 0,
  longitude: 0,
  isOwnerRequested: false,
  ownerUserId: '',
  ownerEmailAddress: '',
  ownerName: '',
  pitchBecause: '',
  pitchFor: '',
  targetAmount: 0,
  sunset: false,
  socialShareUrl: '',
  facebookCommentsUrl: '',
  verificationStatus: 'UnverifiedPayment',
  donationCauseId: '',
  fundsReleaseMode: 'OnDemand',
  isReviewed: false,
  isUpdating: false,
  isDeletePageModalOpen: false,
  hasPageDeletionFailed: false,
  isCreateWizardOpen: false,
  isRequesting: false,
  isActivating: false,
  isShareMenuOpen: false,
  isContactingOwnerWindowOpen: false,
  isFeedbackOwnerWindowOpen: false,
  isFeedbackThankYouWindowOpen: false,
  contactOwnerPageViewSection: '',
  contactOwnerPageSection: '',
  hasPageBeenDeleted: false,
  mode: 0,
  amountRaised: 0,
  amountPledged: 0,
  targetCurrency: 'GBP',
  isEncourageShareOpen: false,
  renderAfter: 28000,
  country: currencyToCountryCodeMap.GBP,
  richStory: [],
  charityId: null,
  tipScheme: null,
  rateLimitReached: false,
  pageOwnerOptimizationFeatureToggle: false,
};

export default function reducer(
  state: PageState = initialState,
  action: Actions
): PageState {
  switch (action.type) {
    case 'CREATE_START':
      return initialState;
    case 'PAGE_REQUEST':
      return {
        ...state,
        isRequesting: true,
        failType: undefined,
      };
    case 'PAGE_SUCCESS': {
      const page = { ...action.payload, story: action.payload.problem };

      return {
        ...state,
        ...page,
        country: currencyToCountryCodeMap[page.targetCurrency!],
        isRequesting: false,
      };
    }
    case 'PAGE_FAILURE':
      return {
        ...state,
        isRequesting: false,
        failType: action.payload.type || '500',
        categoryId: action.payload.categoryId,
      };

    case 'ACTIVATE_REQUEST':
      return {
        ...state,
        isActivating: true,
      };
    case 'ACTIVATE_SUCCESS':
      return {
        ...state,
        mode: 0,
        status: 'Active',
        isActivating: false,
      };
    case 'ACTIVATE_FAILURE':
      return {
        ...state,
        isActivating: false,
        error: action.error,
      };

    case 'CHANGE_MODE':
      return {
        ...state,
        mode: action.mode,
      };

    case 'SCROLL_TO':
      return {
        ...state,
        scrollTo: action.name,
      };

    case 'RESET_SCROLL':
      return {
        ...state,
        scrollTo: undefined,
      };

    case 'UPDATE_REQUEST':
      return {
        ...state,
        isUpdating: true,
      };

    case 'UPDATE_SUCCESS':
      return {
        ...state,
        ...action.payload,
        mode: 0,
        isUpdating: false,
        socialShareImage:
          action.payload.socialShareImage &&
          action.payload.socialShareImage.name,
      };

    case 'UPDATE_FAILURE':
      return {
        ...state,
        isUpdating: false,
        error: 'Please write your story',
      };

    case 'OPEN_SHARE_MENU':
      return {
        ...state,
        isShareMenuOpen: true,
      };

    case 'CLOSE_SHARE_MENU':
      return {
        ...state,
        isShareMenuOpen: false,
      };

    case 'CONTACT_OWNER_REQUEST':
      return {
        ...state,
      };

    case 'CONTACT_OWNER_SUCCESS':
      return {
        ...state,
        isContactingOwnerWindowOpen: false,
        isFeedbackOwnerWindowOpen: false,
      };

    case 'CONTACT_OWNER_FAILURE':
      return {
        ...state,
      };

    case 'OPEN_CONTACT_OWNER':
      return {
        ...state,
        isContactingOwnerWindowOpen: true,
        isFeedbackOwnerWindowOpen: false,
        contactOwnerPageViewSection: action.payload.pageViewSection,
        contactOwnerPageSection: action.payload.pageSection,
        contactOwnerSubjectPlaceHolder: action.payload.subjectPlaceHolder,
        contactOwnerMessagePlaceholder: action.payload.messagePlaceholder,
      };

    case 'OPEN_FEEDBACK_OWNER':
      return {
        ...state,
        isContactingOwnerWindowOpen: false,
        isFeedbackOwnerWindowOpen: true,
        contactOwnerPageViewSection: action.payload.pageViewSection,
        contactOwnerPageSection: action.payload.pageSection,
        contactOwnerSubjectPlaceHolder: action.payload.subjectPlaceHolder,
        contactOwnerMessagePlaceholder: action.payload.messagePlaceholder,
      };

    case 'CLOSE_CONTACT_OWNER':
      return {
        ...state,
        isContactingOwnerWindowOpen: false,
        isFeedbackOwnerWindowOpen: false,
      };

    case 'OPEN_FEEDBACK_THANK_YOU':
      return {
        ...state,
        isFeedbackThankYouWindowOpen: true,
      };

    case 'CLOSE_FEEDBACK_THANK_YOU':
      return {
        ...state,
        isFeedbackThankYouWindowOpen: false,
      };

    case 'CLOSE_ENCOURAGE_SHARE':
      return {
        ...state,
        isEncourageShareOpen: false,
      };

    case 'OPEN_ENCOURAGE_SHARE':
      return {
        ...state,
        isEncourageShareOpen: true,
      };

    case 'SET_RECAPTCHA':
      return {
        ...state,
        recaptcha: action.response,
      };

    case 'CLEAR_RECAPTCHA':
      return {
        ...state,
        recaptcha: '',
      };

    case 'SHARE_ON_FB':
      return {
        ...state,
        details: action.payload.details,
      };

    case 'PROFILE_IMAGE_SUCCESS':
      return {
        ...state,
        ownerProfileImageUrl: action.payload,
      };

    case 'UPDATE_VERIFICATION_STATUS':
      return {
        ...state,
        verificationStatus: action.payload,
      };

    case 'OPEN_DELETE_PAGE_MODAL':
      return {
        ...state,
        isDeletePageModalOpen: true,
        hasPageDeletionFailed: false,
      };

    case 'CLOSE_DELETE_PAGE_MODAL':
      return {
        ...state,
        isDeletePageModalOpen: false,
      };

    case 'DELETE_PAGE_SUCCESS':
      return {
        ...state,
        status: 'Cancelled',
        isDeletePageModalOpen: false,
        hasPageBeenDeleted: true,
      };

    case 'DELETE_PAGE_FAILURE':
      return {
        ...state,
        isDeletePageModalOpen: false,
        hasPageDeletionFailed: true,
      };

    case 'CLOSE_DELETE_PAGE_SUCCESS_ALERT':
      return {
        ...state,
        hasPageBeenDeleted: false,
      };

    case 'LINK_SUCCESS':
      return {
        ...state,
        name: action.payload.name,
      };

    case 'BANK_FAILURE':
      return {
        ...state,
        rateLimitReached: action.payload.message === 'rateLimitReached',
      };
    case 'SUBMIT_ID_AND_BANK_STATEMENT':
      return {
        ...state,
        isRequesting: true,
      };

    case 'SUBMIT_ID_AND_BANK_STATEMENT_SUCCESS':
      return {
        ...state,
        verificationStatus: action.payload,
        isRequesting: false,
      };

    case 'SUBMIT_ID_AND_BANK_STATEMENT_FAILURE':
      return {
        ...state,
        error: action.error,
        isRequesting: false,
      };
    case 'REMOVE_ERRORS_AFTER_MODAL_CLOSING':
      return {
        ...state,
        error: false,
      };

    default:
      return state;
  }
}
