import { CROWDFUNDING_BASE_URI, LOGIN_URI_SSO } from 'config';

import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { isLoggedIn } from 'src/helpers/api/iam';
import { post } from 'src/helpers/api/common';

export interface OpenContactOwnerAction {
  type: 'OPEN_CONTACT_OWNER' | 'OPEN_FEEDBACK_OWNER';
  payload: {
    pageViewSection: string;
    pageSection: string;
    subjectPlaceHolder?: string;
    messagePlaceholder?: string;
  };
}

export interface LoginContactOwnerAction {
  type: 'LOGIN_CONTACT_OWNER';
}

export function openContactOwner(
  returnRoute: string,
  pageViewSection: string,
  pageSection: string,
  friendFeedback: boolean = false,
  subjectPlaceHolder?: string,
  messagePlaceholder?: string
): OpenContactOwnerAction | LoginContactOwnerAction {
  if (__CLIENT__ && !isLoggedIn()) {
    const returnUrl = encodeURIComponent(
      `${CROWDFUNDING_BASE_URI}/${returnRoute}`
    );
    window.location.replace(`${LOGIN_URI_SSO}?returnUrl=${returnUrl}`);
    return { type: 'LOGIN_CONTACT_OWNER' };
  }

  return {
    type: friendFeedback ? 'OPEN_FEEDBACK_OWNER' : 'OPEN_CONTACT_OWNER',
    payload: {
      pageViewSection,
      pageSection,
      subjectPlaceHolder,
      messagePlaceholder,
    },
  };
}

export interface CloseContactOwnerAction {
  type: 'CLOSE_CONTACT_OWNER';
}

export function closeContactOwner(): CloseContactOwnerAction {
  return { type: 'CLOSE_CONTACT_OWNER' };
}

export interface OpenFeedbackThankYouAction {
  type: 'OPEN_FEEDBACK_THANK_YOU';
}

export function openFeedbackThankYou(): OpenFeedbackThankYouAction {
  return { type: 'OPEN_FEEDBACK_THANK_YOU' };
}

export interface CloseFeedbackThankYouAction {
  type: 'CLOSE_FEEDBACK_THANK_YOU';
}

export function closeFeedbackThankYou(): CloseFeedbackThankYouAction {
  return { type: 'CLOSE_FEEDBACK_THANK_YOU' };
}

interface ContactOwnerData {
  id: string;
  email: string;
  message: string;
  subject: string;
  recaptcha: string;
}

export interface ContactOwnerRequestAction {
  type: 'CONTACT_OWNER_REQUEST';
}

export interface ContactOwnerSuccessAction {
  type: 'CONTACT_OWNER_SUCCESS';
}

export interface ContactOwnerFailureAction {
  type: 'CONTACT_OWNER_FAILURE';
}

export function contactOwner({
  id,
  email,
  message,
  subject,
  recaptcha,
}: ContactOwnerData) {
  return async (dispatch: Dispatch<AppState>) => {
    dispatch<ContactOwnerRequestAction>({ type: 'CONTACT_OWNER_REQUEST' });

    message = `Email: ${email || 'N/A'}<br/><br/>Message:<br/><br/>${message}`;

    const payload = {
      projectId: id,
      subject,
      message,
      reCaptchaResponse: recaptcha,
    };

    try {
      const response = await post(`/projects/${id}/emails`, payload, {
        withCredentials: true,
      });

      if (!response.ok) {
        dispatch<ContactOwnerFailureAction>({ type: 'CONTACT_OWNER_FAILURE' });
      } else {
        dispatch<ContactOwnerSuccessAction>({ type: 'CONTACT_OWNER_SUCCESS' });
      }
    } catch (err) {
      dispatch<ContactOwnerFailureAction>({ type: 'CONTACT_OWNER_FAILURE' });

      throw err;
    }
  };
}

export interface SetRecaptchaAction {
  type: 'SET_RECAPTCHA';
  response: string;
}

export function setRecaptcha(response: string): SetRecaptchaAction {
  return { type: 'SET_RECAPTCHA', response };
}

export interface ClearRecaptchaAction {
  type: 'CLEAR_RECAPTCHA';
}

export function clearRecaptcha(): ClearRecaptchaAction {
  return { type: 'CLEAR_RECAPTCHA' };
}
