import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CROWDFUNDING_BASE_URI } from 'config';
import RecurringDonationsPitch from './RecurringDonationsPitch';

interface Props {
  ownerAvatarImage?: string;
  ownerName: string;
  pageName: string;
  pageSection: string;
  recurringDonationsPitch?: string;
}

const RecurringDonationsPitchSupporter: React.StatelessComponent<Props> = ({
  ownerAvatarImage,
  ownerName,
  pageName,
  pageSection,
  recurringDonationsPitch,
}) => (
  <>
    <h4 className="jg-space-mbmd jg-text--center">
      <FormattedMessage
        id="RecurringDonationsPitchSupporter.youCanDonateMonthly"
        defaultMessage="You can donate monthly to this page!"
      />
    </h4>

    {recurringDonationsPitch ? (
      <RecurringDonationsPitch
        recurringDonationsPitch={recurringDonationsPitch}
        ownerAvatarImage={ownerAvatarImage}
        ownerName={ownerName}
      />
    ) : (
      <p className="jg-text--center">
        <FormattedMessage
          id="RecurringDonationsPitchSupporter.whyMonthlyDonations"
          defaultMessage="Crowdfunding pages aren't always one-off projects. Some run for several months and need on-going funding to be successful. By giving monthly, you will be helping to support this page every month until it closes. You can of course cancel your monthly donation at any time."
        />
      </p>
    )}

    <div className="jg-space-mtmd jg-text--center">
      <a
        data-marker="recurring-donations-awareness-donate-monthly-button"
        href={`${CROWDFUNDING_BASE_URI}/${pageName}/donate?pageSection=${pageSection}&paymentType=monthly&eventValue=monthly`}
        className="jg-btn jg-btn--brand jg-width--300"
      >
        <FormattedMessage
          id="RecurringDonationsPitchSupporter.donateMonthly"
          defaultMessage="Donate monthly"
        />
      </a>
    </div>
  </>
);

export default RecurringDonationsPitchSupporter;
