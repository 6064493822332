import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import {
  getAccountStatus,
  StripeRequiredField,
  updateStripeAccount,
  UpdateAccountData,
  uploadStripeVerificationDocument,
  AccountDisabledReason,
  StripeRequiredFields,
  StripeVirtualAccountStatus,
  AddBankAccountData,
  addStripeBankAccount,
  PayoutScheduleInterval,
} from 'src/helpers/api/stripeVirtualAccount';
import { CropData, cropImage } from 'src/helpers/image';
import { stripeVirtualAccountRequiredFields } from 'src/helpers/stripe';
import { loadPaymentDetails } from 'src/redux/modules/paymentDetails';

/*---------------------------------------
  Get Stripe account status
---------------------------------------*/

export interface StripeVirtualAccountRequestAction {
  type: 'STRIPE_VIRTUAL_ACCOUNT_STATUS_REQUEST';
}

export interface StripeVirtualAccountSuccessAction {
  type: 'STRIPE_VIRTUAL_ACCOUNT_STATUS_SUCCESS';
  payload: {
    requiredFields: StripeRequiredFields;
    submittedAllRequiredFields: boolean;
    legalEntityVerificationStatus: StripeVirtualAccountStatus;
    accountDisabledReason?: AccountDisabledReason;
    payoutsEnabled: boolean;
    payoutScheduleInterval: PayoutScheduleInterval;
  };
}

export interface StripeVirtualAccountFailureAction {
  type: 'STRIPE_VIRTUAL_ACCOUNT_STATUS_FAILURE';
  payload: string;
}

export type StripeVirtualAccountAction =
  | StripeVirtualAccountRequestAction
  | StripeVirtualAccountSuccessAction
  | StripeVirtualAccountFailureAction
  | SubmitFieldAction
  | UpdateStripeAccountSuccessAction
  | SubmitStripeVerificationDocumentSuccessAction
  | SubmitStripeBankAccountInformationRequestAction
  | SubmitStripeBankAccountInformationSuccessAction
  | SubmitStripeBankAccountInformationFailureAction
  | ClearStripeBankAccountSubmissionError;

export const getVirtualAccountStatus = () => async (
  dispatch: Dispatch<AppState>,
  getState: () => AppState
) => {
  try {
    const { id } = getState().page;
    dispatch({
      type: 'STRIPE_VIRTUAL_ACCOUNT_STATUS_REQUEST',
    });

    const accountDetails = await getAccountStatus(id);

    dispatch<StripeVirtualAccountSuccessAction>({
      type: 'STRIPE_VIRTUAL_ACCOUNT_STATUS_SUCCESS',
      payload: {
        payoutsEnabled: accountDetails.payoutsEnabled,
        requiredFields: accountDetails.requiredFields.filter(requiredField =>
          stripeVirtualAccountRequiredFields.includes(requiredField)
        ),
        submittedAllRequiredFields: accountDetails.requiredFields.length === 0,
        legalEntityVerificationStatus:
          accountDetails.legalEntityVerificationStatus,
        accountDisabledReason: accountDetails.accountDisabledReason,
        payoutScheduleInterval: accountDetails.payoutScheduleInterval,
      },
    });
  } catch (e) {
    dispatch({
      type: 'STRIPE_VIRTUAL_ACCOUNT_STATUS_FAILURE',
    });
  }
};

/*---------------------------------------
  Track what Stripe further requires to
  authenticate a user
---------------------------------------*/

export interface SubmitFieldAction {
  type: 'SUBMIT_FIELD_ACTION';
  payload: string;
}

export const submitField = (
  submittedField: StripeRequiredField
): SubmitFieldAction => ({
  type: 'SUBMIT_FIELD_ACTION',
  payload: submittedField,
});

/*---------------------------------------
  Update Stripe account details
---------------------------------------*/

export interface UpdateStripeAccountRequestAction {
  type: 'UPDATE_STRIPE_ACCOUNT_REQUEST';
  payload: UpdateAccountData;
}

export interface UpdateStripeAccountSuccessAction {
  type: 'UPDATE_STRIPE_ACCOUNT_SUCCESS';
  payload: {
    payoutScheduleInterval: PayoutScheduleInterval;
  };
}

export interface UpdateStripeAccountFailureAction {
  type: 'UPDATE_STRIPE_ACCOUNT_FAILURE';
  error: true;
  payload: Error;
}

export function updateStripeAccountDetails(payload: UpdateAccountData) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<UpdateStripeAccountRequestAction>({
      type: 'UPDATE_STRIPE_ACCOUNT_REQUEST',
      payload,
    });

    try {
      const { id } = getState().page;

      const result = await updateStripeAccount(id, payload);

      dispatch<UpdateStripeAccountSuccessAction>({
        type: 'UPDATE_STRIPE_ACCOUNT_SUCCESS',
        payload: {
          payoutScheduleInterval: result.payoutScheduleInterval,
        },
      });
    } catch (error) {
      dispatch<UpdateStripeAccountFailureAction>({
        type: 'UPDATE_STRIPE_ACCOUNT_FAILURE',
        payload: error,
        error: true,
      });
    }
  };
}

/*---------------------------------------
  Upload Stripe id verification document
---------------------------------------*/

export interface SubmitStripeVerificationDocumentRequestAction {
  type: 'SUBMIT_STRIPE_VERIFICATION_DOCUMENT_REQUEST';
}

export interface SubmitStripeVerificationDocumentSuccessAction {
  type: 'SUBMIT_STRIPE_VERIFICATION_DOCUMENT_SUCCESS';
}

export interface SubmitStripeVerificationDocumentFailureAction {
  type: 'SUBMIT_STRIPE_VERIFICATION_DOCUMENT_FAILURE';
  payload: Error;
  error: true;
}

export function submitStripeVerificationDocument(
  image: string,
  cropData: CropData
) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<SubmitStripeVerificationDocumentRequestAction>({
      type: 'SUBMIT_STRIPE_VERIFICATION_DOCUMENT_REQUEST',
    });

    try {
      const croppedImage = await cropImage({ image, ...cropData });
      const { id } = getState().page;

      await uploadStripeVerificationDocument(id, croppedImage);

      dispatch<SubmitStripeVerificationDocumentSuccessAction>({
        type: 'SUBMIT_STRIPE_VERIFICATION_DOCUMENT_SUCCESS',
      });
    } catch (error) {
      dispatch<SubmitStripeVerificationDocumentFailureAction>({
        type: 'SUBMIT_STRIPE_VERIFICATION_DOCUMENT_FAILURE',
        payload: error,
        error: true,
      });
    }
  };
}

/*---------------------------------------
  Add bank account to Stripe VA
---------------------------------------*/

export interface SubmitStripeBankAccountInformationRequestAction {
  type: 'SUBMIT_STRIPE_BANK_ACCOUNT_INFORMATION_REQUEST';
}

export interface SubmitStripeBankAccountInformationSuccessAction {
  type: 'SUBMIT_STRIPE_BANK_ACCOUNT_INFORMATION_SUCCESS';
}

export interface SubmitStripeBankAccountInformationFailureAction {
  type: 'SUBMIT_STRIPE_BANK_ACCOUNT_INFORMATION_FAILURE';
  error: true;
  payload: Error;
}

export function submitStripeBankAccountDetails(payload: AddBankAccountData) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<SubmitStripeBankAccountInformationRequestAction>({
      type: 'SUBMIT_STRIPE_BANK_ACCOUNT_INFORMATION_REQUEST',
    });

    try {
      const { id } = getState().page;

      await addStripeBankAccount(id, payload);

      dispatch<SubmitStripeBankAccountInformationSuccessAction>({
        type: 'SUBMIT_STRIPE_BANK_ACCOUNT_INFORMATION_SUCCESS',
      });
      dispatch<any>(loadPaymentDetails());
    } catch (error) {
      dispatch<SubmitStripeBankAccountInformationFailureAction>({
        type: 'SUBMIT_STRIPE_BANK_ACCOUNT_INFORMATION_FAILURE',
        payload: error,
        error: true,
      });
    }
  };
}

/*---------------------------------------
  Reset error message when user closes
  bank account confirmation modal
---------------------------------------*/

export interface ClearStripeBankAccountSubmissionError {
  type: 'CLEAR_STRIPE_BANK_ACCOUNT_SUBMISSION_ERROR';
}

export const clearSubmissionError = () => {
  return (dispatch: Dispatch<AppState>) => {
    dispatch<ClearStripeBankAccountSubmissionError>({
      type: 'CLEAR_STRIPE_BANK_ACCOUNT_SUBMISSION_ERROR',
    });
  };
};
