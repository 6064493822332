import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { Rule } from 'src/helpers/rulesEngine';
import addDays from 'date-fns/add_days';
import { countdown } from 'src/helpers/date';
import { isAccountConnected } from 'src/helpers/payment';
import countdownToRefundingEngine, {
  CountdownRulesEngineOptions,
} from 'src/components/Prompt/CountdownToRefundingFlow/countdownToRefundingEngine';
import { AppState } from 'src/redux/modules';
import { isAfter } from 'date-fns';
import { ACQUISITION_DATE } from 'config';

const CountdownToRefunding: Rule<JSX.Element, PromptRulesEngineOptions> = (
  state,
  options
) => {
  const { firstDonationDate, activatedAt } = state.page;
  const appState = state as AppState;
  const accountConnected = isAccountConnected(state.page, state.paymentDetails);

  if (
    activatedAt &&
    firstDonationDate &&
    !accountConnected &&
    isAfter(activatedAt, ACQUISITION_DATE)
  ) {
    const frozenDate = addDays(firstDonationDate, 30);
    const falseSuspensionDate = addDays(firstDonationDate, 45);
    const refundDate = addDays(firstDonationDate, 60);
    const timeRemainingFrozen = countdown(frozenDate);
    const timeRemainingFalseSuspension = countdown(falseSuspensionDate);

    const mergedOptions = {
      pageName: state.page.name,
      frozenDate,
      falseSuspensionDate,
      refundDate,
      timeRemainingFrozen,
      timeRemainingFalseSuspension,
      ...options,
    };

    try {
      const prompt = countdownToRefundingEngine(
        appState,
        mergedOptions as CountdownRulesEngineOptions
      );

      if (prompt) {
        return prompt;
      }

      return { matched: false };
    } catch (e) {
      return { matched: false };
    }
  }

  return { matched: false };
};

export default CountdownToRefunding;
