if (__SERVER__) {
  const config = require('../../config/server.config');

  let statsd;

  if (config.stats) {
    const Client = require('statsd-client');

    statsd = new Client({
      host: config.statsHost,
      port: config.statsPort,
      prefix: 'jg.crowdfunding.website',
    });
  }

  const { Histogram } = require('prom-client');
  const timingHistogram = new Histogram({
    name: 'app_timing',
    help: `App timing in ms`,
    buckets: [1, 5, 15, 50, 100, 200, 300, 400, 500, 1000, 2500, 5000, 10000],
    labelNames: ['timing_category', 'timing_name'],
  });

  module.exports = {
    timing: (name, value) => {
      if (statsd) {
        statsd.timing(name, value);
      }

      const [timingCategory, timingName] = name.split('.');

      timingHistogram.observe(
        { timing_category: timingCategory, timing_name: timingName },
        value
      );
    },
  };
} else if (__CLIENT__) {
  const thrower = () => {
    throw new Error('Not supported on the client.');
  };

  module.exports = generateCompatibleObject(thrower);
} else {
  // for tests
  module.exports = generateCompatibleObject(() => {});
}

function generateCompatibleObject(method) {
  return {
    timing: method,
  };
}
