import React from 'react';
import format from 'date-fns/format';
import { SupporterAvatar } from '../Avatar';
import styles from './Supporter.scss';
import FormattedCurrency from 'src/components/FormattedCurrency';
import { FormattedMessage } from 'react-intl';
import Svg from '@justgiving/svg';
import comment from '@justgiving/icons/lib/comment.svg';
import tick from '@justgiving/icons/lib/tick.svg';
import ClickableElement from 'src/components/ClickableElement';
import ThankDonorModal from 'src/components/ThankingDonors/ThankDonorModal';
import { SupporterResource } from 'src/helpers/api/activities';
import config from 'config';

interface Props {
  isOwner: boolean;
  pageId: string;
  profileImage?: string | undefined;
  supporter: SupporterResource;
  targetCurrency: Currency;
  key?: number;
  thankSupporter: (supporterId: number, thankYouMessage: string) => void;
}

interface State {
  isModalOpen: boolean;
}

class Supporter extends React.Component<Props, State> {
  state = {
    isModalOpen: false,
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      isOwner,
      pageId,
      profileImage,
      supporter,
      targetCurrency,
      thankSupporter,
    } = this.props;

    const showDonorContent =
      config.SUPPRESS_DONOR_GENERATED_CONTENT.indexOf(pageId) < 0;

    return (
      <>
        {this.state.isModalOpen && (
          <ThankDonorModal
            closeModal={this.closeModal}
            pageId={pageId}
            profileImage={profileImage}
            supporter={supporter}
            targetCurrency={targetCurrency}
            thankSupporter={thankSupporter}
          />
        )}
        <li className={styles.donation}>
          <SupporterAvatar
            src={showDonorContent && supporter.imageProfile}
            alt={showDonorContent && supporter.name}
            size="medium"
          />
          <div className={styles.information}>
            <p className={styles.supporter}>
              {showDonorContent && supporter.name}
            </p>
            <p className={styles.date}>
              {supporter.createdAt &&
                format(supporter.createdAt, 'MMM D, YYYY')}
            </p>
            <p className={styles.message}>
              {showDonorContent && supporter.message}
            </p>
            <div className="jg-space-mtsm">
              <p className="jg-text--brand jg-space-mbn">
                {supporter.donationAmount && (
                  <FormattedCurrency
                    value={supporter.donationAmount}
                    currency={
                      supporter.donationCurrency || targetCurrency || 'GBP'
                    }
                  />
                )}
              </p>

              {isOwner && (
                <>
                  {supporter.thankYouMessage ? (
                    <div className="jg-display-flex jg-flex-aic jg-space-mtsm">
                      <Svg
                        markup={tick}
                        className="jg-icon jg-fill--nobel jg-space-mrxs"
                      />
                      <p className="jg-text--light jg-space-mbn">
                        <FormattedMessage
                          id="Supporter.thanked"
                          defaultMessage="Thanked"
                        />
                      </p>
                    </div>
                  ) : (
                    <ClickableElement
                      className="jg-display-flex jg-flex-aic jg-space-mtsm"
                      onClick={this.openModal}
                      analyticsOptions={{
                        event: 'link click',
                        eventValue: 'say thanks',
                        pageSection: 'admin > page view > supporters',
                        subtype: 'button',
                      }}
                    >
                      <Svg
                        markup={comment}
                        className="jg-icon jg-fill--brand-secondary jg-space-mrxs"
                      />
                      <p className="jg-text--link jg-space-mbn">
                        <FormattedMessage
                          id="Supporter.sayThanks"
                          defaultMessage="Say thanks"
                        />
                      </p>
                    </ClickableElement>
                  )}
                </>
              )}
            </div>
          </div>
        </li>
      </>
    );
  }
}

export default Supporter;
