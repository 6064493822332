import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openSharePrompts } from 'src/redux/modules/sharePrompts';
import { PageOwnerNavbar } from 'src/components/PageOwner/PageOwnerNavbar';

interface DispatchProps {
  openSharePrompts: () => void;
}

type OwnProps = { pageSection: string };

function mapDispatchToProps(dispatch: any, ownProps: OwnProps): DispatchProps {
  const { pageSection } = ownProps;
  return bindActionCreators(
    {
      openSharePrompts: () => openSharePrompts(pageSection),
    },
    dispatch
  );
}

export default connect(
  undefined,
  mapDispatchToProps
)(PageOwnerNavbar);
