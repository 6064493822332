import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './LaunchYourPage.scss';

const LaunchYourPage = () => (
  <div styleName="launch-your-page-container">
    <div styleName="launch-your-page-text">
      <h2 styleName="launch-your-page-header">No supporters yet</h2>
      <p styleName="launch-your-page-paragraph">
        Launch your page to start receiving donations
      </p>
    </div>
  </div>
);

export default CSSModules(LaunchYourPage, styles);
