export type ClickSubtype =
  | 'button'
  | 'inactive button'
  | 'tab'
  | 'hyperlink'
  | 'dismiss'
  | string;

export type ClickEventType = 'click' | 'link click';

export interface ClickAnalyticsOptions {
  event: ClickEventType;
  eventValue: string;
  pageSection: string;
  subtype: ClickSubtype;
  cardId?: number;
  actorSource?: string;
}

export interface AnalyticsMeta {
  event?: string;
  subtype?: string;
  event_value?: string;
  page_section?: string;
  activity_type?: string;
  social_type?: string;
  social_utm_term?: string;
  content_type?: string;
  omitKinesis?: boolean;
  actor_id?: string;
  actor_source?: string;
  location?: string;
  card_id?: number;
}

export type AnalyticsClick = (options: ClickAnalyticsOptions) => void;

export interface AnalyticsClickAction {
  type: 'ANALYTICS_CLICK';
  meta: {
    analytics: {
      event: string;
      subtype: string;
      event_value: string;
      page_section: string;
      content_type: 'crowdfunding_guid';
      activity_type: 'crowdfunding_guid';
    };
  };
}

export function analyticsClick({
  event,
  eventValue,
  subtype,
  pageSection,
}: ClickAnalyticsOptions): AnalyticsClickAction {
  return {
    type: 'ANALYTICS_CLICK',
    meta: {
      analytics: {
        event,
        subtype,
        event_value: eventValue,
        page_section: pageSection,
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export type AnalyticsPageView = (options: PageViewAnalyticsOptions) => void;

export interface PageViewAnalyticsOptions {
  eventValue: string;
  pageSection: string;
  subtype: string;
  actorSource?: string;
}

export function analyticsPageView({
  eventValue,
  pageSection,
  subtype,
  actorSource,
}: PageViewAnalyticsOptions) {
  return {
    type: 'ANALYTICS_PAGE_VIEW',
    meta: {
      analytics: {
        event: 'page view',
        subtype,
        event_value: eventValue,
        page_section: pageSection,
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
        actor_source: actorSource,
      },
    },
  };
}
