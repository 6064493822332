import React, { FunctionComponent } from 'react';
import { useDonatePopup } from 'src/components/DonatePopup/DonatePopupContext';
import Modal from 'src/components/Modal';
import { Trans } from 'react-i18next';
import config from 'config';
import CSSModules from 'react-css-modules';
import styles from './DonatePopup.scss';

const DonatePopupBlockedModal: FunctionComponent = () => {
  const { setPopupCheckoutMode, checkoutHref } = useDonatePopup();

  const finalCheckoutUrl = checkoutHref.replace('checkoutMode=Headless', '');

  return (
    <Modal
      colorTheme={'dark'}
      classNames={styles.modal}
      isOpen
      onClose={() => setPopupCheckoutMode('closed')}
    >
      <img
        src={`${config.IMAGE_BASE_URI}/JG_Roundel_Laptop_User1.png`}
        alt=""
        className={styles.image}
      />
      <h1>
        <Trans i18nKey="popupBlockedModal.heading">
          It looks like you might be using an Adblocker
        </Trans>
      </h1>
      <p className={styles.message}>
        <Trans i18nKey="popupBlockedModal.message">
          Adblockers stop us from being able to show you the latest version of
          our donation checkout. Please disable your adblocker and allow pop-ups
          and try again. Or you can open the checkout in a new window below.
        </Trans>
      </p>

      <button
        className={`${styles.button} jg-btn`}
        onClick={() => setPopupCheckoutMode('closed')}
        type="button"
      >
        <Trans i18nKey="popupBlockedModal.tryAgain">
          I'll turn it off and try again
        </Trans>
      </button>
      <a
        href={finalCheckoutUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => setPopupCheckoutMode('closed')}
      >
        <Trans i18nKey="popupBlockedModal.openCheckoutNewWindow">
          Or open checkout in a new window
        </Trans>
      </a>
    </Modal>
  );
};

export default CSSModules(DonatePopupBlockedModal, styles);
