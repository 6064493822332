import React from 'react';
import { Formik, FormikProps, Form, FormikBag } from 'formik';
import { FormattedMessage } from 'react-intl';
import TextareaWithCharacterCount from 'src/components/TextareaWithCharacterCount';
import { AnalyticsLoadingButton as LoadingButton } from 'src/components/Analytics';
import recurringDonationsPitchValidator from './recurringDonationsPitchValidator';
import Alert from 'src/components/Alert';

export interface RecurringDonationsPitchFormData {
  recurringDonationsPitch: string;
}

interface Props {
  recurringDonationsPitch?: string;
  submitPitch: (
    payload: RecurringDonationsPitchFormData,
    formikBag: FormikBag<any, RecurringDonationsPitchFormData>
  ) => void;
}

class RecurringDonationsPitchForm extends React.Component<Props> {
  render() {
    const { recurringDonationsPitch, submitPitch } = this.props;

    const initialValues = {
      recurringDonationsPitch: recurringDonationsPitch || '',
    };

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={false}
        onSubmit={submitPitch}
        validate={recurringDonationsPitchValidator}
        isInitialValid={
          Object.keys(recurringDonationsPitchValidator(initialValues))
            .length === 0
        }
        validateOnChange
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          isSubmitting,
          isValid,
          status,
        }: FormikProps<RecurringDonationsPitchFormData>) => {
          return (
            <div className="jg-text--pull-left">
              <Form>
                <h4 className="jg-space-mbmd jg-text--center">
                  <FormattedMessage
                    id="RecurringDonationsPitchForm.encourgeDonors"
                    defaultMessage="Encourage your supporters to give a monthly donation"
                  />
                </h4>
                <p className="jg-space-mbmd jg-text--center">
                  <FormattedMessage
                    id="RecurringDonationsPitchForm.helpDonorsUnderstand"
                    defaultMessage="It's important to let anyone donating to your page know how the money you raise will be used. You can help your supporters understand why you need their ongoing support here:"
                  />
                </p>
                <TextareaWithCharacterCount
                  id="recurringDonationsPitch"
                  characterLimit={500}
                  error={errors.recurringDonationsPitch}
                  touched={touched.recurringDonationsPitch}
                  onChange={handleChange}
                  value={values.recurringDonationsPitch}
                  placeholder="Briefly explain what monthly donations could help you achieve..."
                />

                {status && (
                  <div className="jg-space-mtsm jg-space-mbmd">
                    <Alert
                      id="recurring-donations-pitch-form-error"
                      type="red"
                      displayType="inline"
                      persistent
                    >
                      <FormattedMessage
                        id="RecurringDonationsPitchForm.errorAlert"
                        defaultMessage="An error has occurred. Please try again."
                      />
                    </Alert>
                  </div>
                )}

                <div className="row jg-space-phsm@xs">
                  <LoadingButton
                    className="jg-btn jg-btn--brand col-xs-12 col-md-6 offset-md-3"
                    loading={isSubmitting}
                    disabled={!isValid}
                    value={
                      <FormattedMessage
                        id="RecurringDonationsPitchForm.addMessage"
                        defaultMessage="Add a message"
                      />
                    }
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    );
  }
}

export default RecurringDonationsPitchForm;
