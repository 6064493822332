import React from 'react';
import Modal from 'src/components/Modal';
import {
  SupporterResource,
  setSupporterThankYouMessage,
} from 'src/helpers/api/activities';
import ThankDonorMessageForm from 'src/components/ThankingDonors/ThankDonorMessageForm';
import ThankDonorMessageSendSuccess from 'src/components/ThankingDonors/ThankDonorMessageSendSuccess';
import ThankDonorMessageSendFailure from 'src/components/ThankingDonors/ThankDonorMessageSendFailure';

export interface ThankDonorModalData {
  thankYouMessage: string;
}
interface Props {
  closeModal: () => void;
  pageId: string;
  profileImage: string | undefined;
  supporter: SupporterResource;
  targetCurrency: Currency;
  thankSupporter: (supporterId: number, thankYouMessage: string) => void;
}

interface State {
  error: string | null;
  hasSentThankYouMessage: boolean | null;
  isFormSubmitting: boolean;
  thankYouMessage: string;
}

class ThankDonorModal extends React.Component<Props, State> {
  state = {
    error: null,
    hasSentThankYouMessage: null,
    isFormSubmitting: false,
    thankYouMessage: '',
  };

  handleSubmit = async (values: ThankDonorModalData) => {
    const { supporter, pageId } = this.props;

    this.setState({
      isFormSubmitting: true,
      thankYouMessage: values.thankYouMessage,
    });

    try {
      const payload = {
        pageId,
        activityId: supporter.id,
        thankYouMessage: values.thankYouMessage,
      };

      await setSupporterThankYouMessage(payload);

      this.props.thankSupporter(supporter.id, values.thankYouMessage);

      this.setState({
        hasSentThankYouMessage: true,
        isFormSubmitting: false,
      });
    } catch (e) {
      this.setState({ hasSentThankYouMessage: false, isFormSubmitting: false });
    }
  };

  render() {
    const { closeModal, profileImage, supporter, targetCurrency } = this.props;

    const {
      hasSentThankYouMessage,
      isFormSubmitting,
      thankYouMessage,
    } = this.state;

    const displayForm = hasSentThankYouMessage === null;

    return (
      <Modal isOpen maintainPosition colorTheme="dark" onClose={closeModal}>
        <div className="jg-space-phmd@xs jg-space-phxl@md jg-space-ptlg jg-space-pbmd">
          {displayForm && (
            <ThankDonorMessageForm
              supporter={supporter}
              targetCurrency={targetCurrency}
              submitForm={this.handleSubmit}
            />
          )}

          {hasSentThankYouMessage &&
            !displayForm && (
              <ThankDonorMessageSendSuccess
                thankYouMessage={thankYouMessage}
                ownerName="You"
                profileImage={profileImage}
              />
            )}

          {!hasSentThankYouMessage &&
            !displayForm && (
              <ThankDonorMessageSendFailure
                thankYouMessage={thankYouMessage}
                ownerName="You"
                profileImage={profileImage}
                submitForm={() =>
                  this.handleSubmit({
                    thankYouMessage: this.state.thankYouMessage,
                  })
                }
                isSubmitting={isFormSubmitting}
              />
            )}
        </div>
      </Modal>
    );
  }
}

export default ThankDonorModal;
