import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'src/components/Analytics';
import SuspensionCountdown from '../SuspensionCountdown';

interface Props {
  pageName: string;
  suspensionDate: string | Date;
}

const AddBankDetailsImmediatelyContent: React.StatelessComponent<Props> = ({
  pageName,
  suspensionDate,
}) => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-6 offset-md-3">
        <SuspensionCountdown suspensionDate={suspensionDate} />
        <h2>
          <FormattedMessage
            id="AddBankDetailsImmediatelyContent.addBankImmediately"
            defaultMessage="Please add your bank details immediately"
          />
        </h2>
        <p className="jg-space-mbml">
          <FormattedMessage
            id="AddBankDetailsImmediatelyContent.yourPageWillBeFrozen"
            defaultMessage="If you don't add your bank details now, your Page will be frozen. This means that no one will be able to donate to it and we could end up refunding your existing donations."
          />
        </p>
        <div className="row">
          <Link
            to={`/${pageName}/funds/account`}
            className={`jg-btn jg-btn--brand col-xs-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3`}
            analyticsOptions={{
              event: 'link click',
              eventValue: 'add my bank details now',
              pageSection:
                'admin > page view > prompt > add bank details immediately',
              subtype: 'button',
            }}
          >
            <FormattedMessage
              id="AddBankDetailsImmediatelyContent.addBankDetailsButton"
              defaultMessage="Add my bank details now"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AddBankDetailsImmediatelyContent;
