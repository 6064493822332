import React from 'react';
import { Button } from 'src/components/Analytics/';
import { FormattedMessage } from 'react-intl';
import rocket from './rocket.svg';
import Svg from '@justgiving/svg';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { Rule } from 'src/helpers/rulesEngine';
import { Link as ScrollLink } from 'react-scroll';

const RecurringDonationsPrompt: Rule<JSX.Element, PromptRulesEngineOptions> = (
  state,
  {}
) => {
  if (
    state.page.status === 'Active' &&
    state.settings.supportsRecurringDonations &&
    !state.page.recurringDonationsPitch
  ) {
    return {
      matched: true,
      result: (
        <div className="row jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey">
          <section className="jg-text--center jg-space-pvmd jg-position-relative col-xs-12 col-md-6 offset-md-3">
            <Svg markup={rocket} className="jg-svg-sm" />
            <h2 className="jg-text--center jg-space-mbms">
              <FormattedMessage
                id="RecurringDonationsPrompt.greatNews"
                defaultMessage="Great news!"
              />
            </h2>
            <p className="jg-space-mbmd jg-space-phsm">
              <FormattedMessage
                id="RecurringDonationsPrompt.encourageSupportersToDonate"
                defaultMessage="You can now encourage your supporters to give a monthly donation. Help them understand why you need their ongoing support by adding a message below your story."
              />
            </p>
            <ScrollLink
              to="recurring-donations-awareness-banner"
              spy
              smooth
              offset={-150}
              duration={500}
            >
              <Button
                className="jg-btn jg-btn--ghost jg-width--300"
                analyticsOptions={{
                  event: 'click',
                  subtype: 'button',
                  eventValue: 'encourage donors now',
                  pageSection: 'page view > prompt > recurring donations',
                }}
              >
                <FormattedMessage
                  id="RecurringDonationsPrompt.okayGotIt"
                  defaultMessage="Encourage donors now"
                />
              </Button>
            </ScrollLink>
          </section>
        </div>
      ),
    };
  }

  return { matched: false };
};

export default RecurringDonationsPrompt;
