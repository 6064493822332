import React, { Component } from 'react';

interface Props {
  featureFlag: boolean;
  isTrue: boolean;
}

export class FeatureToggle extends Component<Props> {
  render() {
    const { featureFlag, isTrue, children } = this.props;
    return featureFlag === isTrue ? <>{children}</> : null;
  }
}
