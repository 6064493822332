import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import loudspeaker from 'src/components/Prompt/PageStrandedPageOwner/svg/loudspeaker.svg';

const PageStrandedContent: FunctionComponent = () => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-8 offset-md-2">
        <img src={loudspeaker} className="jg-svg-sm" alt="loudspeaker icon" />
        <h2>
          <FormattedMessage
            id="PageStrandedSupporterContent.pageStranded"
            defaultMessage="This page has now closed"
          />
        </h2>
        <p className="jg-space-mbml">
          <FormattedMessage
            id="PageStrandedSupporterContent.weNeedMoreInfo"
            defaultMessage="We've closed this page and refunded the donations back to supporters. Where this isn't possible, we will always send the donations to a registered UK charity which is closely aligned with the goals of the page."
          />
        </p>
      </div>
    </div>
  );
};

export default PageStrandedContent;
