import React from 'react';
import qs from 'qs';
import CSSModules from 'react-css-modules';
import { generateHash } from '../../helpers/hashId';
import currencyMap from '../../helpers/currencyMap';
import styles from './SmsShare.scss';
import { ShareActionOptions } from 'src/redux/modules/page';

interface Props {
  shareBySms: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  ownerName: string;
  isOwner: boolean;
  isActive: boolean;
  customStyle?: string;
  location: string;
  buttonText?: string;
  className?: string;
  targetCurrency: Currency;
  styles?: Styles;
}

class SmsShare extends React.Component<Props> {
  handleSmsShare = () => {
    const {
      ownerName,
      targetAmount,
      pitchFor,
      socialShareUrl,
      isOwner,
      shareBySms,
      location,
      targetCurrency,
    } = this.props;

    const shareHash = generateHash();
    const target = `${currencyMap[targetCurrency] || '£'}${targetAmount}`;

    let body;

    if (isOwner) {
      const pageUrl = `${socialShareUrl}?${qs.stringify({
        utm_id: 92,
        utm_term: shareHash,
      })}`;
      body = `Can you help me raise ${target} to ${pitchFor}. Please donate to my JustGiving Crowdfunding Page: ${pageUrl}`;
    } else {
      const pageUrl = `${socialShareUrl}?${qs.stringify({
        utm_id: 102,
        utm_term: shareHash,
      })}`;
      body = `Can you help ${ownerName} raise ${target} to ${pitchFor}. Please donate to their JustGiving Crowdfunding Page: ${pageUrl}`;
    }

    shareBySms({ shareHash, location });

    if (__CLIENT__) {
      this.share(body);
    }
  };

  share(body: string) {
    const getUserAgentInfo = () => {
      const userAgent = navigator.userAgent;
      const info = { android: false, ios: false, version: 0 };

      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPod/i)
      ) {
        info.ios = true;

        const match = userAgent.match(/os (\d+)_/i);

        if (match) {
          info.version = parseInt(match[1], 10);
        }
      }

      const androidMatches = userAgent.match(/Android/i);
      info.android = Array.isArray(androidMatches) && androidMatches.length > 0;

      return info;
    };

    const userAgentInfo = getUserAgentInfo();
    const bodySeparator = userAgentInfo.ios
      ? userAgentInfo.version >= 8
        ? '&'
        : ';'
      : '?';

    const bodyString = encodeURIComponent(body);

    const encodedAmp = encodeURIComponent('&');
    // FIX for https://stackoverflow.com/questions/19022537/html-sms-message-body-everything-after-symbol-disappears by double encoding on Android
    const smsBody = userAgentInfo.android
      ? bodyString.replace(encodedAmp, encodeURIComponent(encodedAmp))
      : bodyString;

    window.location.assign(`sms:${bodySeparator}body=${smsBody}`);
  }

  render() {
    const { isActive, customStyle, buttonText, className } = this.props;

    return (
      <button
        type="button"
        disabled={!isActive}
        className={className}
        styleName={customStyle}
        onClick={this.handleSmsShare}
      >
        {buttonText || 'SMS'}
      </button>
    );
  }
}

export default CSSModules(SmsShare, styles);
