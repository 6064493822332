import React from 'react';
import RecurringDonationsPitchForm, {
  RecurringDonationsPitchFormData,
} from './RecurringDonationsPitchForm';
import RecurringDonationsPitch from './RecurringDonationsPitch';
import { Button } from 'src/components/Analytics';
import { FormattedMessage } from 'react-intl';
import { FormikBag } from 'formik';

interface Props {
  ownerAvatarImage?: string;
  ownerName: string;
  recurringDonationsPitch?: string;
  submitPitch: (
    payload: RecurringDonationsPitchFormData,
    formikBag: FormikBag<any, RecurringDonationsPitchFormData>
  ) => void;
}

interface State {
  isPitchBeingEdited: boolean;
}

class RecurringDonationsPitchOwner extends React.Component<Props, State> {
  state = {
    isPitchBeingEdited: false,
  };

  editPitch = () => {
    this.setState({ isPitchBeingEdited: true });
  };

  submitForm = async (
    payload: RecurringDonationsPitchFormData,
    formikBag: FormikBag<any, RecurringDonationsPitchFormData>
  ) => {
    await this.props.submitPitch(payload, formikBag);

    if (this.state.isPitchBeingEdited) {
      this.setState({ isPitchBeingEdited: false });
    }
  };

  render() {
    const { ownerAvatarImage, ownerName, recurringDonationsPitch } = this.props;

    if (recurringDonationsPitch && !this.state.isPitchBeingEdited) {
      return (
        <>
          <h4 className="jg-space-mbmd jg-text--center">
            <FormattedMessage
              id="RecurringDonationsPitchOwner.youCanDonateMonthly"
              defaultMessage="You can donate monthly to this page!"
            />
          </h4>
          <RecurringDonationsPitch
            recurringDonationsPitch={recurringDonationsPitch}
            ownerAvatarImage={ownerAvatarImage}
            ownerName={ownerName}
          />

          <div className="row jg-space-phsm@xs">
            <Button
              className="jg-btn jg-btn--ghost jg-space-mtmd col-xs-12 col-md-6 offset-md-3"
              onClick={this.editPitch}
            >
              <FormattedMessage
                id="RecurringDonationsPitchOwner.editMessage"
                defaultMessage="Edit message"
              />
            </Button>
          </div>
        </>
      );
    }

    return (
      <RecurringDonationsPitchForm
        recurringDonationsPitch={recurringDonationsPitch}
        submitPitch={this.submitForm}
      />
    );
  }
}

export default RecurringDonationsPitchOwner;
