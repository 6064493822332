import {
  OptimizelyProvider,
  createInstance,
} from '@optimizely/react-sdk';

import React from 'react';
import { clientAuth } from 'src/helpers/auth';
import { optimizelySdkKey } from 'config';
import { v4 } from 'uuid';

const optimizelyInstance = createInstance({
  sdkKey: optimizelySdkKey,
});

export const CustomOptimizelyProvider = ({ children }) => {
  const accessToken = clientAuth.getAccessToken();

  return (
    <OptimizelyProvider
     // tslint:disable-next-line
      optimizely={optimizelyInstance}
      user={{
        id: getUserId(accessToken?.guid),
      }}
    >
      {children}
    </OptimizelyProvider>
  );
};

function getUserId(userId?: string): string {
  const key = 'jg_optimizely_user_id';
  let id = localStorage.getItem(key);

  if (!id) {
    id = userId ?? optimizelyInstance.getVuid() ?? v4();
    localStorage.setItem(key, id);
  }

  return id;
}
