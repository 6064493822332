import { AppState } from 'src/redux/modules';
import asyncRulesEngine from 'src/helpers/rulesEngine';
import { isLocalStorageAvailable } from 'src/helpers/browser';
import StripeUnverifiedAlert from 'src/helpers/alerts/StripeUnverifiedAlert';
import AdditionalDetailsAlert from 'src/helpers/alerts/AdditionalDetailsAlert';
import StripeVerificationPendingAlert from 'src/helpers/alerts/StripeVerificationPendingAlert';
import StripeBankAccountInvalidAlert from 'src/helpers/alerts/StripeBankAccountInvalidAlert';
import PendingApprovalAlert from 'src/helpers/alerts/PendingApprovalAlert';
import NotificationsPermissionAlert from 'src/helpers/alerts/NotificationsPermissionAlert';
import { Dispatch } from 'redux';

export interface AlertRulesEngineOptions {
  onClose: () => void;
  pageSection: string;
  dispatch: Dispatch<AppState>;
}

const alertRulesEngine = async (
  appState: AppState,
  { onClose, pageSection, dispatch }: AlertRulesEngineOptions
) => {
  try {
    const alert = await asyncRulesEngine(
      [
        PendingApprovalAlert,
        AdditionalDetailsAlert,
        StripeVerificationPendingAlert,
        StripeBankAccountInvalidAlert,
        StripeUnverifiedAlert,
        NotificationsPermissionAlert,
      ],
      appState,
      {
        onClose,
        pageSection,
        dispatch,
      }
    );
    return alert.matched && alert.result ? alert.result : null;
  } catch (e) {
    return null;
  }
};

export default alertRulesEngine;

export const hasDismissedAlert = (id: string, key: string) =>
  __CLIENT__ && isLocalStorageAvailable()
    ? localStorage.getItem(`alert:${id}_${key}`) === 'true'
    : true;
