import React from 'react';
import CSSModules from 'react-css-modules';
import Modal from 'src/components/Modal';
import ClickableElement from 'src/components/ClickableElement';
import { Trans } from 'react-i18next';
import DonateButton from 'src/components/Page/DonateButton';
import { AnalyticsPageView } from 'src/components/Analytics';
import { ClickAnalyticsOptions } from 'src/redux/modules/analytics';
import Svg from '@justgiving/svg';
import highFiveImage from './high-five.svg';
import styles from './ThankYouForHelpingModal.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  pageName: string;
  donate: (analytics: ClickAnalyticsOptions) => void;
  canPageReceiveDonations: boolean;
  id: string;
}

interface State {
  isOpen: boolean;
}

export class ThankYouForHelpingModal extends React.Component<Props, State> {
  pageSection: string = 'page view > send feedback > thanks';

  donate = () => {
    const { donate, canPageReceiveDonations } = this.props;

    if (canPageReceiveDonations) {
      donate({
        event: 'click',
        eventValue: 'donate',
        pageSection: this.pageSection,
        subtype: 'button',
      });
    }
  };

  render() {
    const { pageName, canPageReceiveDonations, id } = this.props;
    const pageSection = this.pageSection;

    const closeAnalytics: ClickAnalyticsOptions = {
      event: 'click',
      eventValue: 'close modal',
      pageSection,
      subtype: 'button',
    };

    const modalClassName = 'thank-you-for-helping-modal';
    const titleClassName = 'title';
    const subtitleClassName = 'subtitle';
    const highFiveImageClassName = 'high-five';
    const buttonContainerClassName = 'button-container';

    return (
      <Modal
        isOpen={this.props.isOpen}
        shouldCloseOnOverlayClick
        closeAnalyticsTracking={{ ...closeAnalytics }}
        onClose={this.props.onClose}
        maintainPosition
        colorTheme={'dark'}
      >
        <AnalyticsPageView
          eventValue="modal displayed"
          pageSection={pageSection}
          subtype="cfp"
        >
          <div
            id={`${styles[modalClassName]}`}
            className="jg-space-ptlg jg-space-pblg jg-space-phml"
          >
            <header>
              <Svg
                markup={highFiveImage}
                className={`jg-svg-sm jg-center-block ${
                  styles[highFiveImageClassName]
                }`}
              />
              <h2 className={`jg-text--center ${styles[titleClassName]}`}>
                <Trans i18nKey="ThankYouForHelpingModal.title" />
              </h2>
            </header>

            <p
              className={`jg-text--center subtitle ${
                styles[subtitleClassName]
              }`}
            >
              <Trans i18nKey="ThankYouForHelpingModal.body" />
            </p>
            <div
              className={`jg-background--white ja-tac jg-space-ptmd jg-center-block ${
                styles[buttonContainerClassName]
              }`}
            >
              <DonateButton
                isActive={canPageReceiveDonations}
                donate={this.donate}
                pageSection={pageSection}
                name={pageName}
                id={id}
              />

              <ClickableElement
                className="jg-text--link jg-text--center jg-space-ptmd"
                onClick={this.props.onClose}
                analyticsOptions={{ ...closeAnalytics }}
              >
                <Trans i18nKey="ThankYouForHelpingModal.laterButton" />
              </ClickableElement>
            </div>
          </div>
        </AnalyticsPageView>
      </Modal>
    );
  }
}
export default CSSModules(ThankYouForHelpingModal, styles);
