/* global FB */

import React from 'react';
import CSSModules from 'react-css-modules';
import config from 'config';
import styles from './SupportMessage.scss';

if (__CLIENT__) {
  window.addEventListener('onload', () => {
    (function fbLoad(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = `//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v5.0&appId=${
        config.FB_APP_ID
      }`;
      fjs.parentNode!.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
}

interface Props {
  socialShareUrl: string;
}

class SupportMessage extends React.Component<Props> {
  componentDidMount() {
    try {
      // todo: move FB API call to a helper function
      if (typeof FB !== 'undefined' && FB.XFBML && FB.XFBML.parse) {
        FB.XFBML.parse();
      }
    } catch (e) {
      // try catch to prevent page failing to load
      // { innerError: undefined, message: 'init not called with valid version' }
    }
  }

  render() {
    const { socialShareUrl } = this.props;

    return (
      <div styleName="container">
        <h1 className="jg-h2" styleName="title">
          Leave a message of support
        </h1>
        <div styleName="comments">
          <div
            className="fb-comments"
            data-colorscheme="light"
            data-href={socialShareUrl}
            data-numposts="3"
            data-width="100%"
          />
        </div>
      </div>
    );
  }
}

export default CSSModules(SupportMessage, styles);
