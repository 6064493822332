import React from 'react';
import Alert from 'src/components/Alert/';
import {
  AlertRulesEngineOptions,
  hasDismissedAlert,
} from 'src/helpers/alerts/index';
import { isActive } from 'src/helpers/page';
import {
  arePushNotificationsSupported,
  askPermission,
  getSubscription,
  subscribe,
} from 'src/helpers/pushNotifications';
import { AppState } from 'src/redux/modules';
import { isLocalStorageAvailable } from 'src/helpers/browser';
import { Dispatch } from 'redux';
import { setNotificationsStatus } from 'src/redux/modules/settings/actions';
import { analyticsClick } from 'src/redux/modules/analytics';
import { AsyncRule, RuleResult } from 'src/helpers/rulesEngine';
import { FEATURE } from 'config';
import { FormattedMessage } from 'react-intl';
import ClickableElement from 'src/components/ClickableElement';

const NotificationsPermissionAlert: AsyncRule<JSX.Element> = async (
  state: AppState,
  options: AlertRulesEngineOptions
): Promise<RuleResult<JSX.Element>> => {
  const { status } = state.page;
  const alertKey = 'enable_notifications';
  const pageSection = options!.pageSection || '';

  if (FEATURE.PUSH_NOTIFICATIONS) {
    if (
      isActive({ status }) &&
      !hasDismissedAlert(state.page.id, alertKey) &&
      arePushNotificationsSupported() &&
      (await getSubscription()) === null
    ) {
      return {
        matched: true,
        result: (
          <Alert
            id={alertKey}
            type="info"
            onClose={options.onClose}
            page={state.page}
          >
            <FormattedMessage
              id="PushNotifications.alert"
              defaultMessage="Do you want to be notified about your fundraising activity? {enableLink}"
              values={{
                enableLink: (
                  <ClickableElement
                    inline
                    className="jg-text--link"
                    onClick={() =>
                      enableNotifications({
                        userId: state.self.id!,
                        pageId: state.page.id,
                        alertKey,
                        onClose: options.onClose,
                        dispatch: options.dispatch,
                        pageSection,
                      })
                    }
                    analyticsOptions={{
                      event: 'link click',
                      eventValue: 'Enable push notifications',
                      pageSection,
                      subtype: 'hyperlink',
                    }}
                  >
                    <FormattedMessage
                      id="PushNotifications.alert.enableLink"
                      defaultMessage="Enable browser push notifications now"
                    />
                  </ClickableElement>
                ),
              }}
            />
          </Alert>
        ),
      };
    }
  }

  return { matched: false };
};

interface EnableNotificationsParams {
  userId: string;
  pageId: string;
  alertKey: string;
  onClose: any;
  dispatch: Dispatch<AppState>;
  pageSection: string;
}

async function enableNotifications({
  userId,
  pageId,
  alertKey,
  onClose,
  dispatch,
  pageSection,
}: EnableNotificationsParams) {
  const permissionResult = await askPermission();
  if (permissionResult === 'granted') {
    subscribe(userId, pageId);
    dispatch(setNotificationsStatus(true));
  }

  dispatch(
    analyticsClick({
      event: 'click',
      eventValue: `${permissionResult}`,
      pageSection,
      subtype: 'browser push notifications',
    })
  );

  dismissAlert(pageId, alertKey, onClose);
}

function dismissAlert(pageid: string, key: string, onClose: any) {
  if (__CLIENT__ && isLocalStorageAvailable()) {
    localStorage.setItem(`alert:${pageid}_${key}`, 'true');
  }
  onClose();
}

export default NotificationsPermissionAlert;
