import {
  getCompletedActions,
  setActionAsComplete,
  CompletedAction,
} from 'src/helpers/api/completedActions';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';

interface LoadCompletedActionsRequestAction {
  type: 'LOAD_COMPLETED_ACTIONS_REQUEST';
}

interface LoadCompletedActionsSuccessAction {
  type: 'LOAD_COMPLETED_ACTIONS_SUCCESS';
  payload: CompletedAction[];
}

interface LoadCompletedActionsFailureAction {
  type: 'LOAD_COMPLETED_ACTIONS_FAILURE';
  error: true;
  payload: Error;
}

export function loadCompletedActions() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<LoadCompletedActionsRequestAction>({
      type: 'LOAD_COMPLETED_ACTIONS_REQUEST',
    });
    const pageId = getState().page.id;
    try {
      const data = await getCompletedActions(pageId);
      dispatch<LoadCompletedActionsSuccessAction>({
        type: 'LOAD_COMPLETED_ACTIONS_SUCCESS',
        payload: data,
      });
    } catch (err) {
      dispatch<LoadCompletedActionsFailureAction>({
        type: 'LOAD_COMPLETED_ACTIONS_FAILURE',
        error: true,
        payload: err,
      });
    }
  };
}

export interface CompleteActionsOptions {
  payload: {
    pageId: string;
    key: string;
  };
}

interface CompleteActionSuccessAction {
  type: 'COMPLETE_ACTION_SUCCESS';
  payload: {
    key: string;
  };
}

interface CompleteActionFailureAction {
  type: 'COMPLETE_ACTION_FAILURE';
  error: true;
  payload: Error;
}

export function completeAction(pageId: string, key: string) {
  return async (dispatch: Dispatch<AppState>) => {
    dispatch({
      type: 'COMPLETE_ACTION_REQUEST',
      payload: {
        pageId,
        key,
      },
    });

    try {
      await setActionAsComplete(pageId, key);
      dispatch<CompleteActionSuccessAction>({
        type: 'COMPLETE_ACTION_SUCCESS',
        payload: { key },
      });
    } catch (err) {
      dispatch<CompleteActionFailureAction>({
        type: 'COMPLETE_ACTION_FAILURE',
        error: true,
        payload: err,
      });
    }
  };
}

export interface CompletedActionsState {
  isLoading: boolean;
  loaded: boolean;
  keys: string[];
}

const initialState = {
  isLoading: false,
  loaded: false,
  keys: [],
};

type Actions =
  | LoadCompletedActionsRequestAction
  | LoadCompletedActionsSuccessAction
  | LoadCompletedActionsFailureAction
  | CompleteActionSuccessAction;

export default function reducer(
  state: CompletedActionsState = initialState,
  action: Actions
): CompletedActionsState {
  switch (action.type) {
    case 'LOAD_COMPLETED_ACTIONS_REQUEST':
      return { ...state, isLoading: true, loaded: false };

    case 'LOAD_COMPLETED_ACTIONS_SUCCESS':
      return {
        ...state,
        keys: action.payload.map(item => item.key),
        isLoading: false,
        loaded: true,
      };

    case 'LOAD_COMPLETED_ACTIONS_FAILURE':
      return { ...state, isLoading: false, loaded: true };

    case 'COMPLETE_ACTION_SUCCESS':
      return {
        ...state,
        keys: [...state.keys, action.payload.key],
      };

    default:
      return state;
  }
}
