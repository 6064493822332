import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'src/components/Analytics';
import SuspensionCountdown from '../SuspensionCountdown';

interface Props {
  suspensionDate: string | Date;
  pageName: string;
}

const TimeIsRunningOutContent: React.StatelessComponent<Props> = ({
  suspensionDate,
  pageName,
}) => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-6 offset-md-3">
        <SuspensionCountdown suspensionDate={suspensionDate} />
        <h2>
          <FormattedMessage
            id="TimeIsRunningOutContent.timeRunningOut"
            defaultMessage="Time is running out!"
          />
        </h2>
        <p className="jg-space-mbml">
          <FormattedMessage
            id="TimeIsRunningOutContent.addYourBankDetailsNow"
            defaultMessage="We need to know where you want the money you've raised to be sent. To avoid your Page being frozen and your donations refunded, please add your bank details now."
          />
        </p>
        <div className="row">
          <Link
            to={`/${pageName}/funds/account`}
            className={`jg-btn jg-btn--brand col-xs-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3`}
            analyticsOptions={{
              event: 'link click',
              eventValue: 'add my bank details now',
              pageSection: 'admin > page view > prompt > time running out',
              subtype: 'button',
            }}
          >
            <FormattedMessage
              id="TimeIsRunningOutContent.addBankDetailsButton"
              defaultMessage="Add my bank details now"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TimeIsRunningOutContent;
