import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';

interface FriendsRequestAction {
  type: 'FRIENDS_REQUEST';
}

interface FriendsSuccessAction {
  type: 'FRIENDS_SUCCESS';
  payload: TaggableFriend[];
}

interface FriendsFailureAction {
  type: 'FRIENDS_FAILURE';
}

export function getFriends() {
  return async (dispatch: Dispatch<AppState>) => {
    dispatch({ type: 'FRIENDS_REQUEST' });

    try {
      const response = await getTaggableFriends();
      dispatch({ type: 'FRIENDS_SUCCESS', payload: response.data });
    } catch (error) {
      dispatch({ type: 'FRIENDS_FAILURE', error: true, payload: error });
    }
  };
}

interface TaggableFriend {
  picture: { data: { url: string } };
  name: string;
  id: string;
}

interface TaggableFriendsResponse {
  data: TaggableFriend[];
}

function getTaggableFriends(): Promise<TaggableFriendsResponse> {
  return new Promise((resolve, reject) => {
    // todo: move FB API call to a helper function
    FB.api('/me/taggable_friends?limit=5000', (response: any) => {
      if (response && response.error) {
        reject(response);
      }

      resolve(response);
    });
  });
}

export interface Friend {
  friendId: string;
  name: string;
  avatar: string;
}

export interface FriendsState {
  items: Friend[];
}

const initialState: FriendsState = {
  items: [],
};

type Actions =
  | FriendsRequestAction
  | FriendsSuccessAction
  | FriendsFailureAction;

export default function reducer(
  state = initialState,
  action: Actions
): FriendsState {
  switch (action.type) {
    case 'FRIENDS_SUCCESS':
      return {
        ...state,
        items: action.payload.map(friend => ({
          avatar: friend.picture.data.url,
          name: friend.name,
          friendId: friend.id,
        })),
      };
    default:
      return state;
  }
}
