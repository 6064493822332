import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import PageFrozenContent from './PageFrozenContent';
import { CountdownRulesEngineOptions } from 'src/components/Prompt/CountdownToRefundingFlow/countdownToRefundingEngine';

const PageFrozen: Rule<JSX.Element, CountdownRulesEngineOptions> = (
  state,
  options
) => {
  if (
    options &&
    state.page.status === 'Suspended' &&
    !options.timeRemainingFalseSuspension.hasCountdownFinished
  ) {
    return {
      matched: true,
      result: (
        <PageFrozenContent
          pageName={options.pageName}
          refundDate={options.refundDate}
        />
      ),
    };
  }

  return { matched: false };
};

export default PageFrozen;
