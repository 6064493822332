import {
  AnalyticsClick,
  ClickAnalyticsOptions,
  analyticsClick as analyticsClickActionCreator,
} from 'src/redux/modules/analytics';

import ClickableElement from 'src/components/ClickableElement';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styles from './Button.scss';

interface OwnProps {
  onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;
  qaClassName?: string;
  className?: string;
  analyticsOptions?: ClickAnalyticsOptions;
  value?: string;
  type?: string;
  isDisabled?: boolean;
}

interface DispatchProps {
  analyticsClick: AnalyticsClick;
}

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = (dispatch: any): DispatchProps =>
  bindActionCreators(
    {
      analyticsClick: analyticsClickActionCreator,
    },
    dispatch
  );

export class TrackableButton extends React.Component<Props, {}> {
  static defaultProps: {
    qaClassName: '';
  };

  constructor(props: Props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    const { onClick, analyticsClick, analyticsOptions } = this.props;

    if (onClick) {
      onClick(event);
    }

    if (analyticsOptions) {
      analyticsClick(analyticsOptions);
    }
  }

  render() {
    const {
      analyticsOptions,
      children,
      className,
      isDisabled,
      type,
      value,
      qaClassName,
    } = this.props;

    if (isDisabled) {
      return (
        <ClickableElement
          analyticsOptions={analyticsOptions}
          className={`${qaClassName}-disabled`}
        >
          <div className={`${styles['disabled-trackable-button']}`}>
            {children}
          </div>
        </ClickableElement>
      );
    }
    return (
      <button
        className={`${className} ${qaClassName || ''}`}
        onClick={this.handleClick}
        type={type}
        value={value}
      >
        {children}
      </button>
    );
  }
}

export default connect<void, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(TrackableButton);
