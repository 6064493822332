/* global FB */

import { FB_APP_ID, FB_APP_NAMESPACE } from '../../config';

export function share({
  method,
  link,
  ref,
  picture,
  name,
  quote,
  description,
  mobileIframe,
}) {
  let options;

  switch (method) {
    case 'feed':
      options = {
        method: 'feed',
        link,
        ref,
        picture,
        name,
        description,
        quote,
      };
      break;

    case 'share':
      options = {
        method: 'share',
        href: link,
        ref,
        picture,
        title: name,
        description,
        quote,
        mobile_iframe: mobileIframe || true,
      };
      break;

    case 'open_graph':
      options = {
        method: 'share_open_graph',
        action_type: `${FB_APP_NAMESPACE}:support`,
        action_properties: JSON.stringify({ yimby_project: link }),
      };
      break;

    default:
      throw new Error(`Unsupported share method ${method}`);
  }

  return new Promise((resolve, reject) => {
    FB.ui(options, response => {
      if (response && response.error_message) {
        reject(response.error_message);
      } else {
        // It's impossible to reliably tell if the share was succesfull unless
        // the user has authorized our app (more http://stackoverflow.com/questions/5363517),
        // which most of our users won't do - we only as this of owners when
        // they try to post page updates to Facebook.
        // This only tell us that the share window was actually opened,
        // even the error_message might not be there on response in some
        // cases
        resolve();
      }
    });
  });
}

export const fbLogPromise = new Promise(resolve => {
  if (__CLIENT__) {
    window.fbAsyncInit = () => {
      FB.init({ appId: FB_APP_ID, xfbml: true, version: 'v5.0', status: true });
      resolve(FB);
    };

    (function facebookScript(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/ /sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }
});

if (__CLIENT__) {
  /* tslint:disable */
  window.ga =
    window.ga ||
    function() {
      (ga.q = ga.q || []).push(arguments);
    };
  ga.l = +new Date();
  window.twttr = (function(d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://platform.twitter.com/widgets.js';
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function(f) {
      t._e.push(f);
    };

    return t;
  })(document, 'script', 'twitter-wjs');
  /* tslint:enable */
}

function isValidResponse(response) {
  return response && !response.error;
}

function getAccessToken(response) {
  return response && response.authResponse
    ? response.authResponse.accessToken
    : null;
}

async function getLoggedInUser() {
  const FB = await fbLogPromise;

  return new Promise(resolve => {
    FB.api(
      '/me?fields=email,first_name,last_name,picture.width(400).height(400)',
      response => {
        if (isValidResponse(response)) {
          resolve(response);
        } else {
          resolve(null);
        }
      }
    );
  });
}

async function requestLogin() {
  const FB = await fbLogPromise;

  return new Promise(resolve => {
    FB.login(
      response => {
        if (isValidResponse(response)) {
          const accessToken = getAccessToken(response);
          resolve(accessToken);
        } else {
          resolve(null);
        }
      },
      {
        scope: 'public_profile, user_friends, email, user_photos',
        return_scopes: true,
        auth_type: 'rerequest',
      }
    );
  });
}

async function createLoggedInUser(accessToken) {
  const user = await getLoggedInUser();

  if (!user) {
    return null;
  }

  return {
    accessToken,
    firstName: user.first_name,
    lastName: user.last_name,
    emailAddress: user.email,
    profilePicture: user.picture,
  };
}

async function requestLoginAndCreateUser() {
  const accessToken = await requestLogin();

  // The user failed to authenticate or grant required permissions
  if (!accessToken) {
    return null;
  }

  return createLoggedInUser(accessToken);
}

export async function login() {
  return requestLoginAndCreateUser();
}

export async function getTaggableFriends() {
  return new Promise((resolve, reject) => {
    // todo: move FB API call to a helper function
    FB.api('/me/taggable_friends?limit=5000', response => {
      if (response && response.error) {
        reject(response);
      }

      resolve(response);
    });
  });
}

export async function getUserToken() {
  let userToken = '';
  FB.getLoginStatus(async response => {
    userToken = getAccessToken(response);

    if (userToken == null) {
      const user = await login();
      userToken = user && user.accessToken;
    }
  });
  return userToken;
}
