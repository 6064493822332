import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';
import { fetchCharityInfo, CharityResponse } from 'src/helpers/api/charity';

interface GetCharityInfoRequest {
  type: 'GET_CHARITY_INFO_REQUEST';
}

export interface GetCharityInfoSuccess {
  type: 'GET_CHARITY_INFO_SUCCESS';
  payload: CharityResponse;
}

interface GetCharityInfoFailure {
  type: 'GET_CHARITY_INFO_FAILURE';
}

export function getCharityInfo(id: number) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<GetCharityInfoRequest>({ type: 'GET_CHARITY_INFO_REQUEST' });

    try {
      const result = await fetchCharityInfo(id);

      if (result !== null) {
        dispatch<GetCharityInfoSuccess>({
          type: 'GET_CHARITY_INFO_SUCCESS',
          payload: result,
        });
      }
    } catch {
      dispatch<GetCharityInfoFailure>({ type: 'GET_CHARITY_INFO_FAILURE' });
    }
  };
}
