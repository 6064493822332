import moment from 'moment';
import { isMobileUserAgent } from 'src/helpers/browser';

export enum CalendarType {
  Google = 'Google',
  Apple = 'Apple',
  Outlook = 'Outlook',
  OutlookWeb = 'Outlook-Web',
  Yahoo = 'Yahoo',
}

export interface AddToCalendarEvent {
  title: string;
  description: string;
  location: string;
  startTime: string | Date;
  endTime: string | Date;
}

const isMobile = isMobileUserAgent();

function formatTime(date: string | Date) {
  const formattedDate = moment.utc(date).format('YYYYMMDDTHHmmssZ');
  return formattedDate.replace('+00:00', 'Z');
}

function calculateDuration(startTime: string | Date, endTime: string | Date) {
  const end = moment.utc(endTime).format('DD/MM/YYYY HH:mm:ss');
  const start = moment.utc(startTime).format('DD/MM/YYYY HH:mm:ss');
  const difference = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(
    moment(start, 'DD/MM/YYYY HH:mm:ss')
  );
  const duration = moment.duration(difference);
  const zeroSuffixedHours = ('0' + Math.floor(duration.asHours())).slice(-2);
  const minutes = moment.utc(difference).format('mm');
  return zeroSuffixedHours + minutes;
}

function getRandomKey() {
  const rand = Math.floor(Math.random() * 999999999999).toString();
  return new Date().getTime().toString() + '_' + rand;
}

function buildEventUrl(event: AddToCalendarEvent, type: CalendarType) {
  let calendarUrl = '';
  const formattedStartTime = formatTime(event.startTime);
  const formattedEndTime = formatTime(event.endTime);
  const encodedLocation = encodeURIComponent(event.location);
  const encodedTitle = encodeURIComponent(event.title);
  const encodedDescription = encodeURIComponent(event.description);

  switch (type) {
    case CalendarType.Google:
      calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${formattedStartTime}/${formattedEndTime}&location=${encodedLocation}&text=${encodedTitle}&details=${encodedDescription}`;
      break;
    case CalendarType.Yahoo:
      const duration = calculateDuration(event.startTime, event.endTime);
      calendarUrl = `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodedTitle}&st=${formattedStartTime}&dur=${duration}&desc=${encodedDescription}&in_loc=${encodedLocation}`;
      break;
    case CalendarType.OutlookWeb:
      calendarUrl = `https://outlook.live.com/owa/?rru=addevent&startdt=${formattedStartTime}&enddt=${formattedEndTime}&subject=${encodedTitle}&location=${encodedLocation}&body=${encodedDescription}&allday=false&uid=${getRandomKey()}&path=/calendar/view/Month`;
      break;
    default:
      calendarUrl = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'URL:' + document.URL,
        'DTSTART:' + formatTime(event.startTime),
        'DTEND:' + formatTime(event.endTime),
        'SUMMARY:' + event.title,
        'DESCRIPTION:' + event.description,
        'LOCATION:' + event.location,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');

      if (isMobileUserAgent()) {
        calendarUrl = encodeURI(
          'data:text/calendar;charset=utf8,' + calendarUrl
        );
      }
  }

  return calendarUrl;
}

export function addToCalendarHandler(
  event: AddToCalendarEvent,
  type: CalendarType
) {
  let isOldIE = false;

  if (
    typeof window !== 'undefined' &&
    (window.navigator as any).msSaveOrOpenBlob &&
    window.Blob
  ) {
    isOldIE = true;
  }
  const url = buildEventUrl(event, type);

  if (!isMobile && (url.startsWith('data') || url.startsWith('BEGIN'))) {
    const filename = 'reminder.ics';
    const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

    if (isOldIE) {
      (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } else {
    window.open(url, '_blank');
  }
}
