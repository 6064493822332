import React from 'react';
import CSSModules from 'react-css-modules';
import LoadingButton from 'src/components/LoadingButton/';
import SupportersHeader from './SupportersHeader/SupportersHeader';
import styles from './Supporters.scss';
import Supporter from './Supporter';
import BecomeSupporter from './BecomeSupporter';
import LaunchYourPage from './LaunchYourPage';
import { PageState } from 'src/redux/modules/page';
import { SupportersState } from 'src/redux/modules/supporters';

interface Props {
  donate: () => void;
  loadSupporters: (
    details: { pageId: string; pageNo: number; pageSize: number }
  ) => void;
  page: PageState;
  isActive: boolean;
  isClosed: boolean;
  canPageReceiveDonations: boolean;
  ownerName: string;
  profileImage?: string | undefined;
  pageId: string;
  supporterPageNo?: number;
  supporters: SupportersState;
  isOwner: boolean;
  targetCurrency: Currency;
  thankSupporter: (supporterId: number, thankYouMessage: string) => void;
  totalCountSupporters: number;
}

class Supporters extends React.Component<Props> {
  handleLoadSupporters = () => {
    const { pageId, supporterPageNo, loadSupporters } = this.props;
    const pageSize = 7;
    const loadDetails = {
      pageId,
      pageNo: (supporterPageNo || 1) + 1,
      pageSize,
    };

    loadSupporters(loadDetails);
  };

  renderShowMore() {
    const {
      supporters: { isLoading },
    } = this.props;

    return (
      <LoadingButton
        loading={isLoading}
        onClick={this.handleLoadSupporters}
        color="purple"
        className={['jg-btn--ghost', styles.action]}
        value="Show more"
      />
    );
  }

  renderAppeal() {
    const {
      isActive,
      isClosed,
      profileImage,
      donate,
      ownerName,
      isOwner,
      canPageReceiveDonations,
    } = this.props;

    if (!isActive && !isClosed && isOwner) {
      return <LaunchYourPage />;
    }

    if (!isClosed) {
      return (
        <BecomeSupporter
          donate={donate}
          canPageReceiveDonations={canPageReceiveDonations}
          ownerName={ownerName}
          profileImage={profileImage}
          page={this.props.page}
        />
      );
    }

    return null;
  }

  render() {
    const {
      isOwner,
      pageId,
      profileImage,
      supporters: { items, totalCount },
      targetCurrency,
      thankSupporter,
      totalCountSupporters,
    } = this.props;

    return (
      <div styleName="container" className="jg-space-phms">
        <div className="jg-space-pln">
          <SupportersHeader totalCountSupporters={totalCountSupporters} />
          <ul styleName="donation-list">
            {items.map(supporter => (
              <Supporter
                key={supporter.id}
                pageId={pageId}
                profileImage={profileImage}
                supporter={supporter}
                targetCurrency={targetCurrency}
                thankSupporter={thankSupporter}
                isOwner={isOwner}
              />
            ))}
          </ul>
          <div styleName="show-more-button-container">
            {items.length < totalCount && this.renderShowMore()}
          </div>
          {this.renderAppeal()}
        </div>
      </div>
    );
  }
}

export default CSSModules(Supporters, styles);
