import { getImageFromCanvas } from 'src/helpers/image';
import { get2dContext, loadImages } from 'src/helpers/canvas';
import backgroundPurple from './background-purple.svg';
import backgroundOrange from './background-orange.svg';
import backgroundGreen from './background-green.svg';
import backgroundBlue from './background-blue.svg';
import arm1 from './arm-1.svg';
import arm2 from './arm-2.svg';
import arm3 from './arm-3.svg';
import totaliser from './totaliser.svg';

export type MilestoneImagePreset = 'purple' | 'orange' | 'green' | 'blue';

export const ASPECT_RATIO = 1200 / 630;

export interface GetMilestoneImageDescriptorOptions {
  percentage: number;
  preset: MilestoneImagePreset;
}

export interface MilestoneImageOptions
  extends GetMilestoneImageDescriptorOptions {
  width: number;
  height: number;
  targetAmount: string;
}

export interface DrawMilestoneImageOptions extends MilestoneImageOptions {
  ctx: CanvasRenderingContext2D;
}

const presets = {
  purple: {
    background: backgroundPurple,
    arm: arm1,
  },
  orange: {
    background: backgroundOrange,
    arm: arm3,
  },
  green: {
    background: backgroundGreen,
    arm: arm1,
  },
  blue: {
    background: backgroundBlue,
    arm: arm2,
  },
};

export function getMilestoneImageDescriptor({
  percentage,
  preset,
}: GetMilestoneImageDescriptorOptions) {
  const width = 1200;
  const height = 630;

  const totaliserRadius = 240;
  const totaliserX = 600;
  const totaliserY = 35 + totaliserRadius;

  const fillPercentage = Math.min(100, percentage) / 100;

  const fillRadius = totaliserRadius - 45;
  const fillTopY = totaliserY + fillRadius - fillRadius * 2 * fillPercentage;
  const fillTopParam =
    Math.sqrt(1 - (1 - fillPercentage * 2) ** 2) * fillRadius;
  const fillTopLeft = -fillTopParam + totaliserX;
  const fillTopRight = fillTopParam + totaliserX;

  const fillStartAngle = fillPercentageToAngle(fillPercentage);
  const fillEndAngle = Math.PI - fillStartAngle;

  const fillWaveCpx1 = fillTopLeft + fillTopParam * 0.8;
  const fillWaveCpy1 = fillTopY - 60 * (1 - Math.abs(percentage - 50) / 50);
  const fillWaveCpx2 = fillTopLeft + fillTopParam * 1.2;
  const fillWaveCpy2 = fillTopY - 10 * (1 - Math.abs(percentage - 50) / 50);

  const armHeight = 137;
  const armWidth = 769;
  const armX = -320;
  const armY = totaliserY - armHeight / 2;

  const percentageSize = 120;
  const targetSize = 32;
  const targetMargin = 20;

  const percentageX = totaliserX;
  const percentageY = totaliserY - (targetSize / 2 + targetMargin / 2);
  const percentageMaxWidth = totaliserRadius * 2 - 180;

  const targetX = percentageX;
  const targetY = percentageY + percentageSize / 2 + targetMargin;
  const targetMaxWidth = totaliserRadius * 2 - 180;

  return {
    width,
    height,
    images: presets[preset],
    totaliser: {
      radius: totaliserRadius,
      x: totaliserX,
      y: totaliserY,
    },
    fill: {
      x: totaliserX,
      y: totaliserY,
      radius: fillRadius,
      topLeft: fillTopLeft,
      topRight: fillTopRight,
      topY: fillTopY,
      startAngle: fillStartAngle,
      endAngle: fillEndAngle,
      waveCpx1: fillWaveCpx1,
      waveCpy1: fillWaveCpy1,
      waveCpx2: fillWaveCpx2,
      waveCpy2: fillWaveCpy2,
    },
    arm: {
      x: armX,
      y: armY,
      width: armWidth,
      height: armHeight,
    },
    percentage: {
      x: percentageX,
      y: percentageY,
      size: percentageSize,
      maxWidth: percentageMaxWidth,
    },
    target: {
      x: targetX,
      y: targetY,
      size: targetSize,
      maxWidth: targetMaxWidth,
    },
  };
}

export async function exportMilestoneImage(
  options: MilestoneImageOptions
): Promise<Blob> {
  const canvas = document.createElement('canvas');

  canvas.width = options.width;
  canvas.height = options.height;

  const ctx = get2dContext(canvas);

  await drawMilestoneImage({
    ctx,
    ...options,
  });

  return getImageFromCanvas(canvas, 'image/png');
}

export async function drawMilestoneImage(options: DrawMilestoneImageOptions) {
  const {
    ctx,
    width: canvasWidth,
    height: canvasHeight,
    percentage,
    targetAmount,
  } = options;

  const descriptor = getMilestoneImageDescriptor(options);

  const {
    width: imageWidth,
    height: imageHeight,
    images: imagePaths,
  } = descriptor;

  const images = await loadImages({ ...imagePaths, totaliser });

  ctx.save();

  try {
    ctx.scale(canvasWidth / imageWidth, canvasHeight / imageHeight);
    drawBackground();
    drawTotaliser();
    drawFill();
    drawArm();
    drawPercentage();
    drawTarget();
  } finally {
    ctx.restore();
  }

  function drawBackground() {
    ctx.drawImage(images.background, 0, 0, imageWidth, imageHeight);
  }

  function drawTotaliser() {
    const { x, y, radius } = descriptor.totaliser;

    ctx.drawImage(
      images.totaliser,
      x - radius,
      y - radius,
      radius * 2,
      radius * 2
    );
  }

  function drawFill() {
    const {
      x,
      y,
      radius,
      topLeft,
      topRight,
      topY,
      startAngle,
      endAngle,
      waveCpx1,
      waveCpy1,
      waveCpx2,
      waveCpy2,
    } = descriptor.fill;

    ctx.fillStyle = '#ad29b6';
    ctx.beginPath();
    ctx.moveTo(topLeft, topY);
    ctx.bezierCurveTo(
      waveCpx1,
      waveCpy1,
      waveCpx2,
      waveCpy2,
      topRight,
      topY + 1
    );
    ctx.lineTo(topRight, topY);
    ctx.arc(x, y, radius, startAngle, endAngle);
    ctx.fill();
  }

  function drawArm() {
    const { x, y, width, height } = descriptor.arm;

    ctx.drawImage(images.arm, x, y, width, height);
  }

  function drawPercentage() {
    const { x, y, size, maxWidth } = descriptor.percentage;

    ctx.font = `${size}px Roboto-Regular, Roboto, Arial, sans-serif`;
    ctx.fillStyle = '#fff';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    ctx.fillText(`${percentage}%`, x, y, maxWidth);
  }

  function drawTarget() {
    const { x, y, size, maxWidth } = descriptor.target;

    ctx.font = `${size}px Roboto-Regular, Roboto, Arial, sans-serif`;
    ctx.fillStyle = '#fff';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    ctx.fillText(` of ${targetAmount} target`, x, y, maxWidth);
  }
}

function fillPercentageToAngle(fillPercentage: number) {
  const y = 1 - fillPercentage * 2;
  const x = Math.sqrt(1 - y ** 2);

  return Math.atan2(y, x);
}
