import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import renderRoutes, { RouteConfig } from 'src/helpers/renderRoutes';
import TabViewLinks from 'src/components/TabView/TabViewLinks/TabViewLinks';
import TabLink from 'src/components/TabView/TabLink';
import TabViewContent from 'src/components/TabView/TabViewContent';
import { RichUpdateNavLink } from 'src/components/RichUpdates/RichUpdateNavLink';
import { getPageSection } from 'src/helpers/analytics';
import {
  isClosed as isPageClosed,
  isEligibleForFlexiblePayments,
  canPostUpdates,
  isPendingApproval,
  isActive as isPageActive,
} from 'src/helpers/page';
import { setUpBankDetails, PageState } from 'src/redux/modules/page';
import { getTipsState } from 'src/redux/modules/dashboard';
import { AppState } from 'src/redux/modules';
import SharePromptsContainer from 'src/containers/page/SharePromptsContainer';
import ShareWithFriendsPromptsContainer from 'src/containers/page/ShareWithFriendsPromptsContainer';
import alertRulesEngine from 'src/helpers/alerts/index';
import { openSharePrompts } from 'src/redux/modules/sharePrompts';
import TabShareButton from 'src/components/TabView/TabShareButton/TabShareButton';
import PageOwnerNavbarContainer from './PageOwnerNavbarContainer';
import { FeatureToggle } from 'src/components/FeatureToggle/FeatureToggle';
import config from 'config';

interface StateProps {
  badgeCount: number;
  page: PageState;
  paypal: any;
  appState: AppState;
}

interface State {
  alert: JSX.Element | null;
}

interface DispatchProps {
  showPayment: () => void;
  openSharePrompts: () => void;
  dispatch: Dispatch<AppState>;
}

interface OwnProps {
  route: RouteConfig;
  location: Location;
  params: { name: string };
}

function mapStateToProps(state: AppState): StateProps {
  const { page, payment } = state;

  return {
    badgeCount: getBadgeCount(state),
    page,
    paypal: payment.paypal,
    appState: state,
  };
}

function mapDispatchToProps(dispatch: any, ownProps: OwnProps): DispatchProps {
  const location = ownProps.location.pathname.split('/')[2] || 'page';

  return {
    ...bindActionCreators(
      {
        showPayment: setUpBankDetails,
        openSharePrompts: () =>
          openSharePrompts(`${location} > sticky owner nav`),
      },
      dispatch
    ),
    dispatch,
  };
}

function getBadgeCount(state: AppState) {
  const tipsState = getTipsState(state);
  const totalTips = Object.keys(tipsState).length;
  const completedTips = Object.values(tipsState).filter(t => t).length;

  return totalTips - completedTips;
}

export type Props = StateProps & DispatchProps & OwnProps;

export class OwnerPageView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { alert: null };
  }

  shouldDisplayEditTab() {
    return !isPageClosed(this.props.page);
  }

  async componentDidMount() {
    const alert = await this.getAlert();

    await this.setState({ alert });
  }

  async componentDidUpdate(_: Props, prevState: State) {
    const newAlert = await this.getAlert();

    if (newAlert === null) {
      return;
    }

    if (!newAlert && this.state.alert) {
      this.setState({ alert: null });
      return;
    }

    if (
      !prevState.alert ||
      (newAlert && prevState.alert.props.id !== newAlert.props.id)
    ) {
      this.setState({ alert: newAlert });
      return;
    }
  }

  getAlert = () => {
    return alertRulesEngine(this.props.appState, {
      onClose: async () => {
        this.setState({ alert: await this.getAlert() });
      },
      dispatch: this.props.dispatch,
      pageSection: getPageSection(this.props.location.pathname),
    });
  };

  render() {
    const { badgeCount, route, page } = this.props;
    const { alert } = this.state;
    const tabContents = renderRoutes(route.routes);
    const pageSection = getPageSection(this.props.location.pathname);
    const isPageOwnerOptimisationOn: boolean = config.FEATURE.PAGE_OWNER_ADMIN;

    return (
      <>
        <FeatureToggle featureFlag={isPageOwnerOptimisationOn} isTrue={false}>
          <TabViewLinks>
            <RichUpdateNavLink
              path={`/${page.name}/update`}
              disabled={!canPostUpdates(page)}
              pageSection={pageSection}
            />
            <TabLink heading="Page view" path={`/${page.name}`} exact />
            {!isPendingApproval(this.props.page) && (
              <TabLink
                heading="Dashboard"
                path={`/${page.name}/dashboard`}
                badgeCount={badgeCount}
              />
            )}
            {!this.props.page.refundedDate && (
              <TabLink
                heading="Funds"
                path={
                  isEligibleForFlexiblePayments(this.props.page)
                    ? `/${page.name}/funds`
                    : `/${page.name}/funds/account`
                }
              />
            )}
            {this.shouldDisplayEditTab() && (
              <TabLink heading="Edit" path={`/${page.name}/edit`} exact />
            )}
            {!isPendingApproval(this.props.page) && (
              <TabLink
                heading="Page settings"
                path={`/${page.name}/settings`}
                exact
              />
            )}
            <TabShareButton
              isActive={isPageActive(page)}
              openSharePrompt={this.props.openSharePrompts}
              pageSection={pageSection}
            />
          </TabViewLinks>
        </FeatureToggle>
        <FeatureToggle featureFlag={isPageOwnerOptimisationOn} isTrue={true}>
          <PageOwnerNavbarContainer page={page} pageSection={pageSection} />
        </FeatureToggle>
        <TabViewContent>
          <SharePromptsContainer />
          {alert}
          {tabContents}
        </TabViewContent>

        <ShareWithFriendsPromptsContainer />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerPageView);
