import { AppState } from 'src/redux/modules';
import { rulesEngine, RuleResult } from 'src/helpers/rulesEngine';
import {
  AddYourBankDetails,
  TimeIsRunningOut,
  AddBankDetailsImmediately,
  PageFrozen,
  PageDeactivated,
} from 'src/components/Prompt';
import { TimeRemaining } from 'src/helpers/date';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';

export type CountdownRulesEngineOptions = PromptRulesEngineOptions & {
  falseSuspensionDate: string | Date;
  frozenDate: string | Date;
  pageName: string;
  refundDate: string | Date;
  timeRemainingFrozen: TimeRemaining;
  timeRemainingFalseSuspension: TimeRemaining;
};

const countdownToRefundingEngine = (
  appState: AppState,
  {
    activatePromptTransition,
    closePrompt,
    dispatch,
    pageName,
    pageSection,
    promptTransitionActive,
    falseSuspensionDate,
    frozenDate,
    refundDate,
    timeRemainingFrozen,
    timeRemainingFalseSuspension,
  }: CountdownRulesEngineOptions
): RuleResult<JSX.Element> | null => {
  try {
    const prompt = rulesEngine<JSX.Element, CountdownRulesEngineOptions>(
      [
        PageDeactivated,
        PageFrozen,
        AddBankDetailsImmediately,
        TimeIsRunningOut,
        AddYourBankDetails,
      ],
      appState,
      {
        activatePromptTransition,
        closePrompt,
        falseSuspensionDate,
        frozenDate,
        dispatch,
        pageName,
        pageSection,
        promptTransitionActive,
        refundDate,
        timeRemainingFrozen,
        timeRemainingFalseSuspension,
      }
    );

    if (prompt.matched && prompt.result) {
      return prompt;
    }

    return null;
  } catch (e) {
    return null;
  }
};

export default countdownToRefundingEngine;
