import React from 'react';
import styles from './MessengerShareButton.scss';
import CSSModules from 'react-css-modules';
import { ShareActionOptions } from 'src/redux/modules/page';
import MessengerShare from '../SocialShare/MessengerShare';

export interface Props {
  shareOnMessenger: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  isActive: boolean;
  targetCurrency: Currency;
  pageSection: string;
  ownerName: string;
}

const MessengerShareButton: React.StatelessComponent<Props> = ({
  pageSection,
  shareOnMessenger,
  socialShareUrl,
  isActive,
}) => {
  return (
    <div styleName="share-button-container">
      <MessengerShare
        shareOnMessenger={shareOnMessenger}
        socialShareUrl={socialShareUrl}
        isActive={isActive}
        location={pageSection}
        className={styles.messenger}
        buttonText="Messenger"
      />
    </div>
  );
};

export default CSSModules(MessengerShareButton, styles);
