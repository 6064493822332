import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from 'src/redux/modules';
import StickyHeaderDonor from 'src/components/StickyHeader/StickyHeaderDonor';
import { donate } from 'src/redux/modules/page';
import { isActive, isClosed, canPageReceiveDonations } from 'src/helpers/page';

interface StateProps {
  ownerName: string;
  ownerProfileImageUrl?: string;
  pageName: string;
  canPageReceiveDonations: boolean;
  disableShare: boolean;
}

interface DispatchProps {
  donate: () => void;
}

interface OwnProps {
  handleShareButtonClick: () => void;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    ownerName: state.page.ownerName,
    ownerProfileImageUrl: state.page.ownerProfileImageUrl,
    pageName: state.page.name,
    canPageReceiveDonations: canPageReceiveDonations(state.page),
    disableShare: !isActive(state.page) && !isClosed(state.page),
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      donate,
    },
    dispatch
  );
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(StickyHeaderDonor);
