import { PageState, ShareActionOptions } from 'src/redux/modules/page';

import CSSModules from 'react-css-modules';
import { CategoryResource } from 'src/helpers/api/categories';
import CharityEndorsement from 'src/components/CharityEndorsed/CharityEndorsement';
import { CharityState } from 'src/redux/modules/charity/reducer';
import CoverImage from 'src/components/Page/CoverImage';
import DonateButton from 'src/components/Page/DonateButton';
import DonationReminder from 'src/components/Reminders';
import LiveStream from 'src/components/LiveStream/LiveStream';
import Meta from 'src/components/Page/Meta';
import Owner from 'src/components/Page/Owner';
import PageSupportsRecurringDonations from 'src/components/RecurringDonations/PageSupportsRecurringDonations';
import Progress from 'src/components/Page/Progress';
import React from 'react';
import RecurringPaymentsButtons from 'src/components/Page/RecurringPaymentsButtons/';
import ReminderModal from 'src/components/ReminderModal/ReminderModal';
import ShareButton from 'src/components/ShareModal/ShareButton';
import SupportersAvatar from 'src/components/SupportersAvatar/SupportersAvatar';
import { SupportersAvatarState } from 'src/redux/modules/supportersAvatar';
import constructSupportersList from 'src/components/SupportersAvatar/constructSupportersList';
import styles from 'src/components/Page/PageHeader/PageHeader.scss';

export interface Props {
  categories: CategoryResource[];
  charity: CharityState;
  isActive: boolean;
  isClosed: boolean;
  canPageReceiveDonations: boolean;
  page: PageState;
  raised: number;
  totalCountSupporters: number;
  supportersAvatar: SupportersAvatarState;
  profileImage?: string;
  recaptcha: string;
  location?: string;
  goEdit: (section: string) => void;
  donate: () => void;
  loadSupportersAvatar: (details: { pageId: string; size: number }) => void;
  clearRecaptcha: () => void;
  contactOwner: (data: any) => void;
  supportsRecurringDonations: boolean;
  sharePageLink: (options: ShareActionOptions) => void;
  handleShareButtonClick: () => void;
  isDonationRemindLaterAvailable: boolean;
  handleReminderModalToggle: (isOpen: boolean) => void;
}

export interface State {
  isReminderModalOpen: boolean;
}

class PageHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isReminderModalOpen: false,
    };
  }

  componentWillMount() {
    const {
      isClosed,
      loadSupportersAvatar,
      page: { id },
    } = this.props;
    const loadDetails = {
      pageId: id,
      size: 15,
    };

    if (isClosed) {
      loadSupportersAvatar(loadDetails);
    }
  }

  donate = () => {
    const { donate, canPageReceiveDonations } = this.props;

    if (canPageReceiveDonations) {
      donate();
    }
  };

  renderDonate(id: string) {
    const {
      isClosed,
      canPageReceiveDonations,
      page: { name },
    } = this.props;

    if (isClosed) {
      return null;
    }

    return (
      <DonateButton
        donate={this.donate}
        isActive={canPageReceiveDonations}
        name={name}
        pageSection="page > top"
        id={id}
      />
    );
  }

  renderSupporters() {
    const {
      isClosed,
      supportersAvatar: { items },
    } = this.props;

    if (!isClosed && items.length < 1) {
      return null;
    }

    const supportersList = constructSupportersList(items);

    if (supportersList) {
      return <SupportersAvatar supportersList={supportersList} />;
    }

    return null;
  }

  toggleReminderModal(isOpen: boolean) {
    this.setState({ isReminderModalOpen: isOpen });
    this.props.handleReminderModalToggle(isOpen);
  }

  renderBeMyFirstDonorProgress() {
    const {
      profileImage,
      page: { ownerName },
    } = this.props;

    const defaultMessage =
      'Be the first to help {ownerName} reach their {target} target';

    const additionalProps = {
      shouldShowProfileImage: true,
      profileImage,
      ownerName,
    };

    return this.renderProgess(defaultMessage, additionalProps);
  }

  renderDefaultProgress() {
    const defaultMessage = 'raised of {target} target by {supporters}';

    return this.renderProgess(defaultMessage);
  }

  renderProgess(defaultMessage: string, additionalProps?: any) {
    const {
      raised,
      page: { targetAmount, targetCurrency },
      totalCountSupporters,
    } = this.props;

    return (
      <Progress
        raised={raised}
        targetAmount={targetAmount}
        targetCurrency={targetCurrency}
        totalCountSupporters={totalCountSupporters}
        defaultMessage={defaultMessage}
        {...additionalProps}
      />
    );
  }

  renderShareButton() {
    if (!this.props.isActive && !this.props.isClosed) {
      return (
        <div styleName="share-unavailable">
          <span>This page isn’t active yet.</span>
        </div>
      );
    }

    return (
      <div className="jg-space-mtsm">
        <ShareButton handleShareClick={this.props.handleShareButtonClick} />
      </div>
    );
  }

  render() {
    const {
      categories,
      charity,
      clearRecaptcha,
      contactOwner,
      goEdit,
      isClosed,
      location,
      page: {
        categoryId,
        finishedAt,
        id,
        initialImage,
        isOwnerRequested,
        name,
        ownerBio,
        ownerName,
        pitchFor,
        recurringDonationsPitch,
        status,
        targetAmount,
        targetCurrency,
        socialShareUrl,
      },
      profileImage,
      raised,
      recaptcha,
      supportsRecurringDonations,
      isActive,
      totalCountSupporters,
      canPageReceiveDonations,
    } = this.props;

    return (
      <div styleName="container">
        <div styleName="cover-image-container">
          <CoverImage coverImage={initialImage} />
          <LiveStream pageId={id} />
        </div>
        <div styleName="progress-donate-container">
          {totalCountSupporters === 0 && canPageReceiveDonations
            ? this.renderBeMyFirstDonorProgress()
            : this.renderDefaultProgress()}
          <div
            styleName="actions"
            className="jg-space-ptms jg-space-pbsm jg-space-phlg@xl jg-space-prml"
          >
            <div className="jg-display-b">
              {this.renderDonate(id)}
              {supportsRecurringDonations && (
                <div className="jg-space-mtsm jg-space-mbmd jg-text--center">
                  <RecurringPaymentsButtons
                    donate={this.donate}
                    name={name}
                    pageSection="page > top"
                  />
                </div>
              )}
            </div>
            {this.renderSupporters()}
            {this.renderShareButton()}
          </div>
        </div>
        <div styleName="owner-meta-container">
          <div styleName="owner">
            <Owner
              clearRecaptcha={clearRecaptcha}
              contactOwner={contactOwner}
              id={id}
              isOwner={isOwnerRequested}
              name={name}
              ownerBio={ownerBio}
              ownerName={ownerName}
              pitchFor={pitchFor}
              profileImage={profileImage}
              recaptcha={recaptcha}
              targetAmount={targetAmount}
              targetCurrency={targetCurrency}
              isClosed={isClosed}
              raised={raised}
            />
          </div>

          <div styleName="meta">
            <Meta
              categories={categories}
              categoryId={categoryId}
              goEdit={goEdit}
              isOwner={isOwnerRequested}
              location={location}
              finishedAt={finishedAt}
              status={status}
              supportsRecurringDonations={supportsRecurringDonations}
            />
          </div>

          {charity && <CharityEndorsement charity={charity} />}
        </div>
        {supportsRecurringDonations && (
          <div
            className={`jg-space-mtsm jg-space-pbsm jg-text--center jg-bdb jg-bd--solid jg-bd--very-light-grey ${
              styles['recurring-donations']
            }`}
          >
            <RecurringPaymentsButtons
              donate={this.donate}
              name={name}
              pageSection="page > top"
            />
          </div>
        )}
        {supportsRecurringDonations && (
          <div className={`${styles['bottom-right']} jg-space-pmd`}>
            <PageSupportsRecurringDonations
              isOwnerRequested={isOwnerRequested}
              recurringDonationsPitch={recurringDonationsPitch}
            />
          </div>
        )}
        <DonationReminder
          pageSection="page > header"
          handleClick={() => this.toggleReminderModal(true)}
        />
        <ReminderModal
          handleModalClose={() => this.toggleReminderModal(false)}
          showModal={this.state.isReminderModalOpen}
          pageSection="page > header"
          isActive={isActive}
          socialShareUrl={socialShareUrl}
          pitchFor={pitchFor}
          targetAmount={targetAmount}
          targetCurrency={targetCurrency}
          ownerName={ownerName}
        />
      </div>
    );
  }
}

export default CSSModules(PageHeader, styles);
