import parseurl from 'url';
import { ParsedUrlQuery } from 'querystring';

export function addHttp(videoURL: string) {
  return `https://${videoURL}`;
}

export function getVideoId(fullVideoUrl: string): string | undefined {
  let url = fullVideoUrl;
  let urlParsed = parseurl.parse(fullVideoUrl);
  if (!(urlParsed.protocol === 'http:' || urlParsed.protocol === 'https:')) {
    url = addHttp(fullVideoUrl);
    urlParsed = parseurl.parse(url);
  }
  const urlHost = urlParsed.host;

  if (urlHost!.indexOf('youtube.com') > -1) {
    const parsedUrl = (parseurl.parse(url, true).query as ParsedUrlQuery).v;
    return parsedUrl as string;
  }
  if (urlParsed.host === 'youtu.be') {
    const urlPath = parseurl.parse(url).pathname;
    const parsedUrl = urlPath!.slice(1);
    return parsedUrl;
  }

  return undefined;
}

export function constructYouTubeVideoUrl(videoId: string) {
  return `http://www.youtube.com/v/${videoId}`;
}

export function constructYouTubeThumbnailUrl(videoId: string) {
  return `http://img.youtube.com/vi/${videoId}/0.jpg`;
}
