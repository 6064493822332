import { getSupporters, SupportersResponse } from 'src/helpers/api/activities';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';

interface SupportersAvatarRequestAction {
  type: 'SUPPORTERS_AVATAR_REQUEST';
}

interface SupportersAvatarsSuccessAction {
  type: 'SUPPORTERS_AVATAR_SUCCESS';
  payload: SupportersResponse;
}

interface SupportersAvatarsFailureAction {
  type: 'SUPPORTERS_AVATAR_FAILURE';
  error: true;
  payload: Error;
}

export function loadSupportersAvatar({
  pageId,
  size,
}: {
  pageId: string;
  size: number;
}) {
  return async (dispatch: Dispatch<AppState>) => {
    try {
      dispatch<SupportersAvatarRequestAction>({
        type: 'SUPPORTERS_AVATAR_REQUEST',
      });

      const data = await getSupporters(pageId, 1, size);

      dispatch<SupportersAvatarsSuccessAction>({
        type: 'SUPPORTERS_AVATAR_SUCCESS',
        payload: data,
      });
    } catch (error) {
      dispatch<SupportersAvatarsFailureAction>({
        type: 'SUPPORTERS_AVATAR_FAILURE',
        error: true,
        payload: error,
      });
    }
  };
}

export interface SupportersAvatarState {
  items: { imageProfile: string }[];
  isLoading?: boolean;
}

export default function reducer(
  state: SupportersAvatarState = {
    items: [],
  },
  action:
    | SupportersAvatarRequestAction
    | SupportersAvatarsSuccessAction
    | SupportersAvatarsFailureAction
): SupportersAvatarState {
  switch (action.type) {
    case 'SUPPORTERS_AVATAR_REQUEST':
      return { ...state, isLoading: true };

    case 'SUPPORTERS_AVATAR_SUCCESS':
      return {
        ...state,
        items: action.payload.activities,
        isLoading: false,
      };

    case 'SUPPORTERS_AVATAR_FAILURE':
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
