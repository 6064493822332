import reducer from './reducer';

export default reducer;
// updating prettier to latest gives tremendous amount of errors
// moved to a separate PR
export {
  type FundsState,  // tslint:disable-line
  type FundsStatus, // tslint:disable-line
  type Withdrawal, // tslint:disable-line
  type WithdrawalStatus, // tslint:disable-line
} from './reducer';
export { getFundsData, closeWithdrawalSuccessfulAlert } from './actions';
