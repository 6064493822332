import { TranslationFunction } from 'i18next';
import validate from './validate';
import { Rules } from '.';
import { WithI18n } from 'react-i18next';

type RuleFunction<T> = (t: TranslationFunction) => Rules<T>;

export default function createValidator<T>(rules: Rules<T>) {
  return (values: RecursivePartial<T>) => validate(rules, values);
}

export function createValidatorWithTranslations<T>(rules: RuleFunction<T>) {
  return (values: RecursivePartial<T>, props: Pick<WithI18n, 't'>) => {
    return validate(rules(props.t), values);
  };
}
