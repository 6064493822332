import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'src/redux/modules';
import { UpdateResource } from 'src/helpers/api/updates';
import { AnalyticsPageView } from 'src/components/Analytics';
import Modal from 'src/components/Modal';
import styles from './UpdateModalView.scss';
import RichUpdatesItemMedia from 'src/components/RichUpdates/RichUpdatesItem/RichUpdatesItemMedia';
import { watchingVideoToggle } from 'src/redux/modules/richUpdates';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { RichUpdatesState } from 'src/redux/modules/richUpdates/reducer';
import CSSModules from 'react-css-modules';
import { OwnerAvatar } from 'src/components/Avatar';
import { distanceInWordsToNow } from 'src/helpers/date';

interface StateProps {
  update: UpdateResource;
  pageName: string;
  richUpdatesState: RichUpdatesState;
  ownerProfileImageUrl?: string;
  ownerName: string;
}

interface DispatchProps {
  watchingVideoToggle: () => void;
  push: (location: any) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      watchingVideoToggle,
      push,
    },
    dispatch
  );
}

const mapStateToProps = (state: AppState): StateProps => ({
  update: state.richUpdates.linkedUpdate,
  pageName: state.page.name,
  richUpdatesState: state.richUpdates,
  ownerProfileImageUrl: state.page.ownerProfileImageUrl,
  ownerName: state.page.ownerName,
});

type Props = StateProps & DispatchProps;

class UpdateModalView extends React.Component<Props> {
  render() {
    const {
      update,
      pageName,
      richUpdatesState,
      ownerProfileImageUrl,
      ownerName,
    } = this.props;

    const updateModalNotRequired =
      !update ||
      update.id === 0 ||
      richUpdatesState.allUpdateIds.find(id => id === update.id);

    if (updateModalNotRequired) {
      return null;
    }

    const onModalClose = () => {
      update.id = 0;
      richUpdatesState.scrollTo = true;
      this.props.push(`/${pageName}`);
    };

    const noMedia = !update.media || update.media.length === 0;
    const reportDate = distanceInWordsToNow(update.createdAt!);

    const displayMedia = () => {
      const { media } = update;

      if (noMedia) {
        return null;
      }
      return (
        <RichUpdatesItemMedia
          type={media[0].type}
          path={media[0].path}
          watchingVideoToggle={this.props.watchingVideoToggle}
        />
      );
    };

    return (
      <AnalyticsPageView
        eventValue="update"
        pageSection="page > update"
        subtype="cfp"
      >
        <Modal
          isOpen
          onClose={onModalClose}
          colorTheme="dark"
          closeAnalyticsTracking={{
            event: 'link click',
            subtype: 'button',
            eventValue: 'x',
            pageSection: 'page > update',
          }}
        >
          <div className="update-details-avatar">
            <OwnerAvatar
              src={ownerProfileImageUrl}
              alt={ownerName}
              className={styles.avatar}
            />
            <div>
              <p className="jg-h5 update-details-owner-name">{ownerName}</p>
              <span className="jg-text--xsmall-light">{reportDate}</span>
            </div>
          </div>
          <div className="update-details">
            <div className="update-details-content">
              {update.type !== 1 && displayMedia()}

              <p
                className={`update-details-text ${
                  update.type !== 1
                    ? 'update-details-text-bottom'
                    : noMedia
                      ? 'update-details-text-only'
                      : ''
                }`}
              >
                {update.message}
              </p>

              {update.type === 1 && displayMedia()}
            </div>
          </div>
        </Modal>
      </AnalyticsPageView>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CSSModules(UpdateModalView, styles));
