import React from 'react';
import { Link } from 'react-router-dom';
import { Route } from 'react-router';
import classNames from 'classnames';
import styles from './TabView.scss';

interface Props {
  heading: string;
  path: string;
  badgeCount?: number;
  exact?: boolean;
  strict?: boolean;
}

const TabLink: React.StatelessComponent<Props> = ({
  badgeCount,
  path,
  heading,
  exact = false,
  strict = false,
}) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    children={({ match }) => (
      <li className={classNames(styles.item, match && styles.active)}>
        {!!badgeCount &&
          badgeCount > 0 && (
            <span className={`${styles.badge} jg-text--small`}>
              {badgeCount}
            </span>
          )}
        <Link to={path}>{heading}</Link>
      </li>
    )}
  />
);

export default TabLink;
