import urlJoin from 'url-join';
import superagent from 'superagent';
import clientConfig from 'config';
import { createTimer } from 'src/helpers/time';
import stats from 'src/helpers/stats';
import { getAuth, refreshAccessToken } from 'src/helpers/auth';

export interface MakeRequestOptions {
  autoRefreshAccessToken?: boolean;
  baseUri?: string;
  path?: string;
  query?: string;
  data?: any;
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
  throwOnError?: boolean;
  withCredentials?: boolean;
  contentType?: string;
  accept?: string;
  clientSideTimeout?: number;
  serverSideTimeout?: number;
  timingName?: string;
  header?: { [key: string]: string };
  userAgent?: string;
  cookies?: { [key: string]: string };
  withBearerAuthHeader?: boolean;
}

export async function makeRequest({
  autoRefreshAccessToken = true,
  baseUri = clientConfig.CROWDFUNDING_API_URI,
  path = '',
  query,
  data,
  method = 'get',
  throwOnError = true,
  withCredentials = true,
  contentType,
  accept,
  clientSideTimeout,
  serverSideTimeout,
  timingName,
  header,
  userAgent,
  cookies,
  withBearerAuthHeader,
}: MakeRequestOptions = {}): Promise<superagent.Response> {
  if (autoRefreshAccessToken) {
    await refreshAccessToken();
  }

  return new Promise<superagent.Response>((resolve, reject) => {
    const request = superagent[method](urlJoin(baseUri, path));

    if (query) {
      request.query(query);
    }

    if (data) {
      request.send(data);
    }

    if (__SERVER__) {
      prepareServerSideRequest(request, {
        timeout: serverSideTimeout,
        forcedUserAgent: userAgent,
        forcedCookies: cookies,
      });
    }

    if (__CLIENT__) {
      if (clientSideTimeout) {
        request.timeout(clientSideTimeout);
      }
    }

    if (withCredentials) {
      request.withCredentials();
    }

    if (contentType) {
      request.type(contentType);
    }

    if (header) {
      request.set(header);
    }

    if (withBearerAuthHeader) {
      const auth = getAuth();
      const authCookie = auth.getParsedAuthCookie();

      if (authCookie) {
        request.set('Authorization', `Bearer ${authCookie.accessToken}`);
      }
    }

    if (accept) {
      request.accept(accept);
    }

    const timer = __SERVER__ && timingName ? createTimer() : null;

    request.end((err, response) => {
      if (timer) {
        stats.timing(`apiRequests.${timingName}`, timer());
      }

      if (err) {
        if (throwOnError || !err.response) {
          reject(new ApiError(err));
        } else {
          resolve(err.response);
        }
      } else {
        resolve(response);
      }
    });
  });
}

export async function get<T>(path: string, options?: MakeRequestOptions) {
  const response = await makeRequest({
    path,
    method: 'get',
    throwOnError: false,
    ...options,
  });

  if (response.status === 404) {
    return null;
  }

  if (!response.ok) {
    throw new ApiError({ response });
  }

  return response.body as T;
}

export async function getOrThrow<T>(
  path: string,
  options?: MakeRequestOptions
) {
  const response = await makeRequest({
    path,
    method: 'get',
    throwOnError: false,
    ...options,
  });

  if (!response.ok) {
    throw new ApiError({ response });
  }

  return response.body as T;
}

export function post(path: string, data: any, options?: MakeRequestOptions) {
  return makeRequest({
    path,
    data: data ? JSON.stringify(data) : null,
    method: 'post',
    contentType: 'application/json',
    ...options,
  });
}

export function postRaw(path: string, data: any, options?: MakeRequestOptions) {
  return makeRequest({
    path,
    data,
    method: 'post',
    ...options,
  });
}

export function patch(path: string, data: any, options?: MakeRequestOptions) {
  return makeRequest({
    path,
    data: data ? JSON.stringify(data) : null,
    method: 'patch',
    contentType: 'application/json',
    ...options,
  });
}

export function put(path: string, data: any, options?: MakeRequestOptions) {
  return makeRequest({
    path,
    data: data ? JSON.stringify(data) : null,
    method: 'put',
    contentType: 'application/json',
    ...options,
  });
}

export function putRaw(path: string, data: any, options?: MakeRequestOptions) {
  return makeRequest({
    path,
    data,
    method: 'put',
    ...options,
  });
}

export function del(path: string, options?: MakeRequestOptions) {
  return makeRequest({ path, method: 'delete', ...options });
}

export class ApiError extends Error {
  response: superagent.Response | undefined;

  constructor({
    message,
    stack,
    response,
  }: {
    message?: string;
    stack?: string;
    response?: superagent.Response;
  }) {
    super(
      message ||
        `Request failed with status: ${response ? response.status : '???'}.`
    );

    this.name = 'ApiError';
    this.response = response;

    if (stack) {
      this.stack = stack;
    }
  }
}

function prepareServerSideRequest(
  request: superagent.Request,
  {
    timeout,
    forcedUserAgent,
    forcedCookies,
  }: {
    timeout?: number;
    forcedUserAgent?: string;
    forcedCookies?: { [key: string]: string };
  }
) {
  // prevent server config and requestStorage modules from leaking into webpack build
  if (__SERVER__) {
    const requestStorage = require('../../server/requestStorage').default;
    const serverConfig = require('../../../config/server.config');

    const userAgent = forcedUserAgent || requestStorage.get('userAgent');

    if (userAgent) {
      request.set('User-Agent', userAgent);
    }

    const cookies = forcedCookies || requestStorage.get('cookies');

    if (cookies) {
      const passedThruCookies = new Set<string>(serverConfig.passedThruCookies);
      request.set(
        'cookie',
        createCookieHeaderValue(cookies, passedThruCookies)
      );
    }

    request.timeout(timeout || serverConfig.apiRequestTimeoutMs);
  }
}

function createCookieHeaderValue(
  cookies: { [key: string]: string },
  passedThruCookies: Set<string>
) {
  const cookie = Object.keys(cookies || {})
    .filter(x => passedThruCookies.has(x))
    .map(x => `${x}=${cookies[x]}`)
    .join(';');

  return cookie;
}
