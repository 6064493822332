interface BeginLoad {
  type: '@jg/AsyncConnectBeginLoad';
  payload: string;
}

interface EndLoad {
  type: '@jg/AsyncConnectEndLoad';
  payload: string;
}

export type AsyncConnectActions = BeginLoad | EndLoad;

export const beginLoad = (id: string): BeginLoad => ({
  type: '@jg/AsyncConnectBeginLoad',
  payload: id,
});

export const endLoad = (id: string): EndLoad => ({
  type: '@jg/AsyncConnectEndLoad',
  payload: id,
});
