import log from 'src/logging';

export default () => next => action => {
  if (action.error) {
    // exclude 404 errors for pages, as these are indicated by the response
    // status and logged at the fleet level
    if (
      action.type === 'PAGE_FAILURE' &&
      action.payload &&
      (action.payload.type === '404' || action.payload.type === '302')
    ) {
      log.debug({ err: action.payload }, action.type);
      return next(action);
    }

    log.error({ err: action.payload }, action.type);
  } else {
    log.debug(action, action.type);
  }

  return next(action);
};
