import React from 'react';
import { formatCurrency, FormatCurrencyOptions } from 'src/helpers/formatter';

interface Props extends FormatCurrencyOptions {
  value: number;
  // TODO:
  // Two issues with the TS definition for Stateless components here:
  // - children property is defined as ReactNode | undefined, which does not allow functions, it has be redefined here
  // - the return type for the component is ReactElement<any> | null, which doesn't allow strings, which means the function
  //   we define for children property type here can't return a string and has to return any instead.
  //   There an issue for this here, revist once fixed: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/23422
  children?: (formattedNumber: string) => any;
}

const FormattedCurrency: React.StatelessComponent<Props> = ({
  value,
  children,
  ...options
}) => {
  const formattedNumber = formatCurrency(value, options);

  if (typeof children === 'function') {
    return children(formattedNumber);
  }

  return formattedNumber;
};

export default FormattedCurrency;
