import React from 'react';
import ClickableElement from 'src/components/ClickableElement';
import { Trans } from 'react-i18next';

interface Props {
  pageSection: string;
  openShareWithFriendsPrompt: () => void;
}

const ShareWithFriendsButton: React.StatelessComponent<Props> = ({
  pageSection,
  openShareWithFriendsPrompt,
}) => (
  <ClickableElement
    onClick={openShareWithFriendsPrompt}
    className="jg-btn jg-space-mrms jg-text--large"
    inline
    analyticsOptions={{
      event: 'click',
      subtype: 'button',
      eventValue: 'share with friends',
      pageSection: `${pageSection}`,
    }}
  >
    <Trans i18nKey="ShareWithFriendsButton.text">Share with friends</Trans>
  </ClickableElement>
);

export default ShareWithFriendsButton;
