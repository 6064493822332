import { get, post } from './common';
import { Withdrawal, FundsStatus } from 'src/redux/modules/funds';

export interface FundsResponse {
  amountAvailable: number;
  status: FundsStatus;
  withdrawalAvailableDate: string;
  withdrawals: Withdrawal[];
}

export function getFundsInfo(pageId: string): Promise<FundsResponse | null> {
  return get(`projects/${pageId}/funds`, { timingName: 'funds' });
}

export function requestWithdrawal(pageId: string, GrossAmount: number) {
  return post(`projects/${pageId}/funds/withdraw`, { GrossAmount });
}
