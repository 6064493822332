import superagent from 'superagent';
import { postRaw, post } from './common';

export interface FilePayload {
  url: string;
}

export interface UploadSuccessResult {
  success: true;
  data: {
    imageName: string;
  };
}

export interface UploadCoverSuccessResult {
  success: true;
  data: {
    imageName: string;
    socialShareImageName: string;
  };
}

export interface UploadFailureResult {
  success: false;
  message: string;
}

export type UploadResult = UploadSuccessResult | UploadFailureResult;
export type UploadCoverResult = UploadCoverSuccessResult | UploadFailureResult;

export async function uploadImage(
  file: Blob | File | FilePayload
): Promise<UploadResult> {
  const response = isFilePayload(file)
    ? await uploadFilePayload('images', file)
    : await uploadRaw('images', file);

  if (response.ok) {
    return { success: true, data: response.body };
  }

  return getUploadFailureResult(response);
}

export async function uploadCoverImage(file: Blob): Promise<UploadCoverResult> {
  const response = await uploadRaw('images/cover', file);

  if (response.ok) {
    return { success: true, data: response.body };
  }

  return getUploadFailureResult(response);
}

function getUploadFailureResult(
  response: superagent.Response
): UploadFailureResult {
  if (response.status === 415) {
    return {
      success: false,
      message:
        'This is an unsupported image. Please upload an image as a JPEG or PNG.',
    };
  }

  return { success: false, message: response.body.message };
}

function uploadFilePayload(path: string, file: FilePayload) {
  return post(path, file, { throwOnError: false });
}

function uploadRaw(path: string, file: Blob | File) {
  const body = new FormData();
  body.append('file', file);
  return postRaw(path, body, { throwOnError: false });
}

function isFilePayload(file: Blob | FilePayload): file is FilePayload {
  return !!(file as FilePayload).url;
}
