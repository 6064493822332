import React from 'react';
import CSSModules from 'react-css-modules';
import { Link } from 'src/components/Analytics';
import Svg from '@justgiving/svg';
import styles from './RichUpdatesCreatorLink.scss';

interface Props {
  path: string;
  type: string;
  title: string;
  icon: string;
  exact?: boolean;
}

const RichUpdatesCreatorLink: React.StatelessComponent<Props> = ({
  path,
  type,
  title,
  icon,
  exact = false,
}) => (
  <Link
    to={path}
    className={styles[type]}
    isIndexLink={exact}
    analyticsOptions={{
      event: 'link click',
      eventValue: type,
      pageSection: 'admin > page view > updates',
      subtype: 'button',
    }}
  >
    <div className={`${styles.col}`}>
      <div
        className={`jg-space-mbxs ${styles['icon-container']} ${styles[type]}`}
      >
        <Svg markup={icon} className={`jg-icon ${styles.icon}`} />
      </div>
      <span className={`${styles.title} jg-space-mbn`}>{title}</span>
    </div>
  </Link>
);

export default CSSModules(RichUpdatesCreatorLink, styles);
