import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './RichUpdatesPlaceholder.scss';

interface Props {
  title: string;
  subtitle?: string;
}

const RichUpdatesPlaceholder: React.StatelessComponent<Props> = ({
  title,
  subtitle,
}) => (
  <div className={`jg-space-plg ${styles.container}`}>
    <div className={styles.image} />
    <h3>{title}</h3>
    {subtitle && <p className="jg-space-mtxs">{subtitle}</p>}
  </div>
);

export default CSSModules(RichUpdatesPlaceholder, styles);
