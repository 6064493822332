import { CropData, cropImage } from 'src/helpers/image';
import { UploadFailureResult, uploadCoverImage } from 'src/helpers/api/images';

import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import config from 'config';
import { patch } from 'src/helpers/api/pages';
import { push } from 'react-router-redux';

interface SubmitCoverRequestAction {
  type: 'SUBMIT_COVER_REQUEST';
}

interface SubmitCoverSuccessAction {
  type: 'SUBMIT_COVER_SUCCESS';
}

interface SubmitCoverFailureAction {
  type: 'SUBMIT_COVER_FAILURE';
}

type SubmitCoverAction =
  | SubmitCoverRequestAction
  | SubmitCoverSuccessAction
  | SubmitCoverFailureAction;

export function submitCover(
  pageName: string,
  image: string,
  cropData: CropData,
  zoomAdjusted: boolean = false
) {
  return async (dispatch: Dispatch<AppState>) => {
    dispatch({
      type: 'SUBMIT_COVER_REQUEST',
      meta: {
        analytics: {
          event: 'link click',
          subtype: 'button',
          event_value: 'save and continue',
          page_section: 'page creation > add cover photo',
          activity_type: 'crowdfunding_guid',
          actor_id: zoomAdjusted ? 1 : 0,
          actor_sources: 'cover photo zoom adjusted',
        },
      },
    });

    try {
      const croppedImage = await cropImage({ image, ...cropData });

      const response = await uploadCoverImage(croppedImage);

      if (!response.success) {
        dispatch({
          type: 'SUBMIT_COVER_FAILURE',
          error: true,
          payload: { message: (response as UploadFailureResult).message },
        });
        return;
      }

      await patch({
        name: pageName,
        initialImage: { name: response.data.imageName },
        socialShareImage: { name: response.data.socialShareImageName },
      });

      dispatch({
        type: 'UPDATE_SUCCESS',
        payload: {
          initialImage: { name: response.data.imageName },
          socialShareImage: { name: response.data.socialShareImageName },
        },
      });
      dispatch({ type: 'SUBMIT_COVER_SUCCESS' });
      dispatch(push(`/${pageName}/story`));
    } catch (error) {
      dispatch({ type: 'SUBMIT_COVER_FAILURE', error: true, payload: error });
    }
  };
}

export function resetCover(pageName: string) {
  return async (dispatch: Dispatch<AppState>) => {
    dispatch({
      type: 'SUBMIT_COVER_REQUEST',
      meta: {
        analytics: {
          event: 'click',
          subtype: 'button',
          event_value: 'save and continue',
          page_section: 'page creation > bottom',
          activity_type: 'crowdfunding_guid',
        },
      },
    });

    try {
      await patch({
        name: pageName,
        initialImage: { name: config.DEFAULT_IMAGE_NAME },
        socialShareImage: { name: config.DEFAULT_IMAGE_NAME },
      });

      dispatch({ type: 'SUBMIT_COVER_SUCCESS' });
      dispatch({
        type: 'UPDATE_SUCCESS',
        payload: {
          initialImage: { name: config.DEFAULT_IMAGE_NAME },
          socialShareImage: { name: config.DEFAULT_IMAGE_NAME },
        },
      });
      dispatch(push(`/${pageName}/story`));
    } catch (error) {
      dispatch({ type: 'SUBMIT_COVER_FAILURE', error: true, payload: error });
    }
  };
}

export interface CoverPhotoState {
  isRequesting: boolean;
  hasError: boolean;
}

const initialState: CoverPhotoState = {
  isRequesting: false,
  hasError: false,
};

export default function reducer(
  state = initialState,
  action: SubmitCoverAction
) {
  switch (action.type) {
    case 'SUBMIT_COVER_REQUEST':
      return {
        ...state,
        isRequesting: true,
        hasError: false,
      };
    case 'SUBMIT_COVER_SUCCESS':
      return {
        ...state,
        isRequesting: false,
        hasError: false,
      };
    case 'SUBMIT_COVER_FAILURE':
      return {
        ...state,
        isRequesting: false,
        hasError: true,
      };
    default:
      return state;
  }
}
