import { getOrThrow } from 'src/helpers/api/common';

export interface CharityResponse {
  id: number;
  name: string;
  logo: string;
  description: string;
  externalUrl: string;
  registrationNumber: string;
  causeId: string;
}

export const fetchCharityInfo = (charityId: number) =>
  getOrThrow<CharityResponse>(`charities/${charityId}`);
