import React from 'react';
import styles from './SmsShareButton.scss';
import CSSModules from 'react-css-modules';
import { ShareActionOptions } from 'src/redux/modules/page';
import SmsShare from '../SocialShare/SmsShare';

export interface Props {
  shareBySms: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  isActive: boolean;
  isOwner: boolean;
  targetCurrency: Currency;
  pageSection: string;
  ownerName: string;
}

const SmsShareButton: React.StatelessComponent<Props> = ({
  shareBySms,
  pageSection,
  socialShareUrl,
  targetAmount,
  pitchFor,
  isActive,
  isOwner,
  targetCurrency,
  ownerName,
}) => {
  return (
    <div styleName="share-button-container">
      <SmsShare
        shareBySms={shareBySms}
        socialShareUrl={socialShareUrl}
        targetAmount={targetAmount}
        pitchFor={pitchFor}
        ownerName={ownerName}
        isOwner={isOwner}
        isActive={isActive}
        location={pageSection}
        buttonText="Text"
        className={styles.sms}
        targetCurrency={targetCurrency}
      />
    </div>
  );
};

export default CSSModules(SmsShareButton, styles);
