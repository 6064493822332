import React from 'react';
import AnimateHeight from 'react-animate-height';

export interface CollapseBodyProps {
  isActive: boolean;
  togglePanel: () => void;
}

interface OwnProps {
  animationDuration?: number;
}

class CollapseBody extends React.Component<
  CollapseBodyProps &
    OwnProps & {
      children: (props: CollapseBodyProps) => JSX.Element;
    }
> {
  static defaultProps = {
    animationDuration: 500,
  };

  render() {
    const { animationDuration, children, ...props } = this.props;
    const height = !props.isActive ? 0 : 'auto';
    return (
      <AnimateHeight duration={animationDuration} height={height}>
        {children({ ...props })}
      </AnimateHeight>
    );
  }
}

export default CollapseBody as React.ComponentClass<OwnProps>;
