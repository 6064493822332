import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'src/components/Analytics';
import deactivated from 'src/components/Prompt/CountdownToRefundingFlow/svg/deactivated-il.svg';
import Svg from '@justgiving/svg';

const DonationsRefundedContent: React.StatelessComponent = () => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-8 offset-md-2">
        <Svg markup={deactivated} className="jg-svg-sm" />
        <h2>
          <FormattedMessage
            id="DonationsRefundedContent.pageDeactivated"
            defaultMessage="Your Page has been deactivated"
          />
        </h2>
        <p className="jg-space-mbmd">
          <FormattedMessage
            id="DonationsRefundedContent.noMoreDonations"
            defaultMessage="Your bank details weren't added in time, so your page has now been deactivated. Your donations have been refunded."
          />
        </p>
        <Link
          href="https://help.justgiving.com/hc/en-us/requests/new?ticket_form_id=137829"
          target="_blank"
          rel="noreferrer noopener"
          analyticsOptions={{
            event: 'link click',
            eventValue: 'need help',
            pageSection: 'admin > page view > prompt > donations refunded',
            subtype: 'link',
          }}
        >
          <FormattedMessage
            id="DonationsRefundedContent.needHelp"
            defaultMessage="Need help? Contact our Customer Support Team"
          />
        </Link>
      </div>
    </div>
  );
};

export default DonationsRefundedContent;
