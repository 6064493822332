import React from 'react';
import ClickableElement from 'src/components/ClickableElement';
import styles from './Modal.scss';
import CSSModules from 'react-css-modules';
import {
  analyticsClick as analyticsClickActionCreator,
  AnalyticsClick,
  ClickAnalyticsOptions,
} from 'src/redux/modules/analytics';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

interface OwnProps {
  colorTheme?: 'light' | 'dark';
  contentLabel?: string;
  isOpen?: boolean;
  onClose?: () => void;
  shouldCloseOnOverlayClick?: boolean;
  closeAnalyticsTracking?: ClickAnalyticsOptions;
  noBackground?: boolean;
  scrollToTop?: boolean;
  scrollToTopOnClose?: boolean;
  maintainPosition?: boolean;
  large?: boolean;
  classNames?: string;
  onOverlayClick?: (event: React.MouseEvent) => void;
  onModalClick?: (event: React.MouseEvent) => void;
}

interface DispatchProps {
  analyticsClick: AnalyticsClick;
}

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      analyticsClick: analyticsClickActionCreator as AnalyticsClick,
    },
    dispatch
  );

class ModalInner extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleKeyUp = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      this.handleModalClose();
    }
  };

  handleModalClose = () => {
    const { onClose, analyticsClick, closeAnalyticsTracking } = this.props;
    if (onClose) {
      onClose();
      if (closeAnalyticsTracking) {
        analyticsClick(closeAnalyticsTracking);
      }
    }
  };

  handleClickOutsideModal = () => {
    if (this.props.isOpen) {
      this.handleModalClose();
    }
  };

  preventBodyToScroll = (e: TouchEvent) => {
    e.stopPropagation();
  };

  componentDidMount() {
    const { scrollToTop } = this.props;

    document.body.classList.add(
      this.props.maintainPosition ? 'no-scroll' : 'body-fixed'
    );

    if (scrollToTop) {
      window.scrollTo(0, 0);
      return;
    }

    document.addEventListener('keyup', this.handleKeyUp);
    document.body.addEventListener('touchmove', this.preventBodyToScroll);
  }

  componentWillUnmount() {
    const { scrollToTopOnClose } = this.props;
    document.body.classList.remove(
      this.props.maintainPosition ? 'no-scroll' : 'body-fixed'
    );

    if (scrollToTopOnClose) {
      window.scrollTo(0, 0);
    }

    document.removeEventListener('keyup', this.handleKeyUp);
    document.body.removeEventListener('touchmove', this.preventBodyToScroll);
  }

  render() {
    const {
      colorTheme,
      contentLabel,
      onClose,
      noBackground,
      large,
      classNames,
      onOverlayClick,
      onModalClick,
    } = this.props;

    const hasClose = !!onClose;

    return (
      <div
        className={styles.overlay}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          if (onOverlayClick) {
            onOverlayClick(event);
          }
          if (this.props.shouldCloseOnOverlayClick) {
            this.handleModalClose();
          }
        }}
      >
        <div
          className={`
            ${noBackground ? styles.modal : styles['modal-background']}
            ${large ? styles['modal--large'] : ''}
            ${classNames || ''}
          `}
          aria-label={contentLabel}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (onModalClick) {
              onModalClick(e);
            }
          }}
        >
          {hasClose && (
            <ClickableElement
              onClick={this.handleModalClose}
              className={`${
                styles[`${colorTheme}-close-button`]
              } qa-close-button`}
              inline
            >
              Close
            </ClickableElement>
          )}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(CSSModules(ModalInner, styles));
