import React from 'react';
import { Formik, FormikProps } from 'formik';
import Svg from '@justgiving/svg';
import { FormattedMessage } from 'react-intl';
import {
  Collapse,
  CollapsePanel,
  CollapseBody,
  CollapseHeader,
} from 'src/components/Collapse';
import SpeechBubbleWithAvatar from 'src/components/ThankingDonors/SpeechBubbleWithAvatar';
import { CollapseHeaderProps } from 'src/components/Collapse/CollapseHeader';
import { ThankDonorModalData } from 'src/components/ThankingDonors/ThankDonorModal';
import {
  AnalyticsLoadingButton as LoadingButton,
  AnalyticsPageView,
} from 'src/components/Analytics';
import styles from './ThankingDonors.scss';
import thankYou from './svg/thankYou-il.svg';
import TextareaWithCharacterCount from 'src/components/TextareaWithCharacterCount';
import { SupporterResource } from 'src/helpers/api/activities';
import validator from './thankMessageValidation';

interface Props {
  submitForm: (values: ThankDonorModalData) => {};
  supporter: SupporterResource;
  targetCurrency: Currency;
}

const ThankDonorMessageForm: React.StatelessComponent<Props> = ({
  submitForm,
  supporter,
  targetCurrency,
}) => {
  const isAnonymous = supporter.name === 'Anonymous';

  const closePanel = (isActive: boolean, togglePanel: () => void) => {
    if (isActive && (supporter.message || supporter.donationAmount)) {
      togglePanel();
    }
    return;
  };

  return (
    <AnalyticsPageView
      eventValue="thank donor"
      pageSection="admin > page view > supporters > thank supporter"
      subtype="admin"
    >
      <Svg markup={thankYou} className="jg-svg-sm" />
      <h2 className="jg-text--center jg-space-mbmd">
        {isAnonymous ? (
          <FormattedMessage
            id="ThankDonorModal.thankAnonymousDonor"
            defaultMessage="Say thanks to this donor"
          />
        ) : (
          <FormattedMessage
            id="ThankDonorModal.thankNamedDonor"
            defaultMessage="Say thanks to {donorName}"
            values={{ donorName: supporter.name }}
          />
        )}
      </h2>

      <Collapse>
        <CollapsePanel
          initialOpenPanel={!!supporter.message || !!supporter.donationAmount}
        >
          <CollapseBody>
            {() => {
              return (
                <SpeechBubbleWithAvatar
                  avatarImage={supporter.imageProfile}
                  name={supporter.name}
                  donationAmount={supporter.donationAmount}
                  message={supporter.message}
                  targetCurrency={targetCurrency}
                />
              );
            }}
          </CollapseBody>
          <CollapseHeader>
            {(collapseHeaderProps: CollapseHeaderProps) => {
              return (
                <Formik
                  initialValues={{
                    thankYouMessage: '',
                  }}
                  onSubmit={submitForm}
                  validate={values => validator(values)}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    errors,
                    touched,
                    values,
                    isSubmitting,
                  }: FormikProps<ThankDonorModalData>) => {
                    const isValid =
                      values.thankYouMessage.length >= 3 &&
                      values.thankYouMessage.length <= 200;

                    return (
                      <form onSubmit={handleSubmit}>
                        <div
                          className="jg-bdt jg-bd--solid jg-bd--very-light-grey jg-space-ptms jg-space-mvms"
                          onClick={() =>
                            closePanel(
                              collapseHeaderProps.isActive,
                              collapseHeaderProps.togglePanel
                            )
                          }
                        >
                          <TextareaWithCharacterCount
                            characterLimit={200}
                            error={errors.thankYouMessage}
                            id="thankYouMessage"
                            labelClassName={styles.label}
                            labelDefaultMessage="to"
                            labelId="PitchFor.label"
                            onChange={handleChange}
                            touched={touched.thankYouMessage}
                            value={values.thankYouMessage}
                            rows={5}
                            showLabel={false}
                            hideTextAreaBorder={true}
                            placeholder="Write your message here..."
                          />
                        </div>
                        <div className="row">
                          <LoadingButton
                            className="col-sm-12 col-md-8 offset-md-2"
                            id="send-message"
                            loading={isSubmitting}
                            disabled={!isValid}
                            value={
                              <FormattedMessage
                                id="ThankDonorModal.sendMessageButton"
                                defaultMessage="Send message"
                              />
                            }
                            analyticsOptions={{
                              event: 'link click',
                              eventValue: 'send message',
                              pageSection:
                                'admin > page view > supporters > thank supporter',
                              subtype: 'button',
                            }}
                          />
                          <p className="jg-width-100pc jg-text--center jg-text--small jg-color--nobel jg-space-mtmd">
                            <FormattedMessage
                              id="ThankDonorModal.footNote"
                              defaultMessage="Some of your supporters may have chosen not to share their email address with us when making a donation to your page through our 'guest checkout'. This means that they won't receive your thank you message, so we'd recommend posting an update on your page so no-one gets missed. Please note, you can only thank each donor once."
                              values={{ donorName: supporter.name }}
                            />
                          </p>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              );
            }}
          </CollapseHeader>
        </CollapsePanel>
      </Collapse>
    </AnalyticsPageView>
  );
};

export default ThankDonorMessageForm;
