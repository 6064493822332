import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, withI18n, WithI18n } from 'react-i18next';

interface Props {
  className?: string;
}

const WhatIsCrowdfunding: React.StatelessComponent<Props & WithI18n> = ({
  className = '',
}) => (
  <div className={className}>
    <h2 className="jg-h4">
      <Link to="/" className="jg-text--link">
        <Trans i18nKey="WhatIsCrowdfunding.title">What is crowdfunding?</Trans>
      </Link>
    </h2>
    <p>
      <Trans i18nKey="WhatIsCrowdfunding.description">
        Crowdfunding is a new type of fundraising where you can raise funds for
        your own personal cause, even if you're not a registered charity.
      </Trans>
    </p>
    <p className="jg-text--small-light jg-space-mbn">
      <Trans i18nKey="WhatIsCrowdfunding.funds">
        The page owner is responsible for the distribution of funds raised.
      </Trans>
    </p>
  </div>
);

export default withI18n()(WhatIsCrowdfunding);
