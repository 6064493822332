import React from 'react';
import styles from './FacebookShareButton.scss';
import CSSModules from 'react-css-modules';
import FacebookShare from '../FacebookShare/FacebookShare';

export interface Props {
  pageSection: string;
}

const FacebookShareButton: React.StatelessComponent<Props> = ({
  pageSection,
}) => {
  return (
    <div styleName="share-button-container">
      <FacebookShare
        buttonText="Facebook"
        location={pageSection}
        customStyle={styles.fb}
        showCounter
        renderButtonOnly
      />
    </div>
  );
};

export default CSSModules(FacebookShareButton, styles);
