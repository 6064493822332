import React from 'react';
import Transition, { TransitionProps } from './Transition';

const RevealTransition: React.StatelessComponent<TransitionProps> = (
  props: TransitionProps
) => (
  <Transition
    property="opacity"
    before={{ opacity: 0 }}
    after={{ opacity: 1 }}
    {...props}
  />
);

export default RevealTransition;
