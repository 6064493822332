import { Email } from './Email';

export class ShareWithFriendsEmail extends Email {
  getSubject = () => {
    return super.i18nWithEnrichment(
      'Share.email.friend.subject',
      'Please help me get started with my JustGiving Page'
    );
  };

  getBody = () => {
    return super.i18nWithEnrichment(
      'Share.email.friend.body',
      "Hello,\n\nI've created a JustGiving page to raise {{targetAmountFormatted}} to {{pitchFor}} and it would be great if you could help me get started:\n\n{{- pageUrl}}\n\nPlease donate and share, and let me know if you have any suggestions.\n\nThank you for your support\n\n{{ownerName}}"
    );
  };
}
