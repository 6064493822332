import {
  getSupporters,
  SupportersResponse,
  SupporterResource,
} from 'src/helpers/api/activities';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { AnalyticsMeta } from 'src/redux/modules/analytics';

interface SupportersRequestAction {
  type: 'SUPPORTERS_REQUEST';
  payload: {
    pageId: string;
    pageNo: number;
    pageSize: number;
  };
  meta?: AnalyticsMeta;
}

interface SupporterThanked {
  type: 'SUPPORTER_THANKED';
  payload: {
    supporterId: number;
    thankYouMessage: string;
  };
}

interface SupportersSuccessAction {
  type: 'SUPPORTERS_SUCCESS';
  payload: SupportersResponse;
}

interface SupportersFailureAction {
  type: 'SUPPORTERS_FAILURE';
  error: true;
  payload: Error;
}

interface LoadSupportersOptions {
  pageId: string;
  pageNo?: number;
  pageSize?: number;
}

export function thankSupporter(supporterId: number, thankYouMessage: string) {
  return {
    type: 'SUPPORTER_THANKED',
    payload: { supporterId, thankYouMessage },
  };
}

export function loadSupporters({
  pageId,
  pageNo = 1,
  pageSize = 7,
}: LoadSupportersOptions) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    let meta: AnalyticsMeta | undefined;

    if (pageNo > 1) {
      meta = {
        event: 'link click',
        subtype: 'button',
        event_value: 'Show more',
        page_section: 'page > supporters',
        activity_type: 'crowdfunding_guid',
      };
    }

    const currentPageNo = getState().supporters.supporterPageNo || 0;

    if (pageNo !== 1 && pageNo <= currentPageNo) {
      return;
    }

    dispatch<SupportersRequestAction>({
      type: 'SUPPORTERS_REQUEST',
      payload: { pageId, pageNo, pageSize },
      meta,
    });

    try {
      const supporters = await getSupporters(pageId, pageNo, pageSize);

      dispatch<SupportersSuccessAction>({
        type: 'SUPPORTERS_SUCCESS',
        payload: supporters,
      });
    } catch (err) {
      dispatch<SupportersFailureAction>({
        type: 'SUPPORTERS_FAILURE',
        error: true,
        payload: err,
      });
    }
  };
}

export interface SupportersState {
  totalCount: number;
  supporterPageNo?: number;
  items: SupporterResource[];
  isLoading?: boolean;
}

const initialState: SupportersState = {
  totalCount: 0,
  items: [],
};

export default function reducer(
  state = initialState,
  action:
    | SupportersRequestAction
    | SupportersSuccessAction
    | SupportersFailureAction
    | SupporterThanked
): SupportersState {
  switch (action.type) {
    case 'SUPPORTERS_REQUEST':
      return { ...state, isLoading: true };

    case 'SUPPORTERS_SUCCESS':
      if (!action.payload) {
        return state;
      }

      const supporters = action.payload;

      return {
        ...state,
        items:
          supporters.pageNo === 1
            ? supporters.activities
            : [...state.items, ...supporters.activities],
        totalCount: supporters.totalCount,
        supporterPageNo: supporters.pageNo,
        isLoading: false,
      };

    case 'SUPPORTERS_FAILURE':
      return { ...state, isLoading: false };

    case 'SUPPORTER_THANKED':
      const updatedItems = state.items.map((item: SupporterResource) => {
        if (item.id === action.payload.supporterId) {
          return {
            ...item,
            thankYouMessage: action.payload.thankYouMessage,
          };
        }
        return item;
      });

      return { ...state, items: updatedItems };

    default:
      return state;
  }
}
