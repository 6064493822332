import React from 'react';
import { FormattedMessage } from 'react-intl';
import Svg from '@justgiving/svg';
import thankSent from './svg/thankSent-il.svg';
import SpeechBubbleWithAvatar from 'src/components/ThankingDonors/SpeechBubbleWithAvatar';
import { AnalyticsPageView } from 'src/components/Analytics';

interface Props {
  ownerName: string;
  profileImage?: string | undefined;
  thankYouMessage: string;
}

const ThankDonorMessageSendSuccess: React.StatelessComponent<Props> = ({
  ownerName,
  profileImage,
  thankYouMessage,
}) => (
  <AnalyticsPageView
    eventValue="thank donor success"
    pageSection="admin > page view > supporters > thank supporter"
    subtype="admin"
  >
    <Svg markup={thankSent} className="jg-svg-sm" />
    <h2 className="jg-text--center jg-space-mbmd">
      <FormattedMessage
        id="ThankDonorMessageSendSuccess.yourMessageHasBeenSent"
        defaultMessage="Your message has been sent!"
      />
    </h2>
    <SpeechBubbleWithAvatar
      avatarImage={profileImage}
      name={ownerName}
      message={thankYouMessage}
    />
  </AnalyticsPageView>
);

export default ThankDonorMessageSendSuccess;
