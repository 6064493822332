import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import BankDetailsVerifiedContent from './BankDetailsVerifiedContent';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { isActive } from 'src/helpers/page';
import {
  isLocalStorageAvailable,
  isPropertyInLocalStorage,
} from 'src/helpers/browser';

const BankDetailsVerified: Rule<JSX.Element, PromptRulesEngineOptions> = (
  state,
  options
) => {
  const localStorageKey = 'prompt_bankDetailsVerified';

  const hasDismissedPrompt = isPropertyInLocalStorage(
    `prompt:${state.page.id}_${localStorageKey}`
  );

  const handleClose = () => {
    if (isLocalStorageAvailable()) {
      localStorage.setItem(
        `prompt:${state.page.id}_${localStorageKey}`,
        'true'
      );
    }

    options!.closePrompt();
  };

  if (
    isActive({ status: state.page.status }) &&
    state.page.verificationStatus === 'Verified' &&
    !hasDismissedPrompt
  ) {
    return {
      matched: true,
      result: <BankDetailsVerifiedContent onClose={handleClose} />,
    };
  }

  return { matched: false };
};

export default BankDetailsVerified;
