import React from 'react';

type Size = {
  width: number;
  height: number;
};

type Props = {
  onMeasure: (size: Size) => void;
  style?: React.CSSProperties;
};

export default class MeasureSize extends React.Component<Props> {
  element: HTMLDivElement;
  size: Size;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.measure();
  }

  componentDidUpdate() {
    if (
      this.size.width !== this.element.offsetWidth ||
      this.size.height !== this.element.offsetHeight
    ) {
      this.measure();
    }
  }

  componentWillUnmount() {
    this.props.onMeasure({ width: 0, height: 0 });
  }

  measure() {
    const size = {
      width: this.element.offsetWidth,
      height: this.element.offsetHeight,
    };

    this.size = size;
    this.props.onMeasure(size);
  }

  render() {
    const { children, onMeasure, ...props } = this.props;

    return (
      <div
        ref={r => {
          this.element = r as HTMLDivElement;
        }}
        {...props}
      >
        {children}
      </div>
    );
  }
}
