import React from 'react';
import CSSModules from 'react-css-modules';
import { generateHash } from 'src/helpers/hashId';
import { buildUrl } from 'src/helpers/url';
import currencyMap from 'src/helpers/currencyMap';
import styles from './EmailShare.scss';
import { ShareActionOptions } from 'src/redux/modules/page';
import { Email } from './Email';
import { OwnerShareEmail } from './OwnerShareEmail';
import { SupporterShareEmail } from './SupporterShareEmail';
import { ShareWithFriendsEmail } from './ShareWithFriendsEmail';
import { HOME_URI } from 'config';

interface Props {
  shareByEmail: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  ownerName: string;
  isActive: boolean;
  customStyle?: string;
  buttonText?: string;
  className?: string;
  location: string;
  eventValue?: string;
  targetCurrency: Currency;
  messageType?: ShareMessageTypes;
}

class EmailShare extends React.Component<Props> {
  getTargetAmountFormatted = () => {
    return `${currencyMap[this.props.targetCurrency] || currencyMap.GBP}${
      this.props.targetAmount
    }`;
  };

  handleEmailShare = () => {
    const {
      messageType,
      location,
      shareByEmail,
      eventValue,
      socialShareUrl,
      pitchFor,
      ownerName,
    } = this.props;

    const shareHash = generateHash();

    let email: Email;

    switch (messageType) {
      case 'ownerShare': {
        email = new OwnerShareEmail();
        email.pageUrl = buildUrl(socialShareUrl, {
          utm_id: 1,
          utm_term: shareHash,
        });
        break;
      }
      case 'shareWithFriends': {
        email = new ShareWithFriendsEmail();
        email.pageUrl = buildUrl(socialShareUrl, {
          utm_term: shareHash,
        });
        break;
      }
      default: {
        email = new SupporterShareEmail();
        email.pageUrl = buildUrl(socialShareUrl, {
          utm_id: 2,
          utm_term: shareHash,
        });
        break;
      }
    }

    email.homeUrl = buildUrl(HOME_URI, {
      utm_id: 3,
      utm_term: shareHash,
    });
    email.pitchFor = pitchFor;
    email.targetAmountFormatted = this.getTargetAmountFormatted();
    email.ownerName = ownerName;

    shareByEmail({
      shareHash,
      location,
      eventValue,
    });

    if (window && window.location) {
      window.location.href = `mailto:?subject=${encodeURIComponent(
        email.getSubject()
      )}&body=${encodeURIComponent(email.getBody())}`;
    }
  };

  render() {
    const { isActive, customStyle, buttonText, className } = this.props;

    return (
      <button
        type="button"
        disabled={!isActive}
        className={className}
        styleName={customStyle}
        onClick={this.handleEmailShare}
      >
        {buttonText || 'Email'}
      </button>
    );
  }
}

export default CSSModules(EmailShare, styles);
