import ContactOwnerModal, {
  ContactOwnerFormData,
} from '../ContactOwner/ContactOwnerModal';

import ClickableElement from '../ClickableElement';
import { Element } from 'react-scroll';
import { OwnerAvatar } from '../Avatar';
import React from 'react';
import Report from '../Page/ReportPage';
import Svg from '@justgiving/svg';
import i18next from 'i18next';
import locationPin from '@justgiving/icons/lib/map-pin.svg';
import styles from './AboutFundraiser.scss';
import { SUPPRESS_DONOR_GENERATED_CONTENT } from 'config';

interface Props {
  addCensure: () => void;
  clearRecaptcha: () => void;
  closeContactOwner: () => void;
  contactOwner: (
    data: {
      id: string;
      recaptcha: string;
      subject: string;
      message: string;
      email: string;
    }
  ) => void;
  id: string;
  pageName: string;
  location?: string;
  isContactingOwnerWindowOpen: boolean;
  contactOwnerPageViewSection: string;
  contactOwnerPageSection: string;
  contactOwnerSubjectPlaceHolder?: string;
  contactOwnerMessagePlaceholder?: string;
  openContactOwner: (
    returnRoute: string,
    pageViewSection: string,
    pageSection: string,
    friendFeedback: boolean,
    subjectPlaceHolder?: string,
    messagePlaceholder?: string
  ) => void;
  ownerBio?: string;
  ownerName: string;
  profileImage?: string;
  recaptcha: string;
  setRecaptcha: (response: string) => void;
  onMessageSent: () => void;
}

interface State {
  submissionError: boolean;
}

class AboutFundraiser extends React.Component<Props, State> {
  state = { submissionError: false };

  handleSubmit = async (data: ContactOwnerFormData) => {
    const { clearRecaptcha, contactOwner, id, onMessageSent } = this.props;

    if (this.state.submissionError) {
      this.setState({ submissionError: false });
    }

    try {
      await contactOwner({
        id,
        recaptcha: this.props.recaptcha,
        ...data,
      });

      clearRecaptcha();
      if (onMessageSent) {
        onMessageSent();
      }
    } catch (e) {
      this.setState({ submissionError: true });
    }
  };

  handleContactOwner = () => {
    const isFriend = false;
    this.props.openContactOwner(
      `${this.props.pageName}/contact`,
      'page view > footer',
      'page > footer',
      isFriend,
      i18next.t('ContactOwner.subject.placeholder', {
        defaultValue: '',
      }),
      i18next.t('ContactOwner.message.placeholder', {
        defaultValue: '',
      })
    );
  };

  render() {
    const {
      addCensure,
      closeContactOwner,
      isContactingOwnerWindowOpen,
      contactOwnerPageViewSection,
      contactOwnerPageSection,
      contactOwnerSubjectPlaceHolder,
      contactOwnerMessagePlaceholder,
      location,
      ownerBio,
      ownerName,
      profileImage,
      recaptcha,
      setRecaptcha,
      id,
    } = this.props;

    const showDonorContent = SUPPRESS_DONOR_GENERATED_CONTENT.indexOf(id) < 0;

    return (
      <Element name="about-fundraiser" className={`${styles.container}`}>
        {isContactingOwnerWindowOpen && (
          <ContactOwnerModal
            closeContactOwner={closeContactOwner}
            onSubmit={this.handleSubmit}
            ownerName={ownerName}
            recaptcha={recaptcha}
            setRecaptcha={setRecaptcha}
            submissionError={this.state.submissionError}
            pageViewSection={contactOwnerPageViewSection}
            pageSection={contactOwnerPageSection}
            subjectPlaceHolder={contactOwnerSubjectPlaceHolder}
            messagePlaceholder={contactOwnerMessagePlaceholder}
          />
        )}
        <div>
          <h5 className="jg-h5 jg-space-mbmd@md jg-space-mbsm">
            About the fundraiser
          </h5>
          <div className={`jg-display-flex ${styles.content}`}>
            <div className={`${styles['avatar-container']}`}>
              <OwnerAvatar
                className="jg-space-mbsm"
                src={profileImage}
                alt={ownerName}
              />
            </div>
            <div>
              <h3 className="jg-h3 jg-display-i jg-space-mrmd">{ownerName}</h3>

              {location && (
                <div className="jg-display-i">
                  <span className="jg-relative">
                    <Svg
                      className={`jg-fill--nobel jg-icon--medium-small jg-display-i ${
                        styles['location-pin']
                      }`}
                      markup={locationPin}
                    />
                    <p className="jg-space-mlmd jg-color--nobel jg-display-i">
                      {location}
                    </p>
                  </span>
                </div>
              )}

              <p className="jg-text--large jg-space-mtsm@md">{ownerBio}</p>
              <div className="jg-space-mtsm">
                {showDonorContent && (
                  <ClickableElement
                    onClick={this.handleContactOwner}
                    className="jg-text--link jg-space-mrlg"
                    inline
                    analyticsOptions={{
                      event: 'link click',
                      subtype: 'button',
                      eventValue: 'Contact page owner',
                      pageSection: 'page > footer',
                    }}
                  >{`Contact ${ownerName}`}</ClickableElement>
                )}
                <Report className="jg-text--link" addCensure={addCensure} />
              </div>
            </div>
          </div>
        </div>
      </Element>
    );
  }
}

export default AboutFundraiser;
