import { push } from 'react-router-redux';
import { unsubscribe as unsub } from '../../helpers/api/subscriptions';
import { wait } from '../../helpers/async';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'react-redux';

interface UnsubscribeRequestAction {
  type: 'UNSUBSCRIBE_REQUEST';
}

interface UnsubscribeSuccessAction {
  type: 'UNSUBSCRIBE_SUCCESS';
}

interface UnsubscribeFailureAction {
  type: 'UNSUBSCRIBE_FAILURE';
  error: true;
  payload: Error;
}

type UnsubscribeAction =
  | UnsubscribeRequestAction
  | UnsubscribeSuccessAction
  | UnsubscribeFailureAction;

export interface UnsubscribeOptions {
  pageId: string;
  pageName: string;
  emailAddress: string;
  consumerId: number;
}

export function unsubscribe({
  pageId,
  pageName,
  emailAddress,
  consumerId,
}: UnsubscribeOptions) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    try {
      dispatch<UnsubscribeRequestAction>({ type: 'UNSUBSCRIBE_REQUEST' });

      const payload = {
        projectId: pageId,
        projectName: pageName,
        emailAddress,
        consumerId,
      };

      await unsub(payload);

      dispatch<UnsubscribeSuccessAction>({ type: 'UNSUBSCRIBE_SUCCESS' });

      await wait(5000);
      dispatch(push(`/${pageName}`));
    } catch (error) {
      dispatch<UnsubscribeFailureAction>({
        type: 'UNSUBSCRIBE_FAILURE',
        error: true,
        payload: error,
      });
    }
  };
}

export interface SubscriptionsState {
  unsubscribed?: boolean;
  isUnsubscribing?: boolean;
  error?: Error;
}

export default function reducer(
  state: SubscriptionsState = {},
  action: UnsubscribeAction
) {
  switch (action.type) {
    case 'UNSUBSCRIBE_REQUEST':
      return {
        unsubscribed: false,
        isUnsubscribing: true,
      };

    case 'UNSUBSCRIBE_SUCCESS':
      return {
        unsubscribed: true,
        isUnsubscribing: false,
      };

    case 'UNSUBSCRIBE_FAILURE':
      return {
        unsubscribed: false,
        isUnsubscribing: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
