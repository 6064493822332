import React from 'react';
import AvatarAndName from './AvatarAndName';
import MessageBox from './MessageBox';

export type NamePosition = 'above' | 'below' | 'left' | 'right';

export type ArrowPositions =
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'left'
  | 'left-bottom'
  | 'left-top'
  | 'right'
  | 'right-bottom'
  | 'right-top'
  | 'top'
  | 'top-left'
  | 'top-right';

interface Props {
  arrowPosition?: ArrowPositions;
  avatarImage: string | undefined;
  message: string;
  name: string;
  namePosition?: NamePosition;
  showAvatar?: boolean;
  showName?: boolean;
}

const SpeechBubbleWithAvatar: React.StatelessComponent<Props> = ({
  arrowPosition = 'bottom',
  avatarImage,
  message,
  name,
  namePosition = 'below',
  showAvatar = true,
  showName = false,
}) => {
  const avatarAndName = (
    <AvatarAndName
      avatarImage={avatarImage}
      name={name}
      namePosition={namePosition}
      showName={showName}
    />
  );

  const messageBox = (
    <MessageBox arrowPosition={arrowPosition} message={message} />
  );

  switch (arrowPosition) {
    case 'bottom':
      return (
        <>
          {messageBox}
          <div className="jg-space-mtms jg-text--center">
            {showAvatar && avatarAndName}
          </div>
        </>
      );
    case 'bottom-left':
      return (
        <>
          {messageBox}
          <div className="jg-space-mtms jg-space-plsm jg-text--pull-left">
            {showAvatar && avatarAndName}
          </div>
        </>
      );
    case 'bottom-right':
      return (
        <>
          {messageBox}
          <div className="jg-space-mtms jg-space-prmd jg-text--pull-right">
            {showAvatar && avatarAndName}
          </div>
        </>
      );
    case 'left':
      return (
        <div className="jg-display-flex jg-display-flex jg-flex-aic">
          <div className="jg-space-mrms jg-flex-s0">
            {showAvatar && avatarAndName}
          </div>
          <div className="jg-flex-g1">{messageBox}</div>
        </div>
      );
    case 'left-top':
      return (
        <div className="jg-display-flex jg-flex-aic jg-flex-ais">
          <div className="jg-space-mrms jg-space-ptsm jg-flex-s0">
            {showAvatar && avatarAndName}
          </div>
          <div className="jg-flex-g1">{messageBox}</div>
        </div>
      );
    case 'left-bottom':
      return (
        <div className="jg-display-flex jg-flex-aic jg-flex-aie">
          <div
            className={`jg-space-mrms jg-flex-s0 ${
              showName ? '' : 'jg-space-pbmd'
            }`}
          >
            {showAvatar && avatarAndName}
          </div>
          <div className="jg-flex-g1">{messageBox}</div>
        </div>
      );
    case 'right':
      return (
        <div className="jg-display-flex">
          <div className="jg-flex-g1">{messageBox}</div>
          <div className="jg-space-mlms jg-display-flex jg-flex-aic jg-flex-s0">
            {showAvatar && avatarAndName}
          </div>
        </div>
      );
    case 'right-bottom':
      return (
        <div className="jg-display-flex jg-flex-aic jg-flex-aie">
          <div className="jg-flex-g1">{messageBox}</div>
          <div
            className={`jg-space-mlms jg-flex-s0 ${
              showName ? '' : 'jg-space-pbmd'
            }`}
          >
            {showAvatar && avatarAndName}
          </div>
        </div>
      );
    case 'right-top':
      return (
        <div className="jg-display-flex jg-flex-aic jg-flex-ais">
          <div className="jg-flex-g1">{messageBox}</div>
          <div className="jg-space-mlms jg-space-ptsm jg-flex-s0">
            {showAvatar && avatarAndName}
          </div>
        </div>
      );
    case 'top':
      return (
        <>
          <div
            className={`jg-text--center ${
              showName ? 'jg-space-mbms' : 'jg-space-pbmd'
            }`}
          >
            {showAvatar && avatarAndName}
          </div>
          {messageBox}
        </>
      );
    case 'top-left':
      return (
        <>
          <div className={`jg-text--pull-left jg-space-plsm jg-space-mbms`}>
            {showAvatar && avatarAndName}
          </div>
          {messageBox}
        </>
      );
    case 'top-right':
      return (
        <>
          <div
            className={`jg-text--pull-right jg-space-prmd ${
              showName ? 'jg-space-mbms' : 'jg-space-pbmd'
            }`}
          >
            {showAvatar && avatarAndName}
          </div>
          {messageBox}
        </>
      );
    default:
      return (
        <>
          {messageBox}
          <div className="jg-space-mtms jg-text--center">
            {showAvatar && avatarAndName}
          </div>
        </>
      );
  }
};

export default SpeechBubbleWithAvatar;
