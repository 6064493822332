import {
  clearRecaptcha,
  closeContactOwner,
  contactOwner,
  openContactOwner,
  openFeedbackThankYou,
  setRecaptcha,
} from 'src/redux/modules/page';

import AboutFundraiser from 'src/components/AboutFundraiser/AboutFundraiser';
import { AppState } from 'src/redux/modules';
import { addCensure } from 'src/redux/modules/censures';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

interface StateProps {
  id: string;
  location?: string;
  isContactingOwnerWindowOpen: boolean;
  isFeedbackOwnerWindowOpen?: boolean;
  contactOwnerPageViewSection: string;
  contactOwnerPageSection: string;
  contactOwnerSubjectPlaceHolder?: string;
  contactOwnerMessagePlaceholder?: string;
  ownerBio?: string;
  ownerName: string;
  profileImage?: string;
  recaptcha: string;
  pageName: string;
}

interface DispatchProps {
  addCensure: () => void;
  clearRecaptcha: () => void;
  closeContactOwner: () => void;
  openFeedbackThankYou?: () => void;
  onMessageSent: () => void;
  contactOwner: (
    data: { id: string; recaptcha: string; subject: string; message: string }
  ) => void;
  openContactOwner: (
    returnRoute: string,
    pageViewSection: string,
    pageSection: string,
    friendFeedback: boolean,
    subjectPlaceHolder?: string,
    messagePlaceholder?: string
  ) => void;
  setRecaptcha: (response: string) => void;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    id: state.page.id,
    location: state.page.locationText,
    isContactingOwnerWindowOpen:
      state.page.isContactingOwnerWindowOpen ||
      state.page.isFeedbackOwnerWindowOpen,
    isFeedbackOwnerWindowOpen: state.page.isFeedbackOwnerWindowOpen,
    contactOwnerPageViewSection: state.page.contactOwnerPageViewSection,
    contactOwnerPageSection: state.page.contactOwnerPageSection,
    contactOwnerSubjectPlaceHolder: state.page.contactOwnerSubjectPlaceHolder,
    contactOwnerMessagePlaceholder: state.page.contactOwnerMessagePlaceholder,
    ownerBio: state.page.ownerBio,
    ownerName: state.page.ownerName,
    profileImage: state.page.ownerProfileImageUrl,
    recaptcha: state.page.recaptcha,
    pageName: state.page.name,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      addCensure,
      clearRecaptcha,
      setRecaptcha,
      closeContactOwner,
      openFeedbackThankYou,
      onMessageSent: () => {},
      openContactOwner,
      contactOwner,
    },
    dispatch
  );
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    onMessageSent: stateProps.isFeedbackOwnerWindowOpen
      ? dispatchProps.openFeedbackThankYou
      : undefined,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AboutFundraiser);
