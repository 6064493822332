import { FundsResponse } from './api/funds';
import isAfter from 'date-fns/is_after';
import isPast from 'date-fns/is_past';

export const fundsStatusWhiteList = [
  'NoFunds',
  'PreviousWithdrawalProcessing',
  'CanWithdraw',
  'NotAvailableYet',
  'ProjectNotActive',
  'ProjectNotReviewed',
  'ProjectNotVerified',
];

export const getFundsStatus = ({
  amountRaised,
  finishedAt,
  fundsResponse,
  isAccountConnected,
}: {
  amountRaised: number;
  finishedAt: string;
  fundsResponse: FundsResponse;
  isAccountConnected: boolean;
}) => {
  const hasPageClosed = isPast(finishedAt);

  if (hasPageClosed && amountRaised > 0) {
    const automaticWithdraw = fundsResponse.withdrawals.find(withdrawal =>
      isAfter(withdrawal.dateRequested, finishedAt)
    );

    if (automaticWithdraw && automaticWithdraw.status === 'Processing') {
      return 'FinalWithdrawalProcessing';
    }

    const totalAmountWithdrawn = fundsResponse.withdrawals
      .filter(withdrawal => withdrawal.status === 'Complete')
      .reduce((total, withdrawal) => total + withdrawal.grossAmount, 0);

    if (totalAmountWithdrawn >= amountRaised) {
      return 'FinalWithdrawalComplete';
    }

    return 'FinalDonationsSettling';
  }

  if (fundsResponse.status !== 'ProjectNotVerified' && amountRaised === 0) {
    return 'NoFirstDonation';
  }

  if (isAccountConnected && fundsResponse.status === 'ProjectNotVerified') {
    return 'BankAccountDetailsPending';
  }

  if (fundsStatusWhiteList.includes(fundsResponse.status)) {
    return fundsResponse.status;
  }

  throw new Error('Status not available');
};

const supportedPaymentSchedules = ['Monthly', 'Weekly'];

export const supportsPaymentSchedule = (paymentSchedule: string) => {
  return supportedPaymentSchedules.includes(paymentSchedule);
};
