import React from 'react';
import styles from './TabShareButton.scss';
import ClickableElement from 'src/components/ClickableElement';
import { FormattedMessage } from 'react-intl';

interface Props {
  isActive: boolean;
  openSharePrompt: () => void;
  pageSection: string;
}

const TabShareButton: React.StatelessComponent<Props> = ({
  isActive,
  openSharePrompt,
  pageSection,
}) => (
  <ClickableElement
    onClick={openSharePrompt}
    className={`jg-btn jg-btn--secondary ${styles.button}`}
    inline
    isDisabled={!isActive}
    analyticsOptions={{
      event: 'link click',
      subtype: 'button',
      eventValue: 'share your page',
      pageSection: `admin > ${pageSection}`,
    }}
  >
    <FormattedMessage
      id="TabShareButton.shareYourPage"
      defaultMessage="Share your page"
    />
  </ClickableElement>
);

export default TabShareButton;
