import { getOrThrow, patch, postRaw, post } from './common';

export type StripeVirtualAccountStatus = 'Pending' | 'Unverified' | 'Verified';

export type StripeRequiredField =
  | 'legal_entity.ssn_last_4'
  | 'legal_entity.personal_id_number'
  | 'legal_entity.verification.document';

export type StripeRequiredFields = StripeRequiredField[];

export type AccountDisabledReason =
  | 'RejectedFraud'
  | 'RejectedTermsOfService'
  | 'RejectedListed'
  | 'RejectedOther'
  | 'FieldsNeeded'
  | 'Listed'
  | 'UnderReview'
  | 'Other';

export interface StripeVirtualAccountStatusResponse {
  legalEntityVerificationStatus: StripeVirtualAccountStatus;
  requiredFields: StripeRequiredFields;
  bankAccountGuids: string[];
  chargesEnabled: boolean;
  payoutsEnabled: boolean;
  accountDisabledReason?: AccountDisabledReason;
  payoutScheduleInterval: PayoutScheduleInterval;
}

export async function getAccountStatus(pageId: string) {
  return getOrThrow<StripeVirtualAccountStatusResponse>(
    `/projects/${pageId}/stripeVirtualAccount/status`
  );
}

export type PayoutScheduleInterval = 'Weekly' | 'Monthly';

export interface UpdateAccountData {
  ssnLast4?: string;
  personalIdNumber?: string;
  payoutScheduleInterval?: PayoutScheduleInterval;
}

export interface UpdateAccountResponse {
  payoutScheduleInterval: PayoutScheduleInterval;
}

export async function updateStripeAccount(
  pageId: string,
  payload: UpdateAccountData
): Promise<UpdateAccountResponse> {
  const response = await patch(
    `projects/${pageId}/stripeVirtualAccount`,
    payload
  );
  return { payoutScheduleInterval: response.body.payoutScheduleInterval };
}

export async function uploadStripeVerificationDocument(
  pageId: string,
  file: File | Blob
) {
  const body = new FormData();
  body.append('file', new File([file], 'document.jpg'));

  return postRaw(`projects/${pageId}/stripeVirtualAccount/images`, body);
}

export interface AddBankAccountData {
  accountNumber: string;
  routingNumber: string;
}

export async function addStripeBankAccount(
  pageId: string,
  payload: AddBankAccountData
) {
  return post(`projects/${pageId}/stripeVirtualAccount/bankAccount`, payload);
}
