import { post, postRaw, ApiError } from './common';

export async function submitPaymentDetails(payload) {
  const response = await post(
    `projects/${payload.projectId}/verifications/v2/paymentDetails`,
    payload,
    { throwOnError: false, clientSideTimeout: 60000 }
  );
  if (response.ok) {
    return { approved: true };
  }

  if (response.badRequest) {
    return { approved: false, reason: response.body.message };
  }

  throw new ApiError({ response });
}

export async function submitIdentityDocument(pageId, documentType, file) {
  const body = new FormData();
  body.append('documentType', documentType);
  body.append('file', file);

  const response = await postRaw(
    `projects/${pageId}/verifications/document`,
    body,
    { throwOnError: false }
  );

  if (response.ok) {
    return { verified: true };
  }

  if (response.badRequest) {
    return { verified: false, message: response.body.message };
  }

  throw new ApiError({ response });
}

export async function uploadIdAndBankStatement(
  pageId,
  file1,
  file2,
  optionalEmailAddress
) {
  const body = new FormData();
  body.append('id', file1);
  body.append('bankStatus', file2);
  body.append('optionalEmailAddress', optionalEmailAddress);
  const response = await postRaw(
    `projects/${pageId}/verifications/documents`,
    body,
    { throwOnError: false }
  );

  if (response.ok) {
    return { verified: true };
  }

  if (response.badRequest) {
    return { verified: false, message: response.body.message };
  }

  throw new ApiError({ response });
}

export async function submitAddress(payload) {
  const response = await post(
    `projects/${payload.projectId}/verifications/address`,
    payload,
    { throwOnError: false }
  );

  if (response.ok) {
    return { verified: true };
  }

  if (response.badRequest) {
    return { verified: false, message: response.body.message };
  }

  throw new ApiError({ response });
}
