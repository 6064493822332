import React, { Component } from 'react';
import { Button, Link } from '../Analytics';
import styles from './PageOwnerNavbar.scss';
import { PageState } from 'src/redux/modules/page';
import Sticky from '../StickyHeader/Sticky';
import { Trans } from 'react-i18next';

interface Props {
  page: PageState;
  pageSection: string;
  openSharePrompts: () => void;
}

const CHROME_HEADER_OFFSET = 58;

export class PageOwnerNavbar extends Component<Props> {
  render() {
    const { page, pageSection, openSharePrompts } = this.props;

    return (
      <Sticky offset={CHROME_HEADER_OFFSET} delay={50}>
        {({ sticky }) => (
          <section
            className={`jg-bdh jg-bdb jg-bd--solid jg-bd--solid jg-bd--very-light-grey jg-background--white jg-space-pvms
            ${sticky ? styles.fixed : ''}`}
          >
            <menu className="jg-page-owner-navbar container jg-space-phsm jg-jcb">
              <Link
                className={`jg-btn jg-btn--ghost ${styles['back-button']} ${
                  pageSection === 'edit' ? 'jg-hidden jg-show@lg' : ''
                }`}
                href={`${page.socialShareUrl}/admin`}
                analyticsOptions={{
                  event: 'click',
                  eventValue: 'back to dashboard',
                  pageSection: `admin > ${pageSection}`,
                  subtype: 'button',
                }}
              >
                <Trans i18nKey="PageOwnerNavbar.back">Dashboard</Trans>
              </Link>

              <div
                className={`jg-display-flex jg-flex-g1 ${styles['mobile-bar']}`}
              >
                {pageSection !== 'edit' && (
                  <Link
                    className={`jg-btn jg-btn--ghost ${
                      styles['mobile-edit-button']
                    }`}
                    to={`/${page.name}/edit`}
                    analyticsOptions={{
                      event: 'click',
                      eventValue: 'edit page',
                      pageSection: `admin > ${pageSection}`,
                      subtype: 'button',
                    }}
                  >
                    <Trans i18nKey="PageOwnerNavbar.edit">Edit your page</Trans>
                  </Link>
                )}
                <Button
                  className="jg-btn jg-hidden jg-show@lg"
                  onClick={openSharePrompts}
                  analyticsOptions={{
                    event: 'link click',
                    eventValue: 'share page',
                    pageSection: `admin > ${pageSection}`,
                    subtype: 'button',
                  }}
                >
                  <Trans i18nKey="PageOwnerNavbar.share">Share page</Trans>
                </Button>
              </div>
            </menu>
          </section>
        )}
      </Sticky>
    );
  }
}
