import qs from 'qs';
import parseurl from 'url';

export function buildUrl(baseUrl: string, queryStringParams: any) {
  return `${baseUrl}?${qs.stringify(queryStringParams)}`;
}

export function buildUrlWithHash(
  baseUrl: string,
  id: string,
  queryStringParams: any
) {
  return `${baseUrl}?${qs.stringify(queryStringParams)}#${id}`;
}

export const removeProtocol = (url: string) => url.replace(/^https?:\/\//, '');

export function getQueryFromUrl(path: string) {
  const url = parseurl.parse(path, true);
  return url.query;
}

export function gotoUrl(url: string) {
  window.location.assign(url);
}
