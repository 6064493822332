import React from 'react';
import Loading from '@justgiving/loading';
import { Portal } from 'react-portal';
import CSSModules from 'react-css-modules';
import styles from './LoadingOverlay.scss';

const LoadingOverlay = () => (
  <Portal>
    <div styleName="overlay">
      <Loading size="medium" />
    </div>
  </Portal>
);

export default CSSModules(LoadingOverlay, styles);
