import { getAvatars, AvatarsResponse } from 'src/helpers/api/avatars';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';

interface LoadAvatarsRequestAction {
  type: 'AVATARS_REQUEST';
}

interface LoadAvatarsSuccessAction {
  type: 'AVATARS_SUCCESS';
  payload: AvatarsResponse;
}

interface LoadAvatarsFailureAction {
  type: 'AVATARS_FAILURE';
  error: true;
  payload: Error;
}

export function loadAvatars(userIds: string[]) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch({ type: 'AVATARS_REQUEST', payload: userIds });

    try {
      const storedAvatars = getState().avatars.avatars;
      const userIdsToFetch = userIds.filter(x => !storedAvatars[x]);

      if (userIdsToFetch.length > 0) {
        const avatars = await getAvatars(userIdsToFetch);

        dispatch<LoadAvatarsSuccessAction>({
          type: 'AVATARS_SUCCESS',
          payload: avatars,
        });
      } else {
        dispatch<LoadAvatarsSuccessAction>({
          type: 'AVATARS_SUCCESS',
          payload: {},
        });
      }
    } catch (err) {
      dispatch<LoadAvatarsFailureAction>({
        type: 'AVATARS_FAILURE',
        error: true,
        payload: err,
      });
    }
  };
}

type Actions =
  | LoadAvatarsRequestAction
  | LoadAvatarsSuccessAction
  | LoadAvatarsFailureAction;

export interface AvatarsState {
  avatars: AvatarsResponse;
  errorMessage?: string;
}

export default function reducer(
  state: AvatarsState = { avatars: {} },
  action: Actions
): AvatarsState {
  switch (action.type) {
    case 'AVATARS_REQUEST':
      return { ...state };
    case 'AVATARS_SUCCESS':
      return { ...state, avatars: { ...action.payload, ...state.avatars } };
    case 'AVATARS_FAILURE':
      return { ...state, errorMessage: action.payload.message };
    default:
      return state;
  }
}
