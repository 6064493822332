import { SettingsActions } from 'src/redux/modules/settings/actions';

export interface SettingsState {
  supportsRecurringDonations: boolean;
  thankYouMessage: string;
  notificationsEnabled: boolean;
  isDonationRemindLaterAvailable: boolean;
}

const initalState: SettingsState = {
  supportsRecurringDonations: false,
  thankYouMessage:
    'Thank you so much for donating to my cause. Your monthly support really means the world to me.',
  notificationsEnabled: false,
  isDonationRemindLaterAvailable: false,
};

export default (state = initalState, action: SettingsActions) => {
  switch (action.type) {
    case 'SET_RECURRING_DONATIONS_STATUS_SUCCESS':
      return {
        ...state,
        supportsRecurringDonations: action.payload,
        isDonationRemindLaterAvailable: !action.payload,
      };

    case 'SET_RECURRING_DONATIONS_STATUS_FAILURE':
      return {
        ...state,
        isDonationRemindLaterAvailable: true,
      };

    case 'GET_PAGE_SETTINGS_SUCCESS':
      return {
        ...state,
        ...action.payload,
      };

    case 'GET_PAGE_SETTINGS_FAILURE':
      return {
        ...state,
        supportsRecurringDonations: false,
        isDonationRemindLaterAvailable: true,
      };

    case 'UPDATE_THANK_YOU_MESSAGE':
      return {
        ...state,
        thankYouMessage: action.payload,
      };

    case 'SET_NOTIFICATIONS_STATUS':
      return {
        ...state,
        notificationsEnabled: action.payload,
      };

    default:
      return state;
  }
};
