import React from 'react';
import { AlertRulesEngineOptions } from 'src/helpers/alerts/index';
import { Rule } from 'src/helpers/rulesEngine';
import { Link } from 'src/components/Analytics';
import Alert from 'src/components/Alert/';
import { FUNDRAISING_BASE_URI } from 'config';
import { FormattedMessage } from 'react-intl';

const PendingApprovalAlert: Rule<JSX.Element, AlertRulesEngineOptions> = (
  state,
  options
) => {
  const { status } = state.page;

  const alertKey = 'pending_approval';

  if (status === 'PendingApproval') {
    return {
      matched: true,
      result: (
        <Alert id={alertKey} type="amber" persistent>
          <div>
            <p className="jg-space-mbmd">
              <FormattedMessage
                id="PendingApprovalAlert.weNeedToApproveYourPage"
                defaultMessage="It looks like we need to approve the content on your page before you can start getting donations. We aim to do this in 2 days."
              />
            </p>
            {state.charity === null && (
              <p className="jg-space-mbn">
                <FormattedMessage
                  id="PendingApprovalAlert.ifYoureFundraising"
                  defaultMessage="If you're raising money for a registered charity, you'll need to {fundraisingLink}."
                  values={{
                    fundraisingLink: (
                      <Link
                        href={FUNDRAISING_BASE_URI}
                        rel="noreferrer noopener"
                        target="_blank"
                        analyticsOptions={{
                          event: 'link click',
                          eventValue: 'create fundraising page',
                          pageSection: options!.pageSection || '',
                          subtype: 'hyperlink',
                        }}
                      >
                        <FormattedMessage
                          id="PendingApprovalAlert.ifYoureFundraising.createFundraisingPageLink"
                          defaultMessage="create a Fundraising Page"
                        />
                      </Link>
                    ),
                  }}
                />
              </p>
            )}
          </div>
        </Alert>
      ),
    };
  }

  return { matched: false };
};

export default PendingApprovalAlert;
