import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from 'react';

const DonatePopupContext = createContext(undefined);

type DonatePopupProviderProps = {
  children?: ReactNode;
};

export type PopupCheckoutModes = 'closed' | 'blocked' | 'open';

type DonatePopupContext = {
  popupWindow: Window | null;
  checkoutHref: string;
  setPopupWindow: () => void;
  showCheckoutPopup: (href: string) => void;
  popupCheckoutMode: PopupCheckoutModes;
  setPopupCheckoutMode: (mode: PopupCheckoutModes) => void;
};

const DonatePopupProvider: FunctionComponent<DonatePopupProviderProps> = ({
  children,
}) => {
  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [checkoutHref, setCheckoutHref] = useState('');
  const [popupCheckoutMode, setPopupCheckoutMode] = useState<
    PopupCheckoutModes
  >('closed');

  const showCheckoutPopup = (href: string) => {
    setCheckoutHref(href);
    const screenX = window.screenX;
    const screenY = window.screenY;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const popupWidth = 938;
    const popupHeight = 640;

    const popupLeft = screenX + (screenWidth / 2 - popupWidth / 2);
    const popupTop = screenY + (screenHeight / 2 - popupHeight / 2);
    const windowFeatures = `width=${popupWidth},height=${popupHeight},left=${popupLeft},top=${popupTop}`;

    const popupCheckout = window.open(href, '_blank', windowFeatures);
    if (popupCheckout) {
      setPopupWindow(popupCheckout);
      setPopupCheckoutMode('open');
      popupCheckout.focus();
    } else {
      setPopupCheckoutMode('blocked');
    }
  };

  return (
    <DonatePopupContext.Provider
      value={{
        popupWindow,
        setPopupWindow,
        showCheckoutPopup,
        popupCheckoutMode,
        setPopupCheckoutMode,
        checkoutHref,
      }}
    >
      {children}
    </DonatePopupContext.Provider>
  );
};

const useDonatePopup = () => {
  return useContext<DonatePopupContext>(DonatePopupContext);
};

export { DonatePopupProvider, DonatePopupContext, useDonatePopup };
