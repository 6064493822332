const routeAnalyticsMap: { [key: string]: string } = {
  dashboard: 'tips',
};

export function getPageSection(path: string): string {
  const splitUrl = path.substring(1).split('/');

  if (splitUrl.length === 1) {
    return 'page view';
  }

  const pageSection = splitUrl[1];

  return routeAnalyticsMap[pageSection] || pageSection;
}
