import {
  createValidator,
  maxLength,
  required,
  Rules,
} from 'src/helpers/validation';
import { ContactOwnerFormData } from 'src/components/ContactOwner/ContactOwnerModal';

export const rules: Rules<ContactOwnerFormData> = {
  message: [
    required({
      message: 'Please write your message',
    }),
    maxLength(1000, {
      message: 'Please write your message in 1000 characters, maximum',
    }),
  ],
  subject: [
    required({
      message: 'Please write the subject of your message',
    }),
    maxLength(100, {
      message: 'Please write the subject in 100 characters, maximum',
    }),
  ],
};

export default createValidator(rules);
