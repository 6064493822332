export function pageClosedPromptOpened() {
  return {
    type: 'PAGE_CLOSED_PROMPT_OPENED',
    meta: {
      analytics: {
        event: 'impression',
        subtype: 'banner',
        event_value: 'inspired',
        page_section: 'page > page banner > inspired',
        version_id: 'active',
        version_type: 'banner type',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function crossSellBannerImpression() {
  return {
    type: 'CROSS_SELL_BANNER_IMPRESSION',
    meta: {
      analytics: {
        event: 'impression',
        subtype: 'banner',
        event_value: 'inspired',
        page_section: 'page > page banner > inspired',
        version_id: 'cross sell',
        version_type: 'banner type',
        activity_type: 'crowdfunding_guid',
        content_type: 'crowdfunding_guid',
      },
    },
  };
}
