import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import DocumentsManualVerificationContent from './DocumentsManualVerificationContent';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { isActive } from 'src/helpers/page';

const DocumentsManualVerification: Rule<
  JSX.Element,
  PromptRulesEngineOptions
> = state => {
  if (
    isActive({ status: state.page.status }) &&
    state.page.verificationStatus === 'UnverifiedManual'
  ) {
    return {
      matched: true,
      result: <DocumentsManualVerificationContent />,
    };
  }

  return { matched: false };
};

export default DocumentsManualVerification;
