import log from '@justgiving/logger';
import clientConfig from 'config';

if (__SERVER__) {
  const serverConfig = require('config/server.config');

  log.level(serverConfig.logLevel);

  if (serverConfig.logstashEndpoint) {
    const logstash = require('bunyan-logstash-tcp');
    const [host, port] = serverConfig.logstashEndpoint.split(':');

    log.addStream({
      name: 'logstash',
      level: 'info',
      type: 'raw',
      stream: logstash.createStream({ host, port }).on('error', console.log),
    });
  }
}

if (__CLIENT__) {
  log.level(clientConfig.LOG_LEVEL);
}

export default log;
