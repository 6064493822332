import {
  AnalyticsClick,
  ClickAnalyticsOptions,
  analyticsClick as analyticsClickActionCreator,
} from 'src/redux/modules/analytics';

import CSSModules from 'react-css-modules';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styles from './ClickableElement.scss';

interface OwnProps {
  id?: string;
  analyticsOptions?: ClickAnalyticsOptions;
  className?: string;
  inline?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLElement>>;
  isDisabled?: boolean;
  onMouseDown?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  onTouchStart?: React.EventHandler<React.TouchEvent<HTMLElement>>;
}

interface DispatchProps {
  analyticsClick: AnalyticsClick;
}

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      analyticsClick: analyticsClickActionCreator as AnalyticsClick,
    },
    dispatch
  );

class ClickableElement extends React.Component<Props, {}> {
  static defaultProps: {
    isDisabled: false;
  };

  constructor(props: Props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    const { onClick, analyticsClick, analyticsOptions } = this.props;
    if (onClick) {
      onClick(event);
    }
    if (analyticsOptions) {
      analyticsClick(analyticsOptions);
    }
  }

  render() {
    const {
      id,
      inline,
      children,
      className,
      isDisabled,
      onMouseDown,
      onTouchStart,
    } = this.props;
    const style = inline
      ? styles['clickable-element-inline']
      : styles['clickable-element-block'];
    return (
      <button
        id={id}
        type="button"
        className={`${style} ${className}`}
        onClick={this.handleClick}
        disabled={isDisabled}
        data-disabled={isDisabled}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
      >
        {children}
      </button>
    );
  }
}

export default connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(CSSModules(ClickableElement, styles));
