import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';
import { AnalyticsMeta } from 'src/redux/modules/analytics';

export interface ShareActionOptions {
  shareHash: string;
  location: string;
  eventValue?: string;
}

export interface ShareOnFacebookAction {
  type: 'SHARE_ON_FB';
  payload: any;
  meta: {
    analytics: AnalyticsMeta;
  };
}

// TODO: type details
export function shareOnFacebook(details: any) {
  return (dispatch: Dispatch<AppState>) => {
    dispatch({ type: 'INCREMENT_FACEBOOK_SHARE_COUNT' });
    dispatch<ShareOnFacebookAction>({
      type: 'SHARE_ON_FB',
      payload: details,
      meta: {
        analytics: {
          social_type: 'facebook',
          event_value: details.eventValue || 'share on facebook',
          page_section: details.location,
          social_utm_term: details.shareHash,
        },
      },
    });
  };
}

export interface ShareOnFacebookClosedAction {
  type: 'SHARE_ON_FB_CLOSED';
  payload: any;
  meta: {
    analytics: AnalyticsMeta;
  };
}

// TODO: rename to Closed? or should it be the other way around...
// TODO: type details
export function shareOnFacebookOpened(
  details: any
): ShareOnFacebookClosedAction {
  return {
    type: 'SHARE_ON_FB_CLOSED',
    payload: details,
    meta: {
      analytics: {
        social_type: 'facebook',
        event_value: 'share on facebook closed',
        page_section: details.location,
        social_utm_term: details.shareHash,
      },
    },
  };
}

// TODO: type details
export function shareOnFacebookCancelled(details: any) {
  return (dispatch: Dispatch<AppState>) => {
    dispatch({ type: 'DECREMENT_FACEBOOK_SHARE_COUNT', details });
  };
}

export interface ShareOnTwitterAction {
  type: 'SHARE_ON_TW';
  payload: any;
  meta: { analytics: AnalyticsMeta };
}

// TODO: type details
export function shareOnTwitter(details: any): ShareOnTwitterAction {
  return {
    type: 'SHARE_ON_TW',
    payload: details,
    meta: {
      analytics: {
        social_type: 'twitter',
        event_value: 'share on twitter',
        page_section: details.location,
        social_utm_term: details.shareHash,
      },
    },
  };
}

export interface ShareOnEmailAction {
  type: 'SHARE_BY_EMAIL';
  payload: any;
  meta: { analytics: AnalyticsMeta };
}

export function shareByEmail(details: ShareActionOptions): ShareOnEmailAction {
  return {
    type: 'SHARE_BY_EMAIL',
    payload: details,
    meta: {
      analytics: {
        social_type: 'email',
        event_value: details.eventValue || 'share on email',
        page_section: details.location,
        social_utm_term: details.shareHash,
      },
    },
  };
}

export interface ShareOnWhatsAppAction {
  type: 'SHARE_BY_WHATSAPP';
  payload: any;
  meta: { analytics: AnalyticsMeta };
}

export function shareByWhatsApp(
  details: ShareActionOptions
): ShareOnWhatsAppAction {
  return {
    type: 'SHARE_BY_WHATSAPP',
    payload: details,
    meta: {
      analytics: {
        social_type: 'whatsapp',
        event_value: 'share on whatsApp',
        page_section: details.location,
        social_utm_term: details.shareHash,
      },
    },
  };
}

export interface ShareBySmsAction {
  type: 'SHARE_BY_SMS';
  payload: any;
  meta: { analytics: AnalyticsMeta };
}

export function shareBySms(details: ShareActionOptions): ShareBySmsAction {
  return {
    type: 'SHARE_BY_SMS',
    payload: details,
    meta: {
      analytics: {
        social_type: 'sms',
        event_value: 'share on sms',
        page_section: details.location,
        social_utm_term: details.shareHash,
      },
    },
  };
}

export interface ShareOnMessangerAction {
  type: 'SHARE_ON_MESSENGER';
  payload: any;
  meta: { analytics: AnalyticsMeta };
}

export function shareOnMessenger(
  details: ShareActionOptions
): ShareOnMessangerAction {
  return {
    type: 'SHARE_ON_MESSENGER',
    payload: details,
    meta: {
      analytics: {
        social_type: 'messenger',
        event_value: 'share on Messenger',
        page_section: details.location,
        social_utm_term: details.shareHash,
      },
    },
  };
}

export interface SharePageLinkAction {
  type: 'SHARE_PAGE_LINK';
  details: any;
  meta: { analytics: AnalyticsMeta };
}

export function sharePageLink(
  details: ShareActionOptions
): SharePageLinkAction {
  return {
    type: 'SHARE_PAGE_LINK',
    meta: {
      analytics: {
        social_type: 'copy link',
        event_value: 'copy link',
        page_section: details.location,
        social_utm_term: details.shareHash,
      },
    },
    details,
  };
}

export interface ShareQrCodeAction {
  type: 'SHARE_QR_CODE';
  payload: any;
  meta: { analytics: AnalyticsMeta };
}

export function shareQrCode(details: ShareActionOptions): ShareQrCodeAction {
  return {
    type: 'SHARE_QR_CODE',
    payload: details,
    meta: {
      analytics: {
        social_type: 'Qr',
        event_value: 'share QR code',
        page_section: details.location,
        social_utm_term: details.shareHash,
      },
    },
  };
}

export interface ShareOnLinkedInAction {
  type: 'SHARE_BY_LINKEDIN';
  payload: any;
  meta: { analytics: AnalyticsMeta };
}

export function shareOnLinkedIn(
  details: ShareActionOptions
): ShareOnLinkedInAction {
  return {
    type: 'SHARE_BY_LINKEDIN',
    payload: details,
    meta: {
      analytics: {
        social_type: 'linkedin',
        event_value: 'share on linkedin',
        page_section: details.location,
        social_utm_term: details.shareHash,
      },
    },
  };
}
