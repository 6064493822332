import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './BecomeSupporter.scss';
import DonateButton from 'src/components/Page/DonateButton';
import { OwnerAvatar } from 'src/components/Avatar';

type Props = {
  donate: () => void;
  canPageReceiveDonations: boolean;
  ownerName: string;
  profileImage?: string;
  page: { name: string; id: string };
};

class BecomeSupporter extends React.Component<Props> {
  donate = () => {
    const { donate, canPageReceiveDonations } = this.props;

    if (canPageReceiveDonations) {
      donate();
    }
  };

  render() {
    const {
      profileImage,
      canPageReceiveDonations,
      ownerName,
      page: { name, id },
    } = this.props;

    return (
      <div>
        <div styleName="become-supporter-container">
          <OwnerAvatar src={profileImage} alt={ownerName} />
          <div styleName="become-supporter-text">
            <h2 styleName="become-supporter-header">Become a supporter</h2>
            <p styleName="become-supporter-paragraph">
              Help {ownerName} raise more
            </p>
          </div>
        </div>
        <div styleName="donate">
          <DonateButton
            donate={this.donate}
            isActive={canPageReceiveDonations}
            name={name}
            pageSection="page > supporters > become supporter"
            id={id}
          />
        </div>
      </div>
    );
  }
}

export default CSSModules(BecomeSupporter, styles);
