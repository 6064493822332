import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import TimeIsRunningOutContent from './TimeIsRunningOutContent';
import { CountdownRulesEngineOptions } from 'src/components/Prompt/CountdownToRefundingFlow/countdownToRefundingEngine';

const TimeIsRunningOut: Rule<JSX.Element, CountdownRulesEngineOptions> = (
  state,
  options
) => {
  if (
    options &&
    options.timeRemainingFrozen.daysRemaining <= 5 &&
    options.timeRemainingFrozen.daysRemaining >= 1 &&
    state.page.status !== 'Suspended'
  ) {
    return {
      matched: true,
      result: (
        <TimeIsRunningOutContent
          pageName={options.pageName}
          suspensionDate={options.frozenDate}
        />
      ),
    };
  }

  return { matched: false };
};

export default TimeIsRunningOut;
