import { push } from 'react-router-redux';
import { delPage } from 'src/helpers/api/pages';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { AnalyticsMeta } from 'src/redux/modules/analytics';

export interface OpenDeletePageModalAction {
  type: 'OPEN_DELETE_PAGE_MODAL';
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function openDeletePageModal(): OpenDeletePageModalAction {
  return {
    type: 'OPEN_DELETE_PAGE_MODAL',
    meta: {
      analytics: {
        event: 'page view',
        subtype: 'admin',
        event_value: 'delete my page',
        page_section: 'admin > edit',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export interface CloseDeletePageModalAction {
  type: 'CLOSE_DELETE_PAGE_MODAL';
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function closeDeletePageModal({
  eventValue,
}: {
  eventValue: string;
}): CloseDeletePageModalAction {
  return {
    type: 'CLOSE_DELETE_PAGE_MODAL',
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: eventValue,
        page_section: 'admin > edit > delete my page',
        content_type: 'crowdfunding_guid',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export interface DeletePageRequestAction {
  type: 'DELETE_PAGE_REQUEST';
}

export interface DeletePageSuccessAction {
  type: 'DELETE_PAGE_SUCCESS';
}

export interface DeletePageFailureAction {
  type: 'DELETE_PAGE_FAILURE';
  error: true;
  payload: Error;
}

export type DeletePageActions =
  | DeletePageRequestAction
  | DeletePageSuccessAction
  | DeletePageFailureAction;

export function deletePage() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<DeletePageRequestAction>({ type: 'DELETE_PAGE_REQUEST' });

    const state = getState();
    const { name } = state.page;

    try {
      await delPage({ name });
      dispatch<DeletePageSuccessAction>({ type: 'DELETE_PAGE_SUCCESS' });
      dispatch(push('/'));
    } catch (err) {
      dispatch<DeletePageFailureAction>({
        type: 'DELETE_PAGE_FAILURE',
        payload: err,
        error: true,
      });
    }
  };
}

export interface CloseDeletePageSuccessAlertAction {
  type: 'CLOSE_DELETE_PAGE_SUCCESS_ALERT';
}

export function closeDeletePageSuccessAlert(): CloseDeletePageSuccessAlertAction {
  return { type: 'CLOSE_DELETE_PAGE_SUCCESS_ALERT' };
}
