import React from 'react';
import { OwnerAvatar } from 'src/components/Avatar';
import { NamePosition } from './SpeechBubbleWithAvatar';

interface Props {
  avatarImage: string | undefined;
  name: string;
  namePosition: NamePosition;
  showName: boolean;
}

const AvatarAndName: React.StatelessComponent<Props> = ({
  avatarImage,
  name,
  namePosition,
  showName,
}) => {
  if (!showName) {
    return (
      <OwnerAvatar
        src={avatarImage}
        alt="image of crowdfunder"
        size="xxsmall"
      />
    );
  }

  switch (namePosition) {
    case 'above':
      return (
        <div>
          <p className="jg-text--xsmall jg-color--nobel jg-space-mbn">{name}</p>
          <OwnerAvatar
            src={avatarImage}
            alt="image of crowdfunder"
            size="xxsmall"
          />
        </div>
      );
    case 'below':
      return (
        <div>
          <OwnerAvatar
            src={avatarImage}
            alt="image of crowdfunder"
            size="xxsmall"
          />
          <p className="jg-text--xsmall jg-color--nobel jg-space-mbn">{name}</p>
        </div>
      );
    case 'left':
      return (
        <div className="jg-display-flex jg-flex-aic jg-flex-jcc">
          <span className="jg-text--xsmall jg-color--nobel jg-space-mrxs">
            {name}
          </span>
          <OwnerAvatar
            src={avatarImage}
            alt="image of crowdfunder"
            size="xxsmall"
          />
        </div>
      );
    case 'right':
      return (
        <div className="jg-display-flex jg-flex-aic jg-flex-jcc">
          <OwnerAvatar
            src={avatarImage}
            alt="image of crowdfunder"
            size="xxsmall"
          />
          <span className="jg-text--xsmall jg-color--nobel jg-space-mlxs">
            {name}
          </span>
        </div>
      );
    default:
      return (
        <>
          <OwnerAvatar
            src={avatarImage}
            alt="image of crowdfunder"
            size="xxsmall"
          />
          <p className="jg-text--xsmall jg-color--nobel jg-space-mbn">{name}</p>
        </>
      );
  }
};

export default AvatarAndName;
