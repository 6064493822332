import reducer from './reducer';

export default reducer;
export {
  getTipsState,
  tipClick,
  activeTipClick,
  inactiveTipClick,
  skipSlide,
  viewSlide,
  closeShareModal,
  openFundsModal,
  closeFundsModal,
  modifyTarget,
  moreAboutFees,
  getDonations,
  uploadProfilePhoto,
} from './actions';
