import React from 'react';
import { updatePage, PatchPageData } from 'src/redux/modules/page';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormikBag } from 'formik';
import { AppState } from 'src/redux/modules';
import RecurringDonationsAwarenessBanner from 'src/components/RecurringDonations/RecurringDonationsAwarenessBanner';
import { RecurringDonationsPitchFormData } from 'src/components/RecurringDonations/RecurringDonationsPitchForm';

interface StateProps {
  isOwnerRequested: boolean;
  ownerAvatarImage?: string;
  ownerName: string;
  pageName: string;
  recurringDonationsPitch?: string;
}

interface DispatchProps {
  updatePage: typeof updatePage;
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    isOwnerRequested: state.page.isOwnerRequested,
    ownerAvatarImage: state.page.ownerProfileImageUrl,
    ownerName: state.page.ownerName,
    pageName: state.page.name,
    recurringDonationsPitch: state.page.recurringDonationsPitch,
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps =>
  bindActionCreators(
    {
      updatePage,
    },
    dispatch
  );

type Props = StateProps & DispatchProps;

class RecurringDonationsAwarenessBannerContainer extends React.Component<
  Props
> {
  handleSubmit = async (
    data: RecurringDonationsPitchFormData,
    formikBag: FormikBag<any, RecurringDonationsPitchFormData>
  ) => {
    const payload: PatchPageData = {
      name: this.props.pageName,
      ...data,
    };

    try {
      formikBag.setStatus(null);
      await this.props.updatePage(payload, false);
      formikBag.resetForm(data);

      return true;
    } catch (error) {
      formikBag.setStatus(error);
      return false;
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  render() {
    const {
      isOwnerRequested,
      ownerAvatarImage,
      ownerName,
      pageName,
      recurringDonationsPitch,
    } = this.props;

    return (
      <RecurringDonationsAwarenessBanner
        isOwnerRequested={isOwnerRequested}
        ownerAvatarImage={ownerAvatarImage}
        ownerName={ownerName}
        pageName={pageName}
        recurringDonationsPitch={recurringDonationsPitch}
        submitPitch={this.handleSubmit}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurringDonationsAwarenessBannerContainer);
