import React from 'react';
import CSSModules from 'react-css-modules';
import Modal from 'src/components/Modal';
import FacebookShare from 'src/components/FacebookShare/FacebookShare';
import TwitterShare from 'src/components/SocialShare/TwitterShare';
import EmailShare from 'src/components/SocialShare/EmailShare';
import SmsShare from 'src/components/SocialShare/SmsShare';
import WhatsAppShare from 'src/components/SocialShare/WhatsAppShare';
import QRCodeShare from 'src/components/SocialShare/QRCodeShare';
import { isDesktopViewport } from 'src/helpers/browser';
import { generateHash } from 'src/helpers/hashId';
import { buildUrl } from 'src/helpers/url';
import styles from './SharePrompts.scss';
import { ShareActionOptions } from 'src/redux/modules/page';
import { AnalyticsPageView } from 'src/components/Analytics';

interface Props {
  socialShareUrl: string;
  currencySymbol: string;
  sharePageLink: (options: ShareActionOptions) => void;
  closeSharePrompts: () => void;
  isActive: boolean;
  raised?: number;
  shareByEmail: (options: ShareActionOptions) => void;
  targetAmount: number;
  targetCurrency: Currency;
  pitchFor: string;
  ownerName: string;
  isOwner: boolean;
  shareBySms: (options: ShareActionOptions) => void;
  shareOnTwitter: (options: ShareActionOptions) => void;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareQrCode: (options: ShareActionOptions) => void;
  pageSection: string;
  push: (location: any) => void;
  pageName: string;
}

interface State {
  copyLinkUrl: string;
  copyLinkUtmTerm: string;
}

class SharePrompts extends React.Component<Props, State> {
  textInput: HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = this.generateCopyLinkState();
  }

  copyLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { pageSection, sharePageLink } = this.props;
    const { copyLinkUtmTerm } = this.state;

    e.preventDefault();
    this.textInput.focus();
    this.textInput.setSelectionRange(0, this.textInput.value.length);
    document.execCommand('copy');

    sharePageLink({ shareHash: copyLinkUtmTerm, location: pageSection });
    this.setState(this.generateCopyLinkState());
  };

  generateCopyLinkState = () => {
    const { socialShareUrl } = this.props;
    const utmTerm = generateHash();
    const url = buildUrl(socialShareUrl, { utm_term: utmTerm });

    return { copyLinkUrl: url, copyLinkUtmTerm: utmTerm };
  };

  handleQRCodeDisplay = () => {
    const { closeSharePrompts, pageName } = this.props;
    closeSharePrompts();
    this.props.push(`/${pageName}/qrcode`);
  };

  getEmailMessageType = (): ShareMessageTypes => {
    if (this.props.isOwner) {
      return 'ownerShare';
    }
    return 'supporterShare';
  };

  getWhatsAppMessageType = (): ShareMessageTypes => {
    if (this.props.isOwner) {
      return 'ownerShare';
    }
    return 'supporterShare';
  };

  render() {
    const {
      closeSharePrompts,
      currencySymbol,
      isActive,
      isOwner,
      ownerName,
      pageSection,
      pitchFor,
      shareByEmail,
      shareBySms,
      shareByWhatsApp,
      shareOnTwitter,
      shareQrCode,
      socialShareUrl,
      targetAmount,
      targetCurrency,
    } = this.props;

    const { copyLinkUrl } = this.state;

    return (
      <Modal
        isOpen
        contentLabel="Share your page"
        onClose={closeSharePrompts}
        colorTheme="dark"
        maintainPosition
      >
        <AnalyticsPageView
          eventValue="share your page with friends"
          pageSection="admin > page creation > your page is ready"
          subtype="admin"
        >
          <div styleName="container">
            <div>
              <header className="jg-background--white-smoke jg-space-pms jg-bdb jg-bd--solid jg-bd--very-light-grey">
                <h1 className="jg-h3 jg-space-mbsm">Share your Page</h1>
                <p>Let everyone know about your Page.</p>
                <p>
                  Sharing your Page to facebook can boost your donations by an
                  average of {currencySymbol}
                  100.
                </p>
              </header>
              <div styleName="social-actions">
                <div className="jg-space-mbms">
                  <FacebookShare
                    isActive={isActive}
                    location={pageSection}
                    buttonText="Share on Facebook"
                  />
                </div>
                <EmailShare
                  shareByEmail={shareByEmail}
                  socialShareUrl={socialShareUrl}
                  targetAmount={targetAmount}
                  pitchFor={pitchFor}
                  ownerName={ownerName}
                  isActive={isActive}
                  targetCurrency={targetCurrency}
                  location={pageSection}
                  className="dna-button dna-space-mbm"
                  buttonText="Share via email"
                  customStyle="email"
                  messageType={this.getEmailMessageType()}
                />
                {!isDesktopViewport() && (
                  <SmsShare
                    shareBySms={shareBySms}
                    socialShareUrl={socialShareUrl}
                    targetAmount={targetAmount}
                    pitchFor={pitchFor}
                    ownerName={ownerName}
                    isActive={isActive}
                    isOwner={isOwner}
                    targetCurrency={targetCurrency}
                    location={pageSection}
                    className="dna-button dna-space-mbm"
                    buttonText="Share via SMS"
                    customStyle="sms"
                  />
                )}
                <WhatsAppShare
                  shareByWhatsApp={shareByWhatsApp}
                  socialShareUrl={socialShareUrl}
                  targetAmount={targetAmount}
                  pitchFor={pitchFor}
                  ownerName={ownerName}
                  isActive={isActive}
                  targetCurrency={targetCurrency}
                  location={pageSection}
                  className="dna-button-whatsapp dna-space-mbm"
                  buttonText="Share on WhatsApp"
                  messageType={this.getWhatsAppMessageType()}
                />
                <TwitterShare
                  shareOnTwitter={shareOnTwitter}
                  socialShareUrl={socialShareUrl}
                  targetAmount={targetAmount}
                  pitchFor={pitchFor}
                  isActive={isActive}
                  isOwner={isOwner}
                  targetCurrency={targetCurrency}
                  location={pageSection}
                  className="dna-button dna-button-twitter dna-space-mbm"
                  buttonText="Share on Twitter"
                />
                <div className="jg-space-mbms">
                  <button
                    className="jg-text--large-regular"
                    styleName="copy-url-action"
                    onClick={this.copyLink}
                  >
                    Copy Link
                  </button>
                  <input
                    ref={c => {
                      this.textInput = c as HTMLInputElement;
                    }}
                    styleName="copy-url"
                    type="text"
                    readOnly
                    value={copyLinkUrl}
                  />
                </div>

                <QRCodeShare
                  shareQrCode={shareQrCode}
                  isActive={isActive}
                  buttonText="Share my QR Code"
                  location={pageSection}
                  handleClick={this.handleQRCodeDisplay}
                />
              </div>
            </div>
          </div>
        </AnalyticsPageView>
      </Modal>
    );
  }
}

export default CSSModules(SharePrompts, styles);
