import { Email } from './Email';

export class SupporterShareEmail extends Email {
  getSubject = () => {
    return super.i18nWithEnrichment(
      'Share.email.supporter.subject',
      "Donate to {{ownerName}}'s Crowdfunding Page to {{pitchFor}}"
    );
  };

  getBody = () => {
    return super.i18nWithEnrichment(
      'Share.email.supporter.body',
      'Hello,\nCan you help {{ownerName}} raise {{targetAmountFormatted}} to {{pitchFor}}? Please donate to their JustGiving Crowdfunding Page:\n\n{{- pageUrl}}\n\nThanks for your support\n\n\nPS. With JustGiving Crowdfunding anyone can raise money to fund their own project - anything from setting up a foodbank, to buying a wheelchair for a relative or even saving a local football club. Want to raise money to make good things happen? Start your Crowdfunding Page today.\n\n{{- homeUrl}}'
    );
  };
}
