import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import Alert from 'src/components/Alert/';
import { AlertRulesEngineOptions, hasDismissedAlert } from 'src/helpers/alerts';

const StripeVerificationPendingAlert: Rule<
  JSX.Element,
  AlertRulesEngineOptions
> = (state, options) => {
  const {
    legalEntityVerificationStatus,
    totalRequiredFields,
  } = state.stripeVirtualAccount;
  const alertKey = 'stripe_verification_pending';

  if (
    totalRequiredFields === 0 &&
    legalEntityVerificationStatus === 'Pending' &&
    !hasDismissedAlert(state.page.id, alertKey)
  ) {
    return {
      matched: true,
      result: (
        <Alert
          id={alertKey}
          type="amber"
          onClose={options!.onClose}
          page={state.page}
        >
          <p className="jg-space-mbn">
            We're just checking your details and we'll let you know if there's
            anything else you need to do.
          </p>
        </Alert>
      ),
    };
  }

  return { matched: false };
};

export default StripeVerificationPendingAlert;
