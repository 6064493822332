import { push } from 'react-router-redux';
import { activate } from 'src/helpers/api/pages';
import { setActionAsComplete } from 'src/helpers/api/completedActions';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';
import { loadPage } from 'src/redux/modules/page';

export interface StripeIdentityData {
  dateOfBirth: string | Date;
  emailAddress: string;
  ssnLast4: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    country: string;
    postCode: string;
  };
}

export interface ActivateRequestAction {
  type: 'ACTIVATE_REQUEST';
}

export interface ActivateSuccessAction {
  type: 'ACTIVATE_SUCCESS';
}

export interface ActivateFailureAction {
  type: 'ACTIVATE_FAILURE';
  error: true;
  payload: { message?: string } | Error;
}

export function activatePage(identityData?: StripeIdentityData) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<ActivateRequestAction>({
      type: 'ACTIVATE_REQUEST',
    });

    try {
      const { page } = getState();

      const result = await activate(page.id, identityData);

      if (result.activated) {
        await setActionAsComplete(page.id, 'tip_publish');
        dispatch<ActivateSuccessAction>({ type: 'ACTIVATE_SUCCESS' });
      } else {
        dispatch<ActivateFailureAction>({
          type: 'ACTIVATE_FAILURE',
          error: true,
          payload: { message: result.reason },
        });
      }

      dispatch(loadPage(page.name));
      dispatch(push(`/${page.name}`));
    } catch (error) {
      dispatch<ActivateFailureAction>({
        type: 'ACTIVATE_FAILURE',
        error: true,
        payload: error,
      });

      throw new Error('An error has occurred. Please try again.');
    }
  };
}
