import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './ConfigurableDesktopTooltip.scss';
import { ClickAnalyticsOptions } from 'src/redux/modules/analytics';
import TooltipClickTrigger from './TooltipClickTrigger';
import info from '@justgiving/icons/lib/info.svg';
import TooltipHoverTrigger from 'src/components/InfoWithTooltip/TooltipHoverTrigger';

export type PositionTypes = 'top' | 'bottom' | 'left' | 'right' | null;

interface Props {
  analyticsOptions?: ClickAnalyticsOptions;
  arrowPosition?: PositionTypes;
  className?: string;
  displayInline?: boolean;
  icon?: string;
  iconClassName?: string;
  onClick?: () => void;
  tooltipPosition?: PositionTypes;
  trigger?: 'hover' | 'click';
}

const positionMap = {
  bottom: styles['tooltip-bottom'],
  left: styles['tooltip-left'],
  right: styles['tooltip-right'],
  top: styles['tooltip-top'],
};

const arrowMap = {
  bottom: styles['arrow-bottom'],
  left: styles['arrow-left'],
  right: styles['arrow-right'],
  top: styles['arrow-top'],
};

class Tooltip extends React.Component<Props> {
  render() {
    const {
      analyticsOptions,
      arrowPosition = null,
      className,
      displayInline,
      icon = info,
      iconClassName,
      onClick,
      tooltipPosition = null,
      trigger = 'click',
    } = this.props;

    const arrowStyles = arrowPosition !== null ? arrowMap[arrowPosition] : null;

    const positionStyles =
      tooltipPosition !== null ? positionMap[tooltipPosition] : positionMap.top;

    if (trigger === 'click') {
      return (
        <TooltipClickTrigger
          analyticsOptions={analyticsOptions}
          className={className}
          displayInline={displayInline}
          icon={icon}
          iconClassName={iconClassName}
          onClick={onClick}
          tooltipPosition={positionStyles}
          arrowPosition={arrowStyles}
        >
          {this.props.children}
        </TooltipClickTrigger>
      );
    }

    if (trigger === 'hover') {
      return (
        <TooltipHoverTrigger
          analyticsOptions={analyticsOptions}
          arrowPosition={arrowStyles}
          className={className}
          displayInline={displayInline}
          icon={icon}
          iconClassName={iconClassName}
          tooltipPosition={positionStyles}
        >
          {this.props.children}
        </TooltipHoverTrigger>
      );
    }

    return (
      <TooltipClickTrigger
        analyticsOptions={analyticsOptions}
        className={className}
        displayInline={displayInline}
        icon={icon}
        iconClassName={iconClassName}
        onClick={onClick}
        tooltipPosition={positionStyles}
      >
        {this.props.children}
      </TooltipClickTrigger>
    );
  }
}

export default CSSModules(Tooltip, styles);
