import { get } from './common';

export enum BankAccountStatus {
  New = 'New',
  Validated = 'Validated',
  Verified = 'Verified',
  VerificationFailed = 'VerificationFailed',
  Errored = 'Errored',
}

export interface PaymentDetailsResource {
  accountEmailAddress?: string;
  accountHolder: string;
  accountNumber: string;
  bankName: string;
  isPayPal: boolean;
  sortCode: string;
  bankAccountStatus?: BankAccountStatus;
}

export async function getPaymentDetails({
  pageId,
}: {
  pageId: string;
}): Promise<PaymentDetailsResource> {
  const url = `projects/${pageId}/paymentdetails`;

  const data = await get<PaymentDetailsResource>(url, {
    timingName: 'paymentDetails',
    clientSideTimeout: 2000,
  });

  if (!data) {
    throw new Error('Payment details not found');
  }

  return data;
}
