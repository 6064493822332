import React from 'react';
import CSSModules from 'react-css-modules';
import { floor, calculatePercentage } from '../../helpers/math';
import Totaliser from '../Totaliser/Totaliser';
import styles from './Progress.scss';
import FormattedCurrency from 'src/components/FormattedCurrency';
import { FormattedMessage } from 'react-intl';
import { OwnerAvatar } from '../Avatar';

interface Props {
  raised: number;
  targetAmount: number;
  targetCurrency: Currency;
  totalCountSupporters: number;
  defaultMessage: string;
  shouldShowProfileImage?: boolean;
  profileImage?: string;
  ownerName?: string;
}

const Progress = ({
  raised,
  targetAmount,
  targetCurrency,
  totalCountSupporters,
  defaultMessage,
  shouldShowProfileImage,
  profileImage,
  ownerName,
}: Props) => {
  const percentage = calculatePercentage(raised, targetAmount);
  const targetFloor = floor(targetAmount, 0);
  const raisedFloor = floor(raised, 0);

  return (
    <div styleName="progress">
      {shouldShowProfileImage && profileImage ? (
        <div className="jg-center-block">
          <div styleName="totaliser-avatar-wrapper">
            <OwnerAvatar
              src={profileImage}
              alt={ownerName}
              size="totaliser"
              template="Size258w"
              className="totaliser-avatar"
            />
          </div>
        </div>
      ) : (
        <Totaliser percentage={percentage} />
      )}
      <div styleName="target">
        <span className="jg-text--brand-large" styleName="raised">
          <FormattedCurrency
            value={raisedFloor}
            currency={targetCurrency}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </span>
        <div styleName="value" className="jg-space-phlg@xl">
          <FormattedMessage
            id="Progress.raisedOf"
            defaultMessage={defaultMessage}
            values={{
              target: (
                <span className="jg-color--primary">
                  <b>
                    <FormattedCurrency
                      value={targetFloor}
                      currency={targetCurrency}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  </b>
                </span>
              ),
              supporters: (
                <b>
                  <FormattedMessage
                    id="Progress.raisedOf.supporters"
                    defaultMessage="{supportersCount} {supportersCount, plural, one {supporter} other {supporters}}"
                    values={{
                      supportersCount: totalCountSupporters,
                    }}
                  />
                </b>
              ),
              ownerName: <b>{ownerName}</b>,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CSSModules(Progress, styles);
