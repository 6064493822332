import { get, post, patch as _patch, del, ApiError } from './common';
import { convertProblemToRichStory } from 'src/helpers/richEditor';
import { removeNewLines } from 'src/helpers/string';
import { StripeIdentityData } from 'src/redux/modules/page/activatePage';
import { TipScheme } from 'src/redux/modules/page/reducer';

const pageGetServerSideTimeout = getPageRequestTimeout();

export interface PageResource {
  id: string;
  name: string;

  categoryId?: number;
  initialImage?: PageImageResource;

  status: PageStatus;

  amountPledged?: number;
  amountRaised: number;

  createdAt: string;
  activatedAt?: string;
  finishedAt?: string;

  latitude: number;
  longitude: number;
  locationText?: string;

  isOwnerRequested: boolean;
  ownerUserId: string;
  ownerBio?: string;
  ownerEmailAddress: string;
  ownerName: string;

  pitchBecause: string;
  pitchFor: string;
  targetAmount: number;
  targetCurrency: Currency;

  care?: string;
  deadline?: string;
  money?: string;
  problem?: string;
  richStory?: string;

  completeImage?: string;
  report?: string;

  thankYou?: string;

  sunset: boolean;

  facebookShareImage?: string;
  socialShareUrl: string;
  facebookCommentsUrl: string;

  verificationStatus: VerificationStatus;
  donationCauseId: string;

  fundsReleaseMode: FundsReleaseMode;
  isReviewed: boolean;

  stripeVirtualAccountGuid?: string;

  tipScheme: TipScheme;

  charityId?: number;

  firstDonationDate?: string;
  refundedDate?: string;

  recurringDonationsPitch?: string;

  totalVerificationsFailed?: number;
}

export interface PageImageResource {
  name: string;
  width?: number;
  height?: number;
  cropArea?: {
    left: number;
    top: number;
    width: number;
    height: number;
  };
}

export type PageStatus =
  | 'Lead'
  | 'Preview'
  | 'PendingApproval'
  | 'Approved'
  | 'Declined'
  | 'Active'
  | 'TargetNotReached'
  | 'FullyFunded'
  | 'ReportGenerated'
  | 'Abandoned'
  | 'Cancelled'
  | 'Suspended'
  | 'Stranded';

export type VerificationStatus =
  | 'UnverifiedPayment'
  | 'UnverifiedManual'
  | 'Verified';

export type FundsReleaseMode = 'OnDemand' | 'OnCompletion';

interface HeaderNode {
  type: 'header';
  text: string;
}

interface QuoteNode {
  type: 'quote';
  text: string;
}

interface VideoNode {
  type: 'video';
  url: string;
}

interface ImageNode {
  type: 'image';
  name: string;
}

interface LinkNode {
  type: 'link';
  url: string;
  ranges: TextRange[];
}

interface TextNode {
  type: 'text';
  ranges: TextRange[];
}

export interface ParagraphNode {
  type: 'paragraph';
  nodes: (LinkNode | TextNode)[];
}

export interface TextRange {
  text: string;
}

export type StoryNode =
  | HeaderNode
  | QuoteNode
  | VideoNode
  | ImageNode
  | LinkNode
  | TextNode
  | ParagraphNode;

export async function getByName(
  pageName: string
): Promise<PageResource & { richStory: StoryNode[] } | null> {
  const data = await get<PageResource>(`projects/${pageName}`, {
    serverSideTimeout: pageGetServerSideTimeout,
    timingName: 'page',
  });

  if (!data) {
    return null;
  }

  let richStory;

  if (data.richStory) {
    try {
      richStory = JSON.parse(data.richStory);
    } catch (e) {
      richStory = [];
    }
  } else {
    richStory = convertProblemToRichStory(data.problem || '');
  }

  return {
    ...data,
    richStory,
  };
}

export interface CreatePagePayload {
  name: string;
  categoryId: number;
  targetAmount: number;
  targetCurrency: Currency;
  pitchFor: string;
  charityId?: number;
  commitmentType?: 'Endorsement' | 'Commitment';
}

export async function create(page: CreatePagePayload): Promise<PageResource> {
  const response = await post(
    'projects',
    {
      ...page,
      pitchFor: removeNewLines(page.pitchFor),
    },
    { throwOnError: false }
  );

  if (response.ok) {
    return response.body;
  }

  if (response.badRequest) {
    throw response.body;
  }

  throw new ApiError({ response });
}

interface ActivateResult {
  activated: boolean;
  reason?: 'riskAnalysisFailed' | 'pageAlreadyActive';
}

export async function activate(
  pageId: string,
  identityData?: StripeIdentityData
): Promise<ActivateResult> {
  const response = await post(
    `projects/${pageId}/activations`,
    { projectId: pageId, owner: identityData },
    { throwOnError: false }
  );

  if (response.ok) {
    return { activated: true };
  }

  if (
    response.status === 400 &&
    response.body &&
    response.body.message === 'err:project-already-active'
  ) {
    return { activated: false, reason: 'pageAlreadyActive' };
  }

  if (response.status === 403) {
    return { activated: false, reason: 'riskAnalysisFailed' };
  }

  throw new ApiError({ response });
}

export interface PatchPagePayload {
  name: string;
  id?: string;
  categoryId?: number | null;
  targetAmount?: number;
  targetCurrency?: Currency;
  care?: string;
  deadline?: string;
  money?: string;
  problem?: string;
  richStory?: StoryNode[];
  completeImage?: string;
  report?: string;
  thankYou?: string;
  initialImage?: {
    name: string;
  };
  socialShareImage?: {
    name: string;
  };
  finishedAt?: string;
  latitude?: number;
  longitude?: number;
  locationText?: string;
  ownerName?: string;
  ownerBio?: string;
  pitchFor?: string;
  recurringDonationsPitch?: string;
}

export function patch(page: PatchPagePayload): Promise<{}> {
  const data: any = { ...page };

  if (page.richStory) {
    data.richStory = JSON.stringify(page.richStory);
  }

  if (page.pitchFor) {
    data.pitchFor = removeNewLines(page.pitchFor);
  }

  return _patch(`projects/${page.id || page.name}`, data);
}

interface DeletePagePayload {
  name: string;
}

export function delPage(page: DeletePagePayload): Promise<{}> {
  return del(`projects/${page.name}`);
}

function getPageRequestTimeout() {
  // prevent server config from leaking into webpack build
  if (__SERVER__) {
    return require('../../../config/server.config').pageRequestTimeoutMs;
  }

  return null;
}
