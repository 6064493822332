import {
  ThankYouImagePreset,
  exportThankYouImage,
} from 'src/helpers/images/thankYouImage';
import {
  UpdateMediaResource,
  UpdateResource,
  getUpdate,
  getUpdates,
  publishUpdate as publish,
  deleteUpdate as removeUpdate,
} from 'src/helpers/api/updates';

import { AnalyticsMeta } from 'src/redux/modules/analytics';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { PageState } from 'src/redux/modules/page';
import { buildUrl } from 'src/helpers/url';
import currencyMap from 'src/helpers/currencyMap';
import { exportMilestoneImage } from 'src/helpers/images/milestoneImage';
import { generateHash } from 'src/helpers/hashId';
import { getUserToken } from 'src/helpers/facebook';
import { getVideoId } from 'src/helpers/youTubeParser';
import { isClosed } from 'src/helpers/page';
import log from 'src/logging';
import { matchPath } from 'react-router';
import { push } from 'react-router-redux';
import { reset } from 'redux-form';
import { uploadImage } from 'src/helpers/api/images';

const DEFAULT_PAGE_SIZE = 3;

export type RichUpdateType = 'update' | 'thank' | 'milestone';

export type RichUpdatesActions =
  | PublishRichUpdateRequestAction
  | PublishRichUpdateSuccessAction
  | PublishRichUpdateFailureAction
  | RichUpdatesRequestAction
  | RichUpdatesSuccessAction
  | RichUpdatesFailureAction
  | RichUpdatesDeleteUpdateRequestAction
  | RichUpdatesDeleteUpdateSuccessAction
  | RichUpdatesDeleteUpdateFailureAction
  | ClearRichUpdatePublishingError
  | WatchingVideoAction
  | ScrolledToAction
  | ScrollToAction;

/*-------------------------------------------------
  Publish update
-------------------------------------------------*/

export interface PublishRichUpdateRequestAction {
  type: 'PUBLISH_RICH_UPDATE_REQUEST';
  meta?: {
    analytics: AnalyticsMeta;
  };
}

export interface PublishRichUpdateSuccessAction {
  type: 'PUBLISH_RICH_UPDATE_SUCCESS';
  payload: UpdateResource;
}

export interface PublishRichUpdateFailureAction {
  type: 'PUBLISH_RICH_UPDATE_FAILURE';
  payload: Error;
  error: true;
}

export interface ClearRichUpdatePublishingError {
  type: 'CLEAR_RICH_UPDATE_PUBLISHING_ERROR';
}

export function clearRichUpdatePublishingError() {
  return {
    type: 'CLEAR_RICH_UPDATE_PUBLISHING_ERROR',
  };
}

export interface PublishRichUpdateData {
  message?: string;
  image?: File | Blob;
  video?: string;
  type: 'Update' | 'Thanks' | 'Milestone';
  shareToFacebook?: boolean;
}

type ImageResource = File | Blob;
type VideoResource = string;

interface MediaResource {
  mediaType: 'image' | 'video';
  resource: ImageResource | VideoResource;
}

export async function prepareMedia({
  mediaType,
  resource,
}: MediaResource): Promise<UpdateMediaResource[]> {
  const uploadedMedia: UpdateMediaResource[] = [];

  if (mediaType === 'image') {
    const uploadResult = await uploadImage(resource as ImageResource);

    if (uploadResult.success) {
      uploadedMedia.push({
        path: uploadResult.data.imageName,
        type: 1,
      });
    }
  } else if (mediaType === 'video') {
    const videoId = getVideoId(resource as VideoResource);

    if (videoId) {
      uploadedMedia.push({ path: videoId, type: 2 });
    }
  }

  return uploadedMedia;
}

interface ShareUpdateToFacebook {
  link: string;
  picture: string | null;
  message?: string;
  name: string | null;
  description: string | null;
  ref: string | null;
  authStatus: string;
}

async function shareUpdateToFacebook({
  link,
  picture,
  message,
  name,
  description,
  ref,
  authStatus,
}: ShareUpdateToFacebook) {
  if (!picture) {
    FB.ui(
      {
        method: 'share',
        quote: message,
        href: link,
      },
      () => void 0
    );
  } else {
    FB.ui(
      {
        method: 'share',
        quote: message,
        href: link,
      },
      () => void 0
    );
  }
}

interface ShareUpdateToFacebookSuccess {
  type: string;
  utmHash: string;
}

function shareUpdateToFacebookSuccess({
  type,
  utmHash,
}: ShareUpdateToFacebookSuccess) {
  return {
    type: 'SHARE_UPDATE_TO_FACEBOOK_SUCCESS',
    meta: {
      analytics: {
        event: 'click',
        subtype: 'share',
        event_value: 'share to facebook',
        page_section: `admin > update > ${type.toLowerCase()}`,
        social_type: 'feed',
        activity_type: 'crowdfunding_guid',
        social_utm_term: utmHash,
      },
    },
  };
}

const getImageMediaPath = (media: UpdateMediaResource): string | null => {
  if (media && media.type === 1) {
    return media.path;
  }
  return null;
};

const resetUpdateForms = (dispatch: any) => {
  dispatch(reset('update'));
  dispatch(reset('thanks'));
  dispatch(reset('milestone'));
};

export function publishRichUpdate(update: PublishRichUpdateData) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const { page } = getState();

    try {
      dispatch<PublishRichUpdateRequestAction>({
        type: 'PUBLISH_RICH_UPDATE_REQUEST',
      });

      let userToken = null;
      let sharedToFacebook = false;

      if (update.shareToFacebook) {
        userToken = await getUserToken();
      }

      let media: UpdateMediaResource[] = [];

      if (update.image || update.video) {
        const mediaType = update.image ? 'image' : 'video';

        media = await prepareMedia({
          mediaType,
          resource: update.image
            ? (update.image as ImageResource)
            : (update.video as VideoResource),
        });
      }

      const result = await publish({
        projectId: page.id,
        message: update.message,
        media,
        type: update.type,
      });

      dispatch<PublishRichUpdateSuccessAction>({
        type: 'PUBLISH_RICH_UPDATE_SUCCESS',
        payload: { ...result, sharedToFacebook },
      });

      dispatch(
        push(`/${page.name}/update/share`, {
          updateId: result.id,
          message: update.message,
          type: 'update',
        })
      );
      resetUpdateForms(dispatch);
      if (update.shareToFacebook && userToken) {
        const utmHash = await shareToFacebook(page, {
          userToken,
          media: result.media[0],
          message: update.message,
          updateId: result.id,
        });

        dispatch(
          shareUpdateToFacebookSuccess({
            utmHash,
            type: 'update',
          })
        );

        sharedToFacebook = true;
      }
    } catch (error) {
      dispatch<PublishRichUpdateFailureAction>({
        type: 'PUBLISH_RICH_UPDATE_FAILURE',
        payload: error,
        error: true,
      });
    }
  };
}

/*-------------------------------------------------
  Publish thank update
-------------------------------------------------*/

export interface PublishThankUpdateData {
  message: string;
  shareToFacebook: boolean;
  text: string;
  preset: ThankYouImagePreset;
}

export function publishThankUpdate(update: PublishThankUpdateData) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const { page } = getState();

    try {
      dispatch<PublishRichUpdateRequestAction>({
        type: 'PUBLISH_RICH_UPDATE_REQUEST',
      });

      let userToken = null;
      let sharedToFacebook = false;

      if (update.shareToFacebook) {
        userToken = await getUserToken();
      }

      const image = await exportThankYouImage({
        text: update.text,
        preset: update.preset,
        width: 1200,
        height: 630,
      });

      const media = await prepareMedia({
        mediaType: 'image',
        resource: image,
      });

      const result = await publish({
        projectId: page.id,
        message: update.message,
        media,
        type: 'Thanks',
      });

      if (update.shareToFacebook && userToken) {
        const utmHash = await shareToFacebook(page, {
          userToken,
          media: result.media[0],
          message: update.message,
          updateId: result.id,
        });

        dispatch(
          shareUpdateToFacebookSuccess({
            utmHash,
            type: 'thanks',
          })
        );

        sharedToFacebook = true;
      }

      dispatch(
        push(`/${page.name}/update/share`, {
          updateId: result.id,
          message: update.message,
          type: 'thank',
        })
      );
      resetUpdateForms(dispatch);

      dispatch<PublishRichUpdateSuccessAction>({
        type: 'PUBLISH_RICH_UPDATE_SUCCESS',
        payload: { ...result, sharedToFacebook },
      });
    } catch (error) {
      dispatch<PublishRichUpdateFailureAction>({
        type: 'PUBLISH_RICH_UPDATE_FAILURE',
        payload: error,
        error: true,
      });
    }
  };
}

/*-------------------------------------------------
  Publish milestone update
-------------------------------------------------*/

export interface PublishMilestoneUpdateData {
  message: string;
  shareToFacebook: boolean;
  preset: ThankYouImagePreset;
  percentage: number;
  targetAmount: string;
}

export function publishMilestoneUpdate(update: PublishMilestoneUpdateData) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const { page } = getState();

    try {
      dispatch<PublishRichUpdateRequestAction>({
        type: 'PUBLISH_RICH_UPDATE_REQUEST',
      });

      let userToken = null;
      let sharedToFacebook = false;

      if (update.shareToFacebook) {
        userToken = await getUserToken();
      }

      const image = await exportMilestoneImage({
        width: 1200,
        height: 630,
        preset: update.preset,
        percentage: update.percentage,
        targetAmount: update.targetAmount,
      });

      const media = await prepareMedia({
        mediaType: 'image',
        resource: image,
      });

      const result = await publish({
        projectId: page.id,
        message: update.message,
        media,
        type: 'Milestone',
      });

      if (update.shareToFacebook && userToken) {
        const utmHash = await shareToFacebook(page, {
          userToken,
          media: result.media[0],
          message: update.message,
          updateId: result.id,
        });

        dispatch(
          shareUpdateToFacebookSuccess({
            utmHash,
            type: 'milestone',
          })
        );

        sharedToFacebook = true;
      }

      dispatch(
        push(`/${page.name}/update/share`, {
          updateId: result.id,
          message: update.message,
          type: 'milestone',
        })
      );
      resetUpdateForms(dispatch);

      dispatch<PublishRichUpdateSuccessAction>({
        type: 'PUBLISH_RICH_UPDATE_SUCCESS',
        payload: { ...result, sharedToFacebook },
      });
    } catch (error) {
      dispatch<PublishRichUpdateFailureAction>({
        type: 'PUBLISH_RICH_UPDATE_FAILURE',
        payload: error,
        error: true,
      });
    }
  };
}

/*-------------------------------------------------
  Share to Facebook
-------------------------------------------------*/

interface ShareToFacebook {
  userToken: string;
  media: UpdateMediaResource;
  message?: string;
  updateId: number;
}

function shareToFacebook(
  page: PageState,
  { userToken, media, message, updateId }: ShareToFacebook
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    const isPageClosed = isClosed(page);
    const { targetCurrency, amountRaised, pitchFor, socialShareUrl } = page;

    try {
      const utmHash = generateHash();
      await shareUpdateToFacebook({
        authStatus: userToken,
        picture: getImageMediaPath(media) || null,
        message,
        link: buildUrl(`${socialShareUrl}/updates/${updateId.toString(16)}`, {
          utm_term: utmHash,
        }),
        name: isPageClosed
          ? `I've raised ${currencyMap[targetCurrency] ||
              '£'}${amountRaised} on JustGiving to ${pitchFor}`
          : null,
        description: isPageClosed ? 'Thank you for your support' : null,
        ref: null,
      });
      resolve(utmHash);
    } catch (err) {
      reject();
    }
  });
}

/*-------------------------------------------------
  Load updates
-------------------------------------------------*/

interface LoadUpdatesOptions {
  pageId: string;
  pageNo?: number;
  pageSize?: number;
}

export interface RichUpdatesRequestAction {
  type: 'RICH_UPDATES_REQUEST';
}

export interface UpdatesById {
  [key: number]: UpdateResource;
}

export interface RichUpdatesSuccessAction {
  type: 'RICH_UPDATES_SUCCESS';
  payload: {
    pageNo: number;
    pageSize: number;
    totalCount: number;
    updatesById: UpdatesById;
    allUpdateIds: number[];
    linkedUpdate: UpdateResource;
  };
}

export interface RichUpdatesFailureAction {
  type: 'RICH_UPDATES_FAILURE';
  error: true;
  payload: Error;
}

function getLinkedUpdateId(state: AppState) {
  const path =
    state.routing && state.routing.location && state.routing.location.pathname;
  let updateId = 0;
  if (path) {
    const match = matchPath<{ name: string; updateId: string }>(path, {
      path: '/:name/updates/:updateId', // trying to look through routes for the path failed - magic string was the only option
    });
    if (match) {
      try {
        updateId = parseInt(match.params.updateId, 16);
      } catch (error) {
        log.error({ error }, 'invalid update id');
      }
    }
  }

  return updateId;
}

export function loadUpdates({
  pageId,
  pageNo = 1,
  pageSize = DEFAULT_PAGE_SIZE,
}: LoadUpdatesOptions) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<RichUpdatesRequestAction>({
      type: 'RICH_UPDATES_REQUEST',
    });

    const state = getState();
    const currentPageNo = state.richUpdates.updatesPageNo || 0;

    if (pageNo !== 1 && pageNo <= currentPageNo) {
      return;
    }

    try {
      const data = await getUpdates(pageId, pageNo, pageSize);

      const allUpdateIds: number[] = [];

      const updatesById = data.updates.reduce((object: UpdatesById, update) => {
        object[update.id] = update;
        allUpdateIds.push(update.id);
        return object;
      }, {});

      let linkedUpdate: UpdateResource = {
        createdAt: '',
        friends: [],
        id: 0,
        media: [],
        message: '',
        projectId: '',
        type: 0,
      };
      const linekdUpdateId = getLinkedUpdateId(state);
      if (linekdUpdateId > 0) {
        const update = await getUpdate(pageId, linekdUpdateId);
        if (update !== null) {
          linkedUpdate = update;
        }
      }

      dispatch<RichUpdatesSuccessAction>({
        type: 'RICH_UPDATES_SUCCESS',
        payload: {
          pageNo: data.pageNo,
          pageSize: data.pageSize,
          totalCount: data.totalCount,
          updatesById,
          allUpdateIds,
          linkedUpdate,
        },
      });
    } catch (err) {
      dispatch<RichUpdatesFailureAction>({
        type: 'RICH_UPDATES_FAILURE',
        error: true,
        payload: err,
      });
    }
  };
}

/*-------------------------------------------------
  Delete update
-------------------------------------------------*/

export interface RichUpdatesDeleteUpdateRequestAction {
  type: 'RICH_UPDATES_DELETE_UPDATE_REQUEST';
  payload: {
    updateId: number;
  };
  meta: { analytics: AnalyticsMeta & { card_id: number } };
}

export interface RichUpdatesDeleteUpdateSuccessAction {
  type: 'RICH_UPDATES_DELETE_UPDATE_SUCCESS';
  payload: {
    updateId: number;
    nextUpdate: UpdateResource | null | undefined;
  };
}

export interface RichUpdatesDeleteUpdateFailureAction {
  type: 'RICH_UPDATES_DELETE_UPDATE_FAILURE';
  error: true;
  payload: Error;
}

export function deleteRichUpdate(updateId: number) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<RichUpdatesDeleteUpdateRequestAction>({
      type: 'RICH_UPDATES_DELETE_UPDATE_REQUEST',
      payload: { updateId },
      meta: {
        analytics: {
          event: 'click',
          subtype: 'button',
          event_value: 'delete post',
          page_section: 'updates',
          card_id: updateId,
          actor_source: 'page owner update',
        },
      },
    });

    try {
      const state = getState();
      const pageId = state.page.id;
      const pageNo = state.richUpdates.updatesPageNo;
      const count = state.richUpdates.totalCount;

      await removeUpdate({ projectId: pageId, updateId });

      let nextUpdate = null;

      if (count > DEFAULT_PAGE_SIZE) {
        const newPage = await getUpdates(pageId, pageNo, DEFAULT_PAGE_SIZE);

        nextUpdate =
          newPage.updates.length === DEFAULT_PAGE_SIZE
            ? newPage.updates.pop()
            : null;
      }

      dispatch<RichUpdatesDeleteUpdateSuccessAction>({
        type: 'RICH_UPDATES_DELETE_UPDATE_SUCCESS',
        payload: {
          updateId,
          nextUpdate,
        },
      });
    } catch (error) {
      dispatch<RichUpdatesDeleteUpdateFailureAction>({
        type: 'RICH_UPDATES_DELETE_UPDATE_FAILURE',
        error: true,
        payload: error,
      });
    }
  };
}

/*-------------------------------------------------
  Toggle watching video
-------------------------------------------------*/

export interface WatchingVideoAction {
  type: 'WATCHING_VIDEO_TOGGLE';
}

export function watchingVideoToggle() {
  return { type: 'WATCHING_VIDEO_TOGGLE' };
}

/*-------------------------------------------------
  CLear update forms
-------------------------------------------------*/

export function clearUpdateForms() {
  return (dispatch: Dispatch<AppState>) => {
    resetUpdateForms(dispatch);
  };
}

/*-------------------------------------------------
  Scrolled to
-------------------------------------------------*/
export interface ScrolledToAction {
  type: 'SCROLLED_TO_UPDATE';
}

export function scrolledToUpdate() {
  return {
    type: 'SCROLLED_TO_UPDATE',
  };
}

export interface ScrollToAction {
  type: 'SCROLL_TO_UPDATE';
}

export function scrollToUpdate() {
  return {
    type: 'SCROLL_TO_UPDATE',
  };
}
