import { push } from 'react-router-redux';
import { loadUserDetails } from 'src/redux/modules/self';
import { loadPaymentDetails } from 'src/redux/modules/paymentDetails';
import { loadCompletedActions } from 'src/redux/modules/completedActions';
import { loadUpdates } from 'src/redux/modules/richUpdates';
import { loadSupporters } from 'src/redux/modules/supporters';
import { getByName, PageResource } from 'src/helpers/api/pages';
import {
  isClosed,
  isEligibleForFlexiblePayments,
  isPendingApproval,
  canPostUpdates,
} from 'src/helpers/page';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { getVirtualAccountStatus } from 'src/redux/modules/stripeVirtualAccount/actions';
import { PageState } from 'src/redux/modules/page';
import { getFundsData } from 'src/redux/modules/funds';
import last from 'lodash/last';
import { getAll, CategoryResource } from 'src/helpers/api/categories';
import { BANK_VERIFICATION_RATE_LIMIT } from 'config';

export interface PageRequestAction {
  type: 'PAGE_REQUEST';
  payload: {
    name: string;
  };
}

export interface PageSuccessAction {
  type: 'PAGE_SUCCESS';
  payload: Partial<PageState>;
}

export interface PageFailureAction {
  type: 'PAGE_FAILURE';
  error: true;
  payload: {
    message: string;
    type: string;
    reason?: string;
    categoryId?: number | null;
  };
}

export function loadPage(name: string) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<PageRequestAction>({
      type: 'PAGE_REQUEST',
      payload: { name },
    });

    try {
      const data = await getByName(name);

      if (
        !data ||
        data.status === 'Declined' ||
        data.status === 'Cancelled' ||
        data.status === 'Abandoned' ||
        data.sunset
      ) {
        if (data && data.categoryId) {
          const categoriesResponse = await getAll();

          const chosenCategory = categoriesResponse.categories.find(
            (category: CategoryResource) => category.id === data.categoryId
          );

          if (!chosenCategory) {
            dispatch<PageFailureAction>({
              type: 'PAGE_FAILURE',
              error: true,
              payload: {
                message: `Page ${name} is not available`,
                type: '404',
                reason: data ? (data.sunset ? 'sunset' : data.status) : '404',
              },
            });
          } else {
            dispatch<PageFailureAction>({
              type: 'PAGE_FAILURE',
              error: true,
              payload: {
                message: `Page ${name} is not available`,
                type: '302',
                reason: data ? (data.sunset ? 'sunset' : data.status) : '302',
              },
            });

            dispatch(push(chosenCategory.url, true));
          }

          return;
        } else {
          dispatch<PageFailureAction>({
            type: 'PAGE_FAILURE',
            error: true,
            payload: {
              message: `Page ${name} is not available`,
              type: '404',
              reason: data ? (data.sunset ? 'sunset' : data.status) : '404',
            },
          });
        }
        return;
      }

      const pathname = getState().routing.location!.pathname;

      if (detectRedirects(data, pathname, dispatch)) {
        return;
      }

      const state: Partial<PageState> = { ...data };

      state.isCreateWizardOpen = isCreateWizardOpen(pathname);

      state.rateLimitReached =
        (data.totalVerificationsFailed || 0) >= BANK_VERIFICATION_RATE_LIMIT;

      dispatch<PageSuccessAction>({ type: 'PAGE_SUCCESS', payload: state });

      const promises = [
        dispatch<any>(loadSupporters({ pageId: data.id })),
        dispatch<any>(loadUpdates({ pageId: data.id })),
        dispatch<any>(loadUserDetails()),
      ];
      // HACK enroll only new new pages owners delete after finishing the AB test.

      if (data.isOwnerRequested) {
        promises.push(dispatch<any>(loadCompletedActions()));

        if (
          data.verificationStatus !== 'UnverifiedPayment' ||
          data.stripeVirtualAccountGuid
        ) {
          promises.push(dispatch<any>(loadPaymentDetails()));
        }

        if (data.stripeVirtualAccountGuid) {
          promises.push(dispatch<any>(getVirtualAccountStatus()));
        }

        promises.push(dispatch<any>(getFundsData()));
      }

      // All the promises have their own *_FAILURE actions, so we can
      // safely ignore all errors here. However, we want to wait for
      // all promises to execute to completion until we resolve
      // from this function, hence we catch and swallow all
      // exceptions on the individual promise level.
      await Promise.all(promises.map(p => p.catch(() => {})));
    } catch (err) {
      if (err.status === '404') {
        dispatch<PageFailureAction>({
          type: 'PAGE_FAILURE',
          error: true,
          payload: { message: 'Page is not available', type: '404' },
        });
      } else {
        dispatch<PageFailureAction>({
          type: 'PAGE_FAILURE',
          error: true,
          payload: err,
        });
      }
    }
  };
}

function isCreateWizardOpen(pathname: string): boolean {
  const paths = ['coverphoto', 'story', 'pagelink'];

  return pathname.split('/').some(p => paths.includes(p));
}

function detectRedirects(
  page: PageResource,
  pathname: string,
  dispatch: Dispatch<AppState>
) {
  // redirect from /{pageName} to /{pageName}/coverphoto for pages in "Lead" status
  if (
    page.isOwnerRequested &&
    page.status === 'Lead' &&
    !isWizardPath(pathname)
  ) {
    dispatch(push(`/${page.name}/coverphoto`));
    return true;
  }

  if (
    page.isOwnerRequested &&
    !page.problem &&
    !page.richStory &&
    !isOneOfWizardSteps(pathname, ['coverphoto', 'story'])
  ) {
    dispatch(push(`/${page.name}/story`));
    return true;
  }

  const pathSegments = pathname.split('/').filter(p => p.length > 0);

  // redirect from /{pageName}/payment to /{pageName} for pages not in UnverifiedPayment verification status
  if (
    pathSegments.includes('payment') &&
    page.verificationStatus !== 'UnverifiedPayment'
  ) {
    dispatch(push(`/${page.name}`));
    return true;
  }

  if (
    !page.isOwnerRequested &&
    (isWizardPath(pathname) || isOwnerTabPath(pathname))
  ) {
    dispatch(push(`/${page.name}`));
    return true;
  }

  if (pathSegments.includes('edit') && !canNavigateToEdit(page)) {
    dispatch(push(`/${page.name}`));
    return true;
  }

  if (last(pathSegments) === 'funds' && !canNavigateToFunds(page)) {
    dispatch(push(`/${page.name}`));
    return true;
  }

  if (
    (pathSegments.includes('dashboard') || pathSegments.includes('settings')) &&
    isPendingApproval(page)
  ) {
    dispatch(push(`/${page.name}`));
    return true;
  }

  if (pathSegments.includes('update') && !canPostUpdates(page)) {
    dispatch(push(`/${page.name}`));
    return true;
  }

  return false;
}

function isWizardPath(pathname: string) {
  const segments = pathname.split('/').filter(p => p.length > 0);
  const wizardSteps = ['coverphoto', 'story', 'pagelink', 'confirmidentity'];

  return segments.some(s => wizardSteps.includes(s));
}

function isOneOfWizardSteps(pathname: string, steps: string[]) {
  const segments = pathname.split('/').filter(p => p.length > 0);

  return segments.some(s => steps.includes(s));
}

function isOwnerTabPath(pathname: string) {
  const segments = pathname.split('/').filter(p => p.length > 0);
  const ownerTabs = ['edit', 'dashboard', 'settings', 'funds'];

  return segments.some(s => ownerTabs.includes(s));
}

function canNavigateToEdit(page: PageResource) {
  return page.isOwnerRequested && !isClosed(page);
}

function canNavigateToFunds(page: PageResource) {
  return (
    page.isOwnerRequested &&
    isEligibleForFlexiblePayments(page) &&
    !page.refundedDate
  );
}
