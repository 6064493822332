import React from 'react';
import CSSModules from 'react-css-modules';
import { OwnerAvatar } from 'src/components/Avatar';
import styles from './RichUpdatesTimelineItem.scss';

interface Props {
  ownerProfileImageUrl?: string;
  ownerName: string;
  date?: string;
}

const RichUpdatesTimelineItem: React.StatelessComponent<Props> = ({
  ownerProfileImageUrl,
  ownerName,
  date,
  children,
}) => (
  <div className={styles.container}>
    <div className={styles['avatar-wrapper']}>
      <OwnerAvatar
        src={ownerProfileImageUrl}
        alt={ownerName}
        className={styles.avatar}
      />
      {date && (
        <span className={`jg-text--xsmall-light ${styles.date}`}>{date}</span>
      )}
    </div>
    <div className={styles['content-wrapper']}>{children}</div>
  </div>
);

export default CSSModules(RichUpdatesTimelineItem, styles);
