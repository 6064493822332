import React from 'react';
import { Totaliser as ToolkitTotaliser } from '@justgiving/totaliser';
import { isMobileUserAgent } from 'src/helpers/browser';

interface Props {
  percentage: number;
  requestedSize?: 'small' | 'medium' | 'medium-large' | 'large';
}

const Totaliser: React.StatelessComponent<Props> = ({
  percentage,
  requestedSize,
}) => {
  const size = requestedSize
    ? requestedSize
    : isMobileUserAgent()
      ? 'medium'
      : 'large';

  return (
    <div className="jg-center-block">
      <ToolkitTotaliser percent={percentage} size={size} isAnimated />
    </div>
  );
};

export default Totaliser;
