/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export function detectIE(userAgent: string) {
  const msie = userAgent.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(
      userAgent.substring(msie + 5, userAgent.indexOf('.', msie)),
      10
    );
  }

  const trident = userAgent.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = userAgent.indexOf('rv:');
    return parseInt(
      userAgent.substring(rv + 3, userAgent.indexOf('.', rv)),
      10
    );
  }

  // other browser
  return false;
}

let fakeUserAgent: string;

export function getUserAgent(): string {
  if (__CLIENT__) {
    return navigator.userAgent;
  } else if (__SERVER__) {
    const requestStorage = require('../server/requestStorage').default;

    return requestStorage.get('userAgent');
  }

  return fakeUserAgent || '';
}

export function overrideUserAgent(userAgent: string) {
  fakeUserAgent = userAgent;
}

//  is LocalStorage and Session Available
const localAndSessionStorageHelper = (method: Storage) => {
  const test = 'isAvailable';
  method.setItem(test, 'true');
  const keyStored = method.getItem(test);
  method.removeItem(test);
  return keyStored && true;
};

export function isLocalStorageAvailable() {
  try {
    return localAndSessionStorageHelper(window.localStorage);
  } catch (e) {
    return false;
  }
}

export function isSessionStorageAvailable() {
  try {
    return localAndSessionStorageHelper(window.sessionStorage);
  } catch (e) {
    return false;
  }
}

export function isDesktopViewport() {
  return __SERVER__ ? true : window.matchMedia('(min-width: 768px)').matches;
}

export function isMobileUserAgent(): boolean {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  // > In summary, we recommend looking for the string “Mobi” anywhere in the User Agent to detect a mobile device.
  return !!getUserAgent().match(/Mobi/i);
}

export function isDateInputAvailable(): boolean {
  if (!__CLIENT__) {
    return false;
  }

  const input = document.createElement('input');
  input.setAttribute('type', 'date');

  const notADateValue = 'not-a-date';
  input.setAttribute('value', notADateValue);

  return input.value !== notADateValue;
}

export function isIOSUserAgent(): boolean {
  return !!getUserAgent().match(/iP(hone|od|ad)/i);
}

export function isAndroidUserAgent(): boolean {
  return !!getUserAgent().match(/Android/i);
}

export function isEdgeUserAgent(): boolean {
  return !!getUserAgent().match(/Edge/i);
}

export function setScrollRestoration(scrollRestoration: 'auto' | 'manual') {
  if (__CLIENT__ && 'history' in window) {
    history.scrollRestoration = scrollRestoration;
  }
}

export function isPropertyInLocalStorage(propertyName: string) {
  try {
    return !!window.localStorage.getItem(propertyName);
  } catch {
    return false;
  }
}

export function isPropertyInSessionStorage(propertyName: string) {
  try {
    return !!window.sessionStorage.getItem(propertyName);
  } catch {
    return false;
  }
}

export function isPropertyInLocalAndSessionStorage({
  localPropertyName,
  sessionPropertyName,
}: {
  localPropertyName: string;
  sessionPropertyName: string;
}) {
  return (
    isPropertyInLocalStorage(localPropertyName) &&
    isPropertyInSessionStorage(sessionPropertyName)
  );
}
