import { constructYouTubeThumbnailUrl } from '../helpers/youTubeParser';

export default function getMediaSource(media: { type: number; path: string }) {
  if (media && media.type === 1) {
    return media.path;
  }

  if (media && media.type === 2) {
    return constructYouTubeThumbnailUrl(media.path);
  }

  return null;
}
