import React from 'react';
import styles from './TabViewLinks.scss';
import Sticky from 'src/components/StickyHeader/Sticky';

const CHROME_HEADER_OFFSET = 58;

const TabViewLinks: React.StatelessComponent = ({ children }) => {
  if (!children) {
    throw new Error('TabView must contain at least one TabPanel.');
  }

  return (
    <Sticky offset={CHROME_HEADER_OFFSET} delay={50}>
      {({ sticky }) => (
        <>
          <div
            className={`jg-bdh jg-bdb jg-bd--solid jg-bd--very-light-grey jg-background--white ${
              sticky ? styles.fixed : ''
            }`}
          >
            <div className="container jg-background--white">
              <div className={styles['nav-bar']}>
                <div className={`${styles['nav-bar-container']}`}>
                  {children}
                </div>
              </div>
            </div>
          </div>
          {sticky && (
            <div style={{ height: 80 }} />
          ) /* used to ensure page does not jump when sticky header is fixed */}
        </>
      )}
    </Sticky>
  );
};

export default TabViewLinks;
