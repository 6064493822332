import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './RichUpdatesItemTooltip.scss';
import ClickableElement from 'src/components/ClickableElement';
import Svg from '@justgiving/svg';
import downChevron from '@justgiving/icons/lib/chevron-down.svg';

interface Props {
  updateId: number;
  deleteRichUpdate: (updateId: number) => void;
}

interface State {
  isOpen: boolean;
}

export class RichUpdatesItemTooltip extends React.Component<Props, State> {
  tooltip: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideTooltip);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideTooltip);
  }

  toggleTooltip = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleClickOutsideTooltip = (e: MouseEvent) => {
    if (
      this.state.isOpen &&
      this.tooltip &&
      !this.tooltip.contains(e.target as Node)
    ) {
      this.toggleTooltip();
    }
  };

  handleDeleteUpdate = () => {
    const { updateId, deleteRichUpdate } = this.props;

    deleteRichUpdate(updateId);
    this.toggleTooltip();
  };

  render() {
    return (
      <div styleName={this.state.isOpen ? 'tooltip-open' : 'tooltip'}>
        <ClickableElement
          className={styles['tooltip-trigger']}
          onClick={this.toggleTooltip}
        >
          <Svg
            className={`jg-fill--nobel jg-icon--medium-small jg-space-mrxs`}
            markup={downChevron}
          />
        </ClickableElement>
        <div
          styleName="tooltip-container"
          ref={tooltip => {
            this.tooltip = tooltip!;
          }}
        >
          <ClickableElement
            className={`${styles['tooltip-delete']} qa-delete-button`}
            onClick={this.handleDeleteUpdate}
          >
            Delete post
          </ClickableElement>
        </div>
      </div>
    );
  }
}

export default CSSModules(RichUpdatesItemTooltip, styles);
