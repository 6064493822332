import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from 'src/redux/modules';
import { donate, PageState } from 'src/redux/modules/page';
import { isClosed, isActive, canPageReceiveDonations } from 'src/helpers/page';
import PageFooter from 'src/components/Page/PageFooter';

interface StateProps {
  isClosed: boolean;
  isActive: boolean;
  canPageReceiveDonations: boolean;
  page: PageState;
  raised: number;
}

function mapStateToProps(state: AppState): StateProps {
  const pageClosed = isClosed(state.page);

  return {
    isClosed: pageClosed,
    isActive: isActive(state.page),
    canPageReceiveDonations: canPageReceiveDonations(state.page),
    page: state.page,
    raised:
      (pageClosed ? state.page.amountRaised : state.page.amountPledged) || 0,
  };
}

interface DispatchProps {
  donate: () => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      donate,
    },
    dispatch
  );
}

interface OwnProps {
  handleShareButtonClick: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

class PageFooterContainer extends React.Component<Props> {
  render() {
    return !this.props.isActive || this.props.isClosed ? null : (
      <PageFooter
        page={this.props.page}
        canPageReceiveDonations={this.props.canPageReceiveDonations}
        donate={this.props.donate}
        handleShareButtonClick={this.props.handleShareButtonClick}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageFooterContainer);
