import React from 'react';
import CSSModules from 'react-css-modules';
import { SupporterAvatar } from '../Avatar';
import styles from './SupportersAvatar.scss';

interface Props {
  supportersList: { imageProfile?: string }[];
}

function SupportersAvatar({ supportersList }: Props) {
  return (
    <div styleName="container">
      <ul styleName="supporters-container">
        {supportersList.map((supporter, id) => {
          const imageSrc = supporter ? supporter.imageProfile : undefined;
          return (
            <li styleName="supporter-avatar-container" key={id}>
              <SupporterAvatar
                src={imageSrc}
                alt="image of supporter"
                size="xxsmall"
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default CSSModules(SupportersAvatar, styles);
