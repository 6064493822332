import { AppState } from 'src/redux/modules';

export type RuleMatched<T> = { matched: true; result: T };
export type RuleResult<T> = RuleMatched<T> | { matched: false };
export type Rule<T, O = {}> = (state: AppState, options?: O) => RuleResult<T>;
export type AsyncRule<T, O = {}> = (
  state: AppState,
  options?: O
) => Promise<RuleResult<T>>;

export default async function asyncRulesEngine<T, O = {}>(
  rules: (AsyncRule<T, O> | Rule<T, O>)[],
  state: AppState,
  options?: O
): Promise<RuleResult<T>> {
  for (const rule of rules) {
    try {
      const result = await rule(state, options);

      if (result.matched) {
        return result;
      }
    } catch (e) {}
  }

  throw new Error('No rules matched.');
}

export function rulesEngine<T, O>(
  rules: Rule<T, O>[],
  state: AppState,
  options: O
): RuleResult<T> {
  for (const rule of rules) {
    try {
      const result = rule(state, options);

      if (result.matched) {
        return result;
      }
    } catch (e) {}
  }

  throw new Error('No rules matched.');
}
