import { STAT_REQUEST_SUCCESS, STAT_REQUEST_FAILURE } from './constants';

const inititalState = {
  views: null,
  shares: null,
  donations: null,
};

export default function reducer(state = inititalState, action) {
  switch (action.type) {
    case STAT_REQUEST_SUCCESS: {
      const type = action.payload.type;
      const dataArrayType = `${type}Data`;
      return {
        ...state,
        [type]: action.payload.value,
        [dataArrayType]: action.payload.data,
      };
    }

    case STAT_REQUEST_FAILURE: {
      const type = action.payload.type;
      const dataArrayType = `${type}Data`;
      if (type !== 'donations') {
        return {
          ...state,
          [type]: '-',
          [dataArrayType]: [],
        };
      }
      return {
        ...state,
        [type]: '-',
      };
    }

    default:
      return state;
  }
}
