import React from 'react';
import LoadingOverlay from 'src/components/LoadingOverlay';
import loadable from './loadable';

export default function loadableWithFetchData(loader) {
  const LoadableComponent = loadable({
    loading: () => <LoadingOverlay />,
    loader,
  });

  LoadableComponent.fetchData = async (...args) => {
    const component = await loader();

    const fetchData = component.default
      ? component.default.fetchData
      : component.fetchData;

    if (fetchData) {
      await fetchData(...args);
    }
  };

  return LoadableComponent;
}
