import React from 'react';
import CollapsePanel from './CollapsePanel';

export interface Props {
  accordion?: boolean;
}

export interface State {
  activePanels: number[];
}

class Collapse extends React.Component<Props, State> {
  static defaultProps = {
    accordion: false,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      activePanels: [],
    };

    React.Children.forEach(this.props.children, (child: any, index: number) => {
      if (child.props.initialOpenPanel) {
        if (!this.props.accordion || this.state.activePanels.length === 0) {
          this.state.activePanels.push(index);
        }
      }
    });
  }

  openPanel = (key: number) => {
    this.setState({
      activePanels: this.props.accordion
        ? [key]
        : [...this.state.activePanels, key],
    });
  };

  closePanel = (key: number) => {
    this.setState({
      activePanels: this.state.activePanels.filter(i => i !== key),
    });
  };

  render() {
    const children = React.Children.map(
      this.props.children,
      (child: any, index: number) => {
        if (child.type.name !== CollapsePanel.name) {
          throw new Error(
            'Collapse component can not handle this type of component'
          );
        }

        return React.cloneElement(child, {
          openPanel: this.openPanel,
          closePanel: this.closePanel,
          index,
          isActive: this.state.activePanels.includes(index),
        });
      }
    );

    return <div>{children}</div>;
  }
}

export default Collapse;
