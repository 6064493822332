import {
  getFundsInfo,
  requestWithdrawal,
  FundsResponse,
} from 'src/helpers/api/funds';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { getFundsStatus } from 'src/helpers/funds';
import { isAccountConnected } from 'src/helpers/payment';

export interface FundsInfoRequestAction {
  type: 'FUNDS_INFO_REQUEST';
}

export interface FundsInfoSuccessAction {
  type: 'FUNDS_INFO_SUCCESS';
  payload: FundsResponse;
}

export interface FundsInfoFailureAction {
  type: 'FUNDS_INFO_FAILURE';
  payload: Error;
}

export interface CloseWithdrawalSuccessfulAlert {
  type: 'CLOSE_WITHDRAWAL_SUCCESSFUL_ALERT';
}

export type FundsInfoAction =
  | FundsInfoRequestAction
  | FundsInfoSuccessAction
  | FundsInfoFailureAction
  | CloseWithdrawalSuccessfulAlert;

export interface WithdrawFundsRequestAction {
  type: 'WITHDRAW_FUNDS_REQUEST';
}

export interface WithdrawFundsSuccessAction {
  type: 'WITHDRAW_FUNDS_SUCCESS';
}

export interface WithdrawFundsFailureAction {
  type: 'WITHDRAW_FUNDS_FAILURE';
}

export interface OpenConfirmationModal {
  type: 'OPEN_CONFIRMATION_MODAL';
}

export interface CloseConfirmationModal {
  type: 'CLOSE_CONFIRMATION_MODAL';
}

export type WithdrawFundsActions =
  | WithdrawFundsRequestAction
  | WithdrawFundsSuccessAction
  | WithdrawFundsFailureAction;

export type ConfirmatioModalActions =
  | OpenConfirmationModal
  | CloseConfirmationModal;

export function getFundsData() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const state = getState();
    const { amountRaised, id, fundsReleaseMode, finishedAt } = state.page;

    // Funds data cannot be obtained for pre flexible payments projects
    if (fundsReleaseMode === 'OnCompletion') {
      return;
    }

    dispatch({ type: 'FUNDS_INFO_REQUEST' });

    try {
      const result = await getFundsInfo(id);

      const fundsStatus = getFundsStatus({
        fundsResponse: result!,
        finishedAt: finishedAt!,
        amountRaised: amountRaised!,
        isAccountConnected: isAccountConnected(
          state.page,
          state.paymentDetails
        ),
      });

      if (!result) {
        throw new Error('No result from funds request.');
      }

      dispatch({
        type: 'FUNDS_INFO_SUCCESS',
        payload: { ...result, status: fundsStatus },
      });
    } catch (error) {
      dispatch({
        type: 'FUNDS_INFO_FAILURE',
        payload: error,
      });
    }
  };
}

export function closeWithdrawalSuccessfulAlert(): CloseWithdrawalSuccessfulAlert {
  return { type: 'CLOSE_WITHDRAWAL_SUCCESSFUL_ALERT' };
}

export function withdrawFunds() {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch({
      type: 'WITHDRAW_FUNDS_REQUEST',
    });

    const state = getState();

    try {
      await requestWithdrawal(state.page.id, state.funds.amountAvailable);
      dispatch({
        type: 'WITHDRAW_FUNDS_SUCCESS',
      });

      dispatch<any>(getFundsData());
    } catch (e) {
      dispatch({
        type: 'WITHDRAW_FUNDS_FAILURE',
        payload: e,
      });
    }
  };
}

export function openConfirmationModal() {
  return {
    type: 'OPEN_CONFIRMATION_MODAL',
  };
}

export function closeConfirmationModal() {
  return {
    type: 'CLOSE_CONFIRMATION_MODAL',
  };
}
