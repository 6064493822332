import React from 'react';
import i18next from 'i18next';
import ClickableElement from 'src/components/ClickableElement';
import { FormattedMessage } from 'react-intl';
import WhatsAppShare from 'src/components/SocialShare/WhatsAppShare';
import FacebookShare from 'src/components/FacebookShare/FacebookShare';
import SmsShare from 'src/components/SocialShare/SmsShare';
import { ShareActionOptions } from 'src/redux/modules/page';
import MobileSharePopup from '../SharePopup/MobileSharePopup';

interface Props {
  closeModal: () => void;
  ownerName: string;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareBySms: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  targetCurrency: Currency;
  ownerProfileImage?: string;
}

class MobileEncourageShare extends React.Component<Props> {
  render() {
    const {
      ownerName,
      closeModal,
      shareByWhatsApp,
      shareBySms,
      socialShareUrl,
      targetAmount,
      pitchFor,
      targetCurrency,
      ownerProfileImage,
    } = this.props;

    const socialShareButton =
      i18next.language === 'en-US' ? (
        <SmsShare
          shareBySms={shareBySms}
          socialShareUrl={socialShareUrl}
          targetAmount={targetAmount}
          pitchFor={pitchFor}
          ownerName={ownerName}
          isOwner={false}
          isActive
          targetCurrency={targetCurrency}
          location="page > share prompt"
          className="jg-space-mbml jg-btn jg-btn--sms"
        />
      ) : (
        <WhatsAppShare
          shareByWhatsApp={shareByWhatsApp}
          socialShareUrl={socialShareUrl}
          targetAmount={targetAmount}
          pitchFor={pitchFor}
          ownerName={ownerName}
          isActive
          targetCurrency={targetCurrency}
          location="page > share prompt"
          className="jg-space-mbml jg-btn jg-btn--whatsapp"
          messageType="supporterShare"
          buttonText={
            <FormattedMessage
              id="DesktopEncourageShare.whatsappButton"
              defaultMessage="Share"
            />
          }
        />
      );

    return (
      <MobileSharePopup
        ownerName={ownerName}
        ownerProfileImage={ownerProfileImage}
      >
        <>
          <h1 className="jg-space-mtsm">
            <FormattedMessage
              id="DesktopEncourageShare.title"
              defaultMessage="Sharing with friends is powerful"
            />
          </h1>

          <p className="jg-space-mbml">
            <FormattedMessage
              id="DesktopEncourageShare.didYouKnow"
              defaultMessage="Sharing this page with your friends could help {ownerName} get over £100* in donations."
              values={{
                ownerName,
              }}
            />
          </p>

          <div className="row">
            <div className="col-sm-6">
              <FacebookShare
                buttonText="Share"
                location="page > share prompt"
                className="jg-space-mbmd"
              />
            </div>

            <div className="col-sm-6">{socialShareButton}</div>
          </div>

          <ClickableElement
            id="skip-share-prompt"
            onClick={closeModal}
            className="jg-text--link jg-text--center jg-space-mbml"
          >
            <FormattedMessage
              id="DesktopEncourageShare.maybeLater"
              defaultMessage="Not now, maybe later."
            />
          </ClickableElement>

          <p className="jg-text--xsmall-light jg-text--center">
            <FormattedMessage
              id="DesktopEncourageShare.basedOn"
              defaultMessage="*Based on JustGiving data as of 2018"
            />
          </p>
        </>
      </MobileSharePopup>
    );
  }
}

export default MobileEncourageShare;
