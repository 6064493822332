import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  SSNLastFourForm,
  SSNLastFourFormData,
} from 'src/components/AdditionalDetails/';
import { updateStripeAccountDetails } from 'src/redux/modules/stripeVirtualAccount/actions';

interface DispatchProps {
  updateStripeAccountDetails: (values: SSNLastFourFormData) => void;
}

interface OwnProps {
  onSubmit: () => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      updateStripeAccountDetails,
    },
    dispatch
  );
}

const SSNLastFourContainer = connect(
  null,
  mapDispatchToProps
)(SSNLastFourForm);

export default SSNLastFourContainer as React.ComponentClass<OwnProps>;
