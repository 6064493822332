import co from 'redux-co';
import thunk from 'redux-thunk';
import errorLogger from './errorLogger';
import googleAnalytics from './googleAnalytics';
import qualaroo from './qualaroo';
import pageViewAnalytics from 'src/redux/middleware/pageViewAnalytics';

const middleware = [co, thunk];

if (__CLIENT__) {
  middleware.push(errorLogger, googleAnalytics, qualaroo, pageViewAnalytics);
}

if (__SERVER__) {
  middleware.push(errorLogger);
}

export default middleware;
