import React from 'react';
import { countdown } from 'src/helpers/date';

interface Props {
  children?: any;
  endDate: Date | string;
  tick?: number;
}

interface State {
  daysRemaining: number;
  hoursRemaining: number;
  minutesRemaining: number;
  secondsRemaining: number;
}

export interface CountdownReturnProps {
  daysRemaining: number;
  hoursRemaining: number;
  minutesRemaining: number;
  secondsRemaining: number;
  hasCountdownFinished: boolean;
}

class Countdown extends React.Component<Props, State> {
  intervalId: number;

  constructor(props: Props) {
    super(props);
    this.state = countdown(this.props.endDate);
  }

  componentDidMount() {
    if (this.props.tick) {
      this.intervalId = window.setInterval(
        () => this.setState(countdown(this.props.endDate)),
        this.props.tick
      );
    }
  }

  componentWillUnmount() {
    if (this.props.tick) {
      clearInterval(this.intervalId);
    }
  }

  render() {
    const { children } = this.props;
    return children(this.state);
  }
}

export default Countdown;
