import React, { Component } from 'react';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import chevronDown from '@justgiving/icons/lib/chevron-down.svg';
import Svg from '@justgiving/svg';

interface SelectItem {
  label: string;
  value: string;
  isSelected: boolean;
}

export interface SelectProps {
  placeholder: string;
  items: SelectItem[];
  onChange: (item: SelectItem) => void;
}

interface State {
  touchSupport: boolean;
  defaultSelectedItem?: SelectItem;
  highlightedIndex?: number;
}

export const hasTouchSupport = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  return (
    typeof window.ontouchstart !== 'undefined' ||
    (window.navigator &&
      (window.navigator as any).msMaxTouchPoints !== undefined)
  );
};
export class Select extends Component<SelectProps, State> {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired,
      })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props: SelectProps) {
    super(props);

    const defaultSelectedItemIndex = this.props.items.findIndex(
      item => item.isSelected
    );

    this.state = {
      highlightedIndex:
        defaultSelectedItemIndex !== -1 ? defaultSelectedItemIndex : undefined,
      touchSupport: hasTouchSupport(),
      defaultSelectedItem:
        defaultSelectedItemIndex !== -1
          ? this.props.items[defaultSelectedItemIndex]
          : undefined,
    };
  }

  handleStateChange = (changes: any) => {
    if (
      changes.highlightedIndex !== null &&
      changes.highlightedIndex !== undefined
    ) {
      this.setState({
        highlightedIndex: changes.highlightedIndex,
      });
    }
  };

  render() {
    return (
      <Downshift
        onStateChange={this.handleStateChange}
        defaultHighlightedIndex={this.state.highlightedIndex}
        onChange={this.props.onChange}
        defaultSelectedItem={this.state.defaultSelectedItem}
        itemToString={item => {
          if (item) {
            return item.value;
          } else {
            return '';
          }
        }}
      >
        {({
          isOpen,
          getButtonProps,
          getItemProps,
          highlightedIndex,
          selectedItem,
          selectItem,
        }) => (
          <div className="jg-select jg-background--white">
            <button
              tabIndex="0"
              className={`jg-select__btn ${
                !selectedItem ? 'jg-select__btn--placeholder' : ''
              }`}
              {...getButtonProps()}
            >
              {(selectedItem && selectedItem.label) || this.props.placeholder}
            </button>
            <Svg
              markup={chevronDown}
              className={`jg-icon--medium-small jg-fill--nobel jg-select__chevron ${
                isOpen ? 'jg-select__chevron--open' : ''
              }`}
            />
            {isOpen ? (
              <div className="jg-select__dropdown jg-display-ib jg-bd jg-bd--solid jg-bd--brand-secondary jg-background--white">
                {this.props.items.map((item, index) => (
                  <div
                    {...getItemProps({ item })}
                    key={item.value}
                    className={`jg-select__option ${
                      highlightedIndex === index
                        ? 'jg-select__option--selected'
                        : ''
                    }`}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
            ) : null}
            {
              <select
                className={`jg-select__select ${
                  !this.state.touchSupport ? 'jg-hidden' : ''
                }`}
                onChange={e => {
                  const value = e.target.value;
                  const itemIndex = this.props.items.findIndex(
                    i => i.value === value
                  );
                  /* istanbul ignore else */
                  if (itemIndex !== -1) {
                    const item = this.props.items[itemIndex];
                    selectItem(item);
                    this.setState({
                      highlightedIndex: itemIndex,
                    });
                  }
                }}
                value={
                  (selectedItem && selectedItem.value) || this.props.placeholder
                }
              >
                <option value={this.props.placeholder} disabled>
                  {this.props.placeholder}
                </option>
                {this.props.items.map(item => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            }
          </div>
        )}
      </Downshift>
    );
  }
}

export default Select;
