import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './Slider.scss';
import ClickableElement from 'src/components/ClickableElement';
import Svg from '@justgiving/svg';
import minusIcon from '@justgiving/icons/lib/minus.svg';
import plusIcon from '@justgiving/icons/lib/plus.svg';

interface Props {
  min: number;
  max: number;
  value: number;
  step: number;
  onChange: (value: number) => void;
}

class Slider extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.increase = this.increase.bind(this);
    this.decrease = this.decrease.bind(this);
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange(parseFloat(e.target.value));
  }

  increase() {
    this.props.onChange(Math.min(this.props.max, this.props.value * 1.1));
  }

  decrease() {
    this.props.onChange(Math.max(this.props.min, this.props.value * 0.9));
  }

  render() {
    const { min, max, value, step } = this.props;

    return (
      <div styleName="slider">
        <ClickableElement
          className="qa-zoom-out"
          inline={true}
          onClick={this.decrease}
        >
          <Svg
            markup={minusIcon}
            className="jg-icon--medium jg-fill--nobel jg-space-mrsm"
          />
        </ClickableElement>
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={this.handleChange}
        />
        <ClickableElement
          className="qa-zoom-in"
          inline={true}
          onClick={this.increase}
        >
          <Svg
            markup={plusIcon}
            className="jg-icon--medium jg-fill--nobel jg-space-mlsm"
          />
        </ClickableElement>
      </div>
    );
  }
}

export default CSSModules(Slider, styles);
