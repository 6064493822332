import { get, post, del } from './common';
import { RichUpdateType } from 'src/redux/modules/richUpdates/actions';

export enum UpdateMediaType {
  Image = 1,
  Video = 2,
}

export interface UpdateMediaResource {
  id?: number;
  path: string;
  type: UpdateMediaType;
}

export interface UpdateFriendResource {
  id?: number;
  name: string;
  externalId: string;
}

export interface UpdateResource {
  createdAt: string;
  friends: UpdateFriendResource[];
  id: number;
  media: UpdateMediaResource[];
  message: string;
  projectId: string;
  sharedToFacebook?: boolean;
  type: number;
}

export interface UpdatesResponse {
  updates: UpdateResource[];
  totalCount: number;
  pageSize: number;
  pageNo: number;
}

export async function getUpdates(
  pageId: string,
  pageNo: number,
  pageSize: number,
  updateType?: RichUpdateType
): Promise<UpdatesResponse> {
  const url = `projects/${pageId}/updates?pageNo=${pageNo}&pageSize=${pageSize}`;

  const updates = await get<UpdatesResponse>(
    !updateType ? url : `${url}&type=${updateType}`,
    {
      timingName: 'updates',
    }
  );

  if (!updates) {
    throw new Error('Updates not found');
  }

  return updates;
}

export async function getUpdate(
  pageId: string,
  updateId: number
): Promise<UpdateResource | null> {
  const url = `projects/${pageId}/updates/${updateId}`;
  const update = await get<UpdateResource>(url, { timingName: 'updates' });
  return update;
}

interface PublishUpdatePayload {
  projectId: string;
  message?: string;
  media: UpdateMediaResource[];
  type: 'Update' | 'Thanks' | 'Milestone';
}

export async function publishUpdate(payload: PublishUpdatePayload) {
  return (await post(`projects/${payload.projectId}/updates`, payload)).body;
}

interface DeleteUpdatesPayload {
  projectId: string;
  updateId: number;
}

export function deleteUpdate(payload: DeleteUpdatesPayload) {
  return del(`projects/${payload.projectId}/updates/${payload.updateId}`);
}
