import React, { FunctionComponent } from 'react';
import { Field, FormikTouched, FormikErrors } from 'formik';

interface FormData {
  addressLine1: string;
  city: string;
  postalCode: string;
}

interface Props {
  touched: FormikTouched<FormData>;
  errors: FormikErrors<FormData>;
}

const GBAddress: FunctionComponent<Props> = ({ touched, errors }) => {
  return (
    <div>
      <div className="row jg-space-mn">
        <div className="col-xs-12 jg-space-pn">
          <div className="jg-form-group jg-space-mbn">
            <label className="jg-form-label" htmlFor="postalCode">
              Postcode
            </label>
            <Field
              className={`jg-form-control ${
                touched.postalCode && errors.postalCode
                  ? 'jg-form-control--error'
                  : ''
              }`}
              type="text"
              id="postalCode"
              name="postalCode"
            />
          </div>
        </div>

        <div className="col-xs-12 jg-space-pn">
          {touched.postalCode &&
            errors.postalCode && (
              <span className="jg-form-error-copy jg-text--small" role="alert">
                {errors.postalCode}
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

export default GBAddress;
