import React from 'react';
import CSSModules from 'react-css-modules';
import YouTube from 'react-youtube';
import RichEditorQuote from 'src/components/RichEditor/RichQuoteNode';
import styles from './RichStory.scss';
import config from 'config';
import { TextRange, StoryNode } from 'src/helpers/api/pages';
import { getVideoId } from 'src/helpers/youTubeParser';
import flatten from 'lodash/flatten';
import { FormattedMessage } from 'react-intl';

const renderRanges = (ranges: TextRange[]): JSX.Element[] =>
  flatten(
    ranges.map((range, index) => {
      const lines = (range.text || '').split('\n');

      if (lines.length > 1) {
        return lines.map((item, i) => (
          <span key={`richstory_lines_${i}`}>
            {item}
            <br />
          </span>
        ));
      } else {
        return <span key={`richstory_ranges_${index}`}>{lines[0] || ''}</span>;
      }
    })
  );

const renderNode = (
  node: StoryNode,
  index: number
): JSX.Element | JSX.Element[] => {
  switch (node.type) {
    case 'header':
      return <h2 key={`richstory_header_${index}`}>{node.text}</h2>;

    case 'quote':
      return (
        <div className="jg-space-mhml" key={`richstory_quote_${index}`}>
          <RichEditorQuote>{node.text}</RichEditorQuote>
        </div>
      );

    case 'image':
      return (
        <div styleName="image-container" key={`richstory_image_${index}`}>
          <img
            src={`${config.IMAGE_BASE_URI}/${
              node.name
            }?template=CrowdfundingStoryImage`}
          />
        </div>
      );

    case 'video':
      return (
        <div styleName="video" key={`richstory_video_${index}`}>
          <YouTube videoId={getVideoId(node.url)} />
        </div>
      );

    case 'paragraph':
      return (
        <p key={`richstory_paragraph_${index}`} className="jg-s-story">
          {node.nodes.map(renderNode)}
        </p>
      );

    case 'link':
      return (
        <a
          href={node.url}
          key={`richstory_link_${index}`}
          target="_blank"
          rel="nofollow noreferrer noopener ugc"
        >
          {renderRanges(node.ranges)}
        </a>
      );

    case 'text':
      return renderRanges(node.ranges);

    default:
      return <span key={index}>{''}</span>;
  }
};

interface Props {
  storyNodes?: StoryNode[];
}

const RichStory: React.StatelessComponent<Props> = ({ storyNodes = [] }) => (
  <div
    styleName="container"
    className="jg-bdt jg-bd--solid jg-bd--very-light-grey"
  >
    <h2>
      <FormattedMessage id="RichStory.story" defaultMessage="Story" />
    </h2>
    <div styleName="story">{storyNodes.map(renderNode)}</div>
  </div>
);

export default CSSModules(RichStory, styles);
