import { createAuth, Auth } from '@justgiving/auth';
import {
  JWT_BEARER_COOKIE_NAME,
  ACTIVE_GROUPS_COOKIE_NAME,
  IAM_API_URI,
} from 'config';
import log from 'src/logging';

export const clientAuth =
  !__SERVER__ &&
  createAuth({
    documentOrReq: document,
    authCookieName: JWT_BEARER_COOKIE_NAME,
    activeGroupCookieName: ACTIVE_GROUPS_COOKIE_NAME,
    refreshAccessTokenUrl: `${IAM_API_URI}/account/refresh-access-token`,
  });

export async function refreshAccessToken(): Promise<void> {
  const auth = await getAuth();

  try {
    await auth.refreshAccessTokenIfExpired();
  } catch (err) {
    if (err.status !== 401) {
      log.warn(
        { err, refreshToken: auth.getRefreshToken() },
        'Unable to refresh access token.'
      );
    }
  }
}

export function getAuth(): Auth {
  if (__SERVER__) {
    const requestStorage = require('../server/requestStorage').default;

    return requestStorage.get('auth');
  }

  return clientAuth as Auth;
}

export function isSuperUser() {
  const auth = getAuth();
  const token = auth ? auth.getAccessToken() : null;
  return token && token.role && token.role.toLowerCase() === 'superuser';
}
