import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'src/components/Analytics';
import deactivated from '../svg/deactivated-il.svg';
import Svg from '@justgiving/svg';

interface Props {
  pageName: string;
  refundDate: string | Date;
}

const PageDeactivatedContent: React.StatelessComponent<Props> = ({
  pageName,
}) => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <div className="col-xs-12 col-md-8 offset-md-2">
        <Svg markup={deactivated} className="jg-svg-sm" />
        <h2>
          <FormattedMessage
            id="PageDeactivatedContent.pageDeactivated"
            defaultMessage="Your Page has been deactivated"
          />
        </h2>
        <p className="jg-space-mbmd">
          <FormattedMessage
            id="PageDeactivatedContent.noMoreDonations"
            defaultMessage="As your bank details weren't added in time, your Page has been deactivated. Your donations will now be refunded unless you add your bank details immediately."
          />
        </p>
        <div className="row">
          <Link
            to={`/${pageName}/funds/account`}
            className={`jg-btn jg-btn--brand col-xs-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3`}
            analyticsOptions={{
              event: 'link click',
              eventValue: 'last chance to add your bank details',
              pageSection: 'admin > page view > prompt > page deactivated',
              subtype: 'button',
            }}
          >
            <FormattedMessage
              id="PageDeactivatedContent.addBankDetailsButton"
              defaultMessage="Last chance to add my bank details"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageDeactivatedContent;
