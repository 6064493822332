import React from 'react';
import { Element } from 'react-scroll';
import Svg from '@justgiving/svg';
import { FormikBag } from 'formik';
import image from './svg/monthly-supporters-il.svg';
import styles from './RecurringDonationsAwarenessBanner.scss';
import RecurringDonationsPitchOwner from './RecurringDonationsPitchOwner';
import RecurringDonationsPitchSupporter from './RecurringDonationsPitchSupporter';
import { RecurringDonationsPitchFormData } from 'src/components/RecurringDonations/RecurringDonationsPitchForm';

type Props = {
  isOwnerRequested: boolean;
  ownerAvatarImage?: string;
  ownerName: string;
  pageName: string;
  recurringDonationsPitch?: string;
  submitPitch: (
    payload: RecurringDonationsPitchFormData,
    formikBag: FormikBag<any, RecurringDonationsPitchFormData>
  ) => void;
};

const RecurringDonationsAwarenessBanner: React.StatelessComponent<Props> = ({
  isOwnerRequested,
  ownerName,
  ownerAvatarImage,
  pageName,
  recurringDonationsPitch,
  submitPitch,
}) => {
  return (
    <div className="jg-background--white jg-bd jg-bd--solid jg-bd--very-light-grey jg-space-pml@md jg-space-phsm@xs jg-space-pvmd@xs">
      <Element name="recurring-donations-awareness-banner">
        <Svg markup={image} className={`${styles.image} jg-space-mbmd`} />
        {isOwnerRequested ? (
          <RecurringDonationsPitchOwner
            ownerAvatarImage={ownerAvatarImage}
            ownerName={ownerName}
            recurringDonationsPitch={recurringDonationsPitch}
            submitPitch={submitPitch}
          />
        ) : (
          <RecurringDonationsPitchSupporter
            ownerAvatarImage={ownerAvatarImage}
            ownerName={ownerName}
            pageName={pageName}
            pageSection="page > story > recurring donations"
            recurringDonationsPitch={recurringDonationsPitch}
          />
        )}
      </Element>
    </div>
  );
};

export default RecurringDonationsAwarenessBanner;
