import 'core-js';
import 'window.requestanimationframe';
import url from 'url';
import { routerMiddleware } from 'react-router-redux';
import Raven from 'raven-js';
import createHistory from 'history/createBrowserHistory';
import createRavenMiddleware from 'raven-for-redux';
import { CROWDFUNDING_BASE_URI, SENTRY_URL, FEATURE } from 'config';
import createStore from './client/createStore';
import middleware from './redux/middleware';
import renderApp from './client/root';
import packageJSON from './../package.json';
import urlJoin from 'url-join';

if (!__DEVELOPMENT__) {
  Raven.config(SENTRY_URL, {
    release: packageJSON.version,
    environment: 'production',
    whitelistUrls: [/justgiving\.com/, /jg-cdn\.com/],
    sampleRate: 0.1,
    ignoreErrors: [
      "this.onready is not a function. (In 'this.onready(a)', 'this.onready' is undefined)",
      'Blocked a frame with origin ',
      'twttr.conversion.trackPid',
    ],
  }).install();
}

function init() {
  if (FEATURE.PUSH_NOTIFICATIONS) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register(
        urlJoin(CROWDFUNDING_BASE_URI, '/serviceWorker.js')
      );
    }
  }

  const history = createHistory({
    basename: url.parse(CROWDFUNDING_BASE_URI).pathname,
  });

  middleware.push(routerMiddleware(history));

  if (!__DEVELOPMENT__) {
    middleware.push(createRavenMiddleware(Raven));
  }

  const store = createStore(middleware, {
    ...window.__data,
    ...window.__userData,
  });

  renderApp(store, history);
}

if (!window.Intl) {
  require.ensure(
    ['intl', 'intl/locale-data/jsonp/en.js'],
    () => {
      require('intl');
      require('intl/locale-data/jsonp/en');

      init();
    },
    'intl'
  );
} else {
  init();
}
