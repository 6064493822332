import qs from 'qs';

const PAGE_VIEW = 'PAGE_VIEW';

export default options => next => action => {
  if (action.type === 'PAGE_SUCCESS' || action.type === 'PAGE_MOUNT') {
    handlePageView(action.payload, options);
  }

  next(action);
};

const creationProcessPages = [
  'coverphoto',
  'story',
  'pagelink',
  'confirmidentity',
];

function handlePageView(page, { getState, dispatch }) {
  const state = getState();
  const { pathname, search } = state.routing.location;

  const query = qs.parse(search ? search.substr(1) : '');

  // Don't track second level sub path page views, i.e. /tiger/dashboard/target
  if (pathname.split('/').length > 3) {
    return;
  }

  const path = pathname.split('/').pop();

  // creation pages have their page view handling code
  if (creationProcessPages.includes(path)) {
    return;
  }

  let analytics = {
    event: 'page view',
    activity_id: page.id || '',
    activity_type: 'crowdfunding_guid',
    social_utm_term: (query && query.utm_term) || '',
    content_id: page.id || '',
    content_type: 'crowdfunding_guid',
  };

  if (page.isOwnerRequested) {
    analytics = {
      ...analytics,
      subtype: 'admin',
      event_value: 'page view',
      page_section: `admin > page view`,
    };
  } else {
    analytics = {
      ...analytics,
      subtype: 'cfp',
    };
  }

  dispatch({
    type: PAGE_VIEW,
    meta: {
      analytics,
    },
  });
}
