import React from 'react';
import CSSModules from 'react-css-modules';
import YouTube from 'react-youtube';
import styles from './RichUpdatesItemMedia.scss';
import { UpdateMediaType } from 'src/helpers/api/updates';

interface Props {
  type: number;
  path: string;
  watchingVideoToggle: () => void;
}

const RichUpdatesItemMedia: React.StatelessComponent<Props> = ({
  type,
  path,
  watchingVideoToggle,
}) => (
  <div className={styles.medium}>
    {type === UpdateMediaType.Image ? (
      <div className={styles.wrapper}>
        <img
          src={`${path}?template=CrowdfundingStoryImage`}
          alt="Update from the Page owner"
        />
      </div>
    ) : (
      <YouTube
        videoId={path}
        onPlay={watchingVideoToggle}
        onEnd={watchingVideoToggle}
        onPause={watchingVideoToggle}
        opts={{
          height: '320px',
          width: '100%',
          playerVars: {
            autoplay: 0,
          },
        }}
      />
    )}
  </div>
);

export default CSSModules(RichUpdatesItemMedia, styles);
