import {
  createNotificationsSubscription,
  addSubscriptionTopic,
  deleteSubscriptionTopic,
  getSubscriptionTopics,
} from 'src/helpers/api/pushNotifications';
import { PUSH_NOTIFICATIONS_SERVER_KEY } from 'config';

export function arePushNotificationsSupported() {
  return __SERVER__
    ? false
    : 'serviceWorker' in navigator &&
        'PushManager' in window &&
        navigator.userAgent.match(/Chrome/i) &&
        !navigator.userAgent.match(/Mobile/i);
}

export async function subscribe(userGuid: string, pageId: string) {
  const { id } = await getOrCreateSubscription(userGuid);

  if (id) {
    await addSubscriptionTopic({
      subscriptionId: id,
      topicName: `cf_${pageId}`,
    });
  }
}

export async function unsubscribe(userGuid: string, pageId: string) {
  const { id } = await getOrCreateSubscription(userGuid);

  if (id) {
    await deleteSubscriptionTopic({
      subscriptionId: id,
      topicName: `cf_${pageId}`,
    });
  }
}

export async function isPageSubscribed(userGuid: string, pageId: string) {
  try {
    const { id } = await getOrCreateSubscription(userGuid);

    if (id) {
      const topics = await getSubscriptionTopics(id);
      return topics.map(topic => topic.name).includes(`cf_${pageId}`);
    }

    return false;
  } catch (e) {
    return false;
  }
}

async function getOrCreateSubscription(userGuid: string) {
  const registration = await navigator.serviceWorker.ready;

  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(PUSH_NOTIFICATIONS_SERVER_KEY),
  };

  const subscription = await registration.pushManager.subscribe(
    subscribeOptions
  );

  const { keys, endpoint } = subscription.toJSON();

  if (!keys) {
    throw new Error('subscriptionData.keys is not set');
  }

  return createNotificationsSubscription({
    endpoint,
    pushKey: keys!.p256dh,
    authKey: keys!.auth,
    userGuid,
  });
}

export async function getSubscription() {
  const registration = await navigator.serviceWorker.ready;

  const subscription = await registration.pushManager.getSubscription();

  return subscription;
}

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function askPermission() {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission(resolve);

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
}
