import { PageState, ShareActionOptions } from 'src/redux/modules/page';
import CSSModules from 'react-css-modules';
import CopyLink from 'src/components/SocialShare/CopyLink';
import { Element } from 'react-scroll';
import EmailShare from 'src/components/SocialShare/EmailShare';
import FacebookShare from 'src/components/FacebookShare/FacebookShare';
import React from 'react';
import SmsShare from 'src/components/SocialShare/SmsShare';
import TwitterShare from 'src/components/SocialShare/TwitterShare';
import WhatsAppShare from 'src/components/SocialShare/WhatsAppShare';
import styles from 'src/components/SocialShare/SocialShare.scss';

const shareLocation = 'page > social share';

interface Props {
  shareOnTwitter: (options: ShareActionOptions) => void;
  shareByEmail: (options: ShareActionOptions) => void;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareBySms: (options: ShareActionOptions) => void;
  sharePageLink: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  ownerName: string;
  name: string;
  isOwner: boolean;
  isActive: boolean;
  page: PageState;
}

const SocialShare = (props: Props) => {
  const getEmailMessageType = (): ShareMessageTypes => {
    if (props.isOwner) {
      return 'ownerShare';
    }
    return 'supporterShare';
  };

  const getWhatsAppMessageType = (): ShareMessageTypes => {
    if (props.isOwner) {
      return 'ownerShare';
    }
    return 'supporterShare';
  };

  return (
    <Element name="share-story">
      <div className={styles.container}>
        <h1 className={`jg-h4 ${styles.title}`}>Share this story</h1>
        <div className={styles['mobile-only']}>
          <div className={styles['mobile-button-container']}>
            <FacebookShare
              isActive={props.isActive}
              buttonText="Facebook"
              location={shareLocation}
              customStyle={styles['button-facebook']}
            />
            <EmailShare
              shareByEmail={props.shareByEmail}
              socialShareUrl={props.socialShareUrl}
              targetAmount={props.targetAmount}
              pitchFor={props.pitchFor}
              ownerName={props.ownerName}
              isActive={props.isActive}
              targetCurrency={props.page.targetCurrency}
              location={shareLocation}
              className={styles['button-email']}
              messageType={getEmailMessageType()}
            />
            <SmsShare
              shareBySms={props.shareBySms}
              socialShareUrl={props.socialShareUrl}
              targetAmount={props.targetAmount}
              pitchFor={props.pitchFor}
              ownerName={props.ownerName}
              isActive={props.isActive}
              isOwner={props.isOwner}
              targetCurrency={props.page.targetCurrency}
              location={shareLocation}
              className={styles['button-sms']}
            />
            <WhatsAppShare
              shareByWhatsApp={props.shareByWhatsApp}
              socialShareUrl={props.socialShareUrl}
              targetAmount={props.targetAmount}
              pitchFor={props.pitchFor}
              ownerName={props.ownerName}
              isActive={props.isActive}
              targetCurrency={props.page.targetCurrency}
              location={shareLocation}
              className={styles['button-whatsapp']}
              messageType={getWhatsAppMessageType()}
            />
            <TwitterShare
              shareOnTwitter={props.shareOnTwitter}
              socialShareUrl={props.socialShareUrl}
              targetAmount={props.targetAmount}
              pitchFor={props.pitchFor}
              isActive={props.isActive}
              isOwner={props.isOwner}
              targetCurrency={props.page.targetCurrency}
              location={shareLocation}
              className={styles['button-twitter']}
            />
          </div>
          <CopyLink
            className="jg-space-mtsm jg-space-mhsm jg-space-mhxl@md"
            copyText="Copy link"
            copiedText="Link copied"
            isDisabled={!props.isActive}
            pageSection={shareLocation}
            socialShareUrl={props.socialShareUrl}
            sharePageLink={props.sharePageLink}
          />
        </div>
        <div className={styles['desktop-button-container']}>
          <div className={styles['social-button-wrapper']}>
            <FacebookShare
              isActive={props.isActive}
              location={shareLocation}
              buttonText="Facebook"
              customStyle="jg-btn jg-btn--facebook"
            />
          </div>
          <div className={styles['social-button-wrapper']}>
            <EmailShare
              shareByEmail={props.shareByEmail}
              socialShareUrl={props.socialShareUrl}
              targetAmount={props.targetAmount}
              pitchFor={props.pitchFor}
              ownerName={props.ownerName}
              isActive={props.isActive}
              targetCurrency={props.page.targetCurrency}
              location={shareLocation}
              buttonText="Email"
              className="jg-btn jg-btn--ghost"
              messageType={getEmailMessageType()}
            />
          </div>
          <div className={styles['social-button-wrapper']}>
            <TwitterShare
              shareOnTwitter={props.shareOnTwitter}
              socialShareUrl={props.socialShareUrl}
              targetAmount={props.targetAmount}
              pitchFor={props.pitchFor}
              isActive={props.isActive}
              isOwner={props.isOwner}
              targetCurrency={props.page.targetCurrency}
              location={shareLocation}
              buttonText="Twitter"
              className="jg-btn jg-btn--twitter"
            />
          </div>
        </div>
      </div>
    </Element>
  );
};

export default CSSModules(SocialShare, styles);
