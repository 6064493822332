import React from 'react';
import Svg from '../../../node_modules/@justgiving/svg';
import { FormattedMessage } from 'react-intl';
import thankError from './svg/thankError-il.svg';
import SpeechBubbleWithAvatar from 'src/components/ThankingDonors/SpeechBubbleWithAvatar';
import {
  AnalyticsLoadingButton as LoadingButton,
  AnalyticsPageView,
} from 'src/components/Analytics';
import styles from './ThankingDonors.scss';

interface Props {
  isSubmitting: boolean;
  ownerName: string;
  profileImage?: string | undefined;
  submitForm: () => void;
  thankYouMessage: string;
}

const ThankDonorMessageSendFailure: React.StatelessComponent<Props> = ({
  isSubmitting,
  ownerName,
  profileImage,
  thankYouMessage,
  submitForm,
}) => (
  <AnalyticsPageView
    eventValue="thank donor failure"
    pageSection="admin > page view > supporters > thank supporter"
    subtype="admin"
  >
    <Svg markup={thankError} className="jg-svg-sm" />
    <h2 className="jg-text--center jg-space-mbmd">
      <FormattedMessage
        id="ThankDonorMessageSendFailure.somethingWentWrong"
        defaultMessage="Looks like something went wrong..."
      />
    </h2>
    <p className="jg-text--center jg-space-mbmd">
      <FormattedMessage
        id="ThankDonorMessageSendFailure.weAreSorry"
        defaultMessage="We're sorry we weren't able to send your message. It's probably just a glitch, so please try again."
      />
    </p>
    <div className="jg-space-pbmd">
      <SpeechBubbleWithAvatar
        avatarImage={profileImage}
        name={ownerName}
        message={thankYouMessage}
      />
    </div>
    <div className={styles.footer}>
      <LoadingButton
        id="send-message"
        loading={isSubmitting}
        value={
          <FormattedMessage
            id="ThankDonorMessageSendFailure.tryAgainButton"
            defaultMessage="Try again"
          />
        }
        className="jg-btn jg-btn--ghost"
        analyticsOptions={{
          event: 'link click',
          eventValue: 'try again',
          pageSection: 'admin > page view > supporters > thank supporter',
          subtype: 'button',
        }}
        onClick={submitForm}
        color="purple"
      />
    </div>
  </AnalyticsPageView>
);

export default ThankDonorMessageSendFailure;
