import {
  FundsReleaseMode,
  PageResource,
  PageStatus,
} from 'src/helpers/api/pages';

import { PageState } from 'src/redux/modules/page';
import compareAsc from 'date-fns/compare_asc';

export function isActive(page: { status: PageStatus }): boolean {
  return [
    'Active',
    'TargetNotReached',
    'FullyFunded',
    'ReportGenerated',
    'Suspended',
    'Stranded',
  ].includes(page.status);
}

export function canPageReceiveDonations(page: {
  status: PageStatus;
  finishedAt?: string;
}): boolean {
  const isPageActive = page.status === 'Active';
  if (page.finishedAt) {
    const hasFinished = compareAsc(page.finishedAt, Date.now()) === -1;
    return isPageActive && !hasFinished;
  }
  return isPageActive;
}

export function isClosed(page: { status: PageStatus }) {
  return ['FullyFunded', 'TargetNotReached', 'ReportGenerated'].includes(
    page.status
  );
}

export function isEligibleForFlexiblePayments(page: {
  fundsReleaseMode?: FundsReleaseMode;
}) {
  return page.fundsReleaseMode === 'OnDemand';
}

export function canPostUpdates(page: PageState | PageResource) {
  return (
    page.isOwnerRequested &&
    (page.status === 'Active' ||
      page.status === 'FullyFunded' ||
      page.status === 'Suspended')
  );
}

export function isPendingApproval(page: PageState | PageResource) {
  return page.status === 'PendingApproval';
}
