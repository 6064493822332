import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './less/error.less';
import notFoundImage from './images/404.svg';
import errorImage from './images/500.svg';

interface Props {
  error: string;
}

const Error: React.StatelessComponent<Props> = ({ error }) => {
  const imgSrc = error === '404' ? notFoundImage : errorImage;

  return (
    <section styleName="errors">
      <object styleName="errors-svg" data={imgSrc} type="image/svg+xml" />
      <h1 styleName="errors-heading">
        {error === '404'
          ? 'Weʼre really sorry but we canʼt find the page you were looking for.'
          : 'Weʼre really sorry but something unexpected happened.'}
      </h1>
      {error === '404' && (
        <p styleName="errors-body">
          This probably means that it has moved to a new location, or has been
          removed from the site. There is also a tiny possibility that you might
          have mistyped something so please check and have another go, or use
          the search function to find what you are looking for.
        </p>
      )}
      <p styleName="errors-body">
        Donʼt forget to follow @JGhelp on Twitter for regular updates. If youʼd
        like to get in touch, please email us at help@justgiving.com
      </p>
    </section>
  );
};

export default CSSModules(Error, styles);
