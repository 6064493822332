import { get, getOrThrow, patch } from './common';

export interface SupporterResource {
  id: number;
  createdAt: string;
  name: string;
  message: string;
  projectId: string;
  userId: string;
  imageProfile: string;
  donationAmount: number;
  donationCurrency: Currency;
  donationId: number;
  thankYouMessage?: string;
}

export interface SupportersResponse {
  activities: SupporterResource[];
  totalCount: number;
  pageSize: number;
  pageNo: number;
}

export async function getSupporters(
  pageId: string,
  pageNo: number,
  pageSize: number,
  minDateTime?: string
): Promise<SupportersResponse> {
  const url = createUrl(pageId, pageNo, pageSize, minDateTime);

  const supporters = await get<SupportersResponse>(url, {
    timingName: 'supporters',
  });

  if (!supporters) {
    throw new Error('Supporters not found');
  }

  return supporters;
}

export async function setSupporterThankYouMessage({
  pageId,
  activityId,
  thankYouMessage,
}: {
  pageId: string;
  activityId: number;
  thankYouMessage: string;
}) {
  const payload = {
    thankYouMessage,
  };

  return patch(`projects/${pageId}/activities/${activityId}`, payload);
}

function createUrl(
  pageId: string,
  pageNo: number,
  pageSize: number,
  minDateTime?: string
) {
  const url = `projects/${pageId}/activities?pageNo=${pageNo}&pageSize=${pageSize}&type=pledge`;

  return minDateTime ? `${url}&minDateTime=${minDateTime}` : url;
}

export async function getSupporter(
  pageId: string,
  activityHashId: string
): Promise<SupporterResource> {
  const url = `projects/${pageId}/activities/${activityHashId}`;

  const response = await getOrThrow<SupporterResource>(url, {
    timingName: 'supporter',
  });

  return response;
}
