import { getImageFromCanvas } from 'src/helpers/image';
import { loadImage, get2dContext, waitForFont } from 'src/helpers/canvas';
import backgroundPurple from './background-purple.svg';
import backgroundOrange from './background-orange.svg';
import backgroundGreen from './background-green.svg';
import backgroundBlue from './background-blue.svg';

export const ASPECT_RATIO = 1200 / 630;

const presets = {
  purple: { backgroundImage: backgroundPurple },
  orange: { backgroundImage: backgroundOrange },
  green: { backgroundImage: backgroundGreen },
  blue: { backgroundImage: backgroundBlue },
};

interface Point {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}

type Rectangle = Point & Size;

interface GetThankYouImageDescriptorOptions {
  placeholder?: string;
  text: string;
  drawInputBox?: boolean;
  caretOffset?: number;
}

export type ThankYouImagePreset = 'purple' | 'orange' | 'green' | 'blue';

interface ExportThankYouImageOptions extends GetThankYouImageDescriptorOptions {
  width: number;
  height: number;
  preset: ThankYouImagePreset;
}

interface DrawThankYouImageOptions extends ExportThankYouImageOptions {
  ctx: CanvasRenderingContext2D;
}

interface ImageDescriptor {
  lines: LineDescriptor[];
  placeholderLines?: LineDescriptor[];
  inputBoxRect?: Rectangle;
  textRect: Rectangle;
  caretPosition?: Point;
}

interface LineDescriptor {
  text: string;
  position: Point;
}

type MeasureText = (text: string) => number;

const width = 1200;
const height = 630;
const boxMargin = 50;
const boxPadding = 20;
const fontSize = 56;
const lineHeight = 60;

export function getThankYouImageDescriptor(
  {
    text,
    placeholder,
    caretOffset,
    drawInputBox,
  }: GetThankYouImageDescriptorOptions,
  measureText: MeasureText
): ImageDescriptor {
  const boxWidth = width - boxMargin * 2;

  const lineMaxWidth = boxWidth - boxPadding * 2;
  const lines = buildLines(text || '', lineMaxWidth, measureText);
  const placeholderLines = !text
    ? buildLines(placeholder || '', lineMaxWidth, measureText)
    : undefined;

  const boxHeight = lineHeight * lines.length + boxPadding * 2;
  const boxOffsetTop = height / 2 - boxHeight / 2 - 40;

  const inputBoxRect = {
    x: boxMargin,
    y: boxOffsetTop,
    width: boxWidth,
    height: boxHeight,
  };

  const textRect = {
    x: boxMargin + boxPadding,
    y: boxOffsetTop + boxPadding,
    width: lineMaxWidth,
    height: boxHeight - boxPadding * 2,
  };

  const lineDescriptors = getLineDescriptors(lines, textRect, measureText);
  const placeholderLineDescriptors = placeholderLines
    ? getLineDescriptors(placeholderLines, textRect, measureText)
    : undefined;

  const caretPosition =
    typeof caretOffset !== 'undefined'
      ? getCaretPosition(caretOffset, lines, textRect, measureText)
      : undefined;

  return {
    lines: lineDescriptors,
    placeholderLines: placeholderLineDescriptors,
    inputBoxRect: drawInputBox ? inputBoxRect : undefined,
    textRect,
    caretPosition,
  };
}

export async function exportThankYouImage(
  options: ExportThankYouImageOptions
): Promise<Blob> {
  const canvas = document.createElement('canvas');

  canvas.width = options.width;
  canvas.height = options.height;

  const ctx = get2dContext(canvas);

  await drawThankYouImage({
    ctx,
    ...options,
  });

  return getImageFromCanvas(canvas, 'image/png');
}

export async function drawThankYouImage(options: DrawThankYouImageOptions) {
  const { ctx, width: canvasWidth, height: canvasHeight, preset } = options;

  const { backgroundImage: backgroundImagePath } = presets[preset];

  const tasks: [Promise<HTMLImageElement>, Promise<void>] = [
    loadImage(backgroundImagePath),
    waitForFont('Montserrat').catch(() => {}), // ignore errors, will fallback to the next font
  ];

  const [backgroundImage] = await Promise.all(tasks);

  ctx.save();

  try {
    ctx.scale(canvasWidth / width, canvasHeight / height);
    ctx.font = `${fontSize}px Montserrat, Roboto-Regular, Roboto, Arial, sans-serif`;

    const measureText = (t: string) => {
      return ctx.measureText(t).width;
    };

    const imageDescriptor = getThankYouImageDescriptor(options, measureText);

    drawBackground(ctx, { width, height }, backgroundImage);

    if (imageDescriptor.inputBoxRect) {
      drawBox(ctx, imageDescriptor.inputBoxRect);
    }

    if (imageDescriptor.placeholderLines) {
      drawLines(
        ctx,
        imageDescriptor.placeholderLines,
        'rgba(255, 255, 255, 0.6)'
      );
    } else {
      drawLines(ctx, imageDescriptor.lines, '#fff');
    }

    if (typeof imageDescriptor.caretPosition !== 'undefined') {
      drawCaret(ctx, imageDescriptor.caretPosition);
    }
  } finally {
    ctx.restore();
  }
}

function buildLines(
  text: string,
  maxWidth: number,
  measureText: MeasureText
): string[] {
  const lines: string[] = [];
  let currentLineIndex = 0;

  for (const word of text.split(' ')) {
    const line =
      typeof lines[currentLineIndex] !== 'undefined'
        ? lines[currentLineIndex] + ' ' + word
        : word;

    const lineWidth = measureText(line);

    if (lineWidth <= maxWidth) {
      lines[currentLineIndex] = line;
    } else {
      currentLineIndex++;
      lines.push(word);
    }
  }

  return lines;
}

function getLineDescriptors(
  lines: string[],
  textRect: Rectangle,
  measureText: MeasureText
): LineDescriptor[] {
  const result = [];

  for (let i = 0; i < lines.length; i++) {
    const centerOffset = (textRect.width - measureText(lines[i])) / 2;

    const x = textRect.x + centerOffset;
    const y = textRect.y + lineHeight * i;

    result.push({
      text: lines[i],
      position: { x, y },
    });
  }

  return result;
}

function getCaretPosition(
  caretOffset: number,
  lines: string[],
  textRect: Rectangle,
  measureText: MeasureText
): Point {
  const caretCoords = getCaretCoords(lines, caretOffset);

  const line = lines[caretCoords.lineIndex];
  const lineWidth = measureText(line);
  const centerOffset = (textRect.width - lineWidth) / 2;
  const offset = measureText(line.substr(0, caretCoords.charOffset));

  const caretX = textRect.x + centerOffset + offset;
  const caretY = textRect.y + lineHeight * caretCoords.lineIndex;

  return { x: caretX, y: caretY };
}

function getCaretCoords(lines: string[], caretPosition: number) {
  let totalLength = 0;
  let lineIndex = 0;

  for (const line of lines) {
    const lineLength = line.length + 1;

    if (totalLength + lineLength > caretPosition) {
      return {
        lineIndex,
        charOffset: caretPosition - totalLength,
      };
    }

    totalLength += lineLength;
    lineIndex++;
  }

  return {
    lineIndex: lines.length - 1,
    charOffset: totalLength - 1,
  };
}

function drawBackground(
  ctx: CanvasRenderingContext2D,
  canvasSize: Size,
  backgroundImage: HTMLImageElement
) {
  ctx.drawImage(backgroundImage, 0, 0, canvasSize.width, canvasSize.height);
}

function drawLines(
  ctx: CanvasRenderingContext2D,
  lines: LineDescriptor[],
  color: string
) {
  ctx.fillStyle = color;
  ctx.textBaseline = 'middle';

  for (const line of lines) {
    ctx.fillText(line.text, line.position.x, line.position.y + lineHeight / 2);
  }
}

function drawCaret(ctx: CanvasRenderingContext2D, caretPosition: Point) {
  ctx.fillStyle = '#8f2497';
  ctx.fillRect(caretPosition.x, caretPosition.y, 2, lineHeight);
}

function drawBox(ctx: CanvasRenderingContext2D, rect: Rectangle) {
  ctx.fillStyle = 'rgba(255, 255, 255, 0.2)';
  ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
}
