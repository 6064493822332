import React from 'react';
import currencyMap from 'src/helpers/currencyMap';
import { SupporterAvatar } from 'src/components/Avatar';
import styles from './ThankingDonors.scss';

interface Props {
  avatarImage: string | undefined;
  donationAmount?: number;
  message: string;
  name: string;
  targetCurrency?: Currency;
}

const SpeechBubbleWithAvatar: React.StatelessComponent<Props> = ({
  avatarImage,
  donationAmount,
  message,
  name,
  targetCurrency,
}) => (
  <>
    <div
      className={`jg-bd jg-bd--solid jg-bd--very-light-grey jg-bd--radius-3px jg-space-pms ${
        styles['supporter-message-box']
      }`}
    >
      {donationAmount &&
        targetCurrency && (
          <h2
            className={`jg-text--center jg-color--primary ${!message &&
              'jg-space-mbn'}`}
          >
            {currencyMap[targetCurrency]}
            {donationAmount}
          </h2>
        )}

      {message && (
        <p className="jg-text--small jg-text--center jg-space-mbn">{message}</p>
      )}
    </div>
    <div className="jg-display-flex jg-flex-aic jg-flex-jcc jg-space-mtms">
      <SupporterAvatar
        src={avatarImage}
        alt="image of supporter"
        size="xxsmall"
      />
      <span className="jg-text--xsmall jg-color--nobel jg-space-mlxs">
        {name}
      </span>
    </div>
  </>
);

export default SpeechBubbleWithAvatar;
