import React from 'react';
import { connect } from 'react-redux';
import {
  analyticsClick as analyticsClickActionCreator,
  AnalyticsClick,
  ClickAnalyticsOptions,
} from 'src/redux/modules/analytics';
import { bindActionCreators } from 'redux';
import CSSModules from 'react-css-modules';
import styles from './RadioButton.scss';

interface OwnProps {
  analyticsOptions?: ClickAnalyticsOptions;
  checked: boolean;
  className?: string;
  id: string;
  labelText?: string | JSX.Element;
  name?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  qaClassName?: string;
  value: string;
}

interface DispatchProps {
  analyticsClick: AnalyticsClick;
}

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = (dispatch: any): DispatchProps =>
  bindActionCreators(
    {
      analyticsClick: analyticsClickActionCreator,
    },
    dispatch
  );

export class RadioButton extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: React.FormEvent<HTMLInputElement>) {
    const { onChange, analyticsClick, analyticsOptions } = this.props;

    if (onChange) {
      onChange(e);
    }
    if (analyticsOptions) {
      analyticsClick(analyticsOptions);
    }
  }

  render() {
    const { checked, id, labelText, name, qaClassName, value } = this.props;
    return (
      <div>
        <input
          id={id}
          type="radio"
          className={`jg-radio ${qaClassName}`}
          name={name}
          value={value}
          checked={checked}
          onChange={this.handleChange}
        />

        {labelText && (
          <label
            className={`jg-label jg-space-mbn ${styles['radio-button-label']}`}
            htmlFor={id}
          >
            {labelText}
          </label>
        )}
      </div>
    );
  }
}

export default connect<void, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(CSSModules(RadioButton, styles));
