import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import { Link } from 'src/components/Analytics';
import Alert from 'src/components/Alert/';

const AdditionalDetailsAlert: Rule<JSX.Element> = state => {
  const {
    totalRequiredFields,
    accountDisabledReason,
  } = state.stripeVirtualAccount;
  const { name } = state.page;
  const alertKey = 'additional_data';

  if (
    totalRequiredFields > 0 &&
    (!accountDisabledReason || accountDisabledReason !== 'RejectedFraud')
  ) {
    return {
      matched: true,
      result: (
        <Alert id={alertKey} type="red" persistent>
          <p className="jg-space-mbn">
            Sorry, but we couldn’t verify your details. We need you to{' '}
            <Link
              to={`/${name}/funds/additionaldetails`}
              analyticsOptions={{
                event: 'link click',
                eventValue: 'submit some further information',
                pageSection: 'admin',
                subtype: 'hyperlink',
              }}
            >
              submit some further information
            </Link>
            .
          </p>
        </Alert>
      ),
    };
  }

  return { matched: false };
};

export default AdditionalDetailsAlert;
