import { get, patch } from 'src/helpers/api/common';

export interface RecurringDonationsThankYouMessageData {
  thankYouMessage: string;
}

export interface UpdateRecurringDonationsSettingsData {
  shouldRecurringDonationsBeEnabled: boolean;
}

export async function updateRecurringDonationsThankYouMessage(
  projectId: string,
  formData: RecurringDonationsThankYouMessageData
) {
  return patch(`/projects/${projectId}/settings`, formData);
}

export async function updateSupportsRecurringDonationsStatus(
  pageId: string,
  supportsRecurringPayments: boolean,
  thankYouMessage: string
) {
  return patch(`projects/${pageId}/settings`, {
    supportsRecurringPayments,
    thankYouMessage,
  });
}

export type SettingsResponse = {
  supportsRecurringPayments: boolean;
  thankYouMessage?: string;
};

export async function getSettings(
  pageId?: string
): Promise<SettingsResponse | null> {
  return get<SettingsResponse>(`projects/${pageId}/settings`);
}
