import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import Alert from 'src/components/Alert/';

const StripeUnverifiedAlert: Rule<JSX.Element> = state => {
  const {
    legalEntityVerificationStatus,
    submittedAllRequiredFields,
  } = state.stripeVirtualAccount;

  const alertKey = 'verify_manual';

  if (
    submittedAllRequiredFields &&
    legalEntityVerificationStatus === 'Unverified'
  ) {
    return {
      matched: true,
      result: (
        <Alert id={alertKey} type="red" persistent>
          <p className="jg-space-mbn">
            Unfortunately we weren't able to verify your identity. Please{' '}
            <a
              href="https://help.justgiving.com/hc/en-us/requests/new?ticket_form_id=5342"
              rel="noreferrer noopener"
              target="_blank"
            >
              contact our customer service team
            </a>{' '}
            to continue Crowdfunding.
          </p>
        </Alert>
      ),
    };
  }

  return { matched: false };
};

export default StripeUnverifiedAlert;
