import React from 'react';
import Countdown, {
  CountdownReturnProps,
} from 'src/components/Countdown/Countdown';

interface Props {
  suspensionDate: string | Date;
}

const SuspensionCountdown: React.StatelessComponent<Props> = ({
  suspensionDate,
}) => {
  return (
    <Countdown endDate={suspensionDate} tick={60000}>
      {({
        daysRemaining,
        hoursRemaining,
        minutesRemaining,
      }: CountdownReturnProps) => {
        const countdownColor =
          daysRemaining < 1
            ? 'jg-color--sunset-red'
            : daysRemaining <= 5
              ? 'jg-color--safety-orange'
              : 'jg-color--mountain-meadow';

        return (
          <div>
            <h1 className={`${countdownColor} jg-text--xlarge jg-space-mbmd`}>
              {`${daysRemaining}d ${hoursRemaining}h ${minutesRemaining}m`}
            </h1>
          </div>
        );
      }}
    </Countdown>
  );
};

export default SuspensionCountdown;
