import { get as getCurrentUser, isLoggedIn } from 'src/helpers/api/self';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';

interface LoadUserDetailsRequestAction {
  type: 'LOAD_USER_DETAILS_REQUEST';
}

interface LoadUserDetailsSuccessAction {
  type: 'LOAD_USER_DETAILS_SUCCESS';
  payload: Self;
}

interface LoadUserDetailsFailureAction {
  type: 'LOAD_USER_DETAILS_FAILURE';
  error: true;
  payload: Error;
}

export function loadUserDetails() {
  return async (dispatch: Dispatch<AppState>) => {
    dispatch<LoadUserDetailsRequestAction>({
      type: 'LOAD_USER_DETAILS_REQUEST',
    });

    try {
      const userDetails = await getUserDetails();

      dispatch<LoadUserDetailsSuccessAction>({
        type: 'LOAD_USER_DETAILS_SUCCESS',
        payload: userDetails,
      });
    } catch (error) {
      dispatch<LoadUserDetailsFailureAction>({
        type: 'LOAD_USER_DETAILS_FAILURE',
        payload: new Error(error),
        error: true,
      });
    }
  };
}

export interface Self {
  id?: string;
  userDetails?: UserDetails;
  locale: {
    country: string;
    isoCode: string;
  };
}

export interface UserDetails {
  title?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: {
    town: string;
    postCode: string;
    addressLine1: string;
    addressLine2: string;
    street: string;
    subStreet: string;
    buildingName: string;
    buildingNumber: string;
    flatNumber: string;
  };
}

async function getUserDetails(): Promise<Self> {
  const data = await getCurrentUser();

  if (!data) {
    throw new Error('User not found');
  }

  const locale = {
    country: data.country,
    isoCode: data.isoCode,
  };

  if (!isLoggedIn(data)) {
    return { locale };
  }

  const userDetails = {
    title: data.title,
    firstName: data.firstName,
    lastName: data.lastName,
    phoneNumber: data.phoneNumber,
    address: {
      town: data.town,
      postCode: data.postCode,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      street: data.street,
      subStreet: data.subStreet,
      buildingName: data.buildingName,
      buildingNumber: data.buildingNumber,
      flatNumber: data.flatNumber,
    },
  };

  return { id: data.id, userDetails, locale };
}

export type SelfState = {
  isLoading?: boolean;
  lastRequested?: number;
} & Partial<Self>;

type LoadUserDetailsAction =
  | LoadUserDetailsRequestAction
  | LoadUserDetailsSuccessAction
  | LoadUserDetailsFailureAction;

export default function reducer(
  state: SelfState = {},
  action: LoadUserDetailsAction
): SelfState {
  switch (action.type) {
    case 'LOAD_USER_DETAILS_REQUEST':
      return { ...state, isLoading: true };
    case 'LOAD_USER_DETAILS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        lastRequested: Date.now(),
        ...action.payload,
      };
    case 'LOAD_USER_DETAILS_FAILURE':
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
