import React from 'react';
import { formatDate } from 'src/helpers/date';
import { withI18n, WithI18n } from 'react-i18next';

interface Props {
  date: string | Date;
}

const FormatDate: React.StatelessComponent<Props & WithI18n> = ({
  lng,
  date,
}) => {
  return <>{formatDate(new Date(date), lng)}</>;
};

export default withI18n()(FormatDate);
