import React from 'react';
import styles from './SpeechBubbleWithAvatar.scss';
import { ArrowPositions } from './SpeechBubbleWithAvatar';

interface Props {
  arrowPosition: ArrowPositions;
  message: string;
}

const MessageBox: React.StatelessComponent<Props> = ({
  arrowPosition,
  message,
}) => (
  <div
    className={`jg-bd jg-bd--solid jg-bd--very-light-grey jg-bd--radius-3px jg-space-pms jg-position-relative jg-preserve-whitespace ${
      styles[`supporter-message-box-${arrowPosition}`]
    }`}
  >
    <p className="jg-text--light jg-space-mbn">{message}</p>
  </div>
);

export default MessageBox;
