import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import PageStrandedPageOwnerContent from './PageStrandedPageOwnerContent';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';

const PageStrandedPageOwner: Rule<
  JSX.Element,
  PromptRulesEngineOptions
> = state => {
  if (state.page.status === 'Stranded' && state.page.isOwnerRequested) {
    return {
      matched: true,
      result: <PageStrandedPageOwnerContent />,
    };
  }

  return { matched: false };
};

export default PageStrandedPageOwner;
