import * as React from 'react';
import { Button } from 'src/components/Analytics';
import { FormattedMessage } from 'react-intl';
import Alert from 'src/components/Alert';
import ClickableElement from 'src/components/ClickableElement';
import StripeVerificationDocumentTips from './StripeVerificationDocumentTips';

interface Props {
  areTipsShowing: boolean;
  toggleTips: () => void;
  error: string;
  addPhoto: (pic: File) => void;
  removeImage: () => void;
}

class StripeVerificationDocumentUpload extends React.Component<Props> {
  fileInput: HTMLInputElement;

  renderAction() {
    if (this.props.areTipsShowing) {
      return (
        <div>
          <FormattedMessage
            id="StripeVerificationDocumentUpload.toggleIdTipsClose"
            defaultMessage="Close"
          />
          <span className="jg-space-plxs">&#10005;</span>
        </div>
      );
    }

    return (
      <FormattedMessage
        id="StripeVerificationDocumentUpload.toggleIdTipsOpen"
        defaultMessage="How to avoid your ID check being rejected"
      />
    );
  }

  renderButtonText() {
    if (window.matchMedia('(max-width: 567px)').matches) {
      return (
        <FormattedMessage
          id="StripeVerificationDocumentUpload.uploadOrTakePhotoButton"
          defaultMessage="Upload or take a photo"
        />
      );
    } else {
      return (
        <FormattedMessage
          id="StripeVerificationDocumentUpload.uploadPhotoButton"
          defaultMessage="Upload a photo"
        />
      );
    }
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.fileInput.click();
  };

  handleUploadPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pic = e.target.files && e.target.files[0];
    if (pic) {
      this.props.addPhoto(pic);
    }
  };

  removeImage = () => {
    this.props.removeImage();
  };

  render() {
    const buttonText = this.renderButtonText();

    return (
      <div>
        <p>
          <FormattedMessage
            id="StripeVerificationDocumentUpload.idTypeSuggestionText"
            defaultMessage={`This could be a photo or scan of your passport, your ID or driving licence.`}
          />
        </p>

        <ClickableElement
          className="jg-text--link jg-text--center jg-space-mtmd jg-space-mbml"
          onClick={this.props.toggleTips}
        >
          {this.renderAction()}
        </ClickableElement>

        <input
          className="jg-hidden qa-file-input"
          type="file"
          name="stripeVerificationDocument"
          id="stripeVerificationDocument"
          ref={input => {
            this.fileInput = input as HTMLInputElement;
          }}
          onChange={this.handleUploadPhoto}
          accept="image/*"
        />

        {this.props.areTipsShowing && <StripeVerificationDocumentTips />}
        <div className="jg-space-phlg@md">
          {this.props.error === 'imageTooLarge' && (
            <div className="jg-space-mbmd">
              <Alert
                id="photo-id-alert"
                type="red"
                displayType="inline"
                persistent
              >
                <FormattedMessage
                  id="StripeVerificationDocumentForm.imageTooLarge"
                  defaultMessage="Please upload a JPEG or PNG image that's smaller than 15mb"
                />
              </Alert>
            </div>
          )}
          <Button
            className="jg-btn jg-btn--secondary"
            onClick={this.handleClick}
            analyticsOptions={{
              event: 'link click',
              eventValue: 'upload or take a photo',
              pageSection: 'admin > verify identity',
              subtype: 'button',
            }}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    );
  }
}

export default StripeVerificationDocumentUpload;
