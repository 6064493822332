import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './TabViewContent.scss';

const TabViewContent: React.StatelessComponent = ({ children }) => (
  <section styleName="panel" className="jg-background--white-smoke">
    {children}
  </section>
);

export default CSSModules(TabViewContent, styles);
