import i18next from 'i18next';

export abstract class Email {
  targetAmountFormatted: string;
  pageUrl: string;
  pitchFor: string;
  ownerName: string;
  homeUrl: string;

  abstract getSubject(): string;
  abstract getBody(): string;

  public i18nWithEnrichment(key: string, defaultValue: string) {
    return i18next.t(key, {
      defaultValue,
      targetAmountFormatted: this.targetAmountFormatted,
      pitchFor: this.pitchFor,
      pageUrl: this.pageUrl,
      ownerName: this.ownerName,
      homeUrl: this.homeUrl,
    });
  }
}
