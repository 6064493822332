import React from 'react';

interface Props {
  totalCountSupporters: number;
}

const SupportersHeader = ({ totalCountSupporters }: Props) => (
  <>
    <div className="jg-display-flex jg-space-pbms">
      <h2 className="jg-h2 jg-flex-g1">Supporters</h2>
      <h2 className="jg-h2">{totalCountSupporters}</h2>
    </div>
  </>
);

export default SupportersHeader;
