export function get2dContext(
  canvas: HTMLCanvasElement
): CanvasRenderingContext2D {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error("Couldn't obtain 2d canvas context");
  }

  return ctx;
}

export function loadImage(path: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const element = document.createElement('img');

    element.onload = () => {
      resolve(element);
    };

    element.onerror = () => {
      reject(new Error(`Failed to load background image ${path}`));
    };

    element.crossOrigin = 'anonymous';
    element.src = path;
  });
}

export async function loadImages<T, K extends keyof T>(
  paths: T
): Promise<{ [P in K]: HTMLImageElement }> {
  const promises = Object.entries(paths).map(async ([name, path]) => {
    const image = await loadImage(path);

    return { name, image };
  });

  const images = await Promise.all(promises);

  return images.reduce(
    (obj, p) => {
      obj[p.name as K] = p.image;
      return obj;
    },
    // tslint:disable-next-line:no-object-literal-type-assertion
    {} as { [P in K]: HTMLImageElement }
  );
}

const loadedFonts = new Set();

export function waitForFont(font: string, timeout = 5000): Promise<void> {
  return new Promise((resolve, reject) => {
    if (loadedFonts.has(font)) {
      resolve();
    }

    const canvas = document.createElement('canvas');
    const ctx = get2dContext(canvas);

    const measure = () => ctx.measureText('abcdefghijklmnopqrstuvwxyz').width;
    const scheduleCheck = () => setTimeout(check, 50);

    ctx.font = '10px sans-serif';
    const originalWidth = measure();
    ctx.font = `10px ${font}, sans-serif`;

    const startTime = Date.now();

    function check() {
      if (measure() !== originalWidth) {
        loadedFonts.add(font);
        resolve();
      }

      if (Date.now() - startTime > timeout) {
        reject();
      } else {
        scheduleCheck();
      }
    }

    scheduleCheck();
  });
}

export function adjustCanvasSize(
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  width: number,
  height: number
) {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const ctx = context as any;
  const backingStoreRatio =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;

  const ratio = devicePixelRatio / backingStoreRatio;

  canvas.width = width * ratio;
  canvas.height = height * ratio;
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
}
