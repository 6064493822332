import React from 'react';
import throttle from 'lodash/throttle';

export interface Props {
  offset: number;
  children?: (state: { sticky: boolean }) => any;
  delay?: number;
}

export interface State {
  sticky: boolean;
}

class Sticky extends React.Component<Props, State> {
  state = {
    sticky: false,
  };

  handleScroll = throttle(() => {
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    const pastOffset = scrollPosition > this.props.offset;

    if (this.state.sticky !== pastOffset) {
      this.setState({
        sticky: pastOffset,
      });
    }
  }, this.props.delay || 750);

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return this.props.children({
      sticky: this.state.sticky,
    });
  }
}

export default Sticky;
