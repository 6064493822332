import React from 'react';
import styles from './CharityEndorsed.scss';
import ClickableElement from 'src/components/ClickableElement';
import { FormattedMessage } from 'react-intl';
import Svg from '@justgiving/svg';
import ConfigurableDesktopTooltip from 'src/components/InfoWithTooltip/ConfigurableDesktopTooltip';
import Modal from 'src/components/Modal';
import info from '@justgiving/icons/lib/info.svg';
import { Button } from 'src/components/Analytics';
interface Props {
  isMobile: boolean;
}
interface State {
  isTooltipShowing: boolean;
}
class CharityEndorsementTooltip extends React.Component<Props, State> {
  state = {
    isTooltipShowing: false,
  };

  toggleTooltip = () => {
    this.setState({ isTooltipShowing: !this.state.isTooltipShowing });
  };

  renderTooltipModal = () => {
    return (
      <Modal
        isOpen
        colorTheme={'dark'}
        onClose={this.toggleTooltip}
        shouldCloseOnOverlayClick
      >
        <div
          className={`${
            styles['jg-tooltip-mobile']
          } jg-space-pvlg jg-space-phms jg-display-flex jg-flex-column jg-flex-jcb`}
        >
          <div>
            <h1>
              <FormattedMessage
                id="CharityEndorsedTooltip.pageAssociatedWithCharity"
                defaultMessage="This page is associated with a&nbsp;charity"
              />
            </h1>
            <p className="jg-text--large">
              <FormattedMessage
                id="CharityEndorsedTooltip.charitiesAreAssociatedWithPages"
                defaultMessage="Charities are associated with pages where the cause is connected to their ongoing&nbsp;mission."
              />
            </p>
            <p className="jg-text--large">
              <FormattedMessage
                id="CharityEndorsedTooltip.ownersCanContribute"
                defaultMessage="Page owners can make a contribution at any time to support the great work the charity&nbsp;does"
              />
            </p>
          </div>
          <Button
            className={`jg-btn jg-btn--ghost jg-flex-ase`}
            onClick={this.toggleTooltip}
          >
            <FormattedMessage
              id="CharityEndorsedTooltip.gotItButton"
              defaultMessage="Okay, got it!"
            />
          </Button>
        </div>
      </Modal>
    );
  };

  render() {
    const { isMobile } = this.props;

    if (isMobile) {
      return (
        <>
          <ClickableElement
            inline
            className={`qa-info-button`}
            onClick={this.toggleTooltip}
          >
            <Svg markup={info} className={`jg-icon jg-fill--empress`} />
          </ClickableElement>
          {this.state.isTooltipShowing && this.renderTooltipModal()}
        </>
      );
    }

    return (
      <ConfigurableDesktopTooltip
        displayInline
        trigger="hover"
        tooltipPosition="right"
        arrowPosition="left"
      >
        <div className="jg-space-psm">
          <p className="jg-space-mbn">
            <FormattedMessage
              id="CharityEndorsedTooltip.infoText"
              defaultMessage="Charities are associated with pages where the cause is connected to their ongoing mission. Page owners can make a contribution at any time to support the great work the charity does."
            />
          </p>
        </div>
      </ConfigurableDesktopTooltip>
    );
  }
}

export default CharityEndorsementTooltip;
