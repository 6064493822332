import React, { FunctionComponent, useEffect } from 'react';
import { useDonatePopup } from 'src/components/DonatePopup/DonatePopupContext';
import Modal from 'src/components/Modal';
import { Trans } from 'react-i18next';
import CSSModules from 'react-css-modules';
import styles from './DonatePopup.scss';

const DonatePopupRefocusModal: FunctionComponent = () => {
  const {
    setPopupCheckoutMode,
    popupWindow,
    popupCheckoutMode,
  } = useDonatePopup();

  useEffect(
    () => {
      if (popupCheckoutMode === 'open') {
        const checkForClosedPopup = setInterval(() => {
          if (!popupWindow || popupWindow.closed) {
            setPopupCheckoutMode('closed');
          }
        }, 1000);
        return () => {
          clearTimeout(checkForClosedPopup);
        };
      }
    },
    [popupWindow, popupCheckoutMode]
  );

  const refocusPopup = (event: React.MouseEvent) => {
    if (!popupWindow) {
      return;
    }
    popupWindow.focus();
    event.stopPropagation();
  };

  return (
    <Modal
      classNames={styles.modal}
      isOpen
      onOverlayClick={refocusPopup}
      onModalClick={refocusPopup}
    >
      <p className="jg-h4 jg-space-mbmd">
        <Trans i18nKey="popupRefocusModal.lostCheckoutWindow">
          Lost the checkout window?
        </Trans>
      </p>
      <p className="jg-h4 jg-space-mbn">
        <Trans i18nKey="popupRefocusModal.clickAnywhereToRetrieve">
          Click anywhere to retrieve it
        </Trans>
      </p>
    </Modal>
  );
};

export default CSSModules(DonatePopupRefocusModal, styles);
