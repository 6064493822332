import * as React from 'react';
import scriptLoader from 'src/helpers/scriptLoader';

interface Props {
  sitekey: string;
  verifyCallback: (arg: any) => void;
}

interface State {
  id: string;
}

class Recaptcha extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: '',
    };
  }

  componentDidMount() {
    if (window.grecaptcha) {
      this.renderRecaptcha();
    } else {
      window.onRecapchaLoad = () => {
        this.renderRecaptcha();
      };
    }
  }

  componentWillUnmount() {
    this.resetRecaptcha();
  }

  resetRecaptcha = () => {
    window.grecaptcha.reset(this.state.id);
  };

  verifyCallback = () => {
    const response = window.grecaptcha.getResponse(this.state.id);
    this.props.verifyCallback(response);
  };

  renderRecaptcha() {
    this.setState({
      id: window.grecaptcha.render('html_element', {
        sitekey: this.props.sitekey,
        callback: this.verifyCallback,
      }),
    });
  }

  render() {
    return <div className="g-recaptcha" id="html_element" />;
  }
}

export default scriptLoader(
  'https://www.google.com/recaptcha/api.js?onload=onRecapchaLoad&render=explicit'
)(Recaptcha);
