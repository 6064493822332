import React from 'react';
import CSSModules from 'react-css-modules';
import DonateButton from './DonateButton';
import styles from './PageFooter.scss';
import { PageState } from 'src/redux/modules/page';
import ShareButton from '../ShareModal/ShareButton';

interface Props {
  canPageReceiveDonations: boolean;
  page: PageState;
  donate: () => void;
  handleShareButtonClick: () => void;
}

const PageFooter: React.StatelessComponent<Props> = ({
  donate,
  canPageReceiveDonations,
  page,
  handleShareButtonClick,
}) => {
  return (
    <div styleName="fixed-footer">
      <DonateButton
        donate={donate}
        isActive={canPageReceiveDonations}
        name={page.name}
        pageSection="page > sticky footer"
        id={page.id}
      />
      <div styleName="fixed-share-button">
        <ShareButton handleShareClick={handleShareButtonClick} />
      </div>
    </div>
  );
};

export default CSSModules(PageFooter, styles);
