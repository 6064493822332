export interface CropData {
  position: { x: number; y: number };
  scale: number;
  rotate: number;
}

export type PhotoEditorMode = 'pick' | 'preview' | 'edit';

interface LoadImageAction {
  type: 'LOAD_IMAGE';
  namespace: string;
  payload: string;
}

interface RejectImageAction {
  type: 'REJECT_IMAGE';
  namespace: string;
  cropData?: CropData;
}

interface LoadPreviewImageAction {
  type: 'LOAD_PREVIEW_IMAGE';
  namespace: string;
  payload: string;
}

interface RemoveImageAction {
  type: 'REMOVE_IMAGE';
  namespace: string;
}

interface UpdateCropAction {
  type: 'UPDATE_CROP';
  namespace: string;
  payload: CropData;
}

interface RotateImageAction {
  type: 'ROTATE_IMAGE';
  namespace: string;
  payload: CropData;
}

interface OpenGalleryAction {
  type: 'OPEN_GALLERY';
  namespace: string;
}

interface CloseGalleryAction {
  type: 'CLOSE_GALLERY';
  namespace: string;
}

interface LoadGalleryImageAction {
  type: 'LOAD_GALLERY_IMAGE';
  namespace: string;
  payload: string;
}

export function loadImage(namespace: string, image: string): LoadImageAction {
  return {
    type: 'LOAD_IMAGE',
    namespace,
    payload: image,
  };
}

export function rejectImage(namespace: string): RejectImageAction {
  return {
    type: 'REJECT_IMAGE',
    namespace,
    cropData: undefined,
  };
}

export function loadPreviewImage(
  namespace: string,
  image: string
): LoadPreviewImageAction {
  return {
    type: 'LOAD_PREVIEW_IMAGE',
    namespace,
    payload: image,
  };
}

export function removeImage(namespace: string): RemoveImageAction {
  return {
    type: 'REMOVE_IMAGE',
    namespace,
  };
}

export function updateCrop(
  namespace: string,
  cropData: CropData
): UpdateCropAction {
  return {
    type: 'UPDATE_CROP',
    namespace,
    payload: cropData,
  };
}

export function rotateImage(
  namespace: string,
  cropData: CropData
): RotateImageAction {
  return {
    type: 'ROTATE_IMAGE',
    namespace,
    payload: cropData,
  };
}

export function openGallery(namespace: string): OpenGalleryAction {
  return {
    type: 'OPEN_GALLERY',
    namespace,
  };
}

export function closeGallery(namespace: string): CloseGalleryAction {
  return {
    type: 'CLOSE_GALLERY',
    namespace,
  };
}

export function loadGalleryImage(
  namespace: string,
  image: string
): LoadGalleryImageAction {
  return {
    type: 'LOAD_GALLERY_IMAGE',
    namespace,
    payload: image,
  };
}

export interface PhotoEditorActions {
  loadImage: (image: string) => void;
  rejectImage: () => void;
  loadPreviewImage: (image: string) => void;
  removeImage: () => void;
  updateCrop: (cropData: CropData) => void;
  rotateImage: (cropData: CropData) => void;
  openGallery: () => void;
  closeGallery: () => void;
  loadGalleryImage: (image: string) => void;
}

export function createPhotoEditorActions(
  namespace: string
): PhotoEditorActions {
  return {
    loadImage: loadImage.bind(null, namespace),
    rejectImage: rejectImage.bind(null, namespace),
    loadPreviewImage: loadPreviewImage.bind(null, namespace),
    removeImage: removeImage.bind(null, namespace),
    updateCrop: updateCrop.bind(null, namespace),
    rotateImage: rotateImage.bind(null, namespace),
    openGallery: openGallery.bind(null, namespace),
    closeGallery: closeGallery.bind(null, namespace),
    loadGalleryImage: loadGalleryImage.bind(null, namespace),
  };
}

type Action =
  | LoadImageAction
  | RejectImageAction
  | LoadPreviewImageAction
  | RemoveImageAction
  | UpdateCropAction
  | RotateImageAction
  | OpenGalleryAction
  | CloseGalleryAction
  | LoadGalleryImageAction;

export interface PhotoEditorState {
  mode: PhotoEditorMode;
  image?: string;
  cropData?: CropData;
  galleryOpen?: boolean;
  imageRejected?: boolean;
}

const initialCropData: CropData = {
  position: { x: 0.5, y: 0.5 },
  scale: 1,
  rotate: 0,
};

const initialState: PhotoEditorState = {
  mode: 'pick',
  cropData: initialCropData,
};

export default function reducer(
  namespace: string,
  state = initialState,
  action: Action
): PhotoEditorState {
  if (action.namespace !== namespace) {
    return state;
  }

  switch (action.type) {
    case 'LOAD_IMAGE':
      return {
        ...state,
        mode: 'edit',
        image: action.payload,
        cropData: initialCropData,
        imageRejected: false,
      };
    case 'REJECT_IMAGE':
      return {
        ...state,
        imageRejected: true,
        cropData: undefined,
      };
    case 'REMOVE_IMAGE':
      return {
        ...state,
        mode: 'pick',
        image: undefined,
        cropData: initialCropData,
      };
    case 'UPDATE_CROP':
      return {
        ...state,
        cropData: action.payload,
      };
    case 'ROTATE_IMAGE':
      return {
        ...state,
        cropData: { ...action.payload, scale: 1 },
      };
    case 'OPEN_GALLERY':
      return {
        ...state,
        galleryOpen: true,
      };
    case 'CLOSE_GALLERY':
      return {
        ...state,
        galleryOpen: false,
      };
    case 'LOAD_GALLERY_IMAGE':
      return {
        ...state,
        galleryOpen: false,
        mode: 'edit',
        image: action.payload,
        cropData: initialCropData,
      };
    case 'LOAD_PREVIEW_IMAGE':
      return {
        ...state,
        mode: 'preview',
        image: action.payload,
      };
    default:
      return state;
  }
}
