import React from 'react';
import { Trans, withI18n, WithI18n } from 'react-i18next';
import bankDetailsVerified from 'src/components/Prompt/BankDetailsVerified/bankDetailsVerified.svg';
import Svg from '@justgiving/svg';
import styles from 'src/components/Prompt/BankDetailsVerified/BankDetailsVerifiedContent.scss';

interface Props {
  onClose: () => void;
}

const BankDetailsVerifiedContent: React.StatelessComponent<
  Props & WithI18n
> = ({ onClose }) => {
  return (
    <div className="jg-background--white jg-bdb jg-bd--solid jg-bd--very-light-grey jg-space-phmd jg-space-pvlg jg-space-mhn jg-text--center row">
      <button className={styles['grey-cross']} onClick={onClose} />
      <div className="col-xs-12 col-md-8 offset-md-2">
        <Svg markup={bankDetailsVerified} className="jg-svg-sm" />
        <h2>
          <Trans i18nKey="BankDetailsVerifiedContent.title">
            Good news! Your payment details have been added
          </Trans>
        </h2>
        <p className="jg-space-mbml">
          <Trans i18nKey="BankDetailsVerifiedContent.subtitle">
            You're all set to start receiving your funds.
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default withI18n()(BankDetailsVerifiedContent);
