import config from 'config';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import differenceInMonths from 'date-fns/difference_in_months';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import subMonths from 'date-fns/sub_months';
import compareAsc from 'date-fns/compare_asc';

export function getTimeLeft(finishDate?: string) {
  if (!finishDate) {
    return `${config.DEFAULT_PAGE_LIFE} days`;
  }

  // if date is in the past
  if (compareAsc(finishDate!, Date.now()) === -1) {
    return '0 minutes';
  }

  const monthDiff = differenceInMonths(finishDate, Date.now());

  // let datefns format days remaining or years
  if (monthDiff === 0 || monthDiff > 12) {
    return distanceInWordsStrict(finishDate, Date.now());
  }

  let result = `${monthDiff} ${monthDiff > 1 ? 'months' : 'month'}`;

  // calculate the amount of days remaining once the months are subtracted
  const daysDiff = differenceInCalendarDays(
    subMonths(finishDate, monthDiff),
    Date.now()
  );

  if (daysDiff > 0) {
    result = `${result} and ${daysDiff} ${daysDiff > 1 ? 'days' : 'day'}`;
  }

  return result;
}
