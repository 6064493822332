import React from 'react';
import LoadingButton from 'src/components/LoadingButton/';
import { connect } from 'react-redux';
import {
  analyticsClick as analyticsClickActionCreator,
  AnalyticsClick,
  ClickAnalyticsOptions,
} from 'src/redux/modules/analytics';
import { bindActionCreators } from 'redux';

type DispatchProps = {
  analyticsClick: AnalyticsClick;
};

type OwnProps = {
  color?: 'purple' | 'white' | 'grey' | 'blue';
  id?: string;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  analyticsOptions?: ClickAnalyticsOptions;
  value?: any;
  loading: boolean;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
};

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = (dispatch: any): DispatchProps =>
  bindActionCreators(
    {
      analyticsClick: analyticsClickActionCreator,
    },
    dispatch
  );

export class AnalyticsLoadingButton extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event: React.MouseEvent<HTMLElement>) {
    const { onClick, analyticsClick, analyticsOptions } = this.props;

    if (onClick) {
      onClick(event);
    }
    if (analyticsOptions) {
      analyticsClick(analyticsOptions);
    }
  }

  render() {
    const { color, id, loading, value, className, disabled, type } = this.props;

    return (
      <LoadingButton
        id={id}
        loading={loading}
        value={value}
        onClick={this.handleClick}
        className={className}
        disabled={disabled}
        color={color}
        type={type}
      />
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(AnalyticsLoadingButton);
