import React from 'react';
import Transition, { TransitionProps } from './Transition';

interface TranslateYTransitionProps extends TransitionProps {
  startOffset?: number;
  endOffset?: number;
}

const TranslateYTransition: React.StatelessComponent<
  TranslateYTransitionProps
> = ({ startOffset, endOffset, before, after, ...props }) => (
  <Transition
    property="transform"
    willChange="transform"
    before={createTransform(startOffset || 0, before || {})}
    after={createTransform(endOffset || 0, after || {})}
    {...props}
  />
);

function createTransform(
  offset: number,
  style: { [key: string]: string | number }
) {
  if (offset === 0) {
    return style;
  }

  const transform = `translateY(${offset}px)`;

  return {
    ...style,
    transform,
    msTransform: transform,
    WebkitTransform: transform,
  };
}

TranslateYTransition.defaultProps = {
  startOffset: 0,
  endOffset: 0,
};

export default TranslateYTransition;
