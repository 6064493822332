import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from 'src/redux/modules';
import {
  shareOnTwitter,
  shareByEmail,
  shareByWhatsApp,
  shareBySms,
  sharePageLink,
  ShareActionOptions,
  shareQrCode,
} from 'src/redux/modules/page';
import { closeSharePrompts } from 'src/redux/modules/sharePrompts';
import SharePrompts from 'src/components/SharePrompts/SharePrompts';
import currencyMap from 'src/helpers/currencyMap';
import { isActive } from 'src/helpers/page';
import { push } from 'react-router-redux';

interface StateProps {
  isActive: boolean;
  raised: number;
  targetAmount: number;
  pitchFor: string;
  ownerName: string;
  isOwner: boolean;
  isSharePromptsOpen: boolean;
  socialShareUrl: string;
  targetCurrency: Currency;
  pageSection: string;
  avatarImage?: string;
  pageName: string;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    isActive: isActive(state.page),
    raised: state.page.amountRaised,
    targetAmount: state.page.targetAmount,
    pitchFor: state.page.pitchFor,
    ownerName: state.page.ownerName,
    isOwner: state.page.isOwnerRequested,
    isSharePromptsOpen: state.sharePrompts.isSharePromptsOpen,
    socialShareUrl: state.page.socialShareUrl,
    targetCurrency: state.page.targetCurrency,
    pageSection: state.sharePrompts.pageSection,
    avatarImage: state.page.ownerProfileImageUrl,
    pageName: state.page.name,
  };
}

interface DispatchProps {
  shareOnTwitter: (options: ShareActionOptions) => void;
  shareByEmail: (options: ShareActionOptions) => void;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareBySms: (options: ShareActionOptions) => void;
  sharePageLink: (options: ShareActionOptions) => void;
  shareQrCode: (options: ShareActionOptions) => void;
  closeSharePrompts: () => void;
  push: (location: any) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      shareOnTwitter,
      shareByEmail,
      shareByWhatsApp,
      shareBySms,
      sharePageLink,
      closeSharePrompts,
      shareQrCode,
      push,
    },
    dispatch
  );
}

type Props = StateProps & DispatchProps;

export class SharePromptsContainer extends React.Component<Props> {
  render() {
    if (!this.props.isSharePromptsOpen) {
      return null;
    }

    return (
      <SharePrompts
        isActive={this.props.isActive}
        raised={this.props.raised}
        shareByEmail={this.props.shareByEmail}
        socialShareUrl={this.props.socialShareUrl}
        targetAmount={this.props.targetAmount}
        targetCurrency={this.props.targetCurrency}
        pitchFor={this.props.pitchFor}
        ownerName={this.props.ownerName}
        isOwner={this.props.isOwner}
        shareBySms={this.props.shareBySms}
        shareByWhatsApp={this.props.shareByWhatsApp}
        shareOnTwitter={this.props.shareOnTwitter}
        shareQrCode={this.props.shareQrCode}
        currencySymbol={currencyMap[this.props.targetCurrency] || '£'}
        sharePageLink={this.props.sharePageLink}
        closeSharePrompts={this.props.closeSharePrompts}
        pageSection={this.props.pageSection}
        push={this.props.push}
        pageName={this.props.pageName}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharePromptsContainer);
