import React from 'react';
import Redux from 'redux';
import { connect } from 'react-redux';
import { beginLoad, endLoad } from 'src/redux/modules/asyncConnect';
import LoadingOverlay from 'src/components/LoadingOverlay';

type FetchData = (dispatch: any, ownProps: any) => Promise<any>;

interface Config<State, StateProps, DispatchProps, TOwnProps> {
  fetchData: FetchData;
  mapStateToProps?: (state: State, ownProps?: TOwnProps) => StateProps;
  mapDispatchToProps?: (
    dispatch: Redux.Dispatch<any>,
    ownProps: TOwnProps
  ) => DispatchProps;
  disableLoadingScreen: boolean;
  id: string;
}

export default <
  TState extends { asyncConnect: { [id: string]: boolean } },
  TStateProps,
  TDispatchProps extends object,
  TOwnProps
>(
  Component: React.ComponentClass<TStateProps & TDispatchProps>,
  config: Config<TState, TStateProps, TDispatchProps, TOwnProps>
) => {
  class HOC extends React.Component<
    TStateProps &
      TDispatchProps & {
        dataLoaded: boolean;
        fetchData: () => Promise<{}>;
        beginLoad: (id: string) => void;
        endLoad: (id: string) => void;
      }
  > {
    static displayName = Component.displayName;
    static fetchData = (dispatch: any, params: any) => {
      return new Promise((resolve, reject) => {
        dispatch(beginLoad(config.id));

        config.fetchData(dispatch, params).then(
          data => {
            dispatch(endLoad(config.id));
            resolve(data);
          },
          err => {
            dispatch(endLoad(config.id));
            reject(err);
          }
        );
      });
    };

    componentDidMount() {
      if (!this.props.dataLoaded) {
        this.props.fetchData();
      }
    }

    render() {
      if (this.props.dataLoaded) {
        return <Component {...this.props} />;
      }

      return <LoadingOverlay />;
    }
  }

  const hocMapStateToProps = (state: TState, ownProps: any) =>
    Object.assign(
      config.mapStateToProps ? config.mapStateToProps(state, ownProps) : {},
      {
        dataLoaded: state.asyncConnect[config.id] || false,
      }
    );

  const hocDispatchToProps = (dispatch: any, ownProps: any) =>
    Object.assign(
      config.mapDispatchToProps
        ? config.mapDispatchToProps(dispatch, ownProps)
        : {},
      {
        fetchData: HOC.fetchData.bind(
          null,
          dispatch,
          ownProps && ownProps.match && ownProps.match.params
        ),
        beginLoad: (id: string) => dispatch(beginLoad(id)),
        endLoad: (id: string) => dispatch(endLoad(id)),
      }
    );

  const connected = connect(
    hocMapStateToProps,
    hocDispatchToProps
  )(HOC);

  (connected as any).WrapperComponent = Component;

  return connected;
};
