import React from 'react';
import GenericSupporter from './GenericSupporter';
import SpecificSupporter from './SpecificSupporter';
import { getQueryFromUrl } from 'src/helpers/url';
import { PromptRulesEngineOptions } from 'src/helpers/prompts';
import { Rule } from 'src/helpers/rulesEngine';

const PageClosedFullyFundedSupporter: Rule<
  JSX.Element,
  PromptRulesEngineOptions
> = state => {
  const { amountRaised, finishedAt, targetCurrency, status } = state.page;
  const { totalCount } = state.supporters;

  const supporterName = __CLIENT__
    ? (getQueryFromUrl(window.location.href).supporterName as string)
    : null;

  if (status === 'FullyFunded' || status === 'ReportGenerated') {
    if (supporterName) {
      return {
        matched: true,
        result: (
          <SpecificSupporter
            targetCurrency={targetCurrency}
            supporterName={supporterName}
            amountRaised={amountRaised}
            totalCountSupporters={totalCount}
            finishedAt={finishedAt}
          />
        ),
      };
    }

    return {
      matched: true,
      result: (
        <GenericSupporter page={state.page} supporters={state.supporters} />
      ),
    };
  }

  return { matched: false };
};

export default PageClosedFullyFundedSupporter;
