import differenceInDays from 'date-fns/difference_in_days';
import { isClosed } from 'src/helpers/page';

function setUserProperties(properties) {
  if (__CLIENT__ && window._kiq) {
    window._kiq.push(['set', properties]);
  }
}

function yesOrNo(bool) {
  return bool ? 'Yes' : 'No';
}

const qualaroo = () => next => action => {
  if (action.type === 'PAGE_SUCCESS' || action.type === 'PAGE_MOUNT') {
    const {
      activatedAt,
      amountPledged,
      amountRaised,
      categoryId,
      finishedAt,
      id,
      isOwnerRequested,
      verificationStatus,
      status,
      targetAmount,
    } = action.payload;

    const pageClosed = isClosed(status);

    amountRaised = (pageClosed ? amountRaised : amountPledged) || 0;

    const daysSinceActivation = activatedAt
      ? differenceInDays(new Date(), activatedAt)
      : 0;

    const customProperties = {
      GUID: id,
      activated_under_7_days: yesOrNo(daysSinceActivation < 7),
      activated_under_30_days: yesOrNo(daysSinceActivation < 30),
      activated_under_60_days: yesOrNo(daysSinceActivation < 60),
      activated_under_90_days: yesOrNo(daysSinceActivation < 90),
      activated_under_120_days: yesOrNo(daysSinceActivation < 120),
      bank_account_verified: yesOrNo(verificationStatus === 'Verified'),
      crowdfunding_page_owner: yesOrNo(isOwnerRequested),
      page_category_id: categoryId,
      page_status: status,
      raised_0: yesOrNo(amountRaised === 0),
      raised_under_500: yesOrNo(amountRaised <= 500),
      raised_under_1000: yesOrNo(amountRaised <= 1000),
      raised_under_1500: yesOrNo(amountRaised <= 1500),
      raised_under_2000: yesOrNo(amountRaised <= 2000),
      raised_over_2000: yesOrNo(amountRaised > 2000),
      target_reached: yesOrNo(amountRaised >= targetAmount),
    };

    setUserProperties(customProperties);
  }

  return next(action);
};

export default qualaroo;
