import React from 'react';
import { Rule } from 'src/helpers/rulesEngine';
import Alert from 'src/components/Alert/';
import { FormattedMessage } from 'react-intl';
import { Link } from 'src/components/Analytics';
import { BankAccountStatus } from 'src/helpers/api/paymentDetails';

const alertKey = 'stripe_bank_account_invalid';

const contactCustomerServiceLink = (
  <Link
    href="https://help.justgiving.com/hc/en-us/requests/new?ticket_form_id=5342"
    rel="noreferrer noopener"
    target="_blank"
  >
    <FormattedMessage
      id="StripeBankAccountInvalidAlert.contactCustomerService.link"
      defaultMessage="Customer Service"
    />
  </Link>
);

const StripeBankAccountInvalidAlert: Rule<JSX.Element> = state => {
  const { funds, paymentDetails, page } = state;

  if (
    paymentDetails.bankAccountStatus &&
    paymentDetails.bankAccountStatus === BankAccountStatus.Errored
  ) {
    return {
      matched: true,
      result: (
        <Alert id={alertKey} type="red" page={page}>
          <p className="jg-space-mbn">
            {funds.withdrawalFailureCount < 2 ? (
              <FormattedMessage
                id="StripeBankAccountInvalidAlert.editBankDetails.message"
                defaultMessage="We were unable to withdraw funds to your account. Please {editBankDetailsLink} or contact {contactCustomerServiceLink}."
                values={{
                  editBankDetailsLink: (
                    <Link
                      to={`/${page.name}/funds/account`}
                      className="jg-text--link"
                    >
                      <FormattedMessage
                        id="StripeBankAccountInvalidAlert.editBankDetails.link"
                        defaultMessage="edit your bank details"
                      />
                    </Link>
                  ),
                  contactCustomerServiceLink,
                }}
              />
            ) : (
              <FormattedMessage
                id="StripeBankAccountInvalidAlert.contactCustomerService.message"
                defaultMessage="We were unable to withdraw funds to your account. Please contact {contactCustomerServiceLink}."
                values={{ contactCustomerServiceLink }}
              />
            )}
          </p>
        </Alert>
      ),
    };
  }

  return { matched: false };
};

export default StripeBankAccountInvalidAlert;
