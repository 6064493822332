import { ParagraphNode, StoryNode } from 'src/helpers/api/pages';
import { SerializedNode, SerializedRange, SerializedState } from 'slate';
import { UploadFailureResult, uploadImage } from 'src/helpers/api/images';

import config from 'config';
import flatten from 'lodash/flatten';

export function convertProblemToRichStory(problem: string): ParagraphNode[] {
  if (problem === '') {
    return [];
  }

  return problem.split(/\n{2,}/).map(
    (item: string): ParagraphNode => {
      return {
        type: 'paragraph',
        nodes: [{ type: 'text', ranges: [{ text: item }] }],
      };
    }
  );
}

export function convertRichStoryToProblem(richStory: StoryNode[]): string {
  if (!richStory) {
    return '';
  }

  return richStory
    .reduce((p, node) => {
      switch (node.type) {
        case 'header':
        case 'quote':
          return p + node.text + '\n\n';
        case 'paragraph':
          return (
            p +
            node.nodes.map(n => n.ranges.map(r => r.text).join('')).join('') +
            '\n\n'
          );
        default:
          return p;
      }
    }, '')
    .trim();
}

export function transformState(state: SerializedState) {
  let transformedState: any = [];

  transformedState = state.document.nodes.map((node: SerializedNode) => {
    const type = node.type;
    switch (type) {
      case 'header':
        return transformHeaderNodeToSave(node);
      case 'paragraph':
        return transformParagraphNodeToSave(node);
      case 'quote':
        return transformQuoteNodeToSave(node);
      case 'image':
        return transformImageNodeToSave(node as SerializedNode<ImageData>);
      case 'video':
        return transformVideoNodeToSave(node as SerializedNode<{
          href: string;
        }>);
      default:
        throw new Error(`Unsupported node type ${type}.`);
    }
  });

  return transformedState;
}

export async function transformStateToSave(state: SerializedState) {
  return uploadMediaAndTransformToSave(transformState(state));
}

export async function uploadMediaAndTransformToSave(transformedState: any) {
  const uploadedImages = await processImages(transformedState);

  transformedState = transformedState.map((node: any) => {
    if (node.type === 'image') {
      const name = uploadedImages.get(node.src);

      return { type: 'image', name };
    }
    return node;
  });

  return transformedState;
}

function transformHeaderNodeToSave(node: SerializedNode) {
  const firstRange = getFirstChildNodeFirstRange(node);

  if (firstRange) {
    return {
      type: 'header',
      text: firstRange.text,
    };
  }
  return {};
}

function transformParagraphNodeToSave(node: SerializedNode) {
  if (node.nodes) {
    const childNodes = node.nodes.map((child: SerializedNode) => {
      switch (child.kind) {
        case 'text':
          if (child.ranges) {
            const childRanges = child.ranges.map(
              (textRange: SerializedRange) => {
                return { text: textRange.text };
              }
            );
            return {
              type: 'text',
              ranges: childRanges,
            };
          }
        case 'inline':
          if (child.type === 'link') {
            const link = child as SerializedNode<{ href: string }>;

            if (!link.nodes || !link.data) {
              return {};
            }

            const linkNodes = link.nodes.map((linkNode: SerializedNode) => {
              if (linkNode.ranges) {
                return linkNode.ranges.map((linkRange: any) => {
                  return {
                    text: linkRange.text,
                  };
                });
              }
              return {};
            });
            return {
              type: 'link',
              url: link.data.href,
              ranges: flatten(linkNodes),
            };
          }
        default:
          throw new Error(`Unsupported child node kind ${child.kind}.`);
      }
    });

    return {
      type: 'paragraph',
      nodes: childNodes,
    };
  }
  return {};
}

function transformQuoteNodeToSave(node: SerializedNode) {
  const firstRange = getFirstChildNodeFirstRange(node);

  if (firstRange) {
    return {
      type: 'quote',
      text: firstRange.text,
    };
  }
  return {};
}

interface ImageData {
  src: string;
  pic: File;
}

function transformImageNodeToSave(node: SerializedNode<ImageData>) {
  if (node.data) {
    if (node.data.src.startsWith('http')) {
      const lastSlash = node.data.src.lastIndexOf('/') + 1;
      const imgName = node.data.src.slice(lastSlash, node.data.src.length);

      return {
        type: 'image',
        src: imgName,
      };
    }

    return {
      type: 'image',
      src: node.data.src,
      pic: node.data.pic,
    };
  }
  return {};
}

function transformVideoNodeToSave(node: SerializedNode<{ href: string }>) {
  if (node.data) {
    if (typeof node.data.href === 'undefined') {
      return {
        type: 'paragraph',
        nodes: [],
      };
    } else {
      return {
        type: 'video',
        url: node.data.href,
      };
    }
  }
  return {};
}

// Save richStory helpers
function getFirstChildNodeFirstRange(
  node: SerializedNode
): SerializedRange | undefined {
  if (node.nodes && node.nodes[0]) {
    const firstNode = node.nodes[0];
    if (firstNode.ranges) {
      return firstNode.ranges[0];
    }
  }
  return undefined;
}

async function processImages(state: any) {
  const promises = state
    .filter((x: SerializedNode) => x.type === 'image')
    .map(async (listing: any) => {
      if (listing.pic) {
        const uploadedImage = await uploadImage(listing.pic);

        if (!uploadedImage.success) {
          throw new Error((uploadedImage as UploadFailureResult).message);
        }

        return [listing.src, uploadedImage.data.imageName];
      }

      return [listing.src, listing.src];
    });

  const processedImages = new Map(await Promise.all<any>(promises));

  return processedImages;
}

interface SavedNode {
  type: string;
  text?: string;
  nodes?: SavedChildNode[];
  name?: string;
  url?: string;
}

interface SavedChildNode {
  type: string;
  ranges: ({ text: string })[];
  url?: string;
}

export function transformRichStoryToEdit(richStory: StoryNode[]) {
  let editorNodes;

  editorNodes = richStory.map((savedNode: SavedNode) => {
    switch (savedNode.type) {
      case 'header':
        return transformHeaderToEdit(savedNode);
      case 'quote':
        return transformQuoteToEdit(savedNode);
      case 'image':
        return transformImageToEdit(savedNode);
      case 'video':
        return tranformVideoToEdit(savedNode);
      case 'paragraph':
        return transformParagraphToEdit(savedNode);
      default:
        return {};
    }
  });

  return editorNodes;
}

function transformHeaderToEdit(savedNode: SavedNode) {
  return {
    data: {},
    kind: 'block',
    isVoid: false,
    type: savedNode.type,
    nodes: [
      {
        kind: 'text',
        ranges: [
          {
            kind: 'range',
            text: savedNode.text,
            marks: [],
          },
        ],
      },
    ],
  };
}

function transformQuoteToEdit(savedNode: SavedNode) {
  return {
    data: {},
    kind: 'block',
    isVoid: false,
    type: savedNode.type,
    nodes: [
      {
        kind: 'text',
        ranges: [
          {
            kind: 'range',
            text: savedNode.text,
            marks: [],
          },
        ],
      },
    ],
  };
}

function transformImageToEdit(savedNode: SavedNode) {
  return {
    data: {
      src: `${config.IMAGE_BASE_URI}/${savedNode.name}`,
    },
    kind: 'block',
    isVoid: true,
    type: savedNode.type,
    nodes: [
      {
        kind: 'text',
        ranges: [
          {
            kind: 'range',
            text: ' ',
            marks: [],
          },
        ],
      },
    ],
  };
}

function tranformVideoToEdit(savedNode: SavedNode) {
  return {
    data: {
      href: savedNode.url,
    },
    kind: 'block',
    isVoid: true,
    type: savedNode.type,
    nodes: [
      {
        kind: 'text',
        ranges: [
          {
            kind: 'range',
            text: ' ',
            marks: [],
          },
        ],
      },
    ],
  };
}

function transformParagraphToEdit(savedNode: SavedNode) {
  const childNodes = savedNode.nodes!.map((child: SavedChildNode) => {
    if (child.url) {
      const linkRanges = child.ranges.map((linkRange: { text: string }) => {
        return {
          kind: 'text',
          ranges: [
            {
              kind: 'range',
              text: linkRange.text,
              marks: [],
            },
          ],
        };
      });

      return {
        data: {
          href: child.url,
        },
        kind: 'inline',
        isVoid: false,
        type: child.type,
        nodes: linkRanges,
      };
    }

    const ranges = child.ranges.map((range: { text: string }) => {
      return {
        kind: 'range',
        text: range.text,
        marks: [],
      };
    });

    return {
      kind: 'text',
      ranges,
    };
  });

  return {
    data: {},
    kind: 'block',
    isVoid: false,
    type: savedNode.type,
    nodes: childNodes,
  };
}
