import React from 'react';
import CSSModules from 'react-css-modules';
import { Link } from 'src/components/Analytics';
import RichUpdatesNavIcon from './RichUpdatesNavIcon';
import styles from './RichUpdateNavLink.scss';

interface Props {
  path: string;
  disabled: boolean;
  pageSection: string;
}

const RichUpdateNavLink: React.StatelessComponent<Props> = ({
  path,
  disabled,
  pageSection,
}) =>
  disabled ? (
    <div>
      <RichUpdatesNavIcon isActive={false} />
    </div>
  ) : (
    <Link
      to={path}
      analyticsOptions={{
        event: 'link click',
        subtype: 'button',
        eventValue: 'post an update',
        pageSection: `admin > ${pageSection}`,
      }}
    >
      <RichUpdatesNavIcon isActive={true} />
    </Link>
  );

export default CSSModules(RichUpdateNavLink, styles);
