import React from 'react';
import EndorsingCharityLogo from 'src/components/CharityEndorsed/EndorsingCharityLogo';
import { CharityState } from 'src/redux/modules/charity/reducer';
import { FormattedMessage } from 'react-intl';
import { isDesktopViewport } from 'src/helpers/browser';
import CharityEndorsementTooltip from 'src/components/CharityEndorsed/CharityEndorsementTooltip';
import { LessThanMedium, Medium } from '@justgiving/responsive';

interface Props {
  charity: CharityState;
}

interface State {
  isTooltipModalOpen: boolean;
}

class CharityEndorsement extends React.Component<Props, State> {
  render() {
    const { charity } = this.props;
    const isMobile = !isDesktopViewport();

    return (
      <div
        className={`jg-display-flex ${!isMobile &&
          'jg-flex-aic'} jg-space-mbml jg-space-plms jg-space-mtms`}
      >
        <LessThanMedium>
          <EndorsingCharityLogo
            displayTick
            charityLogo={charity!.logo}
            className="jg-space-mrms@md jg-space-mbsm jg-space-mbn@md"
            smallIcon
          />
        </LessThanMedium>
        <Medium>
          <EndorsingCharityLogo
            displayTick
            charityLogo={charity!.logo}
            className="jg-space-mrms@md jg-space-mbsm jg-space-mbn@md"
          />
        </Medium>
        <div
          className={`jg-display-flex jg-space-mlms ${
            isMobile ? 'jg-flex-column' : 'jg-flex-row'
          }`}
        >
          <div>
            <p
              className={`${!isMobile &&
                'jg-text--small'} jg-space-mbxs jg-space-mrsm`}
            >
              <FormattedMessage
                id="CharityEndorsement.pageAssociatedWith"
                defaultMessage="This page is associated with {charityName}"
                values={{
                  charityName: (
                    <FormattedMessage
                      id="CharityEndorsement.pageAssociatedWith.charity"
                      defaultMessage="{charity}"
                      tagName="b"
                      values={{ charity: charity!.name }}
                    />
                  ),
                }}
              />
            </p>
            <p
              className={`${
                isMobile ? 'jg-space-mbn' : 'jg-text--small'
              } jg-text--light`}
            >
              <FormattedMessage
                id="CharityEndorsement.charityRegistrationNumber"
                defaultMessage="Charity Registration No. {registrationNumber}"
                values={{
                  registrationNumber: charity!.registrationNumber,
                }}
              />
            </p>
          </div>
          <div>
            <CharityEndorsementTooltip isMobile={isMobile} />
          </div>
        </div>
      </div>
    );
  }
}

export default CharityEndorsement;
