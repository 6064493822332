export function openCenteredPopup(
  url: string,
  popupWidth: number,
  popupHeight: number
) {
  // Fixes dual-screen position       Most browsers        Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
  const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    '_blank',
    'scrollbars=yes, width=' +
      popupWidth / systemZoom +
      ', height=' +
      popupHeight / systemZoom +
      ', top=' +
      top +
      ', left=' +
      left
  );

  // Puts focus on the newWindow
  if (window.focus !== undefined && newWindow) {
    newWindow.focus();
  }
}
