import CSSModules from 'react-css-modules';
import React from 'react';
import { Trans } from 'react-i18next';
import { buildLinkServiceDonateUrl } from 'src/helpers/linkService';
import classNames from 'classnames';
import config from 'config';
import { isMobileUserAgent } from 'src/helpers/browser';
import styles from './DonateButton.scss';
import { useDonatePopup } from 'src/components/DonatePopup/DonatePopupContext';

interface Props {
  isActive: boolean;
  name: string;
  pageSection: string;
  donate: (options: { pageSection: string }) => void;
  id: string;
}

export function DonateButton({
  isActive,
  name,
  pageSection,
  donate,
  id,
}: Props) {
  const { showCheckoutPopup } = useDonatePopup();
  const buttonClass = classNames('jg-btn', {
    'is-disabled': !isActive,
  });

  let donateLink: string;
  if (isActive) {
    donateLink = buildLinkServiceDonateUrl(id);
  }

  const onClickDonate = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      !isMobileUserAgent() &&
      (config.FEATURE.EMBEDDED_CHECKOUT ||
        urlParams.get('popupCheckout') === 'true')
    ) {
      event.preventDefault();
      const modalDonateLink = `${donateLink}&checkoutMode=Headless`;
      showCheckoutPopup(modalDonateLink);
    }
    donate({ pageSection });
  };

  return (
    <a
      data-marker="donate-button"
      onClick={onClickDonate}
      href={donateLink}
      className={buttonClass}
      styleName="fixed-donate-button"
    >
      <Trans i18nKey="Common.Donate">Give Now</Trans>
    </a>
  );
}

export default CSSModules(DonateButton, styles);
