import React from 'react';
import { OwnerAvatar } from '../Avatar';
import icon from './mobile_share.svg';

interface Props {
  ownerName: string;
  ownerProfileImage?: string;
}

const MobileSharePopup: React.StatelessComponent<Props> = ({
  children,
  ownerProfileImage,
  ownerName,
}) => (
  <div
    className="row jg-space-phsm jg-space-ptlg jg-display-flex jg-flex-aic"
    style={{ height: '100vh' }}
  >
    <div className="col-md-12 jg-text--center">
      {ownerProfileImage ? (
        <OwnerAvatar
          template="Size258w"
          src={ownerProfileImage}
          alt={ownerName}
          size="share-prompt-profile-mobile"
        />
      ) : (
        <img
          src={icon}
          alt="People sharing the page via Whatsapp"
          className="jg-space-mbmd"
        />
      )}
      {children}
    </div>
  </div>
);

export default MobileSharePopup;
