import React from 'react';
import CSSModules from 'react-css-modules';
import Svg from '@justgiving/svg';
import Modal from '../Modal';
import styles from './ReminderModal.scss';
import { AnalyticsPageView } from '../Analytics';
import ClickableElement from '../ClickableElement';
import closeIcon from '@justgiving/icons/lib/close.svg';
import AddToCalendarButton from './AddToCalendarButton';
import { CalendarType } from './helpers/addToCalendar';

export interface ReminderModalProps {
  showModal: boolean;
  pageSection: string;
  targetAmount: number;
  targetCurrency: Currency;
  pitchFor: string;
  ownerName: string;
  socialShareUrl: string;
  isActive: boolean;
  handleModalClose: () => void;
}

class ReminderModal extends React.Component<ReminderModalProps> {
  render() {
    const {
      showModal,
      pageSection,
      handleModalClose,
      pitchFor,
      ownerName,
      socialShareUrl,
      targetCurrency,
      targetAmount,
      isActive,
    } = this.props;
    return (
      <Modal
        isOpen={showModal}
        shouldCloseOnOverlayClick={false}
        classNames={styles.modal}
      >
        <AnalyticsPageView
          eventValue="reminder modal"
          pageSection={pageSection}
          subtype="cfp"
        >
          <header>
            <ClickableElement
              onClick={handleModalClose}
              inline
              className={styles.close}
              analyticsOptions={{
                event: 'click',
                eventValue: 'close reminder modal',
                pageSection,
                subtype: 'button',
              }}
            >
              <Svg
                markup={closeIcon}
                className="jg-icon--medium jg-fill--nobel"
              />
            </ClickableElement>
          </header>
          <div className="row jg-space-mhn">
            <div styleName="description">
              <h2 className="jg-h2" styleName="title">
                Add a reminder
              </h2>
              <p>
                Don't worry if you're not ready to donate right now, we can
                remind you before the fundraising ends
              </p>
            </div>
          </div>
          <div styleName="button-container">
            <AddToCalendarButton
              pageSection={pageSection}
              ownerName={ownerName}
              isActive={isActive}
              calendarType={CalendarType.Apple}
              pitchFor={pitchFor}
              targetCurrency={targetCurrency}
              socialShareUrl={socialShareUrl}
              targetAmount={targetAmount}
            />
            <AddToCalendarButton
              pageSection={pageSection}
              ownerName={ownerName}
              isActive={isActive}
              calendarType={CalendarType.Google}
              pitchFor={pitchFor}
              targetCurrency={targetCurrency}
              socialShareUrl={socialShareUrl}
              targetAmount={targetAmount}
            />
            <AddToCalendarButton
              pageSection={pageSection}
              ownerName={ownerName}
              isActive={isActive}
              calendarType={CalendarType.Outlook}
              pitchFor={pitchFor}
              targetCurrency={targetCurrency}
              socialShareUrl={socialShareUrl}
              targetAmount={targetAmount}
            />
            <AddToCalendarButton
              pageSection={pageSection}
              ownerName={ownerName}
              isActive={isActive}
              calendarType={CalendarType.OutlookWeb}
              buttonText="Outlook Web"
              pitchFor={pitchFor}
              targetCurrency={targetCurrency}
              socialShareUrl={socialShareUrl}
              targetAmount={targetAmount}
            />
            <AddToCalendarButton
              pageSection={pageSection}
              ownerName={ownerName}
              isActive={isActive}
              calendarType={CalendarType.Yahoo}
              pitchFor={pitchFor}
              targetCurrency={targetCurrency}
              socialShareUrl={socialShareUrl}
              targetAmount={targetAmount}
            />
          </div>
        </AnalyticsPageView>
      </Modal>
    );
  }
}

export default CSSModules(ReminderModal, styles);
