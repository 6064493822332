import React from 'react';
import SpeechBubbleWithAvatar from 'src/components/SpeechBubbleWithAvatar/SpeechBubbleWithAvatar';

interface Props {
  ownerAvatarImage?: string;
  ownerName: string;
  recurringDonationsPitch: string;
}

const RecurringDonationsPitch: React.StatelessComponent<Props> = ({
  ownerAvatarImage,
  ownerName,
  recurringDonationsPitch,
}) => (
  <SpeechBubbleWithAvatar
    avatarImage={ownerAvatarImage}
    message={recurringDonationsPitch}
    name={ownerName}
    arrowPosition="left-top"
    showAvatar
    showName={false}
  />
);

export default RecurringDonationsPitch;
