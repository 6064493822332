export function abbreviateNumber(num: number) {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return `${formatNumber(num, 1000)}K`;
  } else if (num < 1000000000) {
    return `${formatNumber(num, 1000000)}M`;
  }

  return `${formatNumber(num, 1000000000)}B`;
}

function formatNumber(value: number, base: number) {
  if (value % base === 0) {
    return value / base;
  }
  return (value / base).toFixed(1);
}

type Style = 'currency' | 'decimal' | 'percent';
type CurrencyDisplay = 'symbol' | 'code' | 'name';

export interface FormatCurrencyOptions {
  style?: Style;
  currency: Currency;
  currencyDisplay?: CurrencyDisplay;
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}

const currencyLocaleMap: Record<Currency, string> = {
  GBP: 'en-GB',
  USD: 'en-US',
  AUD: 'en-AU',
};

export function formatCurrency(value: number, options: FormatCurrencyOptions) {
  const locale = currencyLocaleMap[options.currency];

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    ...options,
  }).format(value);
}

const dateFormatLocaleMap: Record<string, string> = {
  USD: 'MM / DD / YYYY',
  GBP: 'DD / MM / YYYY',
};

export function formatDatePlaceholder(targetCurrency: Currency) {
  return dateFormatLocaleMap[targetCurrency] || dateFormatLocaleMap.GBP;
}
