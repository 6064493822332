import React from 'react';
import Svg from '@justgiving/svg';
import qrcode from './images/qrcode.svg';
import { ShareActionOptions } from 'src/redux/modules/page';
import { generateHash } from 'src/helpers/hashId';

interface Props {
  isActive: boolean;
  buttonText: string;
  classNames?: string;
  customStyle?: string;
  handleClick: () => void;
  shareQrCode: (options: ShareActionOptions) => void;
  location: string;
}

class QRCodeShare extends React.Component<Props> {
  handleQrCodeShare = () => {
    const { handleClick, location } = this.props;
    const shareHash = generateHash();

    this.props.shareQrCode({ location, shareHash });

    handleClick();
  };
  render() {
    const { isActive, customStyle, buttonText, classNames } = this.props;

    return (
      <button
        type="button"
        disabled={!isActive}
        className={
          classNames || 'jg-btn jg-btn--ghost jg-space-mbms jg-text--large'
        }
        styleName={customStyle}
        onClick={this.handleQrCodeShare}
      >
        <Svg
          markup={qrcode}
          className="jg-icon jg-fill--nobel jg-icon--medium jg-display-flex jg-position-absolute"
        />
        {buttonText || 'QR Code'}
      </button>
    );
  }
}

export default QRCodeShare;
