import React from 'react';
import CSSModules from 'react-css-modules';
import differenceInDays from 'date-fns/difference_in_days';
import format from 'date-fns/format';
import { getTimeLeft } from 'src/helpers/countdown';
import ClickableElement from 'src/components/ClickableElement';
import styles from './Meta.scss';
import { CategoryResource } from 'src/helpers/api/categories';
import { MIN_PAGE_LIFE } from 'config';
import { FormattedMessage } from 'react-intl';
import Svg from '@justgiving/svg';
import mapPin from '@justgiving/icons/lib/map-pin.svg';
import heart from '@justgiving/icons/lib/care.svg';
import clock from '@justgiving/icons/lib/clock.svg';
import { isActive } from 'src/helpers/page';
import { PageStatus } from 'src/helpers/api/pages';

interface Props {
  categories: CategoryResource[];
  categoryId?: number | null;
  goEdit: (section: string) => void;
  isOwner: boolean;
  location?: string;
  finishedAt?: string;
  status: PageStatus;
  supportsRecurringDonations: boolean;
}

class Meta extends React.Component<Props> {
  getCountdownMessage() {
    const { finishedAt, status } = this.props;

    if (status === 'FullyFunded' || status === 'ReportGenerated') {
      return `Funded on ${format(finishedAt!, 'dddd, Do MMMM YYYY')}`;
    }

    if (status === 'TargetNotReached') {
      return `Closed on ${format(finishedAt!, 'dddd, Do MMMM YYYY')}`;
    }

    return `${getTimeLeft(finishedAt)} to go`;
  }

  canEditField(value?: number | string | null) {
    const { isOwner, status } = this.props;

    return !value && isOwner && isActive({ status });
  }

  shouldDisplayIcon(value?: number | string | null) {
    return value || this.canEditField(value);
  }

  renderLocation() {
    const { location, goEdit } = this.props;

    if (this.canEditField(location)) {
      return (
        <ClickableElement
          onClick={() => goEdit('location')}
          inline
          className="jg-text--link"
        >
          <FormattedMessage
            id="Meta.addLocation"
            defaultMessage="Add location"
          />
        </ClickableElement>
      );
    }

    return location;
  }

  renderCategory() {
    const { categoryId, categories, goEdit } = this.props;

    if (this.canEditField(categoryId)) {
      return (
        <ClickableElement
          onClick={() => goEdit('category')}
          inline
          className="jg-text--link"
        >
          <FormattedMessage
            id="Meta.addCategory"
            defaultMessage="Add category"
          />
        </ClickableElement>
      );
    }

    const category = categories.filter(c => c.id === categoryId)[0];

    if (category) {
      return (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={category.url}
          className="jg-text--link"
        >
          {category.name}
        </a>
      );
    }

    return null;
  }

  render() {
    const {
      location,
      categoryId,
      finishedAt,
      supportsRecurringDonations,
    } = this.props;
    const daysRemaining = differenceInDays(finishedAt!, new Date());

    return (
      <dl styleName="container">
        <div styleName="meta-item">
          {this.shouldDisplayIcon(location) && (
            <dt styleName="heading">
              <Svg
                markup={mapPin}
                className={`${styles.icon} jg-fill--nobel jg-display-i`}
              />
            </dt>
          )}
          <dd className="jg-text--small" styleName="value">
            {this.renderLocation()}
          </dd>
        </div>

        <div styleName="meta-item">
          {this.shouldDisplayIcon(categoryId) && (
            <dt styleName="heading">
              <Svg
                markup={heart}
                className={`${styles.icon} jg-fill--nobel jg-display-i`}
              />
            </dt>
          )}
          <dd className="jg-text--small" styleName="value">
            {this.renderCategory()}
          </dd>
        </div>

        {(daysRemaining <= MIN_PAGE_LIFE || supportsRecurringDonations) && (
          <div styleName="meta-item" id="qa-countdown-container">
            <dt styleName="heading">
              <Svg
                markup={clock}
                className={`${styles.icon} jg-fill--nobel jg-display-i`}
              />
            </dt>
            <dd className="jg-text--small" styleName="value">
              {this.getCountdownMessage()}
            </dd>
          </div>
        )}
      </dl>
    );
  }
}

export default CSSModules(Meta, styles);
