import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';
import { patch } from 'src/helpers/api/pages';
import { Dispatch } from 'redux';
import { AppState } from 'src/redux/modules';

interface LinkRequestAction {
  type: 'LINK_REQUEST';
}

interface LinkSuccessAction {
  type: 'LINK_SUCCESS';
  payload: {
    name: string;
  };
}

interface LinkFailureAction {
  type: 'LINK_FAILURE';
}

type PageLinkActions =
  | LinkRequestAction
  | LinkSuccessAction
  | LinkFailureAction;

export interface SubmitLinkPayload {
  pageLink: string;
}

export function submitLink({ pageLink }: SubmitLinkPayload) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const { id, name } = getState().page;
    dispatch<LinkRequestAction>({ type: 'LINK_REQUEST' });

    const page = {
      id,
      projectId: id,
      name: pageLink,
    };

    try {
      if (name !== pageLink) {
        await patch(page);
      }

      dispatch<LinkSuccessAction>({
        type: 'LINK_SUCCESS',
        payload: { name: page.name },
      });
      dispatch(push(`/${page.name}/confirmidentity`));
    } catch (error) {
      dispatch<LinkFailureAction>({ type: 'LINK_FAILURE' });

      throw new SubmissionError({
        pageLink: 'An error has occurred, please try again.',
      });
    }
  };
}

export interface PageLinkState {
  isRequesting: boolean;
  hasError: boolean;
}

const initalState = {
  isRequesting: false,
  hasError: false,
};

export default function reducer(
  state: PageLinkState = initalState,
  action: PageLinkActions
) {
  switch (action.type) {
    case 'LINK_REQUEST':
      return {
        ...state,
        isRequesting: true,
      };
    case 'LINK_SUCCESS':
      return {
        ...state,
        isRequesting: false,
      };
    case 'LINK_FAILURE':
      return {
        ...state,
        isRequesting: false,
        hasError: true,
      };
    default:
      return state;
  }
}
