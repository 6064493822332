import { PaymentDetailsResource } from 'src/helpers/api/paymentDetails';
import { VerificationStatus } from 'src/helpers/api/pages';

type PaymentPage = {
  verificationStatus: VerificationStatus;
  stripeVirtualAccountGuid?: string | undefined;
};

export function isAccountConnected(
  page: PaymentPage,
  paymentDetails: PaymentDetailsResource
): boolean {
  if (page.stripeVirtualAccountGuid) {
    return (
      !!paymentDetails.accountNumber &&
      !!paymentDetails.sortCode &&
      paymentDetails.sortCode !== '00-00-00'
    );
  }

  return (
    page.verificationStatus !== 'UnverifiedPayment' &&
    page.verificationStatus !== 'UnverifiedManual'
  );
}
