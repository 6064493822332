import {
  FundsInfoAction,
  WithdrawFundsActions,
  ConfirmatioModalActions,
} from 'src/redux/modules/funds/actions';

export interface FundsState {
  isLoading: boolean;
  error?: Error;
  amountAvailable: number;
  status: FundsStatus;
  withdrawalAvailableDate?: string;
  withdrawals: Withdrawal[];
  withdrawalRequestLoading: boolean;
  confirmationModalOpen: boolean;
  withdrawalSuccessful: boolean;
  withdrawalFailureCount: number;
}

export type FundsStatus =
  | 'NoFunds'
  | 'PreviousWithdrawalProcessing'
  | 'CanWithdraw'
  | 'NotAvailableYet'
  | 'ProjectNotActive'
  | 'ProjectNotReviewed'
  | 'ProjectNotVerified';

export interface Withdrawal {
  grossAmount: number;
  netAmount: number;
  dateRequested: string;
  status: WithdrawalStatus;
  dateCompleted?: string;
  justGivingFee: number;
  processingCosts: number;
}

export type WithdrawalStatus = 'Processing' | 'Complete' | 'Failed';

const initialState: FundsState = {
  confirmationModalOpen: false,
  amountAvailable: 0,
  isLoading: true,
  status: 'NoFunds',
  withdrawals: [],
  withdrawalRequestLoading: false,
  withdrawalSuccessful: false,
  withdrawalFailureCount: 0,
};

type Action = FundsInfoAction | WithdrawFundsActions | ConfirmatioModalActions;

export default function reducer(
  state = initialState,
  action: Action
): FundsState {
  switch (action.type) {
    case 'OPEN_CONFIRMATION_MODAL':
      return {
        ...state,
        confirmationModalOpen: true,
      };

    case 'CLOSE_CONFIRMATION_MODAL':
      return {
        ...state,
        confirmationModalOpen: false,
      };

    case 'FUNDS_INFO_REQUEST':
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };

    case 'FUNDS_INFO_SUCCESS':
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        amountAvailable: action.payload.amountAvailable,
        withdrawalAvailableDate: action.payload.withdrawalAvailableDate,
        withdrawals: action.payload.withdrawals,
        withdrawalFailureCount: action.payload.withdrawals.filter(
          x => x.status === 'Failed'
        ).length,
      };

    case 'FUNDS_INFO_FAILURE':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case 'CLOSE_WITHDRAWAL_SUCCESSFUL_ALERT':
      return {
        ...state,
        withdrawalSuccessful: false,
      };

    case 'WITHDRAW_FUNDS_REQUEST':
      return {
        ...state,
        withdrawalRequestLoading: true,
      };

    case 'WITHDRAW_FUNDS_SUCCESS':
      return {
        ...state,
        withdrawalRequestLoading: false,
        confirmationModalOpen: false,
        withdrawalSuccessful: true,
      };

    default:
      return state;
  }
}
