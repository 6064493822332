import reducer from './reducer';

export default reducer;
export {
  deleteRichUpdate,
  loadUpdates,
  publishRichUpdate,
  watchingVideoToggle,
  clearUpdateForms,
} from './actions';
export { getUpdates } from './selectors';
