import { patch } from 'src/helpers/api/pages';
import {
  transformStateToSave,
  convertProblemToRichStory,
} from 'src/helpers/richEditor';
import { SerializedState } from 'slate';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import {
  RichStoryError,
  SubmissionError,
} from 'src/redux/modules/page/updatePage';

interface StoryRequestAction {
  type: 'STORY_REQUEST';
  payload: string | SerializedState;
}

interface StorySuccessAction {
  type: 'STORY_SUCCESS';
}

interface StoryFailureAction {
  type: 'STORY_FAILURE';
  error: true;
  payload: Error;
}

export function submitStory(story: string | SerializedState) {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    dispatch<StoryRequestAction>({ type: 'STORY_REQUEST', payload: story });

    const page = getState().page;

    try {
      const storyPayload = {
        id: page.id,
        name: page.name,
        richStory:
          typeof story === 'string'
            ? convertProblemToRichStory(story)
            : await transformStateToSave(story),
      };

      await patch(storyPayload);

      dispatch<StorySuccessAction>({ type: 'STORY_SUCCESS' });

      dispatch({ type: 'UPDATE_SUCCESS', payload: storyPayload });
    } catch (err) {
      dispatch<StoryFailureAction>({
        type: 'STORY_FAILURE',
        error: true,
        payload: err,
      });

      if (
        err.response &&
        err.response.badRequest &&
        err.response.body &&
        err.response.body.message === 'emptyStory'
      ) {
        throw new RichStoryError('Please write your story');
      }

      throw new SubmissionError('An error has occurred. Please try again.');
    }
  };
}

export interface StoryState {
  isRequesting: boolean;
  hasError: boolean;
}

const initalState = {
  isRequesting: false,
  hasError: false,
};

export default function reducer(
  state: StoryState = initalState,
  action: StoryRequestAction | StorySuccessAction | StoryFailureAction
) {
  switch (action.type) {
    case 'STORY_REQUEST':
      return {
        ...state,
        isRequesting: true,
        hasError: false,
      };
    case 'STORY_SUCCESS':
      return {
        ...state,
        isRequesting: false,
        hasError: false,
      };
    case 'STORY_FAILURE':
      return {
        ...state,
        isRequesting: false,
        hasError: true,
      };
    default:
      return state;
  }
}
