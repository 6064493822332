import addDays from 'date-fns/add_days';
import differenceInDays from 'date-fns/difference_in_days';
import distanceInWordsStrict from 'date-fns/distance_in_words_strict';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import endOfDay from 'date-fns/end_of_day';
import format from 'date-fns/format';
import isFuture from 'date-fns/is_future';
import subYears from 'date-fns/sub_years';
import config from 'config';

const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_MONTH = 30;
const MONTHS_IN_YEAR = 12;

export function calculateEndDate(activatedAt: string, duration: number) {
  return endOfDay(addDays(activatedAt, duration));
}

export function calculateMaxEndDate(activatedAt: string) {
  return endOfDay(addDays(activatedAt, config.MAX_PAGE_LIFE));
}

export function calculateMinEndDate(activatedAt: string) {
  const minEndDate = endOfDay(addDays(activatedAt, 30));
  return isFuture(minEndDate) ? minEndDate : new Date();
}

export function convertDateToUTC(date: Date) {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
}

// https://momentjs.com/docs/#/displaying/fromnow/
export function distanceInWordsToNow(date: string) {
  const seconds = Math.abs(differenceInSeconds(new Date(), date));
  const minutes = Math.round(seconds / SECONDS_IN_MINUTE);
  const hours = Math.round(minutes / MINUTES_IN_HOUR);
  const days = Math.round(hours / HOURS_IN_DAY);
  const months = Math.round(days / DAYS_IN_MONTH);
  const years = Math.round(months / MONTHS_IN_YEAR);

  if (seconds < 45) {
    return 'Just now';
  } else if (seconds >= 45 && seconds < 90) {
    return '1 minute ago';
  } else if (seconds >= 90 && minutes < 45) {
    return `${minutes} mins ago`;
  } else if (minutes >= 45 && minutes < 90) {
    return '1 hour ago';
  } else if (minutes >= 90 && hours < 22) {
    return `${hours} hrs ago`;
  } else if (hours >= 22 && hours < 36) {
    return '1 day ago';
  } else if (hours >= 36 && days < 26) {
    return `${days} days ago`;
  } else if (days >= 26 && days < 45) {
    return '1 month ago';
  } else if (days >= 45 && days < 320) {
    return `${months} months ago`;
  } else if (days >= 320 && days < 548) {
    return '1 year ago';
  } else {
    return `${years} years ago`;
  }
}

export function getConsentDOB(age: number) {
  return format(subYears(new Date(), age), 'YYYY-MM-DD');
}

export function getDaysRemaining(withdrawalAvailableDate: string) {
  return differenceInDays(withdrawalAvailableDate, new Date()) + 1;
}

interface CreatedAt {
  createdAt: string;
}

export function getLastUpdated(
  createdAt: string,
  activatedAt: string,
  supporters: CreatedAt[],
  updates: CreatedAt[]
) {
  if (!supporters.length && !updates.length) {
    return new Date(activatedAt || createdAt);
  }

  const donationsAndUpdatesCreationDates = supporters
    .concat(updates)
    .map(x => Date.parse(x.createdAt));

  const lastUpdated = Math.max.apply(Math, donationsAndUpdatesCreationDates);

  return new Date(lastUpdated);
}

export function getDistanceInWords(numberOfDays: number) {
  const today = new Date();
  const addToToday = addDays(today, numberOfDays);

  return distanceInWordsStrict(addToToday, today);
}

// ensure we are adding calendar days excluding time, important for timezone
// changes
export function addCalendarDays(date: Date, numberOfDays: number) {
  return new Date(
    addDays(new Date(date!).setHours(12), numberOfDays).setHours(23)
  );
}

export interface TimeRemaining {
  daysRemaining: number;
  hoursRemaining: number;
  minutesRemaining: number;
  secondsRemaining: number;
  hasCountdownFinished: boolean;
}

export function countdown(date: Date | string) {
  const countdownDate = new Date(date).getTime();
  const today = new Date().getTime();

  const distance = countdownDate - today;

  const daysRemaining = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hoursRemaining = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesRemaining = Math.floor(
    (distance % (1000 * 60 * 60)) / (1000 * 60)
  );
  const secondsRemaining = Math.floor((distance % (1000 * 60)) / 1000);

  const hasCountdownFinished = distance <= 0;

  return {
    daysRemaining,
    hoursRemaining,
    minutesRemaining,
    secondsRemaining,
    hasCountdownFinished,
  };
}

export function formatDate(date: Date, locale?: string) {
  try {
    return new Intl.DateTimeFormat(locale || 'en-GB').format(date);
  } catch (e) {
    return date;
  }
}
