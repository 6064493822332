import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from 'src/redux/modules';
import {
  shareByWhatsApp,
  shareBySms,
  shareByEmail,
  shareOnTwitter,
  sharePageLink,
  PageState,
  ShareActionOptions,
} from 'src/redux/modules/page';
import SocialShare from 'src/components/SocialShare/SocialShare';
import { isClosed, isActive } from 'src/helpers/page';

interface StateProps {
  isClosed: boolean;
  socialShareUrl: string;
  targetAmount: number;
  page: PageState;
  isActive: boolean;
  pitchFor: string;
  ownerName: string;
  name: string;
  isOwner: boolean;
}

function mapStateToProps(state: AppState): StateProps {
  return {
    isClosed: isClosed(state.page),
    isActive: isActive(state.page),
    socialShareUrl: state.page.socialShareUrl,
    targetAmount: Math.floor(state.page.targetAmount),
    page: state.page,
    pitchFor: state.page.pitchFor,
    ownerName: state.page.ownerName,
    name: state.page.name,
    isOwner: state.page.isOwnerRequested,
  };
}

interface DispatchProps {
  shareOnTwitter: (options: ShareActionOptions) => void;
  shareByEmail: (options: ShareActionOptions) => void;
  shareByWhatsApp: (options: ShareActionOptions) => void;
  shareBySms: (options: ShareActionOptions) => void;
  sharePageLink: (options: ShareActionOptions) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      shareOnTwitter,
      shareByEmail,
      shareByWhatsApp,
      shareBySms,
      sharePageLink,
    },
    dispatch
  );
}

type Props = StateProps & DispatchProps;

class SocialShareContainer extends React.Component<Props> {
  render() {
    if (this.props.isClosed) {
      return null;
    }

    return (
      <SocialShare
        shareOnTwitter={this.props.shareOnTwitter}
        shareByEmail={this.props.shareByEmail}
        shareByWhatsApp={this.props.shareByWhatsApp}
        shareBySms={this.props.shareBySms}
        sharePageLink={this.props.sharePageLink}
        socialShareUrl={this.props.socialShareUrl}
        targetAmount={this.props.targetAmount}
        pitchFor={this.props.pitchFor}
        ownerName={this.props.ownerName}
        name={this.props.name}
        isOwner={this.props.isOwner}
        isActive={this.props.isActive}
        page={this.props.page}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialShareContainer);
