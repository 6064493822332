import React from 'react';
import CSSModules from 'react-css-modules';
import { OwnerAvatar } from 'src/components/Avatar';
import RichUpdatesItemTooltip from './RichUpdatesItemTooltip/';
import RichUpdatesItemMedia from './RichUpdatesItemMedia';
import RichUpdatesItemShareAlert from './RichUpdatesItemShareAlert/';
import RichUpdatesItemShare from './RichUpdatesItemShare/';
import styles from './RichUpdatesItem.scss';

interface Props {
  id: number;
  message: string;
  date: string;
  media?: {
    path: string;
    type: number;
  }[];
  ownerProfileImageUrl?: string;
  ownerName: string;

  isOwner: boolean;

  isLowReachAlertVisible?: boolean;
  watchingVideoToggle: () => void;
  deleteRichUpdate: (updateId: number) => void;
  type?: number;
}

class RichUpdatesItem extends React.Component<Props> {
  displayMedia() {
    const { media, watchingVideoToggle } = this.props;
    if (!media || media.length === 0) {
      return null;
    }
    return (
      <div className={styles['media-container']}>
        <RichUpdatesItemMedia
          type={media[0].type}
          path={media[0].path}
          watchingVideoToggle={watchingVideoToggle}
        />
      </div>
    );
  }

  render() {
    const {
      date,
      deleteRichUpdate,
      id,
      isLowReachAlertVisible,
      isOwner,
      message,
      ownerProfileImageUrl,
      ownerName,
      type,
    } = this.props;

    const updateTypes = ['update', 'thank', 'milestone'];
    const stringType =
      type === undefined ? updateTypes[0] : updateTypes[type - 1];

    return (
      <div>
        <div className={styles.content}>
          <div className={styles['info-container']}>
            <div className="jg-display-flex">
              <div className={`jg-flex-g1 ${styles['avatar-wrapper']}`}>
                <OwnerAvatar
                  src={ownerProfileImageUrl}
                  alt={ownerName}
                  className={styles.avatar}
                />
                <div>
                  <p className={`jg-h5 ${styles['owner-name']}`}>{ownerName}</p>
                  <span className={`jg-text--xsmall-light ${styles.date}`}>
                    {date}
                  </span>
                </div>
              </div>
              {isOwner && (
                <RichUpdatesItemTooltip
                  updateId={id}
                  deleteRichUpdate={deleteRichUpdate}
                />
              )}
            </div>

            <div className="jg-space-ptsm@lg">
              {type !== 1 && this.displayMedia()}

              <p className={styles.message}>{message}</p>

              {type === 1 && this.displayMedia()}
            </div>
          </div>
        </div>
        {isLowReachAlertVisible ? (
          <RichUpdatesItemShareAlert
            updateId={id}
            message={message}
            type={stringType}
          />
        ) : (
          <RichUpdatesItemShare
            updateId={id}
            message={message}
            type={stringType}
          />
        )}
      </div>
    );
  }
}

export default CSSModules(RichUpdatesItem, styles);
