import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  PersonalIdNumberForm,
  SSNValue,
} from 'src/components/AdditionalDetails/';
import { updateStripeAccountDetails } from 'src/redux/modules/stripeVirtualAccount/actions';

interface DispatchProps {
  updateStripeAccountDetails: (values: SSNValue) => void;
}

interface OwnProps {
  onSubmit: () => void;
}

function mapDispatchToProps(
  dispatch: any,
  ownProps: DispatchProps
): DispatchProps {
  return bindActionCreators(
    {
      updateStripeAccountDetails,
    },
    dispatch
  );
}

const PersonalIdNumberContainer = connect(
  null,
  mapDispatchToProps
)(PersonalIdNumberForm);

export default PersonalIdNumberContainer as React.ComponentClass<OwnProps>;
