import {
  createValidator,
  Rules,
  required,
  maxLength,
  minLength,
} from 'src/helpers/validation';
import { RecurringDonationsPitchFormData } from 'src/components/RecurringDonations/RecurringDonationsPitchForm';

const rules: Rules<RecurringDonationsPitchFormData> = {
  recurringDonationsPitch: [
    required({ message: 'Please include a message for your supporters' }),
    maxLength(500),
    minLength(2),
  ],
};

export default createValidator<RecurringDonationsPitchFormData>(rules);
