import React from 'react';

export interface CollapseHeaderProps {
  togglePanel: () => void;
  isActive: boolean;
}

class CollapseHeader extends React.Component<
  CollapseHeaderProps & {
    children: (props: CollapseHeaderProps) => JSX.Element;
  }
> {
  render() {
    const { children, ...props } = this.props;
    return <div>{children({ ...props })}</div>;
  }
}

export default CollapseHeader as React.ComponentClass<{}>;
