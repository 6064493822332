import { AnalyticsMeta } from 'src/redux/modules/analytics';
import { OnSlideOptions } from 'src/components/Carousel/Carousel';

interface SlideCarouselAction {
  type: 'SLIDE_CAROUSEL';
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function slideCarousel({
  location,
  event,
}: OnSlideOptions): SlideCarouselAction {
  // default to system
  let analytics = {
    event: 'system',
    subtype: 'auto',
    event_value: `carousel slide`,
    page_section: location,
    activity_type: 'crowdfunding_guid',
  };

  // if there is an event & element attached
  if (event && event.currentTarget) {
    const value = (event.currentTarget as HTMLElement).getAttribute(
      'data-jqa-event-value'
    );

    analytics = {
      ...analytics,
      event: 'click',
      subtype: 'button',
      event_value: value || '',
    };
  }

  return {
    type: 'SLIDE_CAROUSEL',
    meta: {
      analytics,
    },
  };
}

export default function reducer(state = {}, action: SlideCarouselAction) {
  switch (action.type) {
    case 'SLIDE_CAROUSEL':
    // fallthrough
    default:
      return state;
  }
}
