import { push } from 'react-router-redux';
import { AppState } from 'src/redux/modules';
import { Dispatch } from 'redux';
import { AnalyticsMeta } from 'src/redux/modules/analytics';

const modeMapping = ['page view', 'edit', 'page settings'];

export interface ChangeModeAction {
  type: 'CHANGE_MODE';
  mode: number;
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function changeMode(mode: number): ChangeModeAction {
  return {
    type: 'CHANGE_MODE',
    mode,
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: modeMapping[mode],
        page_section: `admin page > ${modeMapping[mode]}`,
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export type ScrollToLocation = 'location' | 'category';

export interface ScrollToAction {
  type: 'SCROLL_TO';
  name: ScrollToLocation;
  meta: {
    analytics: AnalyticsMeta;
  };
}

export function scrollTo(name: ScrollToLocation): ScrollToAction {
  return {
    type: 'SCROLL_TO',
    name,
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: `add project ${name}`,
        page_section: `admin page > add project ${name}`,
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export interface ResetScrollAction {
  type: 'RESET_SCROLL';
}

export function resetScroll(): ResetScrollAction {
  return {
    type: 'RESET_SCROLL',
  };
}

export function goEdit(scrollToElement: ScrollToLocation) {
  return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const state = getState();

    dispatch(push(`/${state.page.name}/edit`));
    if (scrollToElement) {
      dispatch(scrollTo(scrollToElement));
    }
  };
}

export function createPageLikeThis() {
  return {
    type: 'CREATE_PAGE_LIKE_THIS_REQUEST',
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: 'create your page',
        page_section: 'page > page banner > inspired',
        version_id: 'active',
        version_type: 'banner type',
        activity_type: 'crowdfunding_guid',
        content_type: 'crowdfunding_guid',
      },
    },
  };
}

export function setUpBankDetails() {
  return (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    const state = getState();
    dispatch({
      type: 'SET_UP_BANK_DETAILS',
      meta: {
        analytics: {
          event: 'click',
          subtype: 'button',
          event_value: 'set up funds',
          page_section: 'page creation > set up funds',
          activity_type: 'crowdfunding_guid',
        },
      },
    });

    push(`/${state.page.name}/payment`);
  };
}

export function tipsPromptClosed() {
  return {
    type: 'TIPS_PROMPT_CLOSED',
    meta: {
      analytics: {
        event: 'click',
        subtype: 'button',
        event_value: 'x',
        page_section: 'page > tip prompt',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}

export function viewProTips() {
  return {
    type: 'VIEW_PRO_TIPS',
    meta: {
      analytics: {
        event: 'link click',
        subtype: 'button',
        event_value: 'go to pro tips',
        page_section: 'page > tip prompt',
        activity_type: 'crowdfunding_guid',
      },
    },
  };
}
