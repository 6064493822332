import * as common from './common';

export interface AnonymousUserResponse {
  country: string;
  isoCode: string;
}

export interface LoggedInUserResponse extends AnonymousUserResponse {
  id: string;
  profileImage: string;
  title: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  town: string;
  postCode: string;
  phoneNumber: string;
  street: string;
  subStreet: string;
  buildingName: string;
  buildingNumber: string;
  flatNumber: string;
}

export type UserResponse = AnonymousUserResponse | LoggedInUserResponse;

export function isLoggedIn(user: UserResponse): user is LoggedInUserResponse {
  return (user as LoggedInUserResponse).id !== undefined;
}

export function get() {
  return common.get<UserResponse>('self', { timingName: 'self' });
}
